import getIsDevToolsEnabled from '@/services/DevTools/getIsDevToolsEnabled';
import {
  KEY_DEV_GLOBAL_MESSAGE_PROVIDER,
  KEY_DEV_MESSAGE_PROVIDER,
  SessionStorage,
} from '@/services/Storage';
import * as Wansu from '@/services/Wansu';

import * as Ably from '@17live/app/services/Ably/Ably';
import { MessageProviders } from '@17live/app/services/Message/Message.enums';
import * as PubNub from '@17live/app/services/PubNub/PubNub';

const messageProviderMap = {
  [MessageProviders.ABLY]: Ably,
  [MessageProviders.PUBNUB]: PubNub,
  [MessageProviders.WANSU]: Wansu,
};

class Message {
  messageProvider = MessageProviders.ABLY;
  globalMessageProvider = MessageProviders.ABLY;

  setMessageProvider = provider => {
    if (provider in MessageProviders) {
      this.messageProvider = provider;
    }

    return this;
  };

  getMessageProvider = () => {
    const { provider = this.messageProvider } =
      SessionStorage.getItem(KEY_DEV_MESSAGE_PROVIDER) || {};
    const providerType = getIsDevToolsEnabled()
      ? provider
      : this.messageProvider;

    return messageProviderMap[providerType];
  };

  setGlobalMessageProvider = provider => {
    if (provider in MessageProviders) {
      this.globalMessageProvider = provider;
    }

    return this;
  };

  getGlobalMessageProvider = () => {
    const { provider = this.globalMessageProvider } =
      SessionStorage.getItem(KEY_DEV_GLOBAL_MESSAGE_PROVIDER) || {};
    const globalProviderType = getIsDevToolsEnabled()
      ? provider
      : this.globalMessageProvider;

    return messageProviderMap[globalProviderType];
  };

  subscribeChatRoom = roomID => {
    return this.getMessageProvider().subscribeChatRoom(roomID);
  };

  unsubscribeChatRoom = roomID => {
    return this.getMessageProvider().unsubscribeChatRoom(roomID);
  };

  subscribeGlobalLiveProgram = () => {
    return this.getGlobalMessageProvider().subscribeGlobalLiveProgram();
  };

  unsubscribeGlobalLiveProgram = () => {
    return this.getGlobalMessageProvider().unsubscribeGlobalLiveProgram();
  };

  subscribeGlobalAnnouncementChannel = () => {
    return this.getGlobalMessageProvider().subscribeGlobalAnnouncementChannel();
  };

  unsubscribeGlobalAnnouncementChannel = () => {
    return this.getGlobalMessageProvider().unsubscribeGlobalAnnouncementChannel();
  };

  subscribeGlobalChannel = () => {
    return this.getGlobalMessageProvider().subscribeGlobalChannel();
  };

  unsubscribeGlobalChannel = () => {
    return this.getGlobalMessageProvider().unsubscribeGlobalChannel();
  };
}

export default new Message();
