// @flow
import type { StorageInterface } from './types';
import MemoryStorage from './MemoryStorage';

class Storage {
  storage: StorageInterface;

  static isSupported(storageName: string): boolean {
    try {
      return typeof window !== 'undefined' && !!window[storageName];
    } catch (e) {
      return false;
    }
  }

  constructor(storageName: string) {
    this.storage = Storage.isSupported(storageName)
      ? window[storageName]
      : new MemoryStorage();
  }

  getItem(key: string) {
    try {
      return JSON.parse(this.storage.getItem(key));
    } catch (e) {
      return null;
    }
  }

  setItem(key: string, value: any) {
    try {
      this.storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // do nothing for QuotaExceededError and etc
    }
  }

  updateItem(key: string, value: any) {
    this.setItem(key, {
      ...(this.getItem(key) || {}),
      ...value,
    });
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }
}

export default Storage;
