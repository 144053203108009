/* eslint-disable no-underscore-dangle */

import uuid from 'uuid-v4';

import { isMobile } from '@/services/Device';
import { trackEvent as gaTrackEvent } from '@/services/GoogleAnalytics';
import { trackEvent as matomoTrackEvent } from '@/services/MatomoAnalytics';
import {
  KEY_LOGIN_INFO,
  KEY_TRACKING,
  LocalStorage,
  SessionStorage,
} from '@/services/Storage';

import { getFingerprintVisitor } from '@17live/app/services/fingerprint';
import { getPathWithoutLang } from '@17live/app/utils';

import {
  customDimensionMap,
  genericType,
  sourceURLMapEntries,
  userType,
} from './constants';
import {
  ComponentType,
  EventAction,
  EventCategory,
  EventLabel,
  SourceURL,
} from './types';

type TrackingSession = Partial<{
  contentID: string;
  contentType: 'clip' | 'livestream' | 'profile' | 'show';
  liveContentID: string;
  liveContentType: 'show' | 'livestream';
  profileContentID: string;
}>;

export const getTrackingSession = (): TrackingSession =>
  SessionStorage.getItem(KEY_TRACKING) ?? {};

export const setTrackingSession = (props: TrackingSession) => {
  SessionStorage.setItem(KEY_TRACKING, {
    ...getTrackingSession(),
    ...props,
  });
};

const trackingSessionWhitelists = [
  // TODO: 之後有時間驗證的話再把 live 的規則也搬進來
  /^\/profile\/.+\/(clip)/,
  /^\/clips\/featured/,
  /^\/clips\/following/,
  /^\/profile/,
];

type CustomDimensionMap = typeof customDimensionMap;

type GoogleAnalyticsArgs = Partial<{
  nonInteraction: boolean;
  transport: boolean;
}>;

export type CustomDimensions = Partial<{
  sourceURL: SourceURL;
  contentID: string;
  contentType: 'livestream' | 'profile' | 'show' | 'clip';
  componentID: string | number;
  componentType: ComponentType;

  // 指 LogSpec Type，但一般使用 trackEvent BE 會自動轉 Action；trackPageView 自動轉 Page。目前只有 Event 需要額外帶
  // @see https://17media.slack.com/archives/C04D5MBDSCV/p1693969345824229?thread_ts=1691994318.713399&cid=C04D5MBDSCV
  actionType: 'Action' | 'Page' | 'Event';
}>;

export const getSourceURLByLocation = () => {
  const trimLangPath = getPathWithoutLang();

  return sourceURLMapEntries.find(([urlPath]) =>
    new RegExp(urlPath).test(trimLangPath)
  )?.[1];
};

export const getCommonDimensions = () => {
  const defaultDimension = {
    timestamp: String(Math.round(new Date().getTime() / 1000)),
    user: LocalStorage.getItem(KEY_LOGIN_INFO) ? userType.USER : userType.GUEST,
    deviceID: getFingerprintVisitor().visitorId,
    genericText: isMobile() ? genericType.MOBILE : genericType.WEB,
    // webpack DefinePlugin injected
    appVersion: process.env.VERSION,
    traceID: uuid(),
    sourceURL: getSourceURLByLocation(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const pathWithoutLang = getPathWithoutLang();

  // TODO: 之後這邊希望可以 migrate 到 trackingSessionWhitelists
  if (pathWithoutLang.startsWith('/live/')) {
    const { liveContentID, liveContentType } = getTrackingSession();
    Object.assign(defaultDimension, {
      contentID: liveContentID,
      contentType: liveContentType,
    });
  }
  trackingSessionWhitelists.forEach(path => {
    if (path.test(pathWithoutLang)) {
      const { contentID, contentType } = getTrackingSession();

      Object.assign(defaultDimension, {
        contentID,
        contentType,
      });
    }
  });

  return defaultDimension;
};

export const mapDimensionKeyToID = (customDimensions: CustomDimensionMap) => {
  const dimensions = Object.entries(customDimensions).reduce((o, [k, v]) => {
    if (k in customDimensionMap) {
      const dimensionID = customDimensionMap[k as keyof CustomDimensionMap];

      return {
        ...o,
        [dimensionID]: v,
      };
    }

    return o;
  }, {});

  return dimensions;
};

export const setTrackEventAttrs = (
  category: EventCategory,
  action: EventAction,
  name: EventLabel,
  args: GoogleAnalyticsArgs & CustomDimensions = {}
) => {
  return {
    'data-track-category': category,
    'data-track-action': action,
    'data-track-name': name,
    'data-track-args': JSON.stringify(args),
  };
};

export const trackEvent = (
  category: EventCategory,
  action: EventAction,
  label: EventLabel,
  args: GoogleAnalyticsArgs & CustomDimensions = {}
) => {
  const { nonInteraction, transport, ...dimensionArgs } = args;

  // nonInteraction 參數在 GA4 中改為 non_interaction
  // transport 參數在 GA4 中已棄用
  const gaArgs = {
    non_interaction: nonInteraction,
    transport,
  };

  const dimension = {
    ...getCommonDimensions(),
    ...dimensionArgs,
  } as typeof customDimensionMap;

  const eventPayload = {
    category,
    action,
    label,
    dimension,
  };

  gaTrackEvent(category, action, label, gaArgs);
  matomoTrackEvent(
    category,
    action,
    label,
    null,
    mapDimensionKeyToID(dimension)
  );

  if (process.env.NODE_ENV === 'development') {
    console.group(`📝[trackEvent] at ${new Date()}`);
    console.info(JSON.stringify(eventPayload, null, 2));
    console.groupEnd();
  }

  return {
    traceID: dimension.traceID,
    attrs: setTrackEventAttrs(category, action, label, args),
  };
};

export const getEventCategory = (category: EventCategory) => category;

export const getEventName = (name: EventLabel) => name;

export type APIHeaders = Partial<{
  'trace-id': string;
}>;

export const setTrackAPIHeaders = (headers: APIHeaders) => {
  return headers;
};
