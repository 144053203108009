import { SIZE_LARGE, SIZE_MEDIUM } from '@/constants/sizes';

export const VIDEO_STATE = Object.freeze({
  PIP: 'VIDEO_STATE_PIP',
  CHANNEL: 'VIDEO_STATE_CHANNEL',
  THEATER: 'VIDEO_STATE_THEATER',
  FULLSCREEN: 'VIDEO_STATE_FULLSCREEN',
  PIP_OPENED: 'VIDEO_STATE_PIP_OPENED',
  PIP_CLOSED: 'VIDEO_STATE_PIP_CLOSED',
  ENDED: 'VIDEO_STATE_ENDED',
});

export const LIVE_BADGE_POSITION = {
  [SIZE_MEDIUM]: {
    top: 12,
    left: 12,
    right: 12,
  },
  [SIZE_LARGE]: {
    top: 16,
    left: 16,
    right: 16,
  },
};

export const THEATER_INFO_HEIGHT: number = 100; // px
export const THEATER_INFO_PADDING: number = 20; // px

// According to the UI spec, the position of the Group Call stream is fixed at a distance of 74.5 px from the top
export const GROUP_CALL_STREAM_DISTANCE_ABOVE: number = 74.5; // px

export const VIDEO_CHECK_INTERVAL: number = 5000; // 5 seconds
export const VIDEO_MUTED: string = 'video_muted';
export const VIDEO_PAUSED: string = 'video_paused';
export const VIDEO_DEFAULT: string = 'video_default';
export const VIDEO_AUTOPLAY_POLICY_SAFARI: string =
  'The operation was aborted.';
export const VIDEO_AUTOPLAY_POLICY_CHROME: string =
  "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD";

export const VIDEO_TYPES = Object.freeze({
  FLV: 'flv',
  M3U8: 'm3u8',
  MP4: 'mp4',
  WEBRTC: 'webrtc',
});

export const FOOTER_SHARE_BUTTON_SIZE: number = 30;

export const M3U8_MIME_TYPE: string = 'application/vnd.apple.mpegurl';

export const NOT_SUPPORTED_ERROR: Error = new Error('NOT_SUPPORTED');
/**
 * -1 is a value which should be out of range of HTMLMediaElement.currentTime
 * for preventing case like comparing new video's first currentTime 0 when the lastTime is 0
 */
export const VIDEO_DEFAULT_LAST_TIME: number = -1;

export const MAX_APP_VIDEO_RATIO = 0.75;
export const DEFAULT_VIDEO_RATIO = 0.564;

export const STREAM_DEVICE_TYPE = Object.freeze({
  OBS: 'OBS',
});

/**
 * API response KEY
 */
export const LIVE17_WEBRTC_WEIGHT = '17splus_webrtc_weight';
