import { useEffect, useState } from 'react';

import { getPathWithoutLang } from '@17live/app/utils/getLang';

export const getRoomIDFromUrl = () => {
  return getPathWithoutLang().match(/^\/live\/(\d+)/)?.[1];
};

export const useRoomID = () => {
  const [roomID, setRoomID] = useState(getRoomIDFromUrl());

  useEffect(() => {
    setRoomID(getRoomIDFromUrl());
  }, [location.pathname, location.search, location.hash]);

  return roomID;
};

export default useRoomID;
