// @flow
import { Component, type Node } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  isMounted: boolean,
  rootElement: ?HTMLElement,
  children: Node,
};

class Portal extends Component<Props> {
  static defaultProps = {
    rootElement: document.body,
  };

  componentDidUpdate() {
    if (!this.props.isMounted) {
      this.tearDown();
    }
  }

  componentWillUnmount() {
    this.tearDown();
  }

  getContainer = () => {
    if (!this.props.isMounted) {
      return null;
    }

    if (this.container) {
      return this.container;
    }

    this.container = document.createElement('div');

    if (this.props.rootElement) {
      // FIXME: workaround for PROD-4584 clip fullscreen
      (
        document.querySelector('#overVideoModalPortal') ??
        this.props.rootElement
      ).appendChild(this.container);
    }

    return this.container;
  };

  tearDown = () => {
    if (this.props.rootElement && this.container) {
      // FIXME: workaround for PROD-4584 clip fullscreen
      /**
       * try catch for removeChild
       * https://developer.mozilla.org/zh-TW/docs/Web/API/Node/removeChild
       */
      try {
        if (document.querySelector('#overVideoModalPortal')?.hasChildNodes()) {
          document
            .querySelector('#overVideoModalPortal')
            .removeChild(this.container);
        } else {
          this.props.rootElement.removeChild(this.container);
        }
      } catch (error) {
        console.log(error);
      }

      this.container = null;
    }
  };

  container: ?HTMLElement = null;

  render() {
    const container = this.getContainer();

    return container && createPortal(this.props.children, container);
  }
}

export default Portal;
