import {
  CDN_URL,
  DEFAULT_AVATAR_IMAGE,
  DEFAULT_EMPTY_BANNER,
  DEFAULT_EMPTY_IMAGE,
  GCP_URL,
  HTTP_PREFIX,
  HTTPS_PREFIX,
  THUMBNAIL_PREFIX,
} from '@/constants/services';

export const getDefaultImage = () => `${GCP_URL}/${DEFAULT_EMPTY_IMAGE}`;

export const defaultEmptyBannerPath = `${GCP_URL}/${DEFAULT_EMPTY_BANNER}`;

const defaultAvatarImagePath = `${GCP_URL}/${DEFAULT_AVATAR_IMAGE}`;

const getPicture = (picture, options = {}) => {
  const {
    shouldUseDefault = true,
    isThumbnail = true,
    isAvatar = true,
  } = options;

  if (picture) {
    return picture.indexOf(HTTP_PREFIX) === 0 ||
      picture.indexOf(HTTPS_PREFIX) === 0
      ? picture
      : `${CDN_URL}/${isThumbnail ? THUMBNAIL_PREFIX : ''}${picture}`;
  }

  if (shouldUseDefault) {
    if (isAvatar) {
      return defaultAvatarImagePath;
    }
    return getDefaultImage();
  }

  return '';
};

export default getPicture;
