/*
 *
 * Login actions
 *
 */
import {
  GET_BALANCES,
  GET_FOLLOW_STATUS,
  LOGIN,
  LOGIN_ADDITIONAL,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_THEN,
  LOGOUT,
  LOGOUT_SUCCESS,
  REGISTER_THEN,
  SET_BALANCES,
  SET_FOLLOWING,
  SET_FOLLOWING_STATUS,
  SET_PROGRAM_INFO,
  UPDATE_LOGIN_STATE,
} from './constants';

export const login = ({ openID, password }) => ({
  type: LOGIN,
  payload: { openID, password },
});

export const updateLoginState = payload => ({
  type: UPDATE_LOGIN_STATE,
  payload,
});

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginAdditional = payload => ({
  type: LOGIN_ADDITIONAL,
  payload,
});

export const loginFailed = (error, type) => ({
  type: LOGIN_FAILED,
  error: true,
  payload: {
    type,
    error,
  },
});

export const logout = payload => ({
  type: LOGOUT,
  payload,
});

export const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const setFollowing = following => ({
  type: SET_FOLLOWING,
  payload: following,
});

export const setFollowingStatus = (userID, status) => ({
  type: SET_FOLLOWING_STATUS,
  payload: {
    userID,
    status,
  },
});

export const getFollowStatus = targetUserIDs => ({
  type: GET_FOLLOW_STATUS,
  payload: {
    targetUserIDs,
  },
});

export const getBalances = () => ({
  type: GET_BALANCES,
});

export const setBalances = balances => ({
  type: SET_BALANCES,
  payload: balances,
});

export const setProgramInfo = payload => ({
  type: SET_PROGRAM_INFO,
  payload,
});

export const loginThen = (
  action,
  { shouldContinue = true, additionalOptions } = {}
) => ({
  type: LOGIN_THEN,
  payload: {
    action,
    shouldContinue,
    additionalOptions,
  },
});

export const registerThen = (
  action,
  { shouldContinue = true, additionalOptions } = {}
) => ({
  type: REGISTER_THEN,
  payload: {
    action,
    shouldContinue,
    additionalOptions,
  },
});
