import { from } from 'rxjs';

import { LIVE_STREAM_STATUS } from '@/services/Video/constants';

import { RegionType } from '@17live/app/constants';
import { getIPRegion } from '@17live/app/containers/LanguageProvider/utils';
import { RtmpProviderValue } from '@17live/app/containers/LiveStream/enums';
import { BadgeInfo } from '@17live/app/containers/LoginProvider/types';
import { getUseFetchConfigQueryKey } from '@17live/app/hooks/apis/useFetchConfig.utils';
import { Live17WebrtcWeight } from '@17live/app/types/Config';
import { LiveStream } from '@17live/app/types/LiveStreams';
import { RTMPUrl } from '@17live/app/types/RTMPUrls';
import { queryClient } from '@17live/app/utils';
import { fetchAPI } from '@17live/app/utils/api';
import { randomByWeight } from '@17live/app/utils/rtmpUrls';

import { STREAM_MODE } from './constants';

export const getStreamMode = (badgeInfo: BadgeInfo) => {
  if (!badgeInfo || badgeInfo.size === 0) {
    return STREAM_MODE.NONE;
  }

  if (badgeInfo.some(badge => badge.get('badgeID') === STREAM_MODE.PROGRAM)) {
    return STREAM_MODE.PROGRAM;
  }

  return STREAM_MODE.NORMAL;
};

/**
 * To avoid duplicately calling config api in live stream epic,
 * use React query to help us to cache the apis
 */
export const getConfigWithReactQuery = (
  region: RegionType,
  loginUserID: string,
  appRegion: RegionType
) => {
  const headers = {};

  if (!loginUserID) Object.assign(headers, { 'force-region': region });

  return from(
    queryClient.fetchQuery(
      getUseFetchConfigQueryKey({
        region: !loginUserID ? region : appRegion,
        userID: loginUserID,
      }),
      () =>
        fetchAPI('config', {
          headers,
        })
    )
  );
};

export const checkStreamIsOnline = (stream: LiveStream) => {
  return (
    stream.isOnline ||
    stream.status === LIVE_STREAM_STATUS.PUBLISHED ||
    // The reason that add this key is because the response
    // of API "/followees" doesn't incloud "isOnline" and "status"
    // so need others key to check the stream is online or not
    !!stream?.onliveInfo?.liveStreamID
  );
};
