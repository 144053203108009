import { Observable, Subject } from 'rxjs';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map } from 'rxjs/operators';

import { isBlockResponse, openCaptchaModal } from './abr';

const pxAjax = (
  urlOrRequest: string | AjaxRequest
): Observable<AjaxResponse> => {
  return ajax(urlOrRequest).pipe(
    catchError(err => {
      if (err.status === 403 && err.response && isBlockResponse(err.response)) {
        // 透過 Subject 來持續接收來自 CaptchaModal 的 callback
        const captchaModalCallback$ = new Subject();

        // 開啟 CaptchaModal 並且透過 Callback 接收驗證結果
        // isValid = true 代表驗證成功且 CaptchaModal 會關閉
        // isValid = false 代表驗證失敗，CaptchaModal 會繼續開著並且提示使用者繼續驗證
        openCaptchaModal(err.response, (isValid: boolean) =>
          captchaModalCallback$.next(isValid)
        );

        // 驗證 API response 內容是否符合 blocked response 的特徵
        return captchaModalCallback$.pipe(
          // 當 isValid = true 時才需要 retry API
          filter(isValid => !!isValid),
          map(() => {
            throw new Error('PX_BLOCK_RESPONSE');
          })
        );
      }

      throw err;
    })
  );
};

export default pxAjax;
