import isArray from 'lodash/isArray';
import range from 'lodash/range';

const cacheStore = new Map();

const imageOnLoad = (
  src: string | string[],
  timeout: number = 5000
): Promise<any> => {
  const handleOnLoad = (image: HTMLImageElement) =>
    new Promise(resolve => {
      // handle timeouts of request default to 5 seconds
      const timer = setTimeout(resolve, timeout);

      const onLoad = () => {
        clearTimeout(timer);
        cacheStore.set(image.src, image);
        resolve(image);
      };

      const onError = () => {
        clearTimeout(timer);
        image.setAttribute('error', 'true');
        resolve(image); // we don't want some error to block whole list
      };

      image.onload = onLoad;
      image.onerror = onError;
    });

  if (isArray(src) && src.length) {
    // array of src
    const images = range(src.length).map(i => {
      if (cacheStore.has(src[i])) {
        return Promise.resolve(cacheStore.get(src[i]));
      }

      const img = new Image();
      img.src = src[i];

      return handleOnLoad(img);
    });

    // wait for all to be loaded
    return Promise.all(images);
  } else if (typeof src === 'string') {
    // single src of string
    if (cacheStore.has(src)) {
      return Promise.resolve(cacheStore.get(src));
    }

    const image = new Image();
    image.src = src;

    return handleOnLoad(image);
  }

  // other type of src just return
  return Promise.resolve();
};

export default imageOnLoad;
