// @flow
import { Map } from 'immutable';

import type { VideoInfo } from './types';

export const LOCAL_KEYS = Object.freeze({
  VIEWING_STATUS: 'viewingStatus',
});

export const VIDEO_TYPES = Object.freeze({
  NONE: 0,
  STREAM: 1,
  VOD: 2,
});

export const LIVE_STREAM_STATUS = Object.freeze({
  CLOSED: 0,
  CREATED: 1,
  PUBLISHED: 2,
});

export const LIVE_STREAM_TYPE = Object.freeze({
  NORMAL: 0,
  PROGRAM: 1,
  REPLAY: 2,
  CONCERT: 3,
});

export const VIEWING_STATUS = Object.freeze({
  NONE: 0,
  AVAILABLE: 1,
  FAILED: 2,
  ENDED: 3,
  BLOCKED: 4,
  NOT_APPROVED: 5,
});

// $FlowFixMe
export const DEFAULT_VIDEO_INFO: VideoInfo = {
  url: '',
  picture: '',
  title: '',
  openID: '',
  duration: 0,
  isLandscape: false,
  isOnline: false,
  userInfo: Map({
    openID: '',
    picture: '',
  }),

  // 17live custom attrs
  videoType: VIDEO_TYPES.NONE,
  viewingStatus: VIEWING_STATUS.NONE,
  isLiveStream: false,
  isBlocked: false,
  isLoaded: false,
};

export const VOD_PLAY_HLS_DURATION_THRESHOLD = 30 * 60; // 30 mins in seconds
