// @flow

/*
 * dimensions
 */
export const DIMENSIONS = {
  USER_TYPE: 'dimension1', // member, guest
  VOLUME: 'dimension2', // enable, disable
};

/**
 * Hit Types
 */
export const HIT_TYPES: { [string]: string } = {
  PAGEVIEW: 'pageview',
  SCREENVIEW: 'screenview',
  EVENT: 'event',
  TRANSACTION: 'transaction',
  ITEM: 'item',
  SOCIAL: 'social',
  EXCEPTION: 'exception',
  TIMING: 'timing',
};

/**
 * Purchase Event
 */
type ecSteps =
  | 'ec:addImpression'
  | 'ec:addProduct'
  | 'ec:setAction'
  | 'ec:addPurchase'
  | 'ec:checkout'
  | 'ec:purchase';
type ecActions = 'add' | 'detail' | 'checkout' | 'purchase';
export const EC_ADD_IMPRESSION: ecSteps = 'ec:addImpression';
export const EC_ADD_PRODUCT: ecSteps = 'ec:addProduct';
export const EC_SET_ACTION: ecSteps = 'ec:setAction';
export const EC_ADD_PURCHASE: ecSteps = 'ec:addPurchase';
export const EC_CHECKOUT: ecSteps = 'ec:checkout';
export const EC_PURCHASE: ecSteps = 'ec:purchase';
export const EC_ACTIONS: { [ecActions]: string } = {
  add: 'add',
  detail: 'detail',
  checkout: 'checkout',
  purchase: 'purchase',
};

/**
 * event categories
 */
export const EVENT_CATEGORIES = Object.freeze({
  REGISTER: 'registration',
  UI: 'UI',
  TV: '17Video',
  EC: 'EC',
  LIVE_OFFLINE: 'Liveroom_offline',
  LIVE_ONLINE: 'Liveroom_online',
});

/**
 * event actions
 */
export const EVENT_ACTIONS = Object.freeze({
  // REGISTER
  REGISTER: 'registration_complete',

  // UI
  BUTTON_CLICK: 'button_click',

  // TV
  AUTO_PLAY: 'auto_play',
  FEATURE_VOD_IMPRESSION: 'feature_vod_impression',
  FEATURE_VOD_CLICK: 'feature_vod_click',
  LONG_VIEW: 'long_view',

  // EC
  [EC_ADD_IMPRESSION]: 'product_point_impressions',
  [EC_ADD_PRODUCT]: 'click_product_point',
  [EC_ADD_PURCHASE]: 'third_party_purchase',
  [EC_CHECKOUT]: 'checkout',
  [EC_PURCHASE]: 'purchase',
});

/**
 * event labels
 */
export const EVENT_LABELS = Object.freeze({
  DOWNLOAD_APP_MOBILE_SITE: 'download_app_msite',
  NAV_REGISTER: 'header_register',
  NAV_LOGIN: 'header_login',
  REGISTER_AND_CHAT: 'chat_and_login',
  GIFT_BOX_MORE: 'gift_more',
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
  SHARE: 'share',
  SHARE_FB: 'share_fb',
  SHARE_LINE: 'share_line',
  SHARE_COPY_LINK: 'share_copylink',
  /**
   * Should suffix with a ranking indicating the order of the clicked area, (start from 0)
   * e.g. liveroom_end_recommendation_0, liveroom_end_recommendation_1, etc.
   */
  LIVE_END_RECOMMEND: 'liveroom_end_recommendation_',
  LIVE_HOT_RECOMMEND: 'liveroom_hot_recommendation_',

  LIVE_END_REFER_INDEX: 'liveroom_end_refer_index',
});

/**
 * content groups
 */
export const CONTENT_GROUPS = Object.freeze({
  FEATURE: 'contentGroup1',
  TV: 'contentGroup2',
});

export const CONTENT_GROUP_FEATURES = Object.freeze({
  HOME: 'homepage',
  LIVE: 'LIVE',
  LIVE_ONLINE: 'live_online',
  LIVE_OFFLINE: 'live_offline',
  PROFILE: 'profile',
  POST: 'post',
  TV_HOME: '17tv_homepage',
  TV_LIVE_ONLINE: '17tv_online',
  TV_LIVE_OFFLINE: '17tv_offline',
  TV_PROFILE: '17tv_profile',
  TV_POST: '17tv_post',
  TV_VIDEO: '17tv_video',
  POINT: 'point',
  PURCHASE: 'purchase',
  LEADERBOARD: 'leaderboard',
  SUGGESTED: 'suggested',
  MUSIC: 'music',
  LATEST: 'latest',
  FOLLOWING: 'following',
  RECOMMENDED: 'recommended',
  GAMING: 'gaming',
  VIP: 'vip',
  MALE: 'male_tab',
  FEMALE: 'female_tab',
  TOPIC: 'topictab',
  LIVE_PLUS: '17liveplus_tab',
});

export const MATOMO_VERSIONS = {
  matomo: 'v1',
  kafka: 'v2',
};

export const MATOMO_STA_ENDPOINTS = {
  [MATOMO_VERSIONS.matomo]: 'https://stag-matomo.17app.dev/',
  [MATOMO_VERSIONS.kafka]: 'https://api-ambassador.17app.dev/',
};

export const MATOMO_PROD_ENDPOINTS = {
  [MATOMO_VERSIONS.matomo]: 'https://matomo.17app.dev/',
  [MATOMO_VERSIONS.kafka]: 'https://api-ambassador.17app.co/',
};
