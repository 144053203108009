/**
 *
 * ErrorBlock
 *
 * @flow
 */

import type { Node } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import Button from '@/components/Button';

import Image from './Image';
import { refresh, somethingWrong } from './messages';

type Props = {
  handleRefresh?: () => void,
  message?: Node,
  buttonMessage?: Node,
  shouldShowImage?: boolean,
  shouldShowBg?: boolean,
};

const ErrorBlockWrapper = styled.div`
  color: ${props => props.theme.BD_LIGHTEN_BLACK};
  text-align: center;
  font-size: 14px;
  padding: 15px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

const ErrorBlock = ({
  handleRefresh,
  message,
  shouldShowImage = false,
  shouldShowBg = true,
  buttonMessage,
  ...props
}: Props) => (
  <ErrorBlockWrapper {...props}>
    {shouldShowImage && <Image shouldShowBg={shouldShowBg} />}

    {// we don't do this in props default parameter to bypass a limitation of flow type detection
    typeof message === 'undefined' ? (
      <FormattedMessage {...somethingWrong} />
    ) : (
      message
    )}

    {handleRefresh ? (
      <ButtonWrapper>
        <Button onClick={handleRefresh}>
          {// same here
          typeof buttonMessage === 'undefined' ? (
            <FormattedMessage {...refresh} />
          ) : (
            buttonMessage
          )}
        </Button>
      </ButtonWrapper>
    ) : null}
  </ErrorBlockWrapper>
);

export default ErrorBlock;
