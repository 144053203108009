// @flow
import { MODAL_LOGIN, MODAL_REGISTER } from '@/containers/Modal/constants';

import { ReactionTypes } from '@17live/app/services/Message/Message.enums';

import type { ThirdPartyType } from './types';

/**
 * actions
 */
export const AUTH_WITH_THIRD_PARTY: string =
  'app/ThirdPartyAction/AUTH_WITH_THIRD_PARTY';
export const LOGIN_WITH_THIRD_PARTY: string =
  'app/ThirdPartyAction/LOGIN_WITH_THIRD_PARTY';
export const REGISTER_WITH_THIRD_PARTY: string =
  'app/ThirdPartyAction/REGISTER_WITH_THIRD_PARTY';
export const DO_THIRD_PARTY_REGISTER: string =
  'app/ThirdPartyAction/DO_THIRD_PARTY_REGISTER';
export const SET_AUTH_PROCESSING_STATUS =
  'app/ThirdPartyAction/SET_AUTH_PROCESSING_STATUS';
export const AUTH_WITH_17: string = 'app/ThirdPartyAction/AUTH_WITH_17';

/**
 * Auth constants
 */
export const AUTH_WINDOW_WIDTH: number = 725;
export const AUTH_WINDOW_HEIGHT: number = 610;
export const AUTH_WINDOW_NAME: string = 'AUTH_POPUP_WINDOW';
export const AUTH_WINDOW_CLOSED: string = 'auth_window_closed';

export const GOOGLE_SDK_POPUP_CLOSED_BY_USER_ERROR: string =
  'popup_closed_by_user';
export const FACEBOOK_LOGIN_CANCELLED_ERROR: string =
  'Facebook login cancelled';

/**
 * Auth URL
 */
export const TWITTER_AUTH_URL: string = '/api/twitter-auth';
export const LINE_AUTH_URL: string = '/api/line/authorize';

/**
 * mapping
 *
 * 如果要新增項目記得 packages/app/types/ThirdParty.ts 中的 ThirdPartyType 也要一起調整
 */
export const THIRD_PARTY_TYPES: { [string]: ThirdPartyType } = Object.freeze({
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINE: 'line',
  GOOGLE: 'google',
  APPLE: 'apple',
  MESSENGER: 'messenger',
  WHATS_APP: 'whatsapp',
});

export const MAP_THIRD_PARTY_TYPES_TO_REACTION_TYPES = {
  [THIRD_PARTY_TYPES.FACEBOOK]: ReactionTypes.SHARE_FB,
  [THIRD_PARTY_TYPES.TWITTER]: ReactionTypes.SHARE_TWITTER,
  [THIRD_PARTY_TYPES.LINE]: ReactionTypes.SHARE_LINE,
  [THIRD_PARTY_TYPES.WHATS_APP]: ReactionTypes.SHARE_WHATSAPP,
  [THIRD_PARTY_TYPES.MESSENGER]: ReactionTypes.SHARE_MESSENGER,
};

export const NOT_AVAILABLE_ERROR_SUFFIX: string = '_id_not_available';

export const THIRD_PARTY_ACTIONS: {
  ['LOGIN' | 'REGISTER']: string,
} = Object.freeze({
  LOGIN: 'loginWithThirdParty',
  REGISTER: 'registerWithThirdParty',
});

export const THIRD_PARTY_ACTION_MODAL_MAP: {} = Object.freeze({
  [THIRD_PARTY_ACTIONS.LOGIN]: MODAL_LOGIN,
  [THIRD_PARTY_ACTIONS.REGISTER]: MODAL_REGISTER,
});

export const THIRD_PARTY_TYPES_TEXT = Object.freeze({
  [THIRD_PARTY_TYPES.FACEBOOK]: 'app.components.FB',
  [THIRD_PARTY_TYPES.TWITTER]: 'app.components.X',
  [THIRD_PARTY_TYPES.LINE]: 'app.components.LINE',
  [THIRD_PARTY_TYPES.MESSENGER]: 'app.components.Messenger',
  [THIRD_PARTY_TYPES.WHATS_APP]: 'app.components.WhatsApp',
});
