// Backend 定義的 Currency
// ref: https://github.com/17media/api/blob/master/models/money/money.pb.go#L75-L90
export const CURRENCY_TYPES = [
  'unknown',
  'point',
  'chip',
  'revenue',
  'pointEarmark',
  'nanoUSD',
  'rewardNanoUSD',
  'verifiedNanoUSD',
  'walletNanoUSD',
  'loyaltyPoint',
];

export const URGENT_POINT_THRESHOLD = 500;
