import { createSelector } from 'reselect';

import { WEBAPP_BASE_URL, WEBCDN_BASE_URL } from './constants';

export const isAbsoluteURL = (url: string): boolean => /^https?:\/\//.test(url);

export const getBannerURL = (bannerFilename: string): string =>
  isAbsoluteURL(bannerFilename)
    ? bannerFilename
    : `${WEBCDN_BASE_URL}/${bannerFilename}`;

// create selector for memorizing result
export const getEventURL = createSelector(
  (url: string): string => {
    if (!url) {
      return '';
    }

    return isAbsoluteURL(url) ? url : `${WEBAPP_BASE_URL}${url}`;
  },
  url => url
);

// create selector for memorizing result
export const getEventOrigin = createSelector(
  (url: string): string => {
    try {
      const { origin } = new URL(url);

      return origin;
    } catch (e) {
      return '';
    }
  },
  origin => origin
);
