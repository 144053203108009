import configs from '@17live/app/constants/configs';

import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

let fpVisitor: GetResult;

export const initFingerprint = async () => {
  if (fpVisitor) return fpVisitor;

  const fpAgent = await FingerprintJS.load();

  fpVisitor = await fpAgent.get();

  // @ts-ignore
  configs.device.deviceID = fpVisitor.visitorId;

  return fpVisitor;
};

export const getFingerprintVisitor = () => fpVisitor ?? {};
