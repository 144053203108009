const hasDebugLog =
  process.env.NODE_ENV === 'development' ||
  process.env.DEPLOY_ENV === 'stage' ||
  process.env.DEPLOY_ENV === 'uat';

export const debugInfo = (...log: unknown[]) => {
  if (hasDebugLog) {
    console.info(`%c[Debug]`, 'background: #f2e; color: #fff;', ...log);
  }
};
