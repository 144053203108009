import { Config } from '@17live/app/types/Config';

export const SET_CONFIG = 'SET_CONFIG';

export type SetConfig = {
  type: typeof SET_CONFIG;
  payload: Config;
};

export const setConfig = (config: Config): SetConfig => ({
  type: SET_CONFIG,
  payload: config,
});
