// @flow
import type { MatchPathOptions } from 'react-router-dom';
import { matchPath } from 'react-router-dom';

import { getPathWithoutLang } from '@17live/app/utils';

import { CONTENT_GROUP_FEATURES, CONTENT_GROUPS } from '../constants';
import { FEATURE_PATH_MAP } from './constants';

export type ContentGroup = $Values<typeof CONTENT_GROUPS>;

export type Features = $Values<typeof CONTENT_GROUP_FEATURES>;

export type Options = {
  openID?: string,
  isTV?: boolean,
  isOnline?: boolean,
};

const {
  LIVE,
  LIVE_ONLINE,
  LIVE_OFFLINE,
  PROFILE,
  POST,
  TV_LIVE_ONLINE,
  TV_LIVE_OFFLINE,
  TV_PROFILE,
  TV_POST,
  TV_VIDEO,
} = CONTENT_GROUP_FEATURES;

export const isPathMatchFeature = (
  pathname: string,
  options: string | MatchPathOptions[]
): boolean => {
  if (typeof options === 'string') {
    return !!matchPath(pathname, options);
  }

  return options.some(option => !!matchPath(pathname, option));
};

export const shouldSetOpenID = (feature: ?Features): boolean =>
  [TV_LIVE_ONLINE, TV_LIVE_OFFLINE, TV_PROFILE, TV_POST, TV_VIDEO].includes(
    feature
  );

export const getFeatureName = (options?: Options): ?Features => {
  const featureName = Object.keys(FEATURE_PATH_MAP).find(key =>
    isPathMatchFeature(getPathWithoutLang(), FEATURE_PATH_MAP[key])
  );
  const { isTV, isOnline } = options || {};

  switch (featureName) {
    case PROFILE:
      return isTV ? TV_PROFILE : PROFILE;
    case POST:
      return isTV ? TV_POST : POST;
    case LIVE: {
      if (isTV) {
        return isOnline ? TV_LIVE_ONLINE : TV_LIVE_OFFLINE;
      }

      return isOnline ? LIVE_ONLINE : LIVE_OFFLINE;
    }
    default:
      return featureName;
  }
};

export const getContentGroup = (
  options?: Options
): {
  [ContentGroup]: mixed,
} => {
  const { openID, isTV, isOnline } = options || {};
  const contentGroup = {};
  const featureName = getFeatureName({ isTV, isOnline });

  contentGroup[CONTENT_GROUPS.FEATURE] = featureName;

  if (shouldSetOpenID(featureName)) {
    contentGroup[CONTENT_GROUPS.TV] = openID;
  }

  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-console */
    console.log(
      `📝 PageView sent with ContentGroup: ${JSON.stringify(contentGroup)}`
    );
    /* eslint-enable no-console */
  }

  return contentGroup;
};
