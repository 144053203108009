/*
 *
 * Modal constants
 *
 * @flow
 */
// TODO: remove when GlobalModal move to ts
export const OPEN_MODAL = 'app/Modal/OPEN_MODAL';
export const CLOSE_MODAL = 'app/Modal/CLOSE_MODAL';
export const SET_PARAMS = 'app/Modal/SET_PARAMS';
export const SET_ERROR = 'app/Modal/SET_ERROR';

export type MODALS =
  | 'login-modal'
  | 'register-modal'
  | 'register-oauth-modal'
  | 'forgot-password-modal'
  | 'reset-password-modal'
  | 'phone-verify-modal';

/**
 * This is not only a js variable anymore, it would be an id in the DOM and url fragment.
 * Choosing better naming in id with `lowercase-modal` format.
 */
export const MODAL_LOGIN: MODALS = 'login-modal';
export const MODAL_REGISTER: MODALS = 'register-modal';
export const MODAL_REGISTER_OAUTH: MODALS = 'register-oauth-modal';
export const MODAL_FORGOT_PASSWORD: MODALS = 'forgot-password-modal';
export const MODAL_RESET_PASSWORD: MODALS = 'reset-password-modal';
export const MODAL_PHONE_VERIFY: MODALS = 'phone-verify-modal';

export const MODAL_MIN_WIDTH: number = 262; // px
