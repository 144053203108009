import { RegionType } from '@17live/app/constants';

type getUseFetchConfigQueryKeyParams = {
  region: RegionType;
  userID: string;
  isPersonal?: boolean;
};

export const getUseFetchConfigQueryKey = ({
  region,
  userID,
  isPersonal = false,
}: getUseFetchConfigQueryKeyParams) => {
  return [isPersonal ? 'config/personal' : 'config', region, userID];
};
