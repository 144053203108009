/*
 *
 * LiveStream reducer
 *
 */

import { fromJS } from 'immutable';
import isArray from 'lodash/isArray';

import { createVideoTypeInjector } from '@/services/Video';
import {
  LIVE_STREAM_STATUS,
  LOCAL_KEYS,
  VIDEO_TYPES,
  VIEWING_STATUS,
} from '@/services/Video/constants';

import {
  APPEND_CHAT,
  POST_BARRAGE,
} from '@17live/app/containers/ChatRoom/constants';
import { RECEIVED_GROUP_CALL_INFO } from '@17live/app/containers/GroupCall/GroupCall.constants';
import { getLiveStreamRtmpUrlsByWeight } from '@17live/app/utils/rtmpUrls';

import {
  CHANGE_LIVE_STREAM_PROVIDER,
  CLEAR_GROUP_CALL_INFO,
  CLEAR_LIVE_STREAM_INFO,
  END_STREAM,
  ENTER_LIVE_STREAM,
  ENTER_LIVE_STREAM_SUCCESS,
  GET_LIVE_STREAM_INFO,
  GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED,
  GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED_ONCE,
  LEAVE_LIVE_STREAM,
  PUBLISH_STREAM,
  RECEIVE_MARQUEE,
  RECEIVED_CONCERT_INFO,
  RECEIVED_KEEP_VIEW_RESULT,
  RECEIVED_LIVE_STREAM_INFO,
  SET_COMMENT_ERROR,
  SET_GROUP_CALL_WRAPPER_HOVER,
  SET_IS_LIVE_STREAM_LOADING,
  SET_ROOMID_ALIVE_CALLED,
  UPDATE_BARRAGE_INFOS,
  UPDATE_LIVE_STREAM_STATUS,
} from './constants';

const injectVideoType = createVideoTypeInjector(VIDEO_TYPES.STREAM);
const initialState = fromJS({
  isLoading: false,
  isGroupCallRaiseHandQRCodeOpenedOnce: false,
  isGroupCallRaiseHandQRCodeOpened: false,
  isGroupCallWrapperHovered: false,
  isRoomIDAliveCalledMapping: {},
});

const liveStreamReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED_ONCE: {
      return state.set('isGroupCallRaiseHandQRCodeOpenedOnce', payload);
    }

    case GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED: {
      if (payload) {
        return state
          .set('isGroupCallRaiseHandQRCodeOpenedOnce', true)
          .set('isGroupCallRaiseHandQRCodeOpened', true);
      }

      return state.set('isGroupCallRaiseHandQRCodeOpened', payload);
    }
    case RECEIVE_MARQUEE: {
      const {
        message: { marqueeMsg = {} },
      } = payload;
      return state.set('marquee', fromJS(marqueeMsg));
    }

    case RECEIVED_LIVE_STREAM_INFO: {
      let liveStreams = payload;
      if (!isArray(liveStreams)) {
        liveStreams = [liveStreams];
      }

      // Define a list of fields that should not be merged
      const NON_MERGEABLE_FIELDS = ['customEvent'];

      return state
        .update(currentState => {
          let mergedState = currentState; // Initialize mergedState to currentState

          const updatedState = liveStreams
            .filter(Boolean)
            .reduce((acc, stream) => {
              const roomID = `${stream.roomID || stream.liveStreamID}`;

              /**
               * This because `followerOnlyCommentMins` and `followerOnlyCommentMode` are wrong.
               */
              const {
                followerOnlyCommentMins,
                followerOnlyCommentMode,
                ...omitStream
              } = stream;

              const updatedStream = injectVideoType({
                ...omitStream,
                roomID,
              });

              // Directly set the stream object, overwriting any existing one
              acc[roomID] = updatedStream;

              // Handle non-mergeable fields
              NON_MERGEABLE_FIELDS.forEach(field => {
                const fieldValue = stream[field];
                if (fieldValue !== undefined) {
                  mergedState = mergedState.setIn(
                    [roomID, field],
                    fromJS(fieldValue)
                  );
                } else {
                  mergedState = mergedState.deleteIn([roomID, field]);
                }
              });

              return acc;
            }, {});

          // Merge the updated streams (excluding non-mergeable fields)
          mergedState = mergedState.mergeDeep(fromJS(updatedState));

          return mergedState;
        })
        .set('isLoading', false);
    }

    case CLEAR_LIVE_STREAM_INFO: {
      return state.delete(String(payload));
    }

    case CHANGE_LIVE_STREAM_PROVIDER: {
      const { roomID } = payload;
      const originalRtmpUrls = state
        .getIn([String(roomID), 'rtmpUrls'])
        ?.toJS();

      if (originalRtmpUrls?.length) {
        const newRtmpUrls = getLiveStreamRtmpUrlsByWeight(originalRtmpUrls);
        return state.setIn([String(roomID), 'rtmpUrls'], fromJS(newRtmpUrls));
      }
      return state;
    }

    case RECEIVED_KEEP_VIEW_RESULT: {
      const { roomID } = payload;

      const prevUrl = state.getIn([String(roomID), 'rtmpUrls', 0, 'url']);

      return state
        .setIn(
          [String(roomID), LOCAL_KEYS.VIEWING_STATUS],
          payload[LOCAL_KEYS.VIEWING_STATUS]
        )
        .updateIn([String(roomID), 'rtmpUrls'], rtmpUrls => {
          // 只有付費直播有 deviceID，這裡主要是避免 keepViewAlive 的資料
          // 覆蓋 pullUrls 回傳的資料
          return !prevUrl?.includes('?deviceID')
            ? fromJS(payload.rtmpUrls)
            : rtmpUrls;
        });
    }

    case ENTER_LIVE_STREAM: {
      const { roomID } = payload;

      return state
        .set('currentLiveStreamID', Number(roomID))
        .setIn([String(roomID), LOCAL_KEYS.VIEWING_STATUS], VIEWING_STATUS.NONE)
        .set('marquee', null);
    }

    case LEAVE_LIVE_STREAM: {
      return state
        .delete('currentLiveStreamID')
        .setIn(
          [String(payload), LOCAL_KEYS.VIEWING_STATUS],
          VIEWING_STATUS.NONE
        )
        .set('marquee', null);
    }

    case APPEND_CHAT: {
      const { roomID } = payload;

      return state.setIn([roomID, 'followerOnlyCommentRemainingMins'], 0);
    }

    case SET_COMMENT_ERROR: {
      const { roomID, ...err } = payload;

      return state
        .setIn([roomID, 'commentError'], fromJS(err))
        .setIn(
          [roomID, 'followerOnlyCommentRemainingMins'],
          err.followerOnlyCommentRemainingMins
        );
    }
    case `${POST_BARRAGE}_FAIL`: {
      const { roomID, ...err } = payload;

      return state.set('barrageError', fromJS(err));
    }

    case UPDATE_BARRAGE_INFOS: {
      return state.set('barrageInfos', fromJS(payload));
    }

    case ENTER_LIVE_STREAM_SUCCESS: {
      const {
        roomID,
        defaultComments,
        triviaExtraInfo,
        barrageInfos,
        marquees,
        error,
      } = payload;

      const newState = state
        .setIn(
          [String(roomID), LOCAL_KEYS.VIEWING_STATUS],
          payload[LOCAL_KEYS.VIEWING_STATUS]
        )
        .setIn([String(roomID), 'isEntered'], !error)
        .setIn([String(roomID), 'defaultComments'], fromJS(defaultComments))
        .setIn([String(roomID), 'triviaExtraInfo'], fromJS(triviaExtraInfo))
        .set('barrageInfos', fromJS(barrageInfos))
        .set('marquees', fromJS(marquees))
        .setIn([String(roomID), 'followAt'], fromJS(payload.followAt))
        .setIn(
          [String(roomID), 'followerOnlyCommentMins'],
          fromJS(payload.followerOnlyCommentMins)
        )
        .set('barrageInfos', fromJS(barrageInfos))
        .set('barrageError', fromJS({}))
        .setIn(
          [String(roomID), 'laborRewardInfo'],
          fromJS(payload.laborRewardInfo)
        )
        .setIn(
          [String(roomID), 'gameCenterSetting'],
          fromJS(payload.gameCenterSetting)
        );

      return newState;
    }

    case GET_LIVE_STREAM_INFO: {
      const { roomID } = payload;
      const status = state.getIn([String(roomID), 'status']);

      // Because keepAlive don't need show loading
      if (status === LIVE_STREAM_STATUS.PUBLISHED) {
        return state;
      }

      return state.set('isLoading', true);
    }

    case PUBLISH_STREAM:
    case END_STREAM: {
      return state.set('isLoading', true);
    }

    case UPDATE_LIVE_STREAM_STATUS: {
      const { status, roomID } = payload;
      return state
        .setIn([String(roomID), 'status'], status)
        .set('isLoading', false);
    }

    case RECEIVED_GROUP_CALL_INFO: {
      const { groupCallInfo, roomID } = payload;

      // update groupCallInfo into the liveSteam
      return state.setIn(
        [String(roomID), 'groupCallInfo'],
        fromJS(groupCallInfo)
      );
    }

    case RECEIVED_CONCERT_INFO: {
      const { roomID, vodStartTimestamp, vodEndTimestamp } = payload;

      return state
        .setIn([String(roomID), 'concertVodStartTimestamp'], vodStartTimestamp)
        .setIn([String(roomID), 'concertVodEndTimestamp'], vodEndTimestamp);
    }

    case CLEAR_GROUP_CALL_INFO: {
      const { roomID } = payload;
      return state.deleteIn([String(roomID), 'groupCallInfo']);
    }

    case SET_GROUP_CALL_WRAPPER_HOVER:
      return state.set('isGroupCallWrapperHovered', payload);

    case SET_ROOMID_ALIVE_CALLED: {
      const { roomID, isAliveCalled } = payload;
      return state.setIn(['isRoomIDAliveCalledMapping', roomID], isAliveCalled);
    }

    case SET_IS_LIVE_STREAM_LOADING: {
      return state.set('isLoading', payload);
    }

    default:
      return state;
  }
};

export default liveStreamReducer;
