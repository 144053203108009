import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import apis from './shared/apis';

// error codes from 17App API
export const ERROR_CODE_WRONG_OPENID_FORMAT = 'wrong openID format';
export const ERROR_CODE_INVALID_WORD = 'invalid_word';
export const ERROR_CODE_OPENID_NOT_AVAILABLE = 'open_id_not_available';
export const ERROR_CODE_FACEBOOK_ID_NOT_AVAILABLE = 'facebook_id_not_available';
export const ERROR_CODE_TWITTER_ID_NOT_AVAILABLE = 'twitter_id_not_available';
export const ERROR_CODE_LINE_ID_NOT_AVAILABLE = 'line_id_not_available';
export const ERROR_CODE_GOOGLE_ID_NOT_AVAILABLE = 'google_id_not_available';
export const ERROR_MESSAGE_REGISTER_ACCOUNT_EXISTS =
  'account_for_register_exists';

export const checkFacebookIDAvailable = ({ facebookID }) =>
  apis
    .legacy('checkFacebookIDAvailable', {
      facebookID,
    })
    .pipe(catchError(error => of(error)));
