import configs from '@17live/app/constants/configs';
import Logger from '@17live/app/services/Logger';
import {
  authToken,
  getAuthHeader,
  getAuthHeaderFromQueryString,
} from '@17live/app/utils/authToken';
import getFilenameFromContentDisposition from '@17live/app/utils/getFilenameFromContentDisposition';

import { METHODS } from './constants';

const LOGGER_LABEL = 'FileApi';

const fileApi = async ({
  endpoint,
  method = METHODS.post,
  payload,
  isAuthHeaderFromQueryString = false,
}) => {
  let jwtAccessToken;

  try {
    jwtAccessToken = await authToken.getJwtToken();
  } catch (error) {
    return Promise.reject(error);
  }

  const authHeader = isAuthHeaderFromQueryString
    ? getAuthHeaderFromQueryString(jwtAccessToken)
    : getAuthHeader(jwtAccessToken);

  return fetch(`${configs.url}/${configs.version}/${endpoint}`, {
    method,
    headers: {
      ...authHeader,
    },
    crossDomain: true,
    body: payload,
  })
    .then(async response => {
      const responseData = response.json();
      // Workaround: 處理 BE 定義非標準 HTTP 狀態碼的錯誤情境 (e.g. 420, 520)
      if (!response.ok) throw await responseData;
      return responseData;
    })
    .catch(error => {
      Logger.info(LOGGER_LABEL, '17App file API failed', {
        endpoint,
        method,
        payload,
        error,
      });

      throw error;
    });
};

export const getBlobFileApi = async ({
  endpoint,
  method = METHODS.get,
  isAuthHeaderFromQueryString = false,
}) => {
  let jwtAccessToken;

  try {
    jwtAccessToken = await authToken.getJwtToken();
  } catch (error) {
    return Promise.reject(error);
  }

  const authHeader = isAuthHeaderFromQueryString
    ? getAuthHeaderFromQueryString(jwtAccessToken)
    : getAuthHeader(jwtAccessToken);

  return fetch(`${configs.url}/${configs.version}/${endpoint}`, {
    method,
    headers: {
      ...authHeader,
    },
    crossDomain: true,
    responseType: 'blob',
  })
    .then(async response => {
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const disposition = response.headers.get('Content-Disposition');
      const filename = getFilenameFromContentDisposition(disposition);
      return { blobUrl, filename };
    })
    .catch(error => {
      Logger.info(LOGGER_LABEL, '17App get blob file API failed', {
        endpoint,
        method,
        error,
      });

      throw error;
    });
};

export default fileApi;
