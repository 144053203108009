import { fromJS, Map, Record } from 'immutable-v4';

import { Action } from '@/store/types';

import {
  INIT_SNACK_RANK_SYSTEM_INFO,
  SET_SNACK_RANK_SYSTEM_INFO,
  TOGGLE_SNACK_RANK_SYSTEM_FEATURE_OFF_ALERT,
} from '@17live/app/containers/SnackRankSystem/SnackRankSystem.constants';

export type SnackRankSystemReducerState = Record<{
  rooms: Map<
    string,
    {
      isFeatureEnabled: boolean;
    }
  >;
  isFeatureAlertOpen: boolean;
}>;

export const initialState: SnackRankSystemReducerState = fromJS({
  rooms: {},
  isFeatureAlertOpen: false,
});

const snackRankSystemReducer = (
  state: SnackRankSystemReducerState = initialState,
  action: Action
) => {
  const { payload, type } = action;

  switch (type) {
    case SET_SNACK_RANK_SYSTEM_INFO: {
      const { roomID, snackRankSystemInfo } = payload;
      const original = state.getIn(['rooms', String(roomID)])?.toJS() ?? {};
      return state.setIn(
        ['rooms', String(roomID)],
        fromJS({
          ...original,
          ...snackRankSystemInfo,
        })
      );
    }

    case INIT_SNACK_RANK_SYSTEM_INFO: {
      const { roomID } = payload;
      return state.deleteIn(['rooms', String(roomID)]);
    }

    case TOGGLE_SNACK_RANK_SYSTEM_FEATURE_OFF_ALERT: {
      const { isOpen } = payload;
      return state.set('isFeatureAlertOpen', isOpen);
    }

    default:
      return state;
  }
};

export default snackRankSystemReducer;
