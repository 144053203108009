// @flow
import { stringify } from 'query-string';
import type { Observable } from 'rxjs';
import type { RedeemResponse } from '@/data/coupons';
import apis from './shared/apis';

const API_PATH = 'coupons';

export const redeemPromoCode = (
  promoCode: string
): Observable<RedeemResponse> =>
  apis.putJSON(`${API_PATH}/${promoCode}/used`, {}, {}, { retries: 0 });

export const getRedeemHistory = ({
  count,
  cursor,
}: {
  count?: number,
  cursor?: ?string,
} = {}) =>
  apis.getJSON(
    `${API_PATH}/history?${stringify({
      count,
      cursor,
    })}`
  );
