import { Iterable, Map } from 'immutable';
import { createSelector } from 'reselect';

/**
 * Direct selector to the languageToggle state domain
 */
export const selectLanguage = state => state.get('language');

/**
 * Select the language locale
 */

export const makeSelectLang = () =>
  createSelector(selectLanguage, languageState => languageState.get('lang'));

export const makeSelectRegion = () =>
  createSelector(selectLanguage, languageState => languageState.get('region'));

export const makeSelectIPRegion = () =>
  createSelector(selectLanguage, domain => domain.get('region'));

export const makeSelectMessages = () =>
  createSelector(selectLanguage, language => {
    const messages = language.get('messages') || Map();

    /**
     * IntlProvider needs pure object of messages, so convert to js if it is immutable
     */
    return Iterable.isIterable(messages) ? messages.toJS() : messages;
  });
