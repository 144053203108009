export enum SnackViewerInfoLevelRewardStatus {
  DUMMY,
  CAN_SEND, // 根據 countdownSecs 做倒數, 如果是0即出現, 送出按鈕
  SENT,
  CAN_NOT_SEND,
  COUNTDOWN,
}

/**
 * @see models/laborreward/laborreward.go
 */
export enum SnackRewardType {
  FREE = 1,
  PREMIUM,
  GIFT,
}

export enum AnimationStatus {
  INIT = 'init',
  PLAY = 'play',
}

export enum CountdownStatus {
  INIT = 'init',
  /**
   * Finish the whole countdown progress
   */
  END = 'end',
  RUNNING = 'running',
  /**
   * Stopped the countdown
   * For example, change live room.
   */
  STOP = 'stop',
}

/**
 * @name SnackRewardAnimationType
 * @see models/laborreward/laborreward.go
 * type RewardAnimationType
 */
export enum SnackRewardAnimationType {
  NONE,
  SIMPLE,
  FANCY,
}
