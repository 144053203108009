// @flow

/*
 * dimensions
 */
export const DIMENSIONS = {
  USER_TYPE: 'dimension1', // member, guest
  VOLUME: 'dimension2', // enable, disable
};

/**
 * Hit Types
 */
export const HIT_TYPES: { [string]: string } = {
  PAGEVIEW: 'pageview',
  SCREENVIEW: 'screenview',
  EVENT: 'event',
  TRANSACTION: 'transaction',
  ITEM: 'item',
  SOCIAL: 'social',
  EXCEPTION: 'exception',
  TIMING: 'timing',
};

/**
 * Purchase Event
 */
type ecSteps =
  | 'ec:addImpression'
  | 'ec:addProduct'
  | 'ec:setAction'
  | 'ec:addPurchase'
  | 'ec:checkout'
  | 'ec:purchase';
type ecActions = 'add' | 'detail' | 'checkout' | 'purchase';
export const EC_ADD_IMPRESSION: ecSteps = 'ec:addImpression';
export const EC_ADD_PRODUCT: ecSteps = 'ec:addProduct';
export const EC_SET_ACTION: ecSteps = 'ec:setAction';
export const EC_ADD_PURCHASE: ecSteps = 'ec:addPurchase';
export const EC_CHECKOUT: ecSteps = 'ec:checkout';
export const EC_PURCHASE: ecSteps = 'ec:purchase';
export const EC_ACTIONS: { [ecActions]: string } = {
  add: 'add',
  detail: 'detail',
  checkout: 'checkout',
  purchase: 'purchase',
};

/**
 * event categories
 */
export const EVENT_CATEGORIES = Object.freeze({
  REGISTER: 'registration',
  UI: 'UI',
  TV: '17Video',
  EC: 'EC',
  LIVE_OFFLINE: 'Liveroom_offline',
  LIVE_ONLINE: 'Liveroom_online',

  PREMIUM_CONTENT: 'premium_content',
  RED_ENVELOPE: 'red_envelope',
  BARRAGE: 'barrage',
  GIFT: 'gift',
});

/**
 * event actions
 */
export const EVENT_ACTIONS = Object.freeze({
  // REGISTER
  REGISTER: 'registration_complete',

  // UI
  BUTTON_CLICK: 'button_click',

  // PREMIUM_CONTENT
  ENTER_LIVE_STREAM: 'enter_live_stream',

  // TV
  AUTO_PLAY: 'auto_play',
  FEATURE_VOD_IMPRESSION: 'feature_vod_impression',
  FEATURE_VOD_CLICK: 'feature_vod_click',
  LONG_VIEW: 'long_view',

  // EC
  [EC_ADD_IMPRESSION]: 'product_point_impressions',
  [EC_ADD_PRODUCT]: 'click_product_point',
  [EC_ADD_PURCHASE]: 'third_party_purchase',
  [EC_CHECKOUT]: 'checkout',
  [EC_PURCHASE]: 'purchase',
});

/**
 * event labels
 */
export const EVENT_LABELS = Object.freeze({
  // PREMIUM_CONTENT
  UNLOCK_PREMIUM: 'unlock_premium',
  PREVIEW_VIDEO: 'preview_video',
  PAID_STREAM: 'paid_stream',

  DOWNLOAD_APP_MOBILE_SITE: 'download_app_msite',
  NAV_REGISTER: 'header_register',
  NAV_LOGIN: 'header_login',
  REGISTER_AND_CHAT: 'chat_and_login',
  GIFT_BOX_MORE: 'gift_more',
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
  SHARE: 'share',
  SHARE_FB: 'share_fb',
  SHARE_LINE: 'share_line',
  SHARE_COPY_LINK: 'share_copylink',
  THEATER: 'theater',
  FULL_SCREEN: 'full_screen',

  /**
   * Should suffix with a ranking indicating the order of the clicked area, (start from 0)
   * e.g. liveroom_end_recommendation_0, liveroom_end_recommendation_1, etc.
   */
  LIVE_END_RECOMMEND: 'liveroom_end_recommendation_',
  LIVE_HOT_RECOMMEND: 'liveroom_hot_recommendation_',

  LIVE_END_REFER_INDEX: 'liveroom_end_refer_index',

  // Live setting
  CUSTOM_EVENT_CREATE: 'custom_event_create',
  CUSTOM_EVENT_STOP: 'custom_event_stop',
  CUSTOM_EVENT_CLOSE: 'custom_event_close',
  RTMP_CREATE: 'rtmp_create',
  RTMP_PUBLISH: 'rtmp_publish',
  RTMP_END: 'rtmp_end',
  POLL_CREATE: 'poll_create',
  POLL_END: 'poll_end',
  POLL_DELETE: 'poll_delete',
  POLL_VOTE: 'poll_vote',
  POLL_VOTE_FREE: 'poll_vote_free',
  PROGRAM_SET: 'program_set',
  PROGRAM_DELETE: 'program_delete',

  // ARMY
  ARMY_ONLY_ENABLE_ON: 'army_only_enable_on',
  ARMY_ONLY_ENABLE_OFF: 'army_only_enable_off',
  ARMY_ONLY_ON_HOT_PAGE_ON: 'army_only_on_hot_page_on',
  ARMY_ONLY_ON_HOT_PAGE_OFF: 'army_only_on_hot_page_off',
  ARMY_ONLY_NOTIFY_ON: 'army_only_notify_on',
  ARMY_ONLY_NOTIFY_OFF: 'army_only_notify_off',
  ARMY_ENTER: 'army_enter',
});

/**
 * content groups
 */
export const CONTENT_GROUPS = Object.freeze({
  FEATURE: 'contentGroup1',
  TV: 'contentGroup2',
});

export const CONTENT_GROUP_FEATURES = Object.freeze({
  HOME: 'homepage',
  LIVE: 'LIVE',
  LIVE_ONLINE: 'live_online',
  LIVE_OFFLINE: 'live_offline',
  PROFILE: 'profile',
  POST: 'post',
  TV_HOME: '17tv_homepage',
  TV_LIVE_ONLINE: '17tv_online',
  TV_LIVE_OFFLINE: '17tv_offline',
  TV_PROFILE: '17tv_profile',
  TV_POST: '17tv_post',
  TV_VIDEO: '17tv_video',
  POINT: 'point',
  PURCHASE: 'purchase',
  LEADERBOARD: 'leaderboard',
  SUGGESTED: 'suggested',
  MUSIC: 'music',
  LATEST: 'latest',
  FOLLOWING: 'following',
  RECOMMENDED: 'recommended',
  GAMING: 'gaming',
  VIP: 'vip',
  MALE: 'male_tab',
  FEMALE: 'female_tab',
  TOPIC: 'topictab',
  LIVE_PLUS: '17liveplus_tab',
});

export const GA4_TAG_ID_GROUPS = Object.freeze({
  PROD: 'G-BD1022621R',
  STAGE: 'G-HS5WH2FE5B',
  UAT: 'G-65CRJSQSGC',
});
