import { SnackRankLevel } from '@17live/app/containers/SnackRankSystem/SnackRankSystem.enums';
import $t from '@17live/app/utils/intl';

export const SET_SNACK_RANK_SYSTEM_INFO =
  'app/SnackRankSystem/SET_SNACK_RANK_SYSTEM_INFO';
export const INIT_SNACK_RANK_SYSTEM_INFO =
  'app/SnackRankSystem/INIT_SNACK_RANK_SYSTEM_INFO';
export const TOGGLE_SNACK_RANK_SYSTEM_FEATURE_OFF_ALERT =
  'app/Incentive/TOGGLE_SNACK_RANK_SYSTEM_FEATURE_OFF_ALERT';

export const SNACK_RANK_ICON_MAPPING: { [key in SnackRankLevel]: string } = {
  [SnackRankLevel.UNDEFINED]: '',
  [SnackRankLevel.LEVEL0]: '',
  [SnackRankLevel.LEVEL1]: 'ig_snack_rank_white_big.png',
  [SnackRankLevel.LEVEL2]: 'ig_snack_rank_white_big.png',
  [SnackRankLevel.LEVEL3]: 'ig_snack_rank_white_big.png',
  [SnackRankLevel.LEVEL4]: 'ig_snack_rank_bronze_big.png',
  [SnackRankLevel.LEVEL5]: 'ig_snack_rank_bronze_big.png',
  [SnackRankLevel.LEVEL6]: 'ig_snack_rank_bronze_big.png',
  [SnackRankLevel.LEVEL7]: 'ig_snack_rank_silver_big.png',
  [SnackRankLevel.LEVEL8]: 'ig_snack_rank_silver_big.png',
  [SnackRankLevel.LEVEL9]: 'ig_snack_rank_silver_big.png',
  [SnackRankLevel.LEVEL10]: 'ig_snack_rank_silver_big.png',
  [SnackRankLevel.LEVEL11]: 'ig_snack_rank_silver_big.png',
  [SnackRankLevel.LEVEL12]: 'ig_snack_rank_gold_big.png',
  [SnackRankLevel.LEVEL13]: 'ig_snack_rank_gold_big.png',
  [SnackRankLevel.LEVEL14]: 'ig_snack_rank_gold_big.png',
  [SnackRankLevel.LEVEL15]: 'ig_snack_rank_gold_big.png',
  [SnackRankLevel.LEVEL16]: 'ig_snack_rank_gold_big.png',
};

export const SNACK_RANK_SMALL_ICON_MAPPING: {
  [key in SnackRankLevel]: string;
} = {
  [SnackRankLevel.UNDEFINED]: '',
  [SnackRankLevel.LEVEL0]: '',
  [SnackRankLevel.LEVEL1]: 'ig_snack_rank_white_small.png',
  [SnackRankLevel.LEVEL2]: 'ig_snack_rank_white_small.png',
  [SnackRankLevel.LEVEL3]: 'ig_snack_rank_white_small.png',
  [SnackRankLevel.LEVEL4]: 'ig_snack_rank_bronze_small.png',
  [SnackRankLevel.LEVEL5]: 'ig_snack_rank_bronze_small.png',
  [SnackRankLevel.LEVEL6]: 'ig_snack_rank_bronze_small.png',
  [SnackRankLevel.LEVEL7]: 'ig_snack_rank_silver_small.png',
  [SnackRankLevel.LEVEL8]: 'ig_snack_rank_silver_small.png',
  [SnackRankLevel.LEVEL9]: 'ig_snack_rank_silver_small.png',
  [SnackRankLevel.LEVEL10]: 'ig_snack_rank_silver_small.png',
  [SnackRankLevel.LEVEL11]: 'ig_snack_rank_silver_small.png',
  [SnackRankLevel.LEVEL12]: 'ig_snack_rank_gold_small.png',
  [SnackRankLevel.LEVEL13]: 'ig_snack_rank_gold_small.png',
  [SnackRankLevel.LEVEL14]: 'ig_snack_rank_gold_small.png',
  [SnackRankLevel.LEVEL15]: 'ig_snack_rank_gold_small.png',
  [SnackRankLevel.LEVEL16]: 'ig_snack_rank_gold_small.png',
};

export const SNACK_RANK_COLOR_MAPPING: { [key in SnackRankLevel]: string } = {
  [SnackRankLevel.UNDEFINED]: '',
  [SnackRankLevel.LEVEL0]: '',
  [SnackRankLevel.LEVEL1]: '#F7F4FC',
  [SnackRankLevel.LEVEL2]: '#F7F4FC',
  [SnackRankLevel.LEVEL3]: '#F7F4FC',
  [SnackRankLevel.LEVEL4]: '#F6E8DE',
  [SnackRankLevel.LEVEL5]: '#F6E8DE',
  [SnackRankLevel.LEVEL6]: '#F6E8DE',
  [SnackRankLevel.LEVEL7]: '#E7F2F1',
  [SnackRankLevel.LEVEL8]: '#E7F2F1',
  [SnackRankLevel.LEVEL9]: '#E7F2F1',
  [SnackRankLevel.LEVEL10]: '#E7F2F1',
  [SnackRankLevel.LEVEL11]: '#E7F2F1',
  [SnackRankLevel.LEVEL12]: '#FDF0AE',
  [SnackRankLevel.LEVEL13]: '#FDF0AE',
  [SnackRankLevel.LEVEL14]: '#FDF0AE',
  [SnackRankLevel.LEVEL15]: '#FDF0AE',
  [SnackRankLevel.LEVEL16]: '#FDF0AE',
};

/**
 * @name SNACK_GROUP_NAME_MAPPING
 * @description Group name mapping
 * @see https://docs.google.com/document/d/1ecpX_piEGZqIR7y5ESKpiyMKY29m3Nrw6DEC6O-6I68/edit?usp=sharing
 */
export const SNACK_GROUP_NAME_MAPPING: {
  [key in SnackRankLevel]: string | JSX.Element;
} = {
  [SnackRankLevel.UNDEFINED]: '',
  [SnackRankLevel.LEVEL0]: '',
  [SnackRankLevel.LEVEL1]: $t('snack_rank_white'),
  [SnackRankLevel.LEVEL2]: $t('snack_rank_white'),
  [SnackRankLevel.LEVEL3]: $t('snack_rank_white'),
  [SnackRankLevel.LEVEL4]: $t('snack_rank_bronze'),
  [SnackRankLevel.LEVEL5]: $t('snack_rank_bronze'),
  [SnackRankLevel.LEVEL6]: $t('snack_rank_bronze'),
  [SnackRankLevel.LEVEL7]: $t('snack_rank_silver'),
  [SnackRankLevel.LEVEL8]: $t('snack_rank_silver'),
  [SnackRankLevel.LEVEL9]: $t('snack_rank_silver'),
  [SnackRankLevel.LEVEL10]: $t('snack_rank_silver'),
  [SnackRankLevel.LEVEL11]: $t('snack_rank_silver'),
  [SnackRankLevel.LEVEL12]: $t('snack_rank_gold'),
  [SnackRankLevel.LEVEL13]: $t('snack_rank_gold'),
  [SnackRankLevel.LEVEL14]: $t('snack_rank_gold'),
  [SnackRankLevel.LEVEL15]: $t('snack_rank_gold'),
  [SnackRankLevel.LEVEL16]: $t('snack_rank_gold'),
};

/**
 * @name SNACK_GROUP_RANK_NAME_MAPPING
 * @description Group with rank name mapping
 * @see https://docs.google.com/document/d/1ecpX_piEGZqIR7y5ESKpiyMKY29m3Nrw6DEC6O-6I68/edit?usp=sharing
 */
export const SNACK_GROUP_RANK_NAME_MAPPING: {
  [key in SnackRankLevel]: string | JSX.Element;
} = {
  [SnackRankLevel.UNDEFINED]: '',
  [SnackRankLevel.LEVEL0]: '',
  [SnackRankLevel.LEVEL1]: $t('snack_rank_class_white', { 0: '1' }),
  [SnackRankLevel.LEVEL2]: $t('snack_rank_class_white', { 0: '2' }),
  [SnackRankLevel.LEVEL3]: $t('snack_rank_class_white', { 0: '3' }),
  [SnackRankLevel.LEVEL4]: $t('snack_rank_class_bronze', { 0: '1' }),
  [SnackRankLevel.LEVEL5]: $t('snack_rank_class_bronze', { 0: '2' }),
  [SnackRankLevel.LEVEL6]: $t('snack_rank_class_bronze', { 0: '3' }),
  [SnackRankLevel.LEVEL7]: $t('snack_rank_class_silver', { 0: '1' }),
  [SnackRankLevel.LEVEL8]: $t('snack_rank_class_silver', { 0: '2' }),
  [SnackRankLevel.LEVEL9]: $t('snack_rank_class_silver', { 0: '3' }),
  [SnackRankLevel.LEVEL10]: $t('snack_rank_class_silver', { 0: '4' }),
  [SnackRankLevel.LEVEL11]: $t('snack_rank_class_silver', { 0: '5' }),
  [SnackRankLevel.LEVEL12]: $t('snack_rank_class_gold', { 0: '1' }),
  [SnackRankLevel.LEVEL13]: $t('snack_rank_class_gold', { 0: '2' }),
  [SnackRankLevel.LEVEL14]: $t('snack_rank_class_gold', { 0: '3' }),
  [SnackRankLevel.LEVEL15]: $t('snack_rank_class_gold', { 0: '4' }),
  [SnackRankLevel.LEVEL16]: $t('snack_rank_class_gold', { 0: '5' }),
};

export const SNACK_RANK_SYSTEM_TOOLTIP_MILLISECONDS = 3000;
