// @flow
import React, {
  Component,
  Children,
  isValidElement,
  type Element,
  type Node,
} from 'react';
import { withRouter } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import OpenStateUpdater from './OpenStateUpdater';
import { matchModal } from './utils';

type Props = {
  ...ContextRouter,
  closeModal: () => void,
  children: Node,
};

class ModalSwitch extends Component<Props> {
  latestOpenedModal: Element<*> | null = null;

  handleOpenStateChanged = (isOpen: boolean) => {
    if (!isOpen) {
      this.props.closeModal();
    }
  };

  render() {
    const location = this.props.location;

    let match = null;

    const currentOpenedModal = Children.toArray(this.props.children).find(
      child => {
        if (isValidElement(child)) {
          match = matchModal(location, child.props);

          return !!match;
        }

        return false;
      }
    );

    if (currentOpenedModal) {
      this.latestOpenedModal = currentOpenedModal;
    }

    return (
      <OpenStateUpdater
        isOpen={!!currentOpenedModal}
        onOpenStateChanged={this.handleOpenStateChanged}
      >
        {this.latestOpenedModal
          ? React.cloneElement(this.latestOpenedModal, {
              // trick react to think there is only one component all along
              key: '$0',
              location,
              computedMatch: match,
            })
          : null}
      </OpenStateUpdater>
    );
  }
}

export default withRouter(ModalSwitch);
