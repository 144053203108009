// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import { ERROR_CODES } from '@/services/17App/shared/constants';
import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';
import { dispatch } from '@/store';

import configs from '@17live/app/constants/configs';
import { LoggerLabel } from '@17live/app/constants/log';
import { TOKEN_INVALID } from '@17live/app/containers/App/epics';
import { logout } from '@17live/app/containers/LoginProvider/actions';
import Logger from '@17live/app/services/Logger';
import { RefreshJwtTokenResponse } from '@17live/app/types/Auth';
import { APIError } from '@17live/app/types/Error';
import { parseJSON } from '@17live/app/utils/apiUtils';

const endpoint = 'auth/refresh';

export const checkTokenInvalidStatus = (status: number) => status === 401;

export const getJwtTokenFromLocalStorage = () => {
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) || {};
  const { jwtAccessToken, jwtAccessTokenExpiredTime } = loginInfo;

  return { jwtAccessToken, jwtAccessTokenExpiredTime };
};

export const isJwtTokenExpired = () => {
  const {
    jwtAccessToken,
    jwtAccessTokenExpiredTime: jwtAccessTokenExpiredTimeFromLocalStorage,
  } = getJwtTokenFromLocalStorage();

  const jwtAccessTokenExpiredTime =
    jwtAccessTokenExpiredTimeFromLocalStorage ||
    jwt_decode(jwtAccessToken)?.exp;

  return jwtAccessTokenExpiredTime * 1000 < Date.now();
};

export const getAuthHeader = (token: string) => {
  if (!token) {
    return {};
  }

  return {
    Authorization: `Bearer ${token}`,
  };
};

export const getAuthHeaderFromQueryString = (token: string) => {
  const search = new URLSearchParams(location.search);

  /**
   * For APP webview query accessToken
   */
  if (search.get('accessToken')) {
    return {
      Authorization: `Bearer ${search.get('accessToken')}`,
    };
  }

  return getAuthHeader(token);
};

const logoutUser = () => dispatch(logout(TOKEN_INVALID));

class AuthToken {
  isJwtTokenRefreshing: boolean;
  refreshJwtTokenPromise: Promise<any> | undefined;

  constructor() {
    this.isJwtTokenRefreshing = false;
    this.refreshJwtTokenPromise = undefined;
  }

  resetJwtTokenRefreshStatus() {
    this.isJwtTokenRefreshing = false;
    this.refreshJwtTokenPromise = undefined;
  }

  setJwtTokenRefreshStatus(jwtTokenRefreshStatus: boolean) {
    this.isJwtTokenRefreshing = jwtTokenRefreshStatus;
  }

  setRefreshJwtTokenPromise() {
    const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) || {};
    const { refreshToken } = loginInfo;

    this.refreshJwtTokenPromise = window
      .fetch(`${configs.url}/${configs.version}/${endpoint}`, {
        // @ts-expect-error
        headers: {
          'Content-Type': 'application/json',
          ...configs.device,
        },
        body: JSON.stringify({
          refreshToken,
        }),
        method: 'POST',
      })
      .then((response: Response) => {
        if (
          (response.status >= 200 && response.status < 300) ||
          checkTokenInvalidStatus(response.status)
        ) {
          return response;
        }

        const data = response.json();
        return Promise.reject({ status: response.status, ...data });
      })
      .then(parseJSON)
      .then((res: RefreshJwtTokenResponse) => {
        if (
          res.errorCode === ERROR_CODES.TOKEN_EXPIRED ||
          res.errorCode === ERROR_CODES.INVALID_TOKEN
        ) {
          return Promise.reject(res);
        }

        const decoded = jwt_decode(res.jwtAccessToken);

        LocalStorage.setItem(KEY_LOGIN_INFO, {
          ...loginInfo,
          jwtAccessToken: res.jwtAccessToken,
          jwtAccessTokenExpiredTime: decoded.exp,
          refreshToken: res.refreshToken,
        });

        return res;
      })
      .catch((error: APIError) => {
        // @ts-expect-error
        Logger.info(LoggerLabel.API, '17App API failed', {
          endpoint,
          error,
        });

        this.resetJwtTokenRefreshStatus();
        logoutUser();

        throw error;
      });
  }

  async refreshJwtToken() {
    if (this.isJwtTokenRefreshing && this.refreshJwtTokenPromise) {
      const res = await this.refreshJwtTokenPromise;

      return res;
    }

    this.setJwtTokenRefreshStatus(true);
    this.setRefreshJwtTokenPromise();

    const res = await this.refreshJwtTokenPromise;

    this.resetJwtTokenRefreshStatus();

    return res;
  }

  async getJwtToken() {
    const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) || {};
    let { jwtAccessToken } = loginInfo;

    if (!jwtAccessToken) {
      return;
    }

    if (isJwtTokenExpired()) {
      ({ jwtAccessToken } = await this.refreshJwtToken());
    }

    return jwtAccessToken;
  }
}

/**
 * FIXME:
 * We new Token here because we need to use same class instance everywhere.
 * Change this to API self scope when we refactor API architecture.
 */
export const authToken = new AuthToken();

export default AuthToken;
