export enum GoApiHost {
  PROD = 'api-dsa.17app.co',
  STAG = 'sta-api.17app.co',
  PROD_WAP = 'wap-api.17app.co',
  STAG_WAP = 'sta-wap-api.17app.co',
  DEV = 'dev.api.17app.co:8001',
  UAT = 'uat-api.17app.co',
  UAT_WAP = 'uat-wap-api.17app.co',
}

export enum AdminApiHost {
  PROD = 'admin.17.media',
  STAG = 'sta-admin.17.media',
  UAT = 'uat-admin.17.media',
}

export enum EventApiHost {
  PROD = 'event-server.17app.co',
  STAG = 'event-server-sta.17app.co',
  UAT = 'event-server-uat.17app.co',
}

export enum ShieldApiHost {
  PROD = 'shield.17app.co',
  STAG = 'sta-shield.17app.co',
  UAT = 'uat-shield.17app.co',
}

export enum LegacyWebHost {
  PROD = '17.media',
  STAG = 'sta.17.media',
  UAT = 'sta.17.media', // 17.media has no UAT env
}

export const API_VERSION = 'api/v1';

export const API_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? GoApiHost.PROD
    : process.env.DEPLOY_ENV === 'stage'
    ? GoApiHost.STAG
    : GoApiHost.UAT;

export const API_WAP_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? GoApiHost.PROD_WAP
    : process.env.DEPLOY_ENV === 'stage'
    ? GoApiHost.STAG_WAP
    : GoApiHost.UAT_WAP;

export const ADMIN_API_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? AdminApiHost.PROD
    : process.env.DEPLOY_ENV === 'stage'
    ? AdminApiHost.STAG
    : AdminApiHost.UAT;

export const EVENT_API_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? EventApiHost.PROD
    : process.env.DEPLOY_ENV === 'stage'
    ? EventApiHost.STAG
    : EventApiHost.UAT;

export const SHIELD_API_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? ShieldApiHost.PROD
    : process.env.DEPLOY_ENV === 'stage'
    ? ShieldApiHost.STAG
    : ShieldApiHost.UAT;

export const LEGACY_WEB_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? LegacyWebHost.PROD
    : process.env.DEPLOY_ENV === 'stage'
    ? LegacyWebHost.STAG
    : LegacyWebHost.UAT;
