/*
 *
 * Preference reducer
 *
 */
import { fromJS } from 'immutable';

import Preference, { DEFAULTS } from '@/services/Preference';

import { LOGOUT_SUCCESS } from '@17live/app/containers/LoginProvider/constants';

import { SET_PREFERENCE, UPDATE_PREFERENCE } from './constants';

const initialState = fromJS({
  volume: DEFAULTS.volume,
  carouselVolume: DEFAULTS.carouselVolume,
  clipVolume: DEFAULTS.clipVolume,
  clipFeedVolume: DEFAULTS.clipFeedVolume,
  historyLiveStreams: {},
  hasEnhanceDownload: true,
});

const preferenceProviderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PREFERENCE: {
      const { key, value } = payload;

      return state.set(key, fromJS(value));
    }

    case UPDATE_PREFERENCE:
      return state.merge(payload);

    case LOGOUT_SUCCESS:
      return fromJS(Preference.getAll());

    default:
      return state;
  }
};

export default preferenceProviderReducer;
