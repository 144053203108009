export const ERROR_ACTION = 'app/ERROR_ACTION';

export const errorAction = (baseType, error) => ({
  type: ERROR_ACTION,
  error: true,
  payload: {
    baseType,
    error,
  },
});
