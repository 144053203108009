/*
 *
 * StatusAnnouncement reducer
 *
 */

import { fromJS } from 'immutable';

import getPicture from '@/utils/getPicture';

import { SET_ANNOUNCEMENT } from './StatusAnnouncement.constants';

const initialState = fromJS({
  isMaintaining: false,
});

const statusAnnouncementReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ANNOUNCEMENT:
      return state.merge(
        payload.image
          ? {
              ...payload,
              image: getPicture(payload.image, { isThumbnail: false }),
            }
          : payload
      );
    default:
      return state;
  }
};

export default statusAnnouncementReducer;
