import usePerimeterXStore from '@17live/app/stores/usePerimeterXStore';

import { PerimeterXBlockResponse } from './types';

export const isBlockResponse = (res: any): res is PerimeterXBlockResponse =>
  Boolean(res && res.appId && res.blockScript);

export const openCaptchaModal = (
  res: PerimeterXBlockResponse,
  callback: (isValid: boolean) => void
): void => {
  usePerimeterXStore.getState().openCaptchaModal(res, callback);
};

export const openCaptchaModalPromise = (res: PerimeterXBlockResponse) =>
  new Promise((resolve, reject) => {
    openCaptchaModal(res, (isValid: boolean) =>
      isValid ? resolve() : reject()
    );
  });
