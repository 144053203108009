import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

const omitDeep = (obj, predicateFn = v => isNil(v) || v === '') => {
  if (!isObject(obj) || isArray(obj)) {
    return obj;
  }

  const clone = {};

  Object.keys(obj)
    .forEach((key) => {
      clone[key] = omitDeep(obj[key], predicateFn);
    });

  return omitBy(clone, predicateFn);
};

export default omitDeep;
