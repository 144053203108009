// @flow
import { css } from 'styled-components';
import zip from 'lodash/zip';

export const gradient = (direction: string) => (...steps: string[]) =>
  `linear-gradient(to ${direction}, ${zip(
    ['#ff15d3', '#f5487d', '#f6696c', '#f69355'],
    steps.length ? steps : ['', '33%', '63%', '']
  )
    .map(arg => arg.join(' '))
    .join(', ')})`;

['left', 'right', 'top', 'bottom'].forEach((direction) => {
  gradient[direction] = gradient(direction);
});

/**
 * radio = height / width
 */
export const aspectRatio = (ratio: number) => css`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: 0;
  padding-bottom: ${({ width }) =>
    (width ? `${width * ratio}px` : `${ratio * 100}%`)};
`;

export const cover = (offset?: number = 0) => css`
  position: absolute;
  left: ${offset}px;
  right: ${offset}px;
  top: ${offset}px;
  bottom: ${offset}px;
`;
