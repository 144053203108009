// @flow
import { css } from 'styled-components';

import { OrderedMap } from 'immutable';

import { screenSizeBreakpoints } from '@/constants/sizes';
import { isHandheld, isMobile, isTablet } from '@/services/Device';

type Sizes = {|
  'max-width': number,
  'min-width': number,
|};

const generateMediaQuery = (sizes: Sizes): string =>
  Object.keys(sizes)
    .filter(mediaKey => sizes[mediaKey])
    .map(mediaKey => `(${mediaKey}: ${sizes[mediaKey]}px)`)
    .join(' and ');

const createMedia = (sizes: Sizes, isReverse: boolean = false) => (...args) => {
  const mediaQuery: string = generateMediaQuery(sizes);

  return css`
    @media ${isReverse && 'not all and '}${mediaQuery} {
      ${css(...args)}
    }
  `;
};

const pairMap = (
  orderedMap: OrderedMap<string, number>
): OrderedMap<string, number[]> => {
  const values = orderedMap.toList();
  const pairs = values.zip(values.push(0).rest());

  return OrderedMap(orderedMap.keySeq().zip(pairs));
};

const breakPointsPairMap = pairMap(
  OrderedMap(screenSizeBreakpoints)
    // sort from small to large
    .sort()
).map(([minWidth, maxWidth]) => ({
  /**
   * window.matchMedia will serializes `between` media query into max first then min, like
   * input: '(min-width: 1px) and (max-width: 10px)' or '(max-width: 10px) and (min-width: 1px)'
   * output: '(max-width: 10px) and (min-width: 1px)'
   *
   * for matching media from MediaQueryList, thus define same order for max-width/min-width here
   * e.g. ScreenSizeObserver/utils getScreenSize()
   */
  'max-width': maxWidth,
  'min-width': minWidth,
}));

const media = breakPointsPairMap.reduce(
  (queries, sizes, label) => ({
    ...queries,
    [label]: createMedia(sizes),
    not: {
      ...queries.not,
      [label]: createMedia(sizes, true),
    },
  }),
  { not: {} }
);

export const mobileCSS = (...args: any) => {
  if (!isMobile()) {
    return css``;
  }

  return css(...args);
};

export const tabletCSS = (...args: any) => {
  if (isTablet()) {
    return css``;
  }

  return css(...args);
};

export const handheldCSS = (...args: any) => {
  if (!isHandheld()) {
    return css``;
  }

  return css(...args);
};

export const desktopCSS = (...args: any) => {
  if (isHandheld()) {
    return css``;
  }

  return css(...args);
};

export const mobileOnly = isHandheld()
  ? css``
  : css`
      display: none;
    `;

export const desktopOnly = mobileCSS`display: none;`;

export const landscapeCSS = (...args: any) => css`
  @media screen and (orientation: landscape) {
    ${css(...args)};
  }
`;

const createNotSelector = (content: string, isNegative: boolean): string =>
  isNegative ? `:not(${content})` : content;

export const langCSS = (lang: string, isNegative: boolean = false) => (
  ...args: any
) => css`
  html${createNotSelector(`[lang|=${lang}]`, isNegative)} & {
    ${css(...args)};
  }
`;

export const breakpointsMatchMaps = breakPointsPairMap.map(sizes =>
  generateMediaQuery(sizes)
);

export default media;
