// @flow
import {
  LAYOUT_PORTRAIT,
  SIZE_LARGE,
  SIZE_MEDIUM,
  SIZE_MOBILE,
  SIZE_SMALL,
} from '@/constants/sizes';

/**
 * the margin/padding of the Left and Right in the Stream Room
 */
export const CHANNEL_VIDEO_LEFT_RIGHT_MARGIN: { [string]: number | string } = {
  [SIZE_MOBILE]: '20px',
  [SIZE_SMALL]: '20px',
  [SIZE_MEDIUM]: 'calc((100vh - 70px) * 0.04)',
  [SIZE_LARGE]: '24px',
};

/**
 * the margin/padding of the Top and Bottom in the Stream Room
 */
export const CHANNEL_VIDEO_TOP_BOTTOM_MARGIN = {
  [SIZE_MOBILE]: '40px',
  [SIZE_SMALL]: '40px',
  [SIZE_MEDIUM]: '40px',
  [SIZE_LARGE]: '40px',
};

export const CHANNEL_GAP: number = 20; // px

export const CHANNEL_LAYOUT = {
  GAP: {
    [LAYOUT_PORTRAIT]: {
      [SIZE_MEDIUM]: 12,
      [SIZE_LARGE]: 12,
    },
  },
  GUTTERS: {
    [SIZE_MEDIUM]: 20,
    [SIZE_LARGE]: 24,
  },
  HEADER: {
    WIDTH: {
      [SIZE_MEDIUM]: 314,
      [SIZE_LARGE]: 380,
    },
  },
  VIDEO: {
    // for video minimum size
    [LAYOUT_PORTRAIT]: {
      WIDTH: {
        [SIZE_MEDIUM]: 298,
        [SIZE_LARGE]: 428,
      },
      HEIGHT: {
        [SIZE_MEDIUM]: 530,
        [SIZE_LARGE]: 762,
      },
    },
  },
};

// https://17media.atlassian.net/wiki/spaces/SABE/pages/1018561249/Premium+Content+PassCode
export const PASS_CODE = 'passCode';
