// @flow
import React, { Component, type Node } from 'react';
import { Route, type ContextRouter, type Location } from 'react-router-dom';

type OwnProps = {
  children: ({
    getPreviousLocation: () => ?Location,
    getInitialLocation: () => ?Location,
  }) => Node,
};

type Props = {
  ...ContextRouter,
} & OwnProps;

let entries: Location[] = [];
let index: number = -1;
let unlisten: (() => void) | null = null;

const historyListener = (
  nextLocation: Location,
  action: 'PUSH' | 'REPLACE' | 'POP'
) => {
  if (action === 'PUSH') {
    index += 1;
    entries = entries.slice(0, index);
    entries.push(nextLocation);
  } else if (action === 'REPLACE') {
    entries[index] = nextLocation;
    entries = entries.slice(0, index + 1);
  } else {
    index -= 1;
  }
};

class MemoryHistory extends Component<Props> {
  componentDidMount() {
    const { history } = this.props;

    if (!unlisten) {
      if (index === -1) {
        index += 1;
        entries.push(history.location);
      }

      unlisten = history.listen(historyListener);
      this.unlisten = unlisten;
    }
  }

  componentWillUnmount() {
    // reset upon last unmount, mostly to support MemoryRouter and clean up event listeners
    if (unlisten && this.unlisten === unlisten) {
      unlisten();
      unlisten = null;
      entries = [];
      index = -1;
    }
  }

  getPreviousLocation = (): ?Location => entries[index - 1];
  getInitialLocation = (): ?Location => entries[0];

  unlisten: (() => void) | null = null;

  renderProps = {
    getPreviousLocation: this.getPreviousLocation,
    getInitialLocation: this.getInitialLocation,
  };

  render() {
    return this.props.children(this.renderProps);
  }
}

// we need it to be mounted no matter match or not
// (to record the `previousLocation` when the modal is not matched),
// so we cannot use `withRouter` which use `render` prop under the hood
export default (props: OwnProps) => (
  <Route>{router => <MemoryHistory {...props} {...router} />}</Route>
);
