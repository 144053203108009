import React from 'react';

import styled from 'styled-components';

import background from '@/assets/ig-error-bg.svg';
import AnimatedSprite from '@/components/AnimatedSprite';

import { getGCSImage } from '@17live/app/utils';

const Image = props => (
  <div {...props}>
    <AnimatedSprite
      width={322}
      height={216}
      steps={4}
      duration={1}
      src={getGCSImage('17-error-sprite.png')}
    />
  </div>
);

export default styled(Image)`
  position: relative;
  width: 322px;
  height: 154px;
  background-image: ${props =>
    props.shouldShowBg ? `url(${background})` : 'none'};

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 10px;

  ${AnimatedSprite} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
    transform: scale(0.6);
    transform-origin: bottom center;
  }
`;
