import { stringify } from 'query-string';

import { getBackendLanguageCode, getLang } from '@17live/app/utils';

import apis from '../shared/apis';
import { DEFAULT_COUNT } from '../shared/constants';

export const getPointGainLog = params =>
  apis.getJSON(
    `money/pointGainLog?${stringify({
      count: DEFAULT_COUNT,
      ...params,
    })}`,
    null,
    // temporarily override language in apis.js
    // 現階段 apis.js 裡帶的 language 不符合這支 api 的需求
    // 因此用 override 的方式，不去影響其他 api
    {
      language: getBackendLanguageCode(getLang()),
    }
  );

export const getPointUsageLog = params =>
  apis.getJSON(
    `money/pointUsageLog?${stringify({
      count: DEFAULT_COUNT,
      ...params,
    })}`,
    null,
    // temporarily override language in apis.js
    // 現階段 apis.js 裡帶的 language 不符合這支 api 的需求
    // 因此用 override 的方式，不去影響其他 api
    {
      language: getBackendLanguageCode(getLang()),
    }
  );
