export const SET_SNACK_COUNTDOWN_STATUS: string =
  'app/Snack/SET_COUNTDOWN_STATUS';
export const UPDATE_SNACK_TIMER: string = 'app/Snack/UPDATE_TIMER';
export const SET_SNACK_INIT_TIMER: string = 'app/Snack/SET_INIT_TIMER';
export const RUN_SNACK_COUNTDOWN: string = 'app/Snack/RUN_COUNTDOWN';
export const STOP_SNACK_COUNTDOWN: string = 'app/Snack/STOP_COUNTDOWN';
export const START_SNACK_COUNTDOWN: string = 'app/Snack/START_COUNTDOWN';
export const SET_SNACK_ANIMATION_STATUS: string =
  'app/Snack/SET_ANIMATION_STATUS';
export const SET_SNACK_ANIMATION_INFO: string =
  'app/Snack/SET_SNACK_ANIMATION_INFO';
export const RESET_SNACK_ANIMATION_INFO: string =
  'app/Snack/RESET_SNACK_ANIMATION_INFO';
export const SET_SNACK_STATUS = 'app/Snack/SET_SNACK_STATUS';

// reducer state
export const COUNTDOWN_STATUS = 'countdownStatus';
export const CURRENT_DURATION_SEC = 'currentDurationSec';
export const ORIGINAL_DURATION_SEC = 'originalDurationSec';
export const ANIMATION_STATUS = 'animationStatus';
export const SNACK_STATUS = 'snackStatus';
export const SNACK_ANIMATION_INFO = 'snackAnimationInfo';

export const DEFAULT_COUNTDOWN_SEC: number = 0;

export const FINAL_SNACK_ANIMATION_JSON_PATH = 'snack/snack_ex';
export const NORMAL_SNACK_ANIMATION_JSON_PATH = 'snack/snack_normal';
