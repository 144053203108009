import mapKeys from 'lodash/mapKeys';
import noop from 'lodash/noop';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';

import flattenObject from '@/utils/flattenObject';
import { getEnvInfo } from '@/utils/getEnvInfo';

import configs, { log as logConfig } from '@17live/app/constants/configs';
import { IGNORE_FIELDS } from '@17live/app/services/Logger/constants';
import { getRegion } from '@17live/app/utils';

const value = val => val || 'Unknown';

const send = payload =>
  ajax({
    url: logConfig.url,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .pipe(catchError(error => of(error)))
    .subscribe(noop); // don't care about the result

export default ({ level, label, message, additionalInfo = {} }) => {
  try {
    const currentInfo = getEnvInfo();
    const appVersion = additionalInfo.version || currentInfo.version;
    const log = flattenObject({
      ...additionalInfo,
      ...currentInfo,
      level,
      label,
      message,
      logTime: new Date().getTime(),
      version: appVersion,
      path: additionalInfo.path || window.location.origin,
      userID: value(additionalInfo.userID || configs.device.deviceID),
      webRegion: getRegion(),
    });

    // replace . with _ in keys because fluentd does not accept .
    const payload = mapKeys(log, (_, key) => key.replace(/\./g, '_'));

    IGNORE_FIELDS.forEach(field => {
      delete payload[field];
    });

    send(payload);
  } catch (e) {
    console.error(`[Fluentd] log failed: ${e.message}`);
  }
};
