/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable';

import { formatTranslationMessages, translationMessages } from '@/i18n';

import { getLang, getRegion } from '@17live/app/utils';

import {
  ADD_LANG,
  DEFAULT_LOCALE,
  SET_LANG,
  SET_LOCALE,
  SET_REGION,
} from './constants';

export const initialState = fromJS({
  locale: DEFAULT_LOCALE,
  lang: getLang(),
  region: getRegion(),
  messages: translationMessages,
});

function languageProviderReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCALE:
      return state.set('locale', payload.to);

    case SET_REGION: {
      return state.set('region', payload.to);
    }
    case SET_LANG: {
      return state.set('lang', payload.to);
    }

    case ADD_LANG: {
      return state.update('messages', messages =>
        messages.set(
          payload.lang,
          formatTranslationMessages(payload.locale, payload.translation)
        )
      );
    }

    default:
      return state;
  }
}

export default languageProviderReducer;
