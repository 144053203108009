/**
 * Http error status code (general)
 * ref: https://github.com/17media/wiki/blob/master/pages/Backend/api-design-guide.md#%E9%8C%AF%E8%AA%A4%E8%99%95%E7%90%86
 */
export enum ErrorStatusCode {
  /** 420: client request error */
  STATUS_CLIENT_ERROR = 420,
  /** 520: server error */
  STATUS_SERVER_ERROR = 520,
  /** 521: 全系統的限流 */
  STATUS_SYSTEM_THROTTLING = 521,
  /** 522: 針對單一 api 的限流 */
  STATUS_RATE_LIMITING = 522,
}
