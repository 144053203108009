import { useCallback, useReducer, useRef } from 'react';

import { DEFAULT_VIDEO_RATIO } from '@17live/app/components/VideoPlayer/VideoPlayer.constants';

const PLAYER_READY_CHANGE = 'PLAYER_READY_CHANGE';
const UPDATE_VIDEO_RATIO = 'UPDATE_VIDEO_RATIO';

const reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case PLAYER_READY_CHANGE: {
      return {
        ...state,
        isVideoReady: action.payload.isVideoReady,
      };
    }
    case UPDATE_VIDEO_RATIO: {
      return {
        ...state,
        videoRatio: action.payload.videoRatio,
      };
    }
    default:
      return state;
  }
};

export const useVideoPlayer = () => {
  const offsetRef = useRef();
  const offsetWidth = offsetRef?.current?.offsetWidth;
  const offsetHeight = offsetRef?.current?.offsetHeight;
  const [videoPlayerState, dispatch] = useReducer(reducer, {
    isVideoReady: false,
    videoRatio: DEFAULT_VIDEO_RATIO,
  });

  const handlePlayerReadyChange = useCallback(
    isVideoReady => {
      dispatch({ type: PLAYER_READY_CHANGE, payload: { isVideoReady } });
    },
    [dispatch]
  );

  const handlePlayerLoadedMetadata = useCallback(
    e => {
      const video = e.target;
      const { videoWidth, videoHeight } = video;
      dispatch({
        type: UPDATE_VIDEO_RATIO,
        payload: { videoRatio: (videoWidth / videoHeight).toFixed(3) },
      });
    },
    [dispatch]
  );

  return {
    offsetRef,
    offsetWidth,
    offsetHeight,
    videoPlayerState,
    handlePlayerReadyChange,
    handlePlayerLoadedMetadata,
  };
};
