import { Theme } from '@17live/app/constants/theme';
export enum SubmitChatModeButtonStatus {
  DEFAULT = 'default',
  ACTIVE = 'active',
  DISABLE = 'disable',
}

export enum SubmitChatMode {
  SNACK = 'snack',
  BARRAGE = 'barrage',
  NORMAL = 'normal',
}

export enum SnackStatus {
  NORMAL = 'normal',
  DONE = 'done',
}

export enum BarrageStatus {
  NORMAL = 'normal',
  ERROR = 'error',
}

export const NORMAL_THEME = {
  [SubmitChatModeButtonStatus.DEFAULT]: {
    background: '#E6E6E6',
    fill: '#333333',
    countdownProgressBackgroundStroke: '#BDBDBD',
    countdownProgressStroke: '#525252',
  },
  [SubmitChatModeButtonStatus.ACTIVE]: {
    background: '#333333',
    fill: '#fff',
    countdownProgressBackgroundStroke: '#707070',
    countdownProgressStroke: '#D1D1D1',
  },
  [SubmitChatModeButtonStatus.DISABLE]: {
    background: '#E6E6E6',
    fill: '#BDBDBD',
    countdownProgressBackgroundStroke: '',
    countdownProgressStroke: '',
  },
};

export const THEATER_THEME = {
  [SubmitChatModeButtonStatus.DEFAULT]: {
    background: '#525252',
    fill: '#FFFFFF',
    countdownProgressBackgroundStroke: '#707070',
    countdownProgressStroke: '#BDBDBD',
  },
  [SubmitChatModeButtonStatus.ACTIVE]: {
    background: '#FFFFFF',
    fill: '#333333',
    countdownProgressBackgroundStroke: '#8A8A8A',
    countdownProgressStroke: '#000000',
  },
  [SubmitChatModeButtonStatus.DISABLE]: {
    background: '#525252',
    fill: '#8A8A8A',
    countdownProgressBackgroundStroke: '',
    countdownProgressStroke: '',
  },
};

export const SUBMIT_CHAT_MODE_CONFIG: {
  [key: string]: any;
} = {
  [SubmitChatMode.SNACK]: {
    isNeedTip: true,
    tips: {
      [SnackStatus.NORMAL]: 'snack_livestream_description',
      [SnackStatus.DONE]: 'snack_livestream_description_done',
    },
    buttonTheme: {
      [Theme.NORMAL]: NORMAL_THEME,
      [Theme.THEATER]: THEATER_THEME,
    },
  },

  [SubmitChatMode.BARRAGE]: {
    isNeedTip: true,
    tips: {
      [BarrageStatus.NORMAL]: 'barrage_streamroom_length_reminder',
      [BarrageStatus.ERROR]: 'without_babycoins_barrage',
    },
    buttonTheme: {
      [Theme.NORMAL]: NORMAL_THEME,
      [Theme.THEATER]: THEATER_THEME,
    },
  },
};
