import { defer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeAll, retry } from 'rxjs/operators';

import omitDeep from '@/utils/omitDeep';

import { admin } from '@17live/app/constants/configs';
import Logger from '@17live/app/services/Logger';
import { handleError } from '@17live/app/utils/api';
import { authToken, getAuthHeader } from '@17live/app/utils/authToken';

import { RETRIES } from './constants';

const LOGGER_LABEL = 'AdminApi';

const adminApi = ({ endpoint, method, payload }) => {
  return defer(async () => {
    // 因應 MON-1112 的需求，需在呼叫 admin api 的時候帶上 JWT token
    // 但 admin api 需設定 Access-Control-Allow-Headers，目前只針對儲值相關的 api 設定，
    // 未來若有其他功能需要使用 admin api，需在 admin 增加設定，以避免出現 CORS 錯誤
    let jwtAccessToken;

    try {
      jwtAccessToken = await authToken.getJwtToken();
    } catch (error) {
      return Promise.reject(error);
    }

    const options = omitDeep({
      url: `${admin.url}${endpoint}`,
      method,
      headers: {
        Accept: 'application/json',
        ...getAuthHeader(jwtAccessToken),
      },
      crossDomain: true,
      body: payload,
    });

    return ajax(options).pipe(
      retry(RETRIES),
      map(e => e.response),
      catchError(error => {
        const errorResponse = error.xhr && error.xhr.response;

        Logger.info(LOGGER_LABEL, '17App admin API failed', {
          endpoint,
          method,
          payload,
          error,
        });

        handleError(error);

        if (errorResponse) {
          throw errorResponse;
        }

        throw error;
      })
    );
  }).pipe(mergeAll());
};

export default adminApi;
