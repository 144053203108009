/*
 *
 * Preference constants
 *
 */
export const INIT_PREFERENCE = 'app/PreferenceProvider/INIT_PREFERENCE';
export const SET_PREFERENCE = 'app/PreferenceProvider/SET_PREFERENCE';
export const GET_PREFERENCE = 'app/PreferenceProvider/GET_PREFERENCE';
export const UPDATE_PREFERENCE = 'app/PreferenceProvider/UPDATE_PREFERENCE';

export const MAX_HISTORY_LIVE_STREAMS = 100;
export const MAX_VOLUME = 1;
