// @flow
import { Component, type Node } from 'react';

type Props = {
  onOpenStateChanged: (isOpen: boolean) => void,
  isOpen: boolean,
  children: Node,
};

class OpenStateUpdater extends Component<Props> {
  static defaultProps = {
    onOpenStateChanged: () => {},
  };

  componentDidMount() {
    this.props.onOpenStateChanged(this.props.isOpen);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.onOpenStateChanged(this.props.isOpen);
    }
  }

  componentWillUnmount() {
    this.props.onOpenStateChanged(false);
  }

  render() {
    return this.props.children;
  }
}

export default OpenStateUpdater;
