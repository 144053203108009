// @flow
import Storage from './Storage';

const LocalStorage = new Storage('localStorage');

const SessionStorage = new Storage('sessionStorage');

export * from './constants';

export { LocalStorage, SessionStorage };
