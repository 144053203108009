/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const SET_LOCALE = 'app/LanguageToggle/SET_LOCALE';
export const ADD_LOCALE = 'app/LanguageToggle/ADD_LOCALE';

export const CHANGE_REGION = 'app/LanguageToggle/CHANGE_REGION';
export const SET_REGION = 'app/LanguageToggle/SET_REGION';

export const CHANGE_LANG = 'app/LanguageToggle/CHANGE_LANG';
export const SET_LANG = 'app/LanguageToggle/SET_LANG';
export const ADD_LANG = 'app/LanguageToggle/ADD_LANG';

/**
 * TODO: Move lang const to packages/app/constants/langs.ts
 * @see https://17media.atlassian.net/browse/APP-57507
 */
/**
 * react-intl locale keys
 */
// default
export const LOCALE_EN = 'en';
export const LOCALE_ZH_TW = 'zh-Hant';
export const LOCALE_GLOBAL = 'global';
export const DEFAULT_LOCALE = LOCALE_GLOBAL;

// lazy-loaded
export const LOCALE_ZH_CN = 'zh-Hans';
export const LOCALE_JP = 'ja';
export const LOCALE_KR = 'ko';
export const LOCALE_EN_US = 'en-US';
export const LOCALE_AR = 'ar';
export const LOCALE_HK = 'zh-HK';

// no translation data
export const LOCALE_MY = 'ms-MY';
export const LOCALE_SG = 'zh-Hant-SG';

// parent locale group
export const LOCALE_ZH = 'zh';

/**
 * @deprecated
 */
export const FALLBACK_LOCALE_MAP = {
  [LOCALE_HK]: LOCALE_HK,
  [LOCALE_MY]: LOCALE_EN,
  [LOCALE_SG]: LOCALE_ZH_TW,
  [LOCALE_GLOBAL]: LOCALE_EN,
};
