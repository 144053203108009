import { isObject } from 'lodash';

// 因為把 log 功能從 node server 轉移到前端，為了不改變欄位結構，所以沿用原本邏輯，在送出前會 flatten 所有欄位
// https://github.com/17media/node-logger/blob/3785b8e27764dcb0553a78cae73883a92e1a0909/src/utils/index.js
const flattenObject = (source, prefix = '', refSet = new Set()) => {
  // if type or source is primitive, return value directly
  if (!isObject(source)) {
    return { [prefix || 'value']: source };
  }

  // check for circular reference
  if (refSet.has(source)) {
    return { [prefix]: '[Circular Reference]' };
  }
  refSet.add(source);

  // process fields in object
  return Object.keys(source)
    .map(key =>
      flattenObject(source[key], prefix ? `${prefix}.${key}` : key, refSet)
    )
    .reduce((prev, curr) => Object.assign(prev, curr), {});
};

export default flattenObject;
