// @flow
/* eslint-disable no-underscore-dangle, no-console */
import uuid from 'uuid-v4';

import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';

import {
  CustomDimensions,
  getCommonDimensions,
  mapDimensionKeyToID,
} from '@17live/app/services/analytics';
import { Config } from '@17live/app/types/Config';
import { fetchAPI } from '@17live/app/utils/api';

import {
  MATOMO_PROD_ENDPOINTS,
  MATOMO_STA_ENDPOINTS,
  MATOMO_VERSIONS,
} from './constants';
import loadScript from './loadScript';

export const MATOMO_ENDPOINTS =
  // stage and uat use same endpoint
  process.env.DEPLOY_ENV === 'production'
    ? MATOMO_PROD_ENDPOINTS
    : MATOMO_STA_ENDPOINTS;

export const hasCookies = () => {
  try {
    // Create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
};

/**
 * TODO: 之後可以考慮跟 crm loadMatomo 部分共用
 * @see packages/crm/utils/analytics.ts
 */
export const loadMatomo = async () => {
  const { userID } = LocalStorage.getItem(KEY_LOGIN_INFO) || {};

  window._paq = window._paq || [];

  /**
   * @description 為了 Matomo tracking 對應後台設定的 SiteId
   * @see https://matomo.org/faq/general/faq_19212/
   */
  window._paq.push(['setSiteId', 1]);

  if (userID) {
    window._paq.push(['setUserId', userID]);
  } else if (!hasCookies()) {
    /**
     * Fix issue APP-98105
     * @description Because Matomo in X(Twitter) iFrame don't set sandbox,
     * cookie does not exist visitorID in iOS device,
     * so we use uuid to setVisitorId to Matomo
     * @see https://17media.atlassian.net/l/cp/1AMmFwxX
     */
    const visitorID = uuid()
      .replace(/-/g, '')
      .substring(0, 16);
    window._paq.push(['setVisitorId', visitorID]);
  }

  fetchAPI('config').then((res: Config) => {
    const version =
      // eslint-disable-next-line camelcase
      res?.matomo_event_grpc?.web_matomo_version ?? MATOMO_VERSIONS.matomo;

    loadScript(MATOMO_ENDPOINTS[version]);
  });
};

export const identifyUser = (userID = null) => {
  if (window._paq) {
    if (userID) {
      window._paq.push(['setUserId', userID]);
    } else {
      window._paq.push(['resetUserId']);
    }
  }
};

export const identifyAdminUser = (username = '') => {
  if (window._paq) {
    if (username) {
      window._paq.push(['setCustomDimension', 35, username]);
    } else {
      window._paq.push(['setCustomDimension', 35, null]);
    }
  }
};

export const trackPageView = (
  replaceUrl = null,
  customDimension: CustomDimensions = {}
) => {
  const fullPathName =
    replaceUrl ?? `${location.pathname}${location.search}${location.hash}`;

  const dimension = {
    ...getCommonDimensions(),
    ...customDimension,
  };

  if (window._paq?.push) {
    window._paq.push(['setCustomUrl', fullPathName]);
    window._paq.push([
      'trackPageView',
      null,
      {
        ...mapDimensionKeyToID(dimension),
      },
    ]);
  }

  if (process.env.NODE_ENV === 'development') {
    console.group(`🌍[trackPageView] at ${new Date()}`);
    console.info(
      JSON.stringify(
        {
          // title: document.title,
          url: fullPathName,
          dimension,
        },
        null,
        2
      )
    );
    console.groupEnd();
  }
};

export const trackEvent = (
  eventCategory = '',
  eventAction = '',
  eventLabel = '',
  value = null,
  customDimensions = {}
) => {
  if (window._paq) {
    window._paq.push([
      'trackEvent',
      eventCategory,
      eventAction,
      eventLabel,
      value,
      customDimensions,
    ]);
  }
};
