import * as Ably from 'ably';
import { Observable } from 'rxjs';

import { KEY_DEV_ABLY_SERVER, SessionStorage } from '@/services/Storage';

import {
  STAGE_SUBSCRIBE_ABLY_KEY,
  SUBSCRIBE_ABLY_KEY,
  UAT_SUBSCRIBE_ABLY_KEY,
} from '@17live/app/services/Ably/Ably.constants';
import { getAblyDecodeData } from '@17live/app/services/Ably/Ably.utils';
import {
  GLOBAL_ANNOUNCEMENT_CHANNEL_ID,
  GLOBAL_CHANNEL_ID,
  GLOBAL_LIVE_PROGRAM_CHANNEL_ID,
} from '@17live/app/services/Message/Message.constants';
import { MessageType } from '@17live/app/services/Message/Message.enums';
import { getSubscribeKeyByEnv } from '@17live/app/services/Message/Message.utils';

const FallbackHosts = [
  '17-media-a-fallback.ably-realtime.com',
  '17-media-b-fallback.ably-realtime.com',
  '17-media-c-fallback.ably-realtime.com',
];

const { key = STAGE_SUBSCRIBE_ABLY_KEY } =
  SessionStorage.getItem(KEY_DEV_ABLY_SERVER) || {};
const subscribeKey = getSubscribeKeyByEnv(
  'Ably',
  key,
  SUBSCRIBE_ABLY_KEY,
  STAGE_SUBSCRIBE_ABLY_KEY,
  UAT_SUBSCRIBE_ABLY_KEY
);
const options = {
  key: subscribeKey,
  environment: '17media',
  fallbackHosts: FallbackHosts,
};
const ablyClient = new Ably.Realtime(options);

export const unsubscribeChatRoom = roomID => {
  ablyClient.channels.get(roomID).unsubscribe();

  return roomID;
};

export const subscribeChatRoom = roomID =>
  new Observable(observer => {
    ablyClient.channels.get(roomID).subscribe(message => {
      const data = getAblyDecodeData(message);
      observer.next(data);
    });

    return () => unsubscribeChatRoom(roomID);
  });

export const unsubscribeGlobalLiveProgram = () => {
  ablyClient.channels.get(GLOBAL_LIVE_PROGRAM_CHANNEL_ID).unsubscribe();
};

export const subscribeGlobalLiveProgram = () =>
  new Observable(observer => {
    ablyClient.channels
      .get(GLOBAL_LIVE_PROGRAM_CHANNEL_ID)
      .subscribe(message => {
        const data = getAblyDecodeData(message);
        observer.next(data);
      });

    return () => unsubscribeGlobalLiveProgram();
  });

export const unsubscribeGlobalAnnouncementChannel = () => {
  ablyClient.channels.get(GLOBAL_ANNOUNCEMENT_CHANNEL_ID).unsubscribe();
};

export const subscribeGlobalAnnouncementChannel = () =>
  new Observable(observer => {
    ablyClient.channels
      .get(GLOBAL_ANNOUNCEMENT_CHANNEL_ID)
      .subscribe(message => {
        const data = getAblyDecodeData(message);
        const { type } = data;

        if (type === MessageType.GLOBAL_ANNOUNCEMENT) {
          observer.next();
        }
      });

    ablyClient.connection.whenState('connected', () => {
      observer.next();
    });

    return () => unsubscribeGlobalAnnouncementChannel();
  });

export const unsubscribeGlobalChannel = () => {
  ablyClient.channels.get(GLOBAL_CHANNEL_ID).unsubscribe();
};

export const subscribeGlobalChannel = () =>
  new Observable(observer => {
    ablyClient.channels.get(GLOBAL_CHANNEL_ID).subscribe(message => {
      const data = getAblyDecodeData(message);
      observer.next(data);
    });

    return () => unsubscribeGlobalChannel();
  });
