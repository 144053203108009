import { useMemo } from 'react';

import { medias } from '@/constants/breakpoints';

import { useResize } from './useResize';

export const useMediaQuery = () => {
  const { screenWidth } = useResize(undefined);

  const media = useMemo(() => {
    if (screenWidth < medias.sm) {
      return 'xs';
    } else if (screenWidth >= medias.sm && screenWidth < medias.md) {
      return 'sm';
    } else if (screenWidth >= medias.md && screenWidth < medias.lg) {
      return 'md';
    } else if (screenWidth >= medias.lg && screenWidth < medias.xl) {
      return 'lg';
    } else if (screenWidth >= medias.xl && screenWidth < medias.xxl) {
      return 'xl';
    }
    return 'xxl';
  }, [screenWidth]);

  return { media };
};

export default useMediaQuery;
