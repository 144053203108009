// @flow
import type { Observable } from 'rxjs';
import type { Recap } from '@/data/recap';
import type { Clip } from '@/data/clip';
import apis from './shared/apis';

export const API_PATH = 'clips';

export const CLIP_TYPES = Object.freeze({
  UNKNOWN: 0,
  CLIP: 1,
  RECAP: 2,
});

export const getClip = (clipID: string): Observable<Clip> =>
  apis.get(`${API_PATH}/${clipID}`);

export const getRecap = (recapID: string): Observable<Recap> =>
  apis.get(`${API_PATH}/${recapID}`);
