import md5 from 'md5';
import { of, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, retry } from 'rxjs/operators';

import { registerWithThirdParty } from '@/containers/ThirdPartyAction/actions';
import { THIRD_PARTY_TYPES } from '@/containers/ThirdPartyAction/constants';
import formatPhoneNumber from '@/utils/formatPhoneNumber';

import { MAP_FE_LANG_TO_BE_LANG } from '@17live/app/constants';
import configs from '@17live/app/constants/configs';
import { getRefererForRecordingLandingPage } from '@17live/app/containers/LandingPage/LandingPage.utils';
import { loginSuccess } from '@17live/app/containers/LoginProvider/actions';
import { getLang } from '@17live/app/utils';

import apis from './shared/apis';
import { RETRIES, SMS_KEY } from './shared/constants';
import responseHandler from './shared/responseHandler';

const API_PATH = 'auth';

// error codes from 17App API
export const ERROR_CODE_THIRD_PARTY_ID_NOT_EXISTS = 'third_party_ID_not_exists';
export const ERROR_CODE_WRONG_PASSWORD = 'password_wrong';
export const ERROR_MESSAGE_LOGIN_ACCOUNT_NOT_REGISTERED =
  'login_account_not_registered';

export const ERROR_MESSAGE_LOGIN_ACCOUNT_NO_SUCH_USER = 'no_such_user';

export const loginAction = ({ openID, password }) =>
  apis
    .postJSON(`${API_PATH}/loginAction`, {
      openID,
      password: md5(password),
      language: MAP_FE_LANG_TO_BE_LANG[getLang()].toLocaleUpperCase(),
    })
    .pipe(catchError(error => of(error)));

export const loginWithThirdParty = (type, data) =>
  apis.postJSON(`${API_PATH}/loginAction2`, data).pipe(
    catchError(err =>
      throwError({
        ...err,
        type,
      })
    )
  );

export const loginWithApple = data =>
  apis.postJSON(`auth/loginAction2`, data).pipe(
    map(loginInfo => {
      const { userInfo, jwtAccessToken, refreshToken } = loginInfo;

      return loginSuccess({
        ...userInfo,
        jwtAccessToken,
        refreshToken,
      });
    }),
    catchError(err => {
      if (err?.message === ERROR_MESSAGE_LOGIN_ACCOUNT_NO_SUCH_USER) {
        return of(
          registerWithThirdParty(THIRD_PARTY_TYPES.APPLE, {
            oauthInfo: {
              id: data.appleID,
            },
            thirdPartyInfo: data,
            referer: getRefererForRecordingLandingPage(), // MSite Apple
          })
        );
      }
      return throwError(err);
    })
  );

export const getSelfInfo = () => apis.legacy('getSelfInfo', {});

export const checkOAuthIDAvailable = payload =>
  apis.post(
    `${API_PATH}/checkOAuthIDAvailable`,
    payload,
    {},
    { retries: 0, ignoreFail: true }
  );

export const authWithLine = ({ code, state }) =>
  ajax({
    url: '/api/line/accessToken',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      code,
      state,
    },
  }).pipe(
    retry(RETRIES),
    map(e => e.response),
    map(responseHandler),
    catchError(error => of(error))
  );

// error codes from 17App API
export const ERROR_CODE_REQUEST_ID = 'requestID_error';
export const ERROR_CODE_IP_RATE_LIMIT_EXCEEDS = 'ip_rate_limit_exceeds';
export const ERROR_CODE_NEXMO_ERROR = 'nexmo_error';
export const ERROR_CODE_PHONENUMBER_EXISTS = 'phone_number_exists';
export const ERROR_CODE_PHONENUMBER_USED = 'phoneNumber_used';
export const ERROR_CODE_PHONENUMBER_ERROR = 'phoneNumber_error';
export const ERROR_CODE_OPENID_PHONENUMBER_NOT_PAIRED =
  'openID_phoneNumber_not_paired';
export const ERROR_CODE_VERIFICATION_CODE_TIMEOUT = 'verificationCode_timeout';
export const ERROR_CODE_VERIFICATION_CODE_USED = 'verificationCode_used';
export const ERROR_CODE_VERIFICATION_CODE_ERROR = 'verificationCode_error';
export const ERROR_CODE_OPENID_EXISTS = 'openID_exists';
export const ERROR_CODE_OPENID_NOT_EXISTED = 'openID_not_existed';
export const ERROR_CODE_MAXIMUM_COUNT_EXCEEDS = 'maximum_count_exceeds';
export const ERROR_CODE_NEW_PASSWORD_CONFIRM_ERROR =
  'new_password_confirm_error';
export const ERROR_CODE_RESET_PASSWORD_TOKEN_TIMEOUT =
  'resetPasswordToken_timeout';
export const ERROR_CODE_RESET_PASSWORD_TOKEN_USED = 'resetPasswordToken_used';
export const ERROR_THIRD_PARTY_LOGIN_NOT_AVAILABLE =
  'third_party_login_not_available';
export const ERROR_THIRD_PHONE_NUMBER_NOT_ALLOWED = 'phoneNumber_not_allowed';

export const checkOpenIDAvailable = ({ openID }) =>
  apis.postJSON(
    `${API_PATH}/checkOpenIDAvailable`,
    { openID },
    {},
    { retries: 0, ignoreFail: true }
  );

export const isPhoneNumberUsed = ({ countryCallingCode, localPhoneNumber }) =>
  apis.postJSON(`${API_PATH}/phone/isInUse`, {
    countryCallingCode,
    localPhoneNumber: formatPhoneNumber(localPhoneNumber),
  });

export const checkNeedHumanTest = ({ type }) =>
  apis.postJSON(`${API_PATH}/checkNeedHumanTest`, { type });

export const sendPhoneVerificationCode = ({
  requestID,
  type,
  language,
  countryCallingCode,
  phoneNumber,
  openID,
}) =>
  apis.postJSON(`${API_PATH}/phone/sendVerificationCode`, {
    requestID,
    type,
    language,
    countryCallingCode,
    phoneNumber: formatPhoneNumber(phoneNumber),
    openID,
    signature: md5(
      requestID +
        language +
        countryCallingCode +
        formatPhoneNumber(phoneNumber) +
        SMS_KEY
    ),
  });

export const registerByPhoneAction = ({
  openID,
  password,
  gender = '',
  requestID, // should be the same with sendPhoneVerificationCode
  countryCallingCode,
  localPhoneNumber,
  phoneTwoDigitISO, // should map be country code
  referer,
}) =>
  apis.postJSON(
    `${API_PATH}/phone/register`,
    {
      openID,
      password: md5(password),
      gender,
      requestID,
      countryCallingCode,
      localPhoneNumber: formatPhoneNumber(localPhoneNumber),
      phoneTwoDigitISO,
    },
    {
      'X-Referer-URI': referer,
    }
  );

export const verifyBySMSAction = ({
  requestID, // should be the same with sendPhoneVerificationCode
  verificationCode,
}) =>
  apis.postJSON(`${API_PATH}/sms/verify`, {
    requestID,
    verificationCode,
  });

export const isPhoneNumberOpenIDPaired = ({
  countryCallingCode,
  localPhoneNumber,
  openID,
}) =>
  apis.legacy('isPhoneNumberOpenIDPaired', {
    countryCallingCode,
    localPhoneNumber: formatPhoneNumber(localPhoneNumber),
    openID,
  });

export const getResetPasswordToken = ({
  requestID, // should be the same with sendPhoneVerificationCode
  openID,
  countryCallingCode,
  localPhoneNumber,
  verificationCode,
}) =>
  apis.legacy('getResetPasswordToken', {
    requestID,
    openID,
    countryCallingCode,
    localPhoneNumber: formatPhoneNumber(localPhoneNumber),
    verificationCode,
  });

export const resetPassword = ({
  resetPasswordToken,
  newPassword,
  confirmNewPassword,
}) =>
  apis.legacy('resetPassword', {
    resetPasswordToken,
    newPassword: md5(newPassword),
    confirmNewPassword: md5(confirmNewPassword),
  });

export const registerWithOAuth = ({ openID, oauthInfo, referer }) =>
  apis.postJSON(
    `${API_PATH}/registerAction2`,
    {
      openID,
      deviceType: configs.device.deviceType,
      ...oauthInfo,
    },
    {
      'X-Referer-URI': referer,
    }
  );

export const bindPhoneNumber = ({
  requestID,
  countryCallingCode,
  localPhoneNumber,
  verificationCode,
  phoneTwoDigitISO,
}) =>
  apis.postJSON(`${API_PATH}/phone/change`, {
    requestID,
    countryCallingCode,
    localPhoneNumber: formatPhoneNumber(localPhoneNumber),
    verificationCode,
    phoneTwoDigitISO,
  });
