// @flow
export const CDN_URL: string = 'https://cdn.17app.co';

// For replace API legacy CDN url to SSL CDN url
export const LEGACY_CDN_URL = 'http://cdn.17app.co/';

/**
 * For fetch cors issue
 * this domain has allow-origin: *
 * https://17media.atlassian.net/browse/TECH-446
 *
 * Cost Saving
 * 因為需要省錢所以改回使用 cdn.17app.co
 * https://17media.atlassian.net/browse/APP-98779
 */
export const CDN_CORS_HOST = 'cdn.17app.co';
export const CDN_CORS_URL = `https://${CDN_CORS_HOST}/`;
export const CDN_THUMBNAIL = `//${CDN_CORS_HOST}`;

export const GCP_BASE_HOST = 'webcdn.17app.co';
export const GCP_BASE_URL: string = `https://${GCP_BASE_HOST}`;
export const GCP_BASE_IMG_URL: string = `https://${GCP_BASE_HOST}/17live/images`;
export const GCP_URL: string = `${GCP_BASE_URL}/17live`;
export const GCP_BASE_LOTTIE_JSON_URL: string = `https://${GCP_BASE_HOST}/17live/lottie`;

export const DEFAULT_IMAGE: string = 'ig-default.svg';
export const DEFAULT_AVATAR_IMAGE: string =
  'images/ig_default_profile_image.svg';
export const DEFAULT_EMPTY_BANNER: string = 'images/ig-empty-banner.svg';
export const DEFAULT_EMPTY_IMAGE: string = 'images/ig_empty_image.svg';
export const THUMBNAIL_PREFIX: string = 'THUMBNAIL_';
export const MEDIA_URLS: { [string]: string } = {
  DEFAULT: 'http://17.media',
  JP: 'https://www.17media.jp/',
};

/**
 * status
 */
export const STATUS = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
});

export const HTTP_PREFIX: string = 'http://';
export const HTTPS_PREFIX: string = 'https://';
