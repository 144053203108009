// @flow

export const MODAL_MIN_HEIGHT: number = 300; // px
export const MODAL_MAX_WIDTH: number = 404; // px
export const MODAL_VERTICAL_MARGIN: number = 60; // px
export const MODAL_HORIZONTAL_MARGIN: number = 24; // px
export const MODAL_VERTICAL_PADDING: number = 40; // px
export const MODAL_HORIZONTAL_PADDING: number = 24; // px
export const MODAL_TRANSFORM_Y: number = -50; // px, initial transition height
export const BG_MODAL_OVERLAY: string = 'rgba(40, 35, 45, 0.4)';
export const MODAL_TRANSITION_TIMEOUT: number = 400; // ms

export const TABBALE_ELEMENTS = [
  'input',
  'select',
  'textarea',
  'a[href]',
  'button',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
];

export const MODAL_TITLE_ID = 'react-route-modal-title';
export const MODAL_DESCRIPTION_ID = 'react-route-modal-description';
