// @flow
import noop from 'lodash/noop';

import Logger from '@17live/app/services/Logger';

import {
  WANSU_EVENTS,
  WANSU_HOSTNAME,
  WANSU_MESSAGE_TYPES,
  WS_BINARY_TYPES,
  WS_EXPECTED_CODES,
  WS_STATE,
} from './constants';
import { getReconnectDelayMS, processMessage } from './utils';

type WansuEventName = $Values<typeof WANSU_EVENTS>;

type WansuWebSocket = {
  ...WebSocket,
  roomID: string,
  manualDisconnection: boolean,
  close: () => void,
};

type Connection = {
  ws: ?WansuWebSocket,
  connectionTimes: number,
  reconnectTimerID: ?TimeoutID,
};

type WansuEvents = {
  [eventName: mixed]: (roomID: string, ...args: mixed[]) => void,
};

type WansuEventTarget = EventTarget & {
  roomID?: string,
  manualDisconnection?: boolean,
};

const LOGGER_LABEL: string = 'WansuSDK';

const WSSocket: (url: string, protocol: string) => WansuWebSocket =
  window.WebSocket || window.MozWebSocket;

class WansuSDK {
  hostname: string = WANSU_HOSTNAME;
  port: number = 0;
  isSecure: boolean = process.env.NODE_ENV === 'production';
  connections: Map<string, Connection> = new Map();
  events: Map<string, WansuEvents> = new Map();

  static isSupported = (): boolean =>
    typeof window !== 'undefined' && !!WSSocket;

  getWebSocketURL = (roomID: string): string => {
    const { hostname, port, isSecure } = this;
    const protocol = isSecure ? 'wss' : 'ws';
    const origin = port > 0 ? `${hostname}:${port}` : hostname;

    return encodeURI(`${protocol}://${origin}/${roomID}`);
  };

  handleOpen = ({ target }: { target: WansuEventTarget }) => {
    const { roomID = '', manualDisconnection }: WansuEventTarget = target;

    /**
     * handle leave room before connection is established
     */
    if (manualDisconnection) {
      this.leaveRoom(roomID);
    } else {
      this.getEvent(roomID, WANSU_EVENTS.CONNECT)();
    }
  };

  handleClose = ({
    target,
    code,
    reason,
  }: {
    target: WansuEventTarget,
    code: number,
    reason: string,
  }) => {
    const { roomID = '', manualDisconnection }: WansuEventTarget = target;
    const { connectionTimes } = this.connections.get(roomID) || {};

    // log unexpected close event with reason
    if (!WS_EXPECTED_CODES.includes(code) && reason) {
      Logger.error(
        `${LOGGER_LABEL}:handleError`,
        JSON.stringify({ code, reason, connectionTimes, manualDisconnection })
      );
    }

    if (manualDisconnection) {
      return;
    }

    // auto reconnect
    const reconnectDelayMS = getReconnectDelayMS(connectionTimes);
    const connection = this.connections.get(roomID) || {};

    connection.reconnectTimerID = window.setTimeout(() => {
      connection.ws = this.initWebSocket(roomID);
      connection.connectionTimes = connectionTimes + 1;
    }, reconnectDelayMS);
  };

  handleMessage = ({
    target,
    data,
  }: {
    target: WansuEventTarget,
    data: mixed,
  }) => {
    const { roomID = '' }: WansuEventTarget = target;

    if (!(data instanceof ArrayBuffer)) {
      return;
    }

    processMessage(data)
      .then(({ message, messageType }) => {
        switch (messageType) {
          case WANSU_MESSAGE_TYPES.ENTER_ROOM:
            this.getEvent(roomID, WANSU_EVENTS.ENTER_ROOM)(message[14]);
            break;
          case WANSU_MESSAGE_TYPES.PUBLIC_MESSAGE:
            this.getEvent(roomID, WANSU_EVENTS.BARRAGE)(message);
            break;
          case WANSU_MESSAGE_TYPES.PRIVATE_MESSAGE:
          default:
            // do nothing
            break;
        }
      })
      .catch(error => {
        Logger.info(`${LOGGER_LABEL}:handleMessage`, error && error.toString());
      });
  };

  getEvent = (
    roomID: string,
    eventName: WansuEventName
  ): ((...args: mixed[]) => void) => (...args) => {
    const events = this.events.get(roomID) || {};

    if (events[eventName]) {
      return events[eventName](roomID, ...args);
    }
  };

  initWebSocket = (roomID: string): ?WansuWebSocket => {
    if (!WansuSDK.isSupported()) {
      return;
    }

    const wsURL = this.getWebSocketURL(roomID);
    const ws: WansuWebSocket = new WSSocket(wsURL, 'binary');

    /**
     * bind info on ws for handleOpen and handleClose
     * ws events are async, so `open -> close -> open` with the same roomID would get incorrect info
     */
    ws.roomID = roomID;
    ws.manualDisconnection = false;
    // set to arraybuffer because using FileReader may cause NotReadableError
    ws.binaryType = WS_BINARY_TYPES.ARRAY_BUFFER;
    ws.onopen = this.handleOpen;
    ws.onclose = this.handleClose;
    ws.onmessage = this.handleMessage;

    return ws;
  };

  connect = (roomID: string): ?Connection => {
    // close previous connection
    if (this.connections.has(roomID)) {
      this.leaveRoom(roomID);
    }

    const ws = this.initWebSocket(roomID);

    if (!ws) {
      return;
    }

    // setup connection info
    this.connections.set(roomID, {
      ws,
      connectionTimes: 1,
      reconnectTimerID: null,
    });

    return this.connections.get(roomID);
  };

  setServer = (hostname: string, port: number, isSecure: boolean) => {
    this.hostname = hostname;
    this.port = port;
    this.isSecure = isSecure;
  };

  addEventListener = (roomID: string, events: WansuEvents) => {
    const currentEvents = this.events.get(roomID) || {};

    this.events.set(
      roomID,
      Object.values(WANSU_EVENTS).reduce(
        (acc, eventName) => ({
          ...acc,
          [String(eventName)]: events[eventName] || noop,
        }),
        currentEvents
      )
    );
  };

  enterRoom = (roomID: string): ?Connection => {
    // check roomID is validate or not
    if (isNaN(roomID) || Number(roomID) < 1) {
      if (process.env.NODE_ENV !== 'production') {
        throw new Error('roomID is invalid');
      }

      return;
    }

    return this.connect(roomID);
  };

  leaveRoom = (roomID: string): void => {
    const connection = this.connections.get(roomID);
    if (!connection) {
      return;
    }

    const { ws, reconnectTimerID } = connection;

    // close connection
    if (ws) {
      ws.manualDisconnection = true;
      window.clearTimeout(reconnectTimerID);

      /**
       * Prevent WebSocket is closed before the connection is established.
       * Error code: 1006
       */
      if (ws.readyState !== WS_STATE.CONNECTING) {
        ws.close();
      }
    }

    this.getEvent(roomID, WANSU_EVENTS.DISCONNECT)();
    this.events.delete(roomID);
    this.connections.delete(roomID);
  };
}

export default new WansuSDK();
