import { stringify } from 'query-string';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LOCAL_KEYS, VIEWING_STATUS } from '@/services/Video/constants';

import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import { ReactionTypes } from '@17live/app/services/Message/Message.enums';

import apis from './shared/apis';
import { DEFAULT_COUNT, ERROR_CODES } from './shared/constants';

const API_PATH = 'lives';

/**
 * Error Messages: stores/live/live.go from go api
 */
const LIVES_ERROR_MESSAGES = {
  NOT_APPROVED: "liveStream isn't passed",
  IS_PREMIUM: 'premium content is locked',
  IS_ARMY_ONLY: 'premium content is locked because not subscriber',
  IS_NEW_USER_WELCOME_STREAM:
    'premium content is locked because not new user or invited viewer',
};

const mapErrorToViewingStatus = error => {
  if (!error) {
    return VIEWING_STATUS.AVAILABLE;
  }

  const { result, message, errorCode, errorMessage } = error;

  // legacy error format
  if (result === 'fail') {
    switch (message) {
      case 'blocked':
        return VIEWING_STATUS.BLOCKED;
      case 'ended':
        return VIEWING_STATUS.ENDED;
      default:
        return VIEWING_STATUS.FAILED;
    }
  }

  // error format
  switch (errorCode) {
    case ERROR_CODES.STREAMER_BLOCKED:
      return VIEWING_STATUS.BLOCKED;
    case ERROR_CODES.GENERIC_ERROR: {
      switch (errorMessage) {
        case LIVES_ERROR_MESSAGES.IS_PREMIUM:
        case LIVES_ERROR_MESSAGES.IS_ARMY_ONLY:
        case LIVES_ERROR_MESSAGES.IS_NEW_USER_WELCOME_STREAM:
          return VIEWING_STATUS.AVAILABLE;
        case LIVES_ERROR_MESSAGES.NOT_APPROVED:
          return VIEWING_STATUS.NOT_APPROVED;
        default: {
          return VIEWING_STATUS.FAILED;
        }
      }
    }
    default: {
      return VIEWING_STATUS.FAILED;
    }
  }
};

const handleEnterOrKeepViewLiveStream = (liveStream = {}) => {
  return {
    ...liveStream,
    [LOCAL_KEYS.VIEWING_STATUS]: mapErrorToViewingStatus(liveStream.error),
  };
};

export const sendReactMessage = (roomID, type) => {
  const payload = {
    liveStreamID: Number(roomID),
    type,
  };

  return apis.postJSON(`${API_PATH}/${roomID}/reacts`, payload);
};

export const sendSharedOnFacebookMessage = roomID =>
  sendReactMessage(roomID, ReactionTypes.SHARE_FB);
export const sendSharedOn17Message = roomID =>
  sendReactMessage(roomID, ReactionTypes.SHARE_17);
export const sendLikedMessage = roomID =>
  sendReactMessage(roomID, ReactionTypes.LIKE);
export const sendFollowedMessage = roomID =>
  sendReactMessage(roomID, ReactionTypes.FOLLOW);

export const likeLiveStream = (roomID, traceID) =>
  apis.postJSON(
    `${API_PATH}/${roomID}/like`,
    {},
    setTrackAPIHeaders({ 'trace-id': traceID })
  );

export const getLiveStreamViewers = roomID =>
  apis.getJSON(`${API_PATH}/${roomID}/viewers`);

export const getPullURLs = roomID =>
  apis.getJSON(`${API_PATH}/${roomID}/pullURLs?${Date.now()}`).pipe(
    catchError(error => of({ error })),
    map(rtmpUrls => rtmpUrls)
  );

export const enterLiveStream = (roomID, customParameters) =>
  apis
    .postJSON(`${API_PATH}/${roomID}/enter?${stringify(customParameters)}`)
    .pipe(
      catchError(error => of({ error })),
      map(liveStream => handleEnterOrKeepViewLiveStream(liveStream))
    );

export const keepViewLiveStream = roomID =>
  apis
    .postJSON(
      `${API_PATH}/${roomID}/viewers/alive`,
      { liveStreamID: roomID },
      {},
      { retries: 0 }
    )
    .pipe(
      catchError(error => of({ error })),
      map(liveStream => handleEnterOrKeepViewLiveStream(liveStream))
    );

export const getLiveStreamGiftLeaderboard = (
  userID,
  liveStreamID,
  count = DEFAULT_COUNT,
  offset = 0
) =>
  apis.legacy('getLiveStreamGiftLeaderboard', {
    userID,
    liveStreamID,
    count,
    offset,
  });
