import deepEqual from 'lodash/isEqual';
import { is } from 'immutable';

// TODO: replace this with the same function from package when upgrade to immutable >= 4
const isCollection = (maybeCollection: any) =>
  !!(maybeCollection && maybeCollection['@@__IMMUTABLE_ITERABLE__@@']);

const isEqual = (prev: any, cur: any) => {
  if (isCollection(prev) && isCollection(cur)) {
    return is(prev, cur);
  }

  return deepEqual(prev, cur);
};

export default isEqual;
