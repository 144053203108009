// @flow
/*
 *
 * AsideLive actions
 *
 */

import {
  RESET_ASIDE_LIVE,
  SET_ASIDE_LIVE_ACTIVE_TAB,
  SWITCH_THEME,
} from '@/containers/AsideLive/constants';

export const switchTheme = (theme: string) => ({
  type: SWITCH_THEME,
  payload: { theme },
});

export const resetAsideLive = () => ({
  type: RESET_ASIDE_LIVE,
});

export const setAsideLiveActiveTab = activeTab => ({
  type: SET_ASIDE_LIVE_ACTIVE_TAB,
  payload: { activeTab },
});
