import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { THEME_THEATER } from './constants';

/**
 * Direct selector to the asideLive state domain
 */
export const selectAsideLiveDomain = state => state.get('asideLive', Map());

export const makeSelectTheme = () =>
  createSelector(selectAsideLiveDomain, domain => domain.get('theme'));

export const makeSelectIsTheater = () =>
  createSelector(makeSelectTheme(), theme => theme === THEME_THEATER);

export const makeSelectAsideLiveActiveTab = () =>
  createSelector(selectAsideLiveDomain, domain => domain.get('activeTab'));
