import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const getWindowSize = () => {
  return {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    ratioWH: window.innerWidth / window.innerHeight,
    ratioHW: window.innerHeight / window.innerWidth,
  };
};

export const useResize = <T extends HTMLElement>(
  elementRef?: React.RefObject<T>,
  { debounceTime = 300 } = {}
) => {
  const [
    { screenWidth, screenHeight, ratioWH, ratioHW, rect },
    setState,
  ] = useState({
    ...getWindowSize(),
    rect: undefined as DOMRect | undefined,
  });

  const onResize = useCallback(() => {
    let resize = () => {
      setState({
        ...getWindowSize(),
        rect: elementRef?.current?.getBoundingClientRect(),
      });
    };

    if (debounceTime > 0) resize = debounce(resize, debounceTime);

    resize();
  }, [elementRef, debounceTime]);

  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, [onResize]);

  return { screenWidth, screenHeight, ratioWH, ratioHW, rect };
};
