// @flow
import React, {
  createElement,
  createContext,
  // $FlowFixMe
  forwardRef,
  type ElementType,
  type Node,
} from 'react';
import invariant from 'invariant';
import Portal from './Portal';
import type { StyleProps } from './styles';
import type { ModalOwnProps, ContainerProps } from './types';

type ComponentProps = {
  Component?: ElementType,
};

type WrapperProps = ComponentProps & {
  rootElement?: HTMLElement,
  // eslint-disable-next-line react/no-unused-prop-types
  children: Node,
};

type Context = ModalOwnProps & {
  getBackgroundProps: (ownProps: {}) => {},
  getBackgroundStyles: StyleProps => {},
  getContainerProps: (ownProps: ContainerProps) => {},
  getContainerStyles: StyleProps => {},
  getBodyProps: (ownProps: {}) => {},
  getBodyStyles: () => {},
  getTitleProps: (ownProps: {}) => {},
  getDescriptionProps: (ownProps: {}) => {},
};

const ModalContext = createContext<?Context>();

const { Provider } = ModalContext;

const Consumer = ({ children }: { children: (*) => Node }) => (
  <ModalContext.Consumer>
    {context => {
      invariant(
        context,
        'Unable to get ElementsContext! Do you forgot to wrap it in a `<Modal />` component?'
      );

      return children(context);
    }}
  </ModalContext.Consumer>
);

export { Portal, Provider, Consumer as ModalConsumer };

export const Background = forwardRef((props, ref) => (
  <Consumer>
    {({ isOpen, getBackgroundProps, getBackgroundStyles }) => (
      <div
        {...getBackgroundProps({
          style: getBackgroundStyles({ isOpen }),
          ref,
          ...props,
        })}
      />
    )}
  </Consumer>
));

export const Container = forwardRef((props, ref) => (
  <Consumer>
    {({ isOpen, getContainerProps, getContainerStyles }) => (
      <div
        {...getContainerProps({
          style: getContainerStyles({ isOpen }),
          ref,
          ...props,
        })}
      />
    )}
  </Consumer>
));

export const Body = forwardRef(
  ({ Component, ...props }: ComponentProps, ref) => (
    <Consumer>
      {({ getBodyProps, getBodyStyles }) =>
        createElement(
          Component || 'form',
          getBodyProps({
            style: getBodyStyles(),
            ref,
            ...props,
          })
        )
      }
    </Consumer>
  )
);

export const Wrapper = forwardRef(
  ({ Component, children, rootElement, ...props }: WrapperProps, ref) => (
    <Consumer>
      {({ isOpen }) => (
        <Portal isMounted={!!isOpen} rootElement={rootElement}>
          <Background ref={ref}>
            <Container>
              <Body Component={Component} {...props}>
                {children}
              </Body>
            </Container>
          </Background>
        </Portal>
      )}
    </Consumer>
  )
);

export const Title = ({ Component, ...props }: ComponentProps) => (
  <Consumer>
    {({ getTitleProps }) =>
      createElement(Component || 'h1', getTitleProps(props))
    }
  </Consumer>
);

export const Description = ({ Component, ...props }: ComponentProps) => (
  <Consumer>
    {({ getDescriptionProps }) =>
      createElement(Component || 'p', getDescriptionProps(props))
    }
  </Consumer>
);
