import { getRegion } from '@17live/app/utils';

import apis from './shared/apis';

const API_PATH = 'missions';

export const getBaggageMissions = isGuest =>
  apis.getJSON(`${API_PATH}${`?region=${isGuest ? getRegion() : ''}`}`);

export const receiveMissionAward = ID =>
  apis.postJSON(`${API_PATH}`, {
    ID,
  });
