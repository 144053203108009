// @flow
import type { Map } from 'immutable';

import type { Video, VideoInfo, VideoTypes } from '@17live/app/types/VideoInfo';

import { DEFAULT_VIDEO_INFO, VIDEO_TYPES } from './constants';
import { getLiveStreamInfo, getVODInfo } from './utils';

export const getVideoInfo = (video?: Map<Video>): VideoInfo => {
  if (!video) {
    return DEFAULT_VIDEO_INFO;
  }

  switch (video.get('videoType')) {
    case VIDEO_TYPES.STREAM: {
      return getLiveStreamInfo(video);
    }
    case VIDEO_TYPES.VOD:
      return getVODInfo(video);
    default:
      return video.toJS();
  }
};

export const createVideoTypeInjector = (videoType: VideoTypes) => (
  video: Video
): Video => ({
  ...video,
  videoType,
});
