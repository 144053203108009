/*
 *
 * UserProvider constants
 * @flow
 */

export const GET_USER_INFO: string = 'app/UserProvider/GET_USER_INFO';
export const RECEIVED_USER_INFO: string = 'app/UserProvider/RECEIVED_USER_INFO';
export const SET_STATUS: string = 'app/UserProvider/SET_STATUS';

/**
 * PrivacyMode
 */
export const PRIVACY_MODE = Object.freeze({
  OPEN: 'open',
  PRIVATE: 'private',
});

export const FOLLOW_PRIVACY_MODE = Object.freeze({
  OPEN: 0,
  PRIVATE: 1,
});
