// @flow
/**
 *
 * Button
 *
 */

import React from 'react';

import styled, { css } from 'styled-components';

import isEmpty from 'lodash/isEmpty';

import { LinkWithLang } from '@17live/app/components/Link';

import type { ButtonProps } from './types';

const minWidthStyle = css`
  min-height: 32px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
`;

const loadingStyle = css`
  cursor: progress;

  &:hover:after {
    background-color: transparent;
  }
`;

const disabledStyle = css`
  color: ${props => props.disabledColor || props.theme.BD_LIGHT_BLACK};
  cursor: not-allowed;

  &:hover:after {
    background-color: transparent;
  }
`;

export const ButtonWrapper = styled.button.attrs(({ type = 'button' }) => ({
  type,
}))`
  display: inline-block;
  border-radius: 4px;
  background-color: ${props =>
    props.backgroundColor ||
    props.theme.BG_DARK_BUTTON ||
    props.theme.BD_BLACK};
  color: ${props => props.color || props.theme.BG_WHITE};
  box-sizing: border-box;
  padding: 12px 18px;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
  text-align: center;
  cursor: pointer;
  /* stylelint-disable-next-line value-list-comma-newline-after */
  transition-property: color, border-color, background-color, box-shadow,
    transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  box-shadow: none;
  border: 1px solid
    ${props =>
      props.borderColor || props.theme.DARK_BUTTON_BORDER || 'transparent'};
  outline: none;
  position: relative;
  font-size: 1em;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.15s ease-out;
  }

  &:hover:after {
    background-color: rgba(255, 255, 255, 0.08);
  }

  &:active:after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  ${props => props.hasMinWidth && minWidthStyle};
  ${props => props.isLoading && loadingStyle};
  ${props => props.disabled && disabledStyle};
`;

const ButtonLink = ({
  backgroundColor,
  isFullWidth,
  isLoading,
  isLightBG,
  hasMinWidth,
  themeName,
  isOutlined,
  isInverted,
  ...rest
}: {
  backgroundColor: string,
  isFullWidth: boolean,
  isLoading: boolean,
  isLightBG: boolean,
  hasMinWidth: boolean,
  themeName: string,
  isOutlined: boolean,
  isInverted: boolean,
}) => <LinkWithLang {...rest} />;

export const ButtonLinkWrapper = styled(ButtonWrapper).attrs(() => ({
  as: ButtonLink,
}))`
  span {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
`;

// [TODO] enzyme doesn't support full React16 features yet. Using forwardRef directly
// will cause snapshot incorrect. So I create a ref as second parameter and only
// register ref when component is wrapped with forwardRef to prevent other snapshot
// fails. for more information:
// * https://github.com/airbnb/enzyme/pull/1592
// * https://github.com/airbnb/enzyme/issues/1604
type Ref = { current: ?HTMLElement };
const Button = ({ to, disabled, ...props }: ButtonProps, ref: Ref) => {
  if (to) {
    return (
      <ButtonLinkWrapper
        to={disabled ? '#' : to}
        disabled={disabled}
        ref={(!isEmpty(ref) && ref) || undefined}
        {...props}
      />
    );
  }

  return (
    <ButtonWrapper
      disabled={disabled}
      ref={(!isEmpty(ref) && ref) || undefined}
      {...props}
    />
  );
};

export default Button;
