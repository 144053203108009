/*
 *
 * LanguageProvider actions
 *
 */

import {
  ADD_LANG,
  ADD_LOCALE,
  CHANGE_LANG,
  CHANGE_LOCALE,
  CHANGE_REGION,
  SET_LANG,
  SET_LOCALE,
  SET_REGION,
} from './constants';

export const changeLocale = (languageLocale: string) => ({
  type: CHANGE_LOCALE,
  payload: languageLocale,
});

export const setLocale = (fromLocale: string, toLocale: string) => ({
  type: SET_LOCALE,
  payload: {
    from: fromLocale,
    to: toLocale,
  },
});

export const addLocale = (
  locale: string,
  translation: { [key: string]: string }
) => ({
  type: ADD_LOCALE,
  payload: {
    locale,
    translation,
  },
});

export const changeLang = (lang: string) => ({
  type: CHANGE_LANG,
  payload: lang,
});

export const setLang = ({ from, to }) => ({
  type: SET_LANG,
  payload: {
    from,
    to,
  },
});

export const addLang = (
  lang: string,
  translation: { [key: string]: string }
) => ({
  type: ADD_LANG,
  payload: {
    lang,
    translation,
  },
});

export const changeRegion = (region: string) => ({
  type: CHANGE_REGION,
  payload: region,
});

export const setRegion = ({ from, to }) => ({
  type: SET_REGION,
  payload: {
    from,
    to,
  },
});
