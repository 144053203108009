import {
  GET_ANNOUNCEMENT,
  REDIRECT_TO_OVERLOAD,
  SET_ANNOUNCEMENT,
  SUBSCRIBE_GLOBAL_ANNOUNCEMENT_CHANNEL,
} from './StatusAnnouncement.constants';

export const getAnnouncement = () => ({
  type: GET_ANNOUNCEMENT,
});

export const setAnnouncement = payload => ({
  type: SET_ANNOUNCEMENT,
  payload,
});

export const subscribeGlobalAnnouncementChannel = () => ({
  type: SUBSCRIBE_GLOBAL_ANNOUNCEMENT_CHANNEL,
});

export const redirectToOverload = () => ({
  type: REDIRECT_TO_OVERLOAD,
});
