// @flow
export const WANSU_HOSTNAME: string = '17mts2.8686c.com';

export const WANSU_MESSAGE_TYPE_INDEX: number = 2;

export const WANSU_MESSAGE_TYPES = Object.freeze({
  UNKNOWN: 0,
  // 11 登入
  ENTER_ROOM: 11,
  // 14 私聊
  PRIVATE_MESSAGE: 14,
  // 19 彈幕
  PUBLIC_MESSAGE: 19,
});

export const WANSU_EVENTS = Object.freeze({
  // 鍵接成功的回調
  CONNECT: 'CONNECT',
  // 鍵接斷開的回調
  DISCONNECT: 'DISCONNECT',
  // 進入結果的回調
  ENTER_ROOM: 'ENTER_ROOM',
  // 收到彈幕消息的回調
  BARRAGE: 'BARRAGE',
  // 收到私聊消息的回調
  CHAT: 'CHAT',
});

export const WS_STATE = Object.freeze({
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
});

export const WS_BINARY_TYPES = Object.freeze({
  BLOB: 'blob',
  ARRAY_BUFFER: 'arraybuffer',
});

/**
 *  https://developer.mozilla.org/zh-TW/docs/WebSockets/WebSockets_reference/CloseEvent
 */
export const WS_EXPECTED_CODES = [
  // normal closure
  1000,
  // normal closure overwritten by Wansu
  12592,
];
