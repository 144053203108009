// @flow
import React, { Component } from 'react';
import callAll from '@/utils/callAll';
import { Provider } from './Elements';
import { MODAL_TITLE_ID, MODAL_DESCRIPTION_ID } from './constants';
import {
  getBackgroundStyles,
  getContainerStyles,
  getBodyStyles,
} from './styles';
import type { ModalProps, ContainerProps } from './types';

class ModalProvider extends Component<ModalProps> {
  getBackgroundProps = (ownProps: {}) => ({
    role: 'dialog',
    'aria-hidden': String(!this.props.isOpen),
    'aria-labelledby': MODAL_TITLE_ID,
    'aria-describedby': MODAL_DESCRIPTION_ID,
    ...ownProps,
  });

  getContainerProps = ({ onClick, ...ownProps }: ContainerProps) => ({
    onClick: callAll(this.handleClickOutside, onClick),
    ...ownProps,
  });

  getBodyProps = (ownProps: {}) => ({
    role: 'document',
    id: this.props.id,
    ...ownProps,
  });

  getTitleProps = (ownProps: {}) => ({
    id: MODAL_TITLE_ID,
    ...ownProps,
  });

  getDescriptionProps = (ownProps: {}) => ({
    id: MODAL_DESCRIPTION_ID,
    ...ownProps,
  });

  getRenderProps = () => ({
    getBackgroundProps: this.getBackgroundProps,
    getContainerProps: this.getContainerProps,
    getBodyProps: this.getBodyProps,
    getTitleProps: this.getTitleProps,
    getDescriptionProps: this.getDescriptionProps,
    getBackgroundStyles,
    getContainerStyles,
    getBodyStyles,
    ...this.props,
    // isOpen might be undefined, convert it to strict boolean
    isOpen: !!this.props.isOpen,
  });

  handleClickOutside = (e: React.MouseEvent) => {
    const { closeModal, beforeCloseModal, disableClickOutside } = this.props;

    if (disableClickOutside) {
      return;
    }

    if (e.currentTarget === e.target && closeModal) {
      if (beforeCloseModal) {
        beforeCloseModal(e.nativeEvent);
      }
      closeModal();
    }
  };

  render() {
    const { children, ...renderProps } = this.getRenderProps();

    return (
      <Provider value={renderProps}>
        {typeof children === 'function' ? children(renderProps) : children}
      </Provider>
    );
  }
}

export default ModalProvider;
