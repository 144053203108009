import { useCallback, useState } from 'react';

export const useTabList = (
  defaultTabID: string,
  switchTabCallback?: (tabID: string) => void
) => {
  const [currentTab, setTab] = useState(defaultTabID);

  const onSwitchTab = useCallback(
    tabID => {
      setTab(tabID);

      if (switchTabCallback) {
        switchTabCallback(tabID);
      }
    },
    [switchTabCallback]
  );

  return { currentTab, onSwitchTab };
};
