/* eslint-disable no-underscore-dangle */
const loadScript = urlBase => {
  window._paq.push(['setTrackerUrl', `${urlBase}matomo.php`]);

  const doc = document;
  const scriptElement = doc.createElement('script');
  const scripts = doc.getElementsByTagName('script')[0];
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.src = `${urlBase}matomo.js`;

  if (scripts && scripts.parentNode) {
    scripts.parentNode.insertBefore(scriptElement, scripts);
  }
};

export default loadScript;
