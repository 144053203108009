/**
 * refs: https://stackoverflow.com/questions/40939380/how-to-get-file-name-from-content-disposition
 */
const getFilenameFromContentDisposition = (contentDisposition: string) => {
  let filename: string | undefined;
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
  }
  return filename;
};

export default getFilenameFromContentDisposition;
