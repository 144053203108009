import { Map, Record } from 'immutable-v4';
import { createSelector } from 'reselect';

import { FOLLOW_STATUS } from '@/containers/FollowButton/constants';

import { getStreamMode } from '@17live/app/containers/LiveStream/utils';
import { RootState } from '@17live/app/types/RootState';

import { LOGGED_IN } from './constants';
import { getIsLoggedIn, getUserRegion } from './utils';

export const selectLoginDomain = state => state.get('login');

export const makeSelectLogin = () =>
  createSelector(selectLoginDomain, login => login);

export const makeSelectIsLoggedIn = () =>
  createSelector(selectLoginDomain, login => getIsLoggedIn(login));

export const makeSelectLoginUserInfo = () =>
  createSelector(makeSelectLogin(), login => login && login.get('userInfo'));

export const makeSelectLoginUserID = () =>
  createSelector(selectLoginDomain, login =>
    login.getIn(['userInfo', 'userID'])
  );

export const makeSelectLoginUserRegion = () =>
  createSelector(selectLoginDomain, login =>
    getUserRegion(login.get('userInfo', Map()).toJS())
  );

export const makeSelectBalances = () =>
  createSelector(selectLoginDomain, login => login.get('balances', Map()));

export const makeSelectPoint = () =>
  createSelector(selectLoginDomain, makeSelectBalances(), balances =>
    balances.get('point')
  );

export const makeSelectFollowingList = () =>
  createSelector(selectLoginDomain, login =>
    login
      .get('following', Map())
      .filter(followingStatus => followingStatus === FOLLOW_STATUS.FOLLOWING)
  );

export const makeSelectLoginRoomID = () =>
  createSelector(selectLoginDomain, login =>
    login.getIn(['userInfo', 'roomID'])
  );

export const makeSelectLoginError = () =>
  createSelector(selectLoginDomain, login => login.get('error') || null);

export const makeSelectBadgeInfo = () =>
  createSelector(selectLoginDomain, login =>
    login.getIn(['userInfo', 'badgeInfo'])
  );

export const makeSelectStreamMode = () =>
  createSelector(makeSelectBadgeInfo(), badgeInfo => getStreamMode(badgeInfo));

export const makeSelectProgramInfo = () =>
  createSelector(selectLoginDomain, login =>
    login.getIn(['userInfo', 'programInfo'])
  );

export const makeSelectContractInfo = () =>
  createSelector(selectLoginDomain, login =>
    login.getIn(['userInfo', 'contract'])
  );

export const makeIsContract = (state: Record<RootState>) => {
  const userInfo: Record<RootState['login']['userInfo']> = state.getIn([
    'login',
    'userInfo',
  ]);

  return {
    isLogin: state.getIn(['login', 'status']) === LOGGED_IN,
    isContract: !!userInfo.get('contract'),
  };
};
