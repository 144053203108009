// @flow
import { stringify } from 'query-string';
import { type Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { moveToFirst } from '@/containers/Leaderboard/utils';

import { MAP_FE_LANG_TO_BE_LANG, REGION } from '@17live/app/constants';
import { checkStreamIsOnline } from '@17live/app/containers/LiveStream/utils';
import { getLang, getRegion } from '@17live/app/utils';

import apis from './shared/apis';

const API_PATH: string = 'cells';

/**
 * refs Cells API https://github.com/17media/api/blob/master/models/tab.go#L32-L56
 */
export const CELLS_TABS = Object.freeze({
  HOT: 'hot',
  HOT_OPT: 'hot_opt',
  CLIP: 'clip',
  CLIP_EVENT: 'clipEvent',
  STREAMER_RECAP: 'streamerRecap',
  ACCOMPANY: 'accompany',
  GAMING: 'gaming',
  YOUTUBER: 'youtuber',
  WEB_CHANNEL: 'web:channel',
  EVENT: 'event',
  VIRTUAL_STREAMER: 'virtualStreamer',
  MUSIC: 'music',
  MUSIC_JP: 'musicJP',
  LATEST: 'latest',
  VIP: 'vip',
  MALE: 'male_tab',
  FEMALE: 'female_tab',
  TOPIC: 'topictab',
  TV: '17tv_tab',
  LIVE_PLUS: '17liveplus_tab',
  PK: 'pk',
  FOLLOWING: 'following',
  GROUP_CALL: 'groupCall',
});

export const CELLS_TYPES = Object.freeze({
  STREAM: 0,
  BANNER: 1,
  VOD: 2,
  CAROUSEL_BANNER: 3,
  CLIP: 4,
  BELT_BANNER: 5,
  TAB_RECOMMEND: 11,
});

export const CELLS_ERRORS = Object.freeze({
  CURSOR_EXPIRED: 19,
});

type CellsTabs = $Values<typeof CELLS_TABS>;

type CellsTypes = $Values<typeof CELLS_TYPES>;

// refs /api/cell/cells.go
type CellsOptions = {
  cursor?: string,
  count?: number,
  countv2?: number,
  region?: string,
  creatorID?: string,
  paging?: number,
  listName: CellsTabs,
  subtab?: string,
  userID?: string,
};

type CellsResponse = Observable<{
  cells: Array<{
    stream?: Object,
    type: CellsTypes,
    vod?: Object,
  }>,
  cursor: string,
  topics: Array<*>,
}>;

export const getCells = (
  tab: CellsTabs,
  options?: CellsOptions,
  // 帶 all=true 時 api 就不帶 count, paging, cursor
  // 在需要一次撈所有 data 時用，例如 getSubtabCells (TW 除外地區)
  all = false
): CellsResponse => {
  const {
    cursor = '',
    count = 50,
    countv2 = 50,
    region = getRegion(),
    creatorID,
    paging = 1,
    userID,
  } = options || {};

  // backend didn't support latest for GLOBAL region
  const queryRegion =
    tab === CELLS_TABS.LATEST && region === REGION.GLOBAL ? REGION.TW : region;

  const params = stringify({
    tab,
    region: queryRegion,
    creatorID,
    userID,
    ...(!all && { count, paging, cursor }),
    ...(!all && queryRegion === REGION.GLOBAL && { countv2 }),
  });

  const lang = getLang();

  // 因為 getCells 拿 subtabs displayname 欄位是透過 Header 的 language 回傳相對應的翻譯，
  // 這裏的 language code 跟網站上用的 language code 不相同，所以用 MAP_FE_LANG_TO_BE_LANG 去 mapping。
  const language = MAP_FE_LANG_TO_BE_LANG[lang];

  return apis.getJSON(`${API_PATH}?${params}`, null, {
    language,
  });
};

export const getSuggestedTab = (payload: CellsOptions): CellsResponse =>
  getCells(CELLS_TABS.HOT_OPT, payload);

export const getSubtabCells = ({
  region,
  subtab,
  cursor,
  paging,
  creatorID,
}: CellsOptions): CellsResponse =>
  // TW 有提供 `tab=label:{subtab}` 方法取得該 label 下的直播
  // 但其他地區沒有，需要用 `tab=hot` 一次撈全部再 filter subtab
  region === REGION.TW
    ? getCells(`${subtab}`, {
        region,
        cursor,
        paging,
      })
    : getCells(
        CELLS_TABS.HOT,
        {
          region,
          creatorID,
        },
        true
      );

export const getLivePlus = (payload: CellsOptions): CellsResponse =>
  getCells(CELLS_TABS.LIVE_PLUS, payload);

export const getGamingTab = (payload: CellsOptions): CellsResponse =>
  getCells(
    payload.region === REGION.TW ? CELLS_TABS.TOPIC : CELLS_TABS.GAMING,
    payload
  );

export const getLatestTab = (payload: CellsOptions): CellsResponse =>
  getCells(CELLS_TABS.LATEST, payload);

export const getSuggestedLiveStreams = (payload: CellsOptions) => {
  return getSuggestedTab(payload).pipe(
    map(({ cells, cursor }) => ({
      cursor,
      list: cells
        .filter(({ type }) => type === CELLS_TYPES.STREAM)
        .map(({ stream }) => stream),
    }))
  );
};

export const getSubtabLiveStreams = (payload: CellsOptions) => {
  const { region, subtab } = payload;

  return getSubtabCells(payload).pipe(
    map(({ cells, cursor }) => ({
      cursor,
      list: cells
        .filter(({ type, stream }) => {
          if (region === REGION.TW || subtab === 'all') {
            return type === CELLS_TYPES.STREAM;
          }

          return (
            type === CELLS_TYPES.STREAM &&
            stream?.subtabs?.includes(decodeURI(subtab))
          );
        })
        .map(({ stream }) => stream),
    }))
  );
};

const CAROUSEL_COUNT = 8;
const MAX_GROUP_CALL_STREAMER_COUNT = 9;
const GRID_STYLE = {
  ONE_ONE: 0,
  TWO_TWO: 1, // app 置頂
  ONE_TWO: 2, // app 超級置頂
};

// sepc: https://www.figma.com/file/1n5IX4sERxaln6jGJMKun8/%5BStory-Map%5D-17.live-Homepage-Overhaul?type=whiteboard&node-id=101-1464&t=BIXkUG6fJ1GVDZ31-4
export const getCarouselOrderedRegions = region => {
  const chineseRegionsOrder = [
    REGION.TW,
    REGION.HK,
    REGION.MY,
    REGION.SG,
    REGION.US,
  ];

  const regionsOrderMap = Object.freeze({
    // JP: JP only
    [REGION.JP]: [REGION.JP],
    // TW / HK / MY / SG: Region > TW > HK > MY > SG > US
    [REGION.TW]: chineseRegionsOrder,
    [REGION.HK]: chineseRegionsOrder,
    [REGION.MY]: chineseRegionsOrder,
    [REGION.SG]: chineseRegionsOrder,
    // SEA (PH / ID / TH / VN / MM) / IN: Region > US
    [REGION.PH]: [REGION.US],
    [REGION.ID]: [REGION.US],
    [REGION.TH]: [REGION.US],
    [REGION.VN]: [REGION.US],
    [REGION.MM]: [REGION.US],
    [REGION.IN]: [REGION.US],
    // US: US > SEA
    [REGION.US]: [REGION.PH, REGION.ID, REGION.TH, REGION.VN, REGION.MM],
  });

  // Global: US > SEA > HK > MY > SG > IN > TW > JP
  const globalRegionsOrder = [
    REGION.US,
    REGION.PH,
    REGION.ID,
    REGION.TH,
    REGION.VN,
    REGION.MM,
    REGION.HK,
    REGION.MY,
    REGION.SG,
    REGION.IN,
    REGION.TW,
    REGION.JP,
  ];

  const regions = regionsOrderMap[region];

  if (regions) {
    return moveToFirst(regions, region);
  }
  // If the region is not in the mapping table, then fallback to global
  return moveToFirst(globalRegionsOrder, REGION.US);
};

export const getCarouselLiveStreams = ({ region }) => {
  const orderedRegions = getCarouselOrderedRegions(region);

  return forkJoin(
    orderedRegions.map(orderedRegion =>
      getSuggestedTab({
        region: orderedRegion,
        // 因為後面會 filter 在同一個 group call room 裡的 streamer
        // 避免所有的主播都剛好在同一個 group call room 裡面被 filter 掉，取最大值
        count: CAROUSEL_COUNT * MAX_GROUP_CALL_STREAMER_COUNT,
      })
    )
  ).pipe(
    map(res => {
      const checkDuplicateSet = new Set();
      const list = res
        .map(({ cells }, index) =>
          cells
            .filter(
              ({ type, stream }) =>
                type === CELLS_TYPES.STREAM &&
                stream?.gridStyle === GRID_STYLE.ONE_ONE
            )
            .filter(({ stream }) => {
              // 如果是 group call room，則 filter 不是 host 的房間
              return stream.groupCallInfo
                ? stream.userID === stream.groupCallInfo?.hostID
                : true;
            })
            // 因為 API 有可能包含其他 region 的內容，因此這裡只找出對應 region 的內容
            .filter(({ stream }) => stream.region === orderedRegions[index])
            // 過濾掉沒開播的
            .filter(({ stream }) => checkStreamIsOnline(stream))
        )
        .reduce((acc, arr) => [...acc, ...arr], [])
        .filter(({ stream }) => {
          return checkDuplicateSet.has(stream.userID)
            ? false
            : checkDuplicateSet.add(stream.userID);
        })
        .slice(0, CAROUSEL_COUNT)
        .map(({ stream }) => stream);

      return {
        list,
      };
    })
  );
};

const injectType = data =>
  data.type === CELLS_TYPES.VOD
    ? { ...data.vod, type: data.type }
    : { ...data.stream, type: data.type };

const injectCategoryList = cells =>
  (cells || [])
    .filter(({ type }) => type === CELLS_TYPES.STREAM)
    .map(({ stream }) => stream);

const injectSubTabs = (subTabs, cells) =>
  subTabs ||
  (cells || [])
    .find(({ type }) => type === CELLS_TYPES.TAB_RECOMMEND)
    ?.tabRecommend.tabs.map(({ id, name: { key } }) => ({
      ID: id,
      displayName: key,
    }));

export const getVODListByChannel = (creatorID: string, payload: CellsOptions) =>
  getCells(CELLS_TABS.WEB_CHANNEL, { creatorID, ...payload }).pipe(
    map(({ cells, cursor }) => ({
      list: cells
        .filter(
          ({ type }) => type === CELLS_TYPES.VOD || type === CELLS_TYPES.STREAM
        )
        .map(injectType),
      cursor,
    }))
  );

export const getLivePlusList = (payload: CellsOptions) =>
  getLivePlus(payload).pipe(
    map(({ cells, cursor }) => ({
      list: cells
        .filter(({ type }) => type === CELLS_TYPES.VOD)
        .map(injectType),
      cursor,
    }))
  );

export const getGamingList = (payload: CellsOptions) =>
  getGamingTab(payload).pipe(
    map(({ cells, cursor }) => ({
      cursor,
      list: cells
        .filter(
          ({ type }) => type === CELLS_TYPES.VOD || type === CELLS_TYPES.STREAM
        )
        .map(injectType),
    }))
  );

export const getCategoryList = (payload: CellsOptions) =>
  getCells(payload.listName, payload).pipe(
    map(({ cells, cursor }) => ({
      cursor,
      list: injectCategoryList(cells),
    }))
  );

export const getLatestLiveStreamsList = (payload: CellsOptions) =>
  getLatestTab(payload).pipe(
    map(({ cells, cursor }) => ({
      cursor,
      list: cells.map(injectType),
    }))
  );

export const getHotCategories = (payload: CellsOptions): CellsResponse => {
  return getCells(
    payload.region === REGION.TW ? CELLS_TABS.HOT_OPT : CELLS_TABS.HOT,
    {
      ...payload,
      count: 0,
      countv2: 1, // we just need subTabs but countv2 = 0 will get all cells, so set 1
    }
  ).pipe(
    map(({ cells, subtabs: subTabs }) => ({
      list: [],
      subTabs: injectSubTabs(subTabs, cells, payload.region),
    }))
  );
};
