import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import { getBackendLanguageCode, getLang } from '@17live/app/utils';

import apis from './shared/apis';

const API_PATH = 'baggage';

export const getBaggage = (language = getLang()) =>
  apis.getJSON(`${API_PATH}`, null, {
    language: getBackendLanguageCode(language),
  });

export const sendBaggage = (liveStreamID, itemID, traceID) =>
  apis.postJSON(
    `${API_PATH}/item/${itemID}`,
    {
      liveStreamID,
    },
    {
      ...setTrackAPIHeaders({ 'trace-id': traceID }),
    }
  );
