import { map } from 'rxjs/operators';

import apis from './shared/apis';

const API_PATH = 'feed';

export const POST_FEED_TYPES = {
  ALL: 1,
  POST: 2,
  LIVE: 3,
};

export const getPostFeed = () =>
  apis
    .postJSON(`${API_PATH}/getPostFeed`, {
      count: 1000, // max limitation of API is 1000
      beforeTime: Math.floor(Date.now() / 1000),
      queryType: POST_FEED_TYPES.LIVE,
      apiVersion: 'v2',
    })
    .pipe(
      map(({ liveStreams }) => liveStreams),
      map(list => ({
        /**
         * Following liveStreams contain liveStream which is shared by following users
         * but user doesn't follow it. Currently, we should ignore those liveStreams.
         */
        list: list.filter(liveStream => !liveStream.restreamerOpenID),
        cursor: '',
      }))
    );
