import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const selectEventDomain = state => {
  return state.get('event', Map());
};

export const makeSelectEvent = () =>
  createSelector(selectEventDomain, domain => domain.get('eventList', List()));

export const makeSelectAvatarWeeklyEventInfo = () =>
  createSelector(selectEventDomain, domain =>
    domain.get('avatarWeeklyEventInfo', Map())
  );

export const makeSelectEventList = () =>
  createSelector(makeSelectEvent(), eventList => {
    const now = Date.now();

    return eventList
      .filter(
        event => now >= event.get('startTime') && now <= event.get('endTime')
      )
      .sortBy(event => event.get('endTime'));
  });

export const makeSelectEventListWithAvatarWeekly = () =>
  createSelector(
    makeSelectEventList(),
    makeSelectAvatarWeeklyEventInfo(),
    (eventList, avatarWeeklyEventInfo) => {
      return avatarWeeklyEventInfo
        ? List([avatarWeeklyEventInfo, ...eventList])
        : List(eventList);
    }
  );

export const makeSelectEventIFrameUrl = () =>
  createSelector(selectEventDomain, domain => domain.get('iFrameUrl'));

export const makeSelectEventIFrameEventID = () =>
  createSelector(selectEventDomain, domain => domain.get('iFrameEventID'));

export const makeSelectEventIsIFrameActive = () =>
  createSelector(selectEventDomain, domain =>
    domain.get('isIFrameActive', false)
  );

export const makeSelectIsLoading = () =>
  createSelector(selectEventDomain, domain => domain.get('isLoading'));
