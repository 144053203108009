// @flow
import {
  LEGACY_CDN_URL,
  CDN_URL,
  THUMBNAIL_PREFIX,
} from '@/constants/services';

const transformURLToHttps = (
  url: string = '',
  isThumb: boolean = false
): string =>
  typeof url === 'string'
    ? url.replace(
        LEGACY_CDN_URL,
        `${CDN_URL}/${isThumb ? THUMBNAIL_PREFIX : ''}`
      )
    : url;

export default transformURLToHttps;
