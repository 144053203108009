import debounce from 'lodash/debounce';
import { Observable } from 'rxjs';

import Logger from '@17live/app/services/Logger';

import { WANSU_EVENTS } from './constants';
import { decodeMessage } from './utils';
import WansuSDK from './WansuSDK';

const LOGGER_LABEL = 'Wansu Message';
const logger = debounce(Logger.error, 1000);

export const unsubscribeChatRoom = roomID => {
  WansuSDK.leaveRoom(roomID);

  return roomID;
};

export const subscribeChatRoom = roomID =>
  Observable.create(observer => {
    WansuSDK.addEventListener(roomID, {
      [WANSU_EVENTS.BARRAGE]: (targetRoomID, gzipMessage) => {
        // ignore message from other roomID
        if (roomID !== targetRoomID) {
          return;
        }

        try {
          const message = decodeMessage(gzipMessage);

          observer.next(message);
        } catch (error) {
          // FIXME: debounce it because too many errors in Android-4.4.4
          logger(LOGGER_LABEL, error.toString());
        }
      },
    });

    WansuSDK.enterRoom(roomID);

    return () => unsubscribeChatRoom(roomID);
  });
