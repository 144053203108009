import { Reducer } from 'redux';
import { SET_CONFIG } from './actions';

const initialState = {};

const configReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return action.payload;

    default:
      return state;
  }
};

export default configReducer;
