// @flow
import { fromJS, type Record } from 'immutable';
import { STATUS } from '@/constants/services';
import { LOGIN_FAILED } from '@17live/app/containers/LoginProvider/constants';
import type { Action } from '@/store/types';
import { SET_STATUS } from './constants';

export type State = {|
  status: $Values<typeof STATUS>,
  error: ?string,
|};

export const initialState: Record<State> = fromJS({
  status: STATUS.IDLE,
  error: null,
});

const registerModalReducer = (
  state: Record<State> = initialState,
  action: Action
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_STATUS:
      return state
        .set('status', payload.status)
        .set('error', (action.error && payload.error) || null);
    case LOGIN_FAILED:
      return state.set('status', STATUS.ERROR).set('error', payload.error);
    default:
      return state;
  }
};

export default registerModalReducer;
