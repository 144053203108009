import React, { useMemo } from 'react';
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';

import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';

import { getPathWithLang } from './utils';

type Props = (LinkProps | NavLinkProps) & {
  isNavLink?: boolean;
};

export const LinkWithLang: React.FC<Props> = ({
  children,
  to,
  isNavLink = false,
  ...props
}) => {
  const { lang } = useLocale();
  const RenderComponent = isNavLink ? NavLink : Link;

  const linkTo = useMemo(() => {
    const isString = typeof to === 'string';
    const pathname = isString ? to : to?.pathname;
    const pathWithLang = getPathWithLang(lang, pathname);

    return isString ? pathWithLang : { ...to, pathname: pathWithLang };
  }, [lang, to]);

  return (
    <RenderComponent {...props} to={linkTo}>
      {children}
    </RenderComponent>
  );
};

export default LinkWithLang;
