// @flow

export const GET: string = 'GET';
export const POST: string = 'POST';
export const PATCH: string = 'PATCH';
export const PUT: string = 'PUT';
export const DELETE: string = 'DELETE';

export const METHODS: { [string]: string } = {
  get: GET,
  post: POST,
  patch: PATCH,
  put: PUT,
  delete: DELETE,
};

export const RETRIES: number = 2; // 3 times with the original request

/**
 * region and language are the same thing for us to call backend api.
 * Note that we default to `TW`, which is different from language(locale) setting.
 */
export const DEFAULT_REGION: string = 'TW';
export const DEFAULT_COUNT: number = 100;

export const SMS_KEY: string = 'ce7c4cb6-ac5a-4b86-9798-26ce3016e599';

/**
 * Error Message
 */
export const ERROR_PARAMETER: string = 'parameter_error';

/**
 * Error Codes: models/error.pb.go from go api
 */
export const ERROR_CODES = {
  UNKNOWN: 0,
  DEFAULT: 1,
  PARAMETER_ERROR: 7,
  STREAMER_BLOCKED: 13,
  GENERIC_ERROR: 35,
  TOKEN_EXPIRED: 40001,
  INVALID_TOKEN: 40002,
  NOT_CONCERT_ROOM: 55001,
  IP_NOT_AVAILABLE: 55002,
};

/**
 * Request header for get realtime data
 *
 * Some API cannot get realtime data (ex. /lives/:roomID)
 * Setup this request header when you need to get the realtime data.
 */
export const REQUEST_HEADER_FOR_GET_REAL_TIME_DATA = {
  '17media-Custom-Host': 'go_lives_url',
};
