import { KEY_PREFERENCES, LocalStorage } from '@/services/Storage';

import { defaultPreferences } from '@17live/app/utils';

export const DEFAULTS = defaultPreferences;

const updateLocalStorage = preferences => {
  LocalStorage.setItem(KEY_PREFERENCES, preferences);
};

const Preference = {
  get(key) {
    const preferences = LocalStorage.getItem(KEY_PREFERENCES) ?? {};
    return preferences[key];
  },

  set(key, value) {
    const preferences = LocalStorage.getItem(KEY_PREFERENCES) ?? {};
    preferences[key] = value;
    updateLocalStorage(preferences);
  },

  getAll() {
    const preferences = LocalStorage.getItem(KEY_PREFERENCES) ?? {};
    return preferences;
  },
};

export default Preference;
