// @flow
import { type MatchPathOptions } from 'react-router-dom';
import { CONTENT_GROUPS, CONTENT_GROUP_FEATURES } from '../constants';

export type ContentGroup = $Values<typeof CONTENT_GROUPS>;

export type Features = $Values<typeof CONTENT_GROUP_FEATURES>;

export type Options = {
  openID?: string,
  isTV?: boolean,
  isOnline?: boolean,
};

const {
  HOME,
  LIVE,
  PROFILE,
  POST,
  TV_HOME,
  TV_VIDEO,
  LIVE_PLUS,
  POINT,
  PURCHASE,
  LEADERBOARD,
  SUGGESTED,
  MUSIC,
  LATEST,
  FOLLOWING,
  RECOMMENDED,
  GAMING,
  MALE,
  FEMALE,
  VIP,
  TOPIC,
} = CONTENT_GROUP_FEATURES;

export const FEATURE_PATH_MAP: { [Features]: string | MatchPathOptions[] } = {
  [HOME]: [
    {
      path: '/',
      exact: true,
    },
  ],
  [LIVE]: [
    {
      path: '/live/:roomID',
      exact: true,
    },
  ],
  [POST]: [
    {
      path: '/profile/:liveID/post/:postID',
      exact: true,
    },
    {
      path: '/profile/r/:roomID/post/:postID',
      exact: true,
    },
    {
      path: '/profile/u/:userID/post/:postID',
      exact: true,
    },
    {
      path: '/post/:postID',
      exact: true,
    },
  ],
  [PROFILE]: [
    {
      path: '/profile/:liveID',
      exact: true,
    },
    {
      path: '/profile/r/:roomID',
      exact: true,
    },
    {
      path: '/profile/u/:userID',
      exact: true,
    },
  ],
  [TV_HOME]: [
    {
      path: '/vod',
      exact: true,
    },
  ],
  [TV_VIDEO]: [
    {
      path: '/vod/:roomID/:vodID',
      exact: true,
    },
    {
      path: '/vod/:vodID',
      exact: true,
    },
  ],
  [LIVE_PLUS]: '/vod/hot',
  [PURCHASE]: '/purchase',
  [POINT]: '/point',
  [LEADERBOARD]: '/leaderboard',
  [SUGGESTED]: '/suggested',
  [MUSIC]: '/music',
  [LATEST]: '/latest',
  [FOLLOWING]: '/following',
  [RECOMMENDED]: '/recommended',
  [GAMING]: '/gaming',

  [MALE]: '/male',
  [FEMALE]: '/female',
  [VIP]: '/vip',
  [TOPIC]: '/topic',
};

export const EXCLUDE_AUTO_TRACK_FEATURES: Features[] = [LIVE, POST, PROFILE];
