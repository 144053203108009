// @flow
import { HIT_TYPES } from '../constants';
import { EXCLUDE_AUTO_TRACK_FEATURES, FEATURE_PATH_MAP } from './constants';
import {
  type Options as ContentGroupOptions,
  getContentGroup,
  isPathMatchFeature,
} from './contentGroups';

/**
 * strip out query string and hash
 */
const getPath = (path: ?string): string => (path ? path.split(/[?#]/)[0] : '');

class PageView {
  curPath: ?string;

  shouldTrackUrlChange = (newPath: string, oldPath?: ?string): boolean => {
    const oldPathName = getPath(oldPath);
    const newPathName = getPath(newPath);

    return oldPathName !== newPathName;
  };

  shouldAutoTrackUrlChange = (newPath: string, oldPath?: string): boolean => {
    const newPathName = getPath(newPath);
    const shouldTrackUrlChange = this.shouldTrackUrlChange(newPath, oldPath);
    const shouldAutoTrackUrlChange = !EXCLUDE_AUTO_TRACK_FEATURES.some(
      feature => isPathMatchFeature(newPathName, FEATURE_PATH_MAP[feature])
    );

    return shouldTrackUrlChange && shouldAutoTrackUrlChange;
  };

  /**
   * Expose setCurPath for urlChangeTracker to update the current path
   */
  setCurPath = (newPath: string): void => {
    this.curPath = newPath;
  };

  send = (options?: ContentGroupOptions) => {
    if (
      !(
        window.ga &&
        this.shouldTrackUrlChange(window.location.pathname, this.curPath)
      )
    ) {
      return;
    }

    this.setCurPath(window.location.pathname);

    const contentGroup = getContentGroup(options);

    /**
     * Call the update logic asynchronously to help ensure that app logic
     * responding to the URL change happens prior to this.
     */
    setTimeout(() => {
      window.gtag('event', HIT_TYPES.PAGEVIEW, {
        event_category: HIT_TYPES.PAGEVIEW,
        page_path: this.curPath,
        title: document.title,
        ...contentGroup,
      });

      window.ga('send', {
        hitType: HIT_TYPES.PAGEVIEW,
        page: this.curPath,
        title: document.title,
        ...contentGroup,
      });
    }, 0);
  };
}

export default new PageView();
