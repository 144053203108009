// @flow
import type { ScreenSize } from '@/constants/sizes';
import { CHANNEL_LAYOUT } from '@/containers/HeaderLive/constants';

/**
 * actions
 */
export const SWITCH_THEME = 'app/AsideLive/SWITCH_THEME';
export const RESET_ASIDE_LIVE = 'app/AsideLive/RESET_ASIDE_LIVE';
export const SET_ASIDE_LIVE_ACTIVE_TAB =
  'app/AsideLive/SET_ASIDE_LIVE_ACTIVE_TAB';

/**
 * styles
 */
export const ASIDE_LIVE_WIDTH: { [ScreenSize]: number } =
  CHANNEL_LAYOUT.HEADER.WIDTH;
export const ASIDE_TAB_HEIGHT: number = 56; // pixels

/**
 * theme states
 */
export const THEME_NORMAL: string = 'THEME_NORMAL';
export const THEME_THEATER: string = 'THEME_THEATER';
