export const LOG_LEVELS = Object.freeze({
  FATAL: 'fatal',
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
  DEBUG: 'debug',
});

export const IGNORE_FIELDS = [
  'headers_Authorization', // user access token
  'request_password', // user password when call login api
];
