import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Record as ImmutableRecord } from 'immutable-v4';

import { LangType, RegionType } from '@17live/app/constants';
import {
  changeLang,
  changeLocale,
  changeRegion,
} from '@17live/app/containers/LanguageProvider/actions';
import { useImmutableSelector } from '@17live/app/hooks';
import { RootState } from '@17live/app/types/RootState';
import { getSupportedLangCaseInsensitive } from '@17live/app/utils';

export const useLocale = () => {
  const dispatch = useDispatch();
  const language = useImmutableSelector<ImmutableRecord<RootState['language']>>(
    s => s.getIn(['language'])
  );

  return {
    changeLocale: useCallback(locale => dispatch(changeLocale(locale)), [
      dispatch,
    ]),
    changeRegion: useCallback(
      (region: string) => {
        dispatch(changeRegion(region));
      },
      [dispatch]
    ),
    changeLang: useCallback(
      (lang: string) => {
        dispatch(changeLang(lang));
      },
      [dispatch]
    ),
    lang: language.get('lang') as LangType,
    region: language.get('region') as RegionType,
    getSupportedLangCaseInsensitive: useCallback(
      getSupportedLangCaseInsensitive,
      []
    ),
  };
};
