import { Reducer as eventPage } from 'redux';
import {
  FETCH_EVENTS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAIL,
} from './actions';
import { EventList } from './types';

export const isLoading: eventPage<boolean> = (state = false, { type }) => {
  switch (type) {
    case FETCH_EVENTS: {
      return true;
    }

    case FETCH_EVENTS_SUCCESS:
    case FETCH_EVENTS_FAIL: {
      return false;
    }

    default:
      return state;
  }
};

export const list: eventPage<EventList[]> = (state = [], { type, payload }) => {
  switch (type) {
    case FETCH_EVENTS_SUCCESS: {
      return payload;
    }
    case FETCH_EVENTS_FAIL: {
      return [];
    }

    default:
      return state;
  }
};
