/*
 *
 * Modal actions
 *
 */
import { OPEN_MODAL, CLOSE_MODAL, SET_PARAMS, SET_ERROR } from './constants';

export const openModal = ({
  name,
  params = {},
  error = '',
  replace = false,
  ...additionalOptions
}) => ({
  type: OPEN_MODAL,
  payload: {
    name,
    params,
    error,
    replace,
    ...additionalOptions,
  },
});

export const setParams = params => ({
  type: SET_PARAMS,
  payload: params,
});

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
});

export const closeModal = modal => ({
  type: CLOSE_MODAL,
  payload: modal,
});
