import { useMemo, useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js-legacy';

import useConst from './useConst';

type PixiOptions = Partial<{
  autoStart: boolean;
  width: number;
  height: number;
  view: HTMLCanvasElement;
  transparent: boolean;
  autoDensity: boolean;
  antialias: boolean;
  preserveDrawingBuffer: boolean;
  resolution: number;
  forceCanvas: boolean;
  backgroundColor: number;
  clearBeforeRender: boolean;
  powerPreference: string;
  sharedTicker: boolean;
  sharedLoader: boolean;
  resizeTo: Window | HTMLElement;
}>;

PIXI.utils.skipHello();

export const usePixi = (options: PixiOptions = {}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const opts = useConst(options);
  const [app, setApp] = useState<PIXI.Application>();

  useEffect(() => {
    if (!app && canvasRef.current) {
      const pixi = new PIXI.Application({
        transparent: true,
        antialias: true,
        view: canvasRef.current,
        ...opts,
      });

      setApp(pixi);
    }
  }, [app, opts, setApp]);

  useEffect(() => {
    app?.renderer.resize(app.view.clientWidth, app.view.clientHeight);
  }, [app?.view.clientWidth, app?.view.clientHeight]);

  useEffect(() => {
    if (app) {
      return () => {
        app.destroy();
      };
    }
  }, [app]);

  return { app, ref: canvasRef };
};
