import { LANG } from '@17live/app/constants/langs';

export const LANG_REGEX = new RegExp(
  `^/(${Object.values(LANG).join('|')})`,
  'i'
);

export const getPathWithLang = (lang: string, pathname: string) => {
  const parentPath = lang ? `/${lang}` : '';

  if (!pathname) {
    return parentPath;
  }

  /**
   * 下面兩種狀況返回原本的 pathname
   * 1. 已經帶有 /:lang 的網址
   * 2. 開頭沒有 / 的網址，需要跳轉同一層所以不需要修改
   */

  if (LANG_REGEX.test(pathname) || !pathname.startsWith('/')) {
    return pathname;
  }

  let transformRoute = `${parentPath}${pathname}`;

  if (transformRoute.endsWith('/')) {
    transformRoute = transformRoute.slice(0, -1);
  }

  return transformRoute;
};
