import { ungzip } from 'pako/dist/pako_inflate.min';
import { map } from 'rxjs/operators';

import DevTools from '@/services/DevTools';
import getIsDevToolsEnabled from '@/services/DevTools/getIsDevToolsEnabled';

import { makeSelectRegion } from '@17live/app/containers/LanguageProvider/selectors';
import { getConfigWithReactQuery } from '@17live/app/containers/LiveStream/utils';
import {
  makeSelectLoginUserID,
  makeSelectLoginUserRegion,
} from '@17live/app/containers/LoginProvider/selectors';
import Logger from '@17live/app/services/Logger';
import { Config } from '@17live/app/types/Config';

const mapUnit8ArrayToString = (array: Uint8Array): string =>
  String.fromCodePoint.apply(null, array);

export const decodeMessage = (gzipMessage: Uint8Array): string =>
  [escape, decodeURIComponent, JSON.parse].reduce(
    (message: string, fn): string => fn(message),
    mapUnit8ArrayToString(ungzip(gzipMessage))
  );

const ALG_GZIP_BASE64 = 'gzip_base64';

export const decodeCompressedData = (
  rawMessage,
  loggerLabel = 'PubNub Message'
) => {
  if (!rawMessage.cdata) {
    return rawMessage;
  }

  let message = rawMessage;

  const { cdata, ...rest } = rawMessage;

  cdata.forEach(({ alg, data = '' }) => {
    if (alg === ALG_GZIP_BASE64) {
      try {
        message = {
          ...rest,
          ...decodeMessage(window.atob(data)),
        };
      } catch (e) {
        Logger.error(loggerLabel, e.toString());
      }
    }
  });

  return message;
};

export const getSubscribeKeyByEnv = (
  provider,
  devToolKey,
  prodKey,
  stageKey,
  uatKey
) => {
  if (getIsDevToolsEnabled()) {
    if (provider === DevTools.getMessageProvider().name) {
      return devToolKey;
    }
    return uatKey;
  }

  if (process.env.DEPLOY_ENV === 'stage') {
    return stageKey;
  }

  if (
    process.env.DEPLOY_ENV === 'uat' ||
    process.env.DEPLOY_ENV === 'development'
  ) {
    return uatKey;
  }

  return prodKey;
};

export const getMessengerConfig = state$ => {
  const region = makeSelectRegion()(state$.value);
  const appRegion = makeSelectLoginUserRegion()(state$.value);
  const loginUserID = makeSelectLoginUserID()(state$.value);

  return getConfigWithReactQuery(region, loginUserID, appRegion).pipe(
    map((cfg: Config) => {
      const {
        addOns: { messenger },
      } = cfg;

      return messenger;
    })
  );
};
