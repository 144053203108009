import React, { SyntheticEvent } from 'react';

import styled from 'styled-components';

import { SIZE_LARGE, SIZE_MEDIUM } from '@/constants/sizes';
import { defaultEmptyBannerPath } from '@/utils/getPicture';
import media from '@/utils/media';

import ImageOnLoad from '@17live/app/components/ImageOnLoad';
import withFadeIn from '@17live/app/components/ImageOnLoad/withFadeIn';

import { EVENT_BANNER_HEIGHT } from '../constants';
import { getBannerURL } from '../utils';

type Props = {
  bannerFilename: string;
  onClick: (e: SyntheticEvent<HTMLElement>) => void;
};

export const BannerImage = styled.div.attrs((props: { src: string }) => ({
  style: {
    backgroundImage: props.src
      ? `
      url(${props.src}),
      url(${defaultEmptyBannerPath})
    `
      : `url(${defaultEmptyBannerPath})`,
  },
}))`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.BG_DEFAULT};
  background-size: cover;
  background-position: center;
`;

export const BannerImageWithFadeIn = withFadeIn(BannerImage);

export const BannerImageWrapper = styled.div`
  width: 100%;
  height: ${EVENT_BANNER_HEIGHT[SIZE_MEDIUM]}px;
  border-top-left-radius: 4.5px;
  border-top-right-radius: 4.5px;
  overflow: hidden;

  ${media[SIZE_MEDIUM]`
    height: ${EVENT_BANNER_HEIGHT[SIZE_LARGE]}px;
  `};

  ${media[SIZE_LARGE]`
    height: ${EVENT_BANNER_HEIGHT[SIZE_LARGE]}px;
  `};
`;

const EventBanner: React.FC<Props> = ({ bannerFilename, ...props }) => {
  const bannerSrc = getBannerURL(bannerFilename);

  return (
    <BannerImageWrapper {...props}>
      <ImageOnLoad src={bannerSrc} loading={<BannerImageWithFadeIn />}>
        <BannerImageWithFadeIn src={bannerSrc} />
      </ImageOnLoad>
    </BannerImageWrapper>
  );
};

export default EventBanner;
