// @flow
import { ELEMENT_ORDERS } from '@/constants/orders';

import {
  BG_MODAL_OVERLAY,
  MODAL_HORIZONTAL_MARGIN,
  MODAL_HORIZONTAL_PADDING,
  MODAL_MAX_WIDTH,
  MODAL_MIN_HEIGHT,
  MODAL_VERTICAL_MARGIN,
  MODAL_VERTICAL_PADDING,
} from './constants';

export type StyleProps = {
  isOpen?: boolean,
};

export const getBackgroundStyles = ({ isOpen }: StyleProps) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: ELEMENT_ORDERS.MODAL,
  backgroundColor: BG_MODAL_OVERLAY,
  opacity: isOpen ? 1 : 0,
  overflow: 'auto',
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0,
  // We deliberately don't use -webkit-overflow-scrolling: touch due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695
  WebkitOverflowScrolling: 'auto',
});

export const getContainerStyles = ({ isOpen }: StyleProps) => ({
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  minHeight: '100%',
  padding: `${MODAL_VERTICAL_MARGIN}px ${MODAL_HORIZONTAL_MARGIN}px`,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: isOpen ? 1 : 0,
});

export const getBodyStyles = () => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  // Ensure Body extends the full width of the parent Container
  minHeight: `${MODAL_MIN_HEIGHT}px`,
  maxWidth: `${MODAL_MAX_WIDTH}px`,
  width: '100%',
  padding: `${MODAL_VERTICAL_PADDING}px ${MODAL_HORIZONTAL_PADDING}px`,
  pointerEvents: 'auto',
  backgroundColor: '#ffffff',
  backgroundClip: 'padding-box',
  borderRadius: '4px',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
  // Remove focus outline from opened modal
  outline: 0,
});
