export enum LaborRewardType {
  SNACK = 1, // 普通點心
  SNACK_PREMIUM = 2, // 奢華點心
}

export enum MessageProviders {
  PUBNUB = 0,
  WANSU = 1,
  ABLY = 2,
}

// PubNub MsgType
// refs /api/models/message.go
export enum MessageType {
  UNKNOWN = 0,
  LIKE = 2,
  COMMENT = 3,
  BLOCK = 4,
  LIVE_STREAM_END = 5,
  LIVE_STREAM_INFO_CHANGE = 6,
  WATCHER_KEEP_ALIVE = 7,
  PUBLISHER_KEEP_ALIVE = 8,
  WATCHER_END = 9,
  NEW_LIVE_CHUNK_READY = 11,
  NEW_GIFT = 13,
  NEW_JOIN_REQUEST = 14,
  WITHDRAW_JOIN_REQUEST = 15,
  ACCEPT_JOIN_REQUEST = 16,
  REJECT_JOIN_REQUEST = 17,
  JOIN_ROOM = 18,
  LEAVE_ROOM = 19,
  KICK = 20,
  KICK_BY_SKY_EYE = 21,
  ENABLE_BLAB = 22,
  DISABLE_BLAB = 23,
  REQUEST_EXPIRE = 24,
  PARTICIPANT_EXPIRE = 25,
  STREAMER_CONNECT_FAIL = 26,
  SUBSCRIBER_ENTER_LIVE = 27,
  REACT_MSG = 28,
  GUARDIAN_NEW_OWNER = 30,
  GUARDIAN_ENTER = 31,
  NEW_LUCKYBAG = 32,
  NEW_GLOBAL_LUCKYBAG = 33,
  LIVE_STREAM_BROADCAST = 34,
  GLOBAL_INFO_UPDATE = 35,
  GAME = 36,
  USER = 37,
  LIVE = 38,
  POKE_OLD = 39, // Deprecated
  POLL = 40,
  MEDIA_MESSAGE = 41,
  WEREWOLVES_GAME = 42,
  WEREWOLVES_COMMENT = 43,
  GLOBAL_ANNOUNCEMENT = 44,
  POKE_CHALLENGE = 45,
  WEREWOLVES_VOUCHER = 46,
  POKE = 47,
  LIVE_STREAM_PROMOTION = 48,
  TRIVIA = 49,
  SYNC_GIFT = 50,
  RED_ENVELOPE = 51,
  RED_ENVELOPE_END = 52,
  VOTE = 53,
  POP_ROCKVIEWER = 54,
  ARMY_LIVE_STREAM_PROMOTION = 55,
  MY_ARMY_OVERVIEW = 56,
  ENTER_ANIMATION = 57,
  TRIVIA_MEDAL = 58,
  LIVE_STREAM_SYNC = 60,
  ACCOMPANY_ORDER = 61,
  ACCOMPANY_STREAM_INFO = 62,
  ARMY_INVITATION = 63,
  FRESH_USER_ENTER = 64,
  PM = 65,
  RED_ENVELOPE_RECOMMEND = 66,
  MONSTER = 67,
  TOAST = 68,
  MARQUEE = 69,
  STAMP = 70,
  STREAMER_ASSISTANT = 71,
  EVENT_MESSAGE = 72,
  VIDEO_CHAT = 73,
  ROCKVIEWER_UPDATE = 74,
  NEW_POLL = 75,
  PROGRAM_PIN_COMMENT = 76,
  LABORREWARD_STREAMER_RANK_UP = 77,
  DAY_CHANGE = 78,
  LABORREWARD_STREAMER_RECEIVE = 79,
  MISSION_REMIND = 80,
  PK_ACTION = 81,
  PK_SCORE = 82,
  POKE_NEWBIES = 83,
  SHOW_ANIMATION = 84,
  PROMOTION_IAP = 85,
  STREAM_REVIVED = 86,
  RISING_STAR_MESSAGE = 87,
  GROUP_CALL_ACTION = 88,
  GROUP_CALL_MEMBER_STATUS = 89,
  CHANGE_ROOM = 90,
  POKE_GROUP_CALL = 91,
  ARMY_GIFT = 100,
  BONUS_BOX = 101,
  SNACK_SPEED_UP = 105,
  ENERGY_CHANGED = 106,
  ENERGY_RANK_UP = 107,
  SPOTLIGHT_CHANGED = 108,
  SPOTLIGHT_CLAIMED = 109,
  LIVE_STREAM_WARN = 111,
  ARMY_SUBSCRIBE_ANIMATION = 113,
  EVENT_CHANGED = 117,
  LUCKYBAG_COUNT_NOTIFY = 118,
  AI_COHOST_MESSAGE = 120,
  ENGAGEMENT_REWARD_MSG = 122,
}

export enum ReactionTypes {
  SHARE_FB = 0,
  SHARE_17 = 1,
  LIKE = 2,
  FOLLOW = 3,
  SHARE_TWITTER = 4,
  SHARE_LINE = 5,
  SHARE_WHATSAPP = 6,
  SHARE_WECHAT = 7,
  SHARE_MESSENGER = 8,
  SHARE_COPY_LINK = 12,
}

/**
 * API 的架構是 Client 第一次要先 call message_provider 讓 redis 記錄動態分配的 provider
 * 之後其他頁面需要動態切換用 message_provider_dynamic 就會拿到 redis 裡面或是啟用備援功能的 provider
 * @see https://17media.atlassian.net/l/cp/pgvc1vD0
 */
export enum MessageProviderFeatureTypes {
  MESSAGE_PROVIDER = 'message_provider',
  MESSAGE_PROVIDER_DYNAMIC = 'message_provider_dynamic',
}

/**
 * pubnub message param type
 * 0: no need to transfer, use directly
 * 1: lokalise key
 * 2: army gift giftID
 * @see backend_repo https://github.com/17media/api/blob/306736cabeb2a5c2ff1e1b9aad1770edfaef2f2e/models/i18n/i18n.go#L5
 */
export enum MessageMapOption {
  NONE = 0,
  LOKALISE = 1,
  GIFT = 2,
}

export enum CommentShadowType {
  NONE = 'none',
  LIGHT = 'light',
  DARK = 'dark',
}
