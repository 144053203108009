import { map, switchMap } from 'rxjs/operators';

import apis from '../shared/apis';
import { API_PATH, API_PATH_USER } from './constants';

export const getUserInfoByOpenID = openID =>
  apis.legacy('getUserInfo', {
    targetOpenID: openID,
    userObject: {},
  });

export const getProfileInfo = roomID =>
  apis.getJSON(`${API_PATH_USER}/room/${roomID}`).pipe(
    switchMap(info =>
      apis
        .postJSON(`liveStreams/areUsersOnlive`, {
          targetUserIDs: [info.userID],
        })
        .pipe(
          map(([streamID]) => ({ ...info, isOnline: +streamID > -1, roomID }))
        )
    )
  );

export const getUserInfo = userID =>
  apis.getJSON(`${API_PATH}/${userID}/info?onLive=1`).pipe(
    map(userInfo => {
      return {
        ...userInfo,
        isOnline: !!userInfo.onliveInfo?.liveStreamID,
      };
    })
  );

export const getCountry = () => apis.legacy('getCountry');
