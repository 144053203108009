/*
 *
 * Login reducer
 *
 */

import { fromJS, Map } from 'immutable';

import { CURRENCY_TYPES } from '@/containers/BalancesProvider/constants';
import { FOLLOW_STATUS } from '@/containers/FollowButton/constants';
import { SET_LIST as SET_LEADERBOARD_LIST } from '@/containers/Leaderboard/constants';
import { READ_TERM } from '@/containers/ServiceTermsModal/constants';

import { convertToUserFollowingStatus } from '@17live/app/containers/LoginProvider/utils';

import {
  LOGGED_IN,
  LOGGED_OUT,
  LOGIN_ADDITIONAL,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  SET_BALANCES,
  SET_FOLLOWING,
  SET_FOLLOWING_STATUS,
  SET_PROGRAM_INFO,
  UPDATE_LOGIN_STATE,
} from './constants';

const initialState = fromJS({
  status: LOGGED_OUT,
  following: {},
  balances: {},
});

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_LOGIN_STATE:
    case LOGIN_ADDITIONAL:
      return state.set('userInfo', fromJS(payload)).set('status', LOGGED_IN);

    case LOGIN_FAILED:
      return state.set('error', payload.error);

    case LOGOUT_SUCCESS:
      return state
        .set('userInfo', fromJS({}))
        .set('following', Map())
        .set('status', LOGGED_OUT);

    case SET_FOLLOWING:
      return state.mergeIn(
        ['following'],
        Map(
          payload.map(following => [following.userID, FOLLOW_STATUS.FOLLOWING])
        )
      );

    case SET_FOLLOWING_STATUS: {
      const { userID, status } = payload;
      return state.setIn(['following', userID], status);
    }

    case SET_LEADERBOARD_LIST: {
      const { list } = payload;

      return state.mergeIn(
        ['following'],
        Map(
          list.map(({ userInfo }) => {
            const {
              userID,
              isFollowing,
              followRequestTime,
              privacyMode,
            } = userInfo;
            const status = convertToUserFollowingStatus(
              isFollowing,
              followRequestTime,
              privacyMode
            );

            return [userID, status];
          })
        )
      );
    }

    case SET_BALANCES: {
      const balances = payload.balances.reduce((current, balance) => {
        const { currency, amount } = balance;
        const currencyType = CURRENCY_TYPES[currency];

        if (!currencyType) {
          return current;
        }

        return {
          ...current,
          [currencyType]: amount,
        };
      }, {});

      return state.set('balances', fromJS(balances));
    }

    case SET_PROGRAM_INFO: {
      return state.setIn(['userInfo', 'programInfo'], fromJS(payload));
    }

    case READ_TERM:
      return state.setIn(['userInfo', 'unreadTerm'], '');

    default:
      return state;
  }
};

export default loginReducer;
