import {
  FALLBACK_LOCALE_MAP,
  LOCALE_EN,
  LOCALE_EN_US,
  LOCALE_GLOBAL,
  LOCALE_JP,
  LOCALE_KR,
  LOCALE_ZH_CN,
  LOCALE_ZH_TW,
} from './constants';

// map iso locale to react-intl locale keys
export const LocaleMap = lang =>
  ({
    'en-us': LOCALE_EN_US,
    en: LOCALE_GLOBAL,
    zh: LOCALE_ZH_TW,
    'zh-tw': LOCALE_ZH_TW,
    'zh-cn': LOCALE_ZH_CN,
    ja: LOCALE_JP,
    ko: LOCALE_KR,
  }[lang.toLowerCase()]);

export const getIPRegion = () =>
  process.env.NODE_ENV === 'development'
    ? null
    : document.documentElement.dataset.region;

export const mapLocaleToTranslationFileName = locale =>
  FALLBACK_LOCALE_MAP[locale] || locale || LOCALE_EN;
