import { KEY_LANDINGPAGE_RECORD, LocalStorage } from '@/services/Storage';

import { VALID_TIME } from '@17live/app/containers/LandingPage/LandingPage.constants';

export const getRefererForRecordingLandingPage = () => {
  const landingPageRecord = LocalStorage.getItem(KEY_LANDINGPAGE_RECORD);

  if (
    landingPageRecord?.timestamp &&
    Date.now() - landingPageRecord?.timestamp < VALID_TIME
  ) {
    return landingPageRecord?.url;
  }
  return null;
};
