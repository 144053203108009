import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Reducer } from 'redux';
import { Epic } from 'redux-observable';

import { getAsyncInjectors } from '@/utils/asyncInjectors';

export const useAsyncInjectors = (
  key: string,
  reducer: Reducer,
  epics: Epic[]
) => {
  const store = useStore();
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const { injectReducer, injectEpics } = getAsyncInjectors(store);

    injectReducer(key, reducer);
    injectEpics(epics);

    setIsDone(true);
  }, [epics, key, reducer, store]);

  return isDone;
};
