import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';

import {
  DEFAULT_LANG,
  DEFAULT_REGION,
  LANG,
  LangType,
  MAP_BROWSER_LANG_TO_SUPPORT_LANG,
  RegionType,
  SUPPORT_LANGS,
  SUPPORT_REGIONS,
  SWAP_LANG_REGION,
} from '@17live/app/constants';
import { MAP_FE_LANG_TO_BE_LANG } from '@17live/app/constants/langs';
import { getIPRegion } from '@17live/app/containers/LanguageProvider/utils';
import { getPreference } from '@17live/app/utils/preferences';

const supportLangsSet = new Set(SUPPORT_LANGS);

/**
 * TODO: remove it if app language mapping implement
 *
 * This is a workaround for mapping language from app's share link
 * The variant language table is from: https://17media.atlassian.net/browse/PROD-4749
 */
export const variantLanguagesFromAppClientMap = {
  'zh-tw': LANG.ZH_HANT,
  zh: LANG.ZH_HANT,
  'zh-hant-tw': LANG.ZH_HANT,
  'zh-cn': LANG.ZH_HANT,
  zh_sg: LANG.ZH_HANT,
  'zh-sg': LANG.ZH_HANT,

  'zh-hant-hk': LANG.ZH_HK,

  'ja-jp': LANG.JA,
};

export const getSupportedVariantLanguagesFromAppClientCaseInsensitive = (
  checkLang: string
) => {
  const langLowerCase = checkLang.toLowerCase();

  return langLowerCase in variantLanguagesFromAppClientMap ? checkLang : null;
};

export const getSupportedLangCaseInsensitive = (checkLang: string) =>
  SUPPORT_LANGS.find(l => l.toLowerCase() === checkLang.toLowerCase());

const getUserRegion = () =>
  LocalStorage.getItem(KEY_LOGIN_INFO)?.region as string | undefined;

const getUserLang = () =>
  LocalStorage.getItem(KEY_LOGIN_INFO)?.language as string | undefined;

/**
1. URL
2. localStorage's preference
3. Browser's preference
3. User info language country code
4. User info region country code
5. IP region country code
7. Default en
   */
export const getLang = () => {
  const urlLang: string | undefined = location.pathname.split('/')[1];

  const lang =
    getSupportedLangCaseInsensitive(urlLang) ??
    getPreference('lang') ??
    navigator.languages
      // @ts-expect-error
      .map(l => MAP_BROWSER_LANG_TO_SUPPORT_LANG[l] ?? l)
      .find(l => supportLangsSet.has(l)) ??
    // @ts-expect-error
    SWAP_LANG_REGION[getUserLang()] ??
    // @ts-expect-error
    SWAP_LANG_REGION[getUserRegion()] ??
    // @ts-expect-error
    SWAP_LANG_REGION[getIPRegion()] ??
    DEFAULT_LANG;
  return lang as LangType;
};

/**
 * 根據 MAP_FE_LANG_TO_BE_LANG ，來轉成 backend 的 language code
 * @see MAP_FE_LANG_TO_BE_LANG packages/app/constants/langs.ts
 */
export const getBackendLanguageCode = (languageCode: string) => {
  return (
    MAP_FE_LANG_TO_BE_LANG[languageCode]?.toLocaleUpperCase() ?? languageCode
  );
};

/**
1. localStorage's preference
2. User info region country code
3. User info language country code
4. IP region country code
5. Preference lang to region
6. Default region global
   */
export const getRegion = () => {
  const lang = getLang();

  const originalRegion =
    getPreference('region') ??
    getUserRegion() ??
    getUserLang() ??
    getIPRegion() ??
    // @ts-expect-error
    SWAP_LANG_REGION[lang] ??
    DEFAULT_REGION;

  // 因為有些判斷方式，可能會出現 web 並不支援的 region（例如：getIPRegion）
  // 所以最後包一層確認是不是 web 支援
  // https://17media.atlassian.net/l/cp/TLy25zod
  const region = SUPPORT_REGIONS.includes(originalRegion)
    ? originalRegion
    : DEFAULT_REGION;

  // avoid empty string in e2e mode
  return region as RegionType;
};

export const getPathWithoutLang = (pathname = location.pathname) => {
  const urlLang: string | undefined = pathname.split('/')[1];

  const lang = getSupportedLangCaseInsensitive(urlLang);

  if (lang) return pathname.replace(/^\/[^/]+/, '') || '/';

  return pathname;
};

/**
 * This is an outdated logic, please DO NOT use this process, but we kept the comment as a record
 * You can check detail at
 * https://github.com/17media/17live/pull/2480
 * https://github.com/17media/17live/pull/2481
 *
 * The order of source to get locale from is as follow:
 *  1. `lang` key in query string
 *  2. `locale` key in query string
 *  3. `locale` key in preference sessionStorage
 *  4. `region` key in login info
 *  5. `region` provided by server-side rendering
 *  6. `navigator.language` from browser variable
 *  7. `defaultLocale` defined
 * And the order of parsing of the raw value is as follow:
 *  1. if is from preference sessionStorage, return it as unparsed
 *  2. otherwise try parse value as raw language code
 *  3. try parse value as region code
 *  4. try get only the prefix and parse value as language code
 */
