import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { setAsideLiveActiveTab } from '@/containers/AsideLive/actions';
import { makeSelectAsideLiveActiveTab } from '@/containers/AsideLive/selectors';

const asideLiveTabSelector = createStructuredSelector({
  activeTab: makeSelectAsideLiveActiveTab(),
});

export const useAsideLiveTab = () => {
  const dispatch = useDispatch();
  const selector = useSelector(asideLiveTabSelector);

  const switchTab = useCallback(
    tab => {
      dispatch(setAsideLiveActiveTab(tab));
    },
    [dispatch]
  );

  return {
    ...selector,
    switchTab,
  };
};
