export enum VIPGroups {
  NONE = 0,
  VIP_BABY = 1,
  VIP_SUPER = 2,
}

/**
 * 這裡的 value 是 FE 定義的，會與未來的 purchase config API response 有關，不可隨意更改
 */
export enum VIPGroupsName {
  NONE = 'vipGroupNone',
  VIP_BABY = 'vipGroupBaby',
  VIP_SUPER = 'vipGroupSuper',
}

// BE definnition
// ref: https://github.com/17media/api/blob/29a7d03b6fdb3e9fc51934beaf477d4f9d1ca291/models/purchase/pay/pay.go#L28
export enum PurchasePayMethod {
  NEWEBPAY = 72,
  ECPAY = 56,
  JKOPAY = 53,
  MYCARD = 67,
  GASH = 64,
  GMO = 8,
  PAIDY = 70,
  FIRSTDATA = 58,
  STRIPE = 62,
  PAYERMAX = 78,
  PAYUNI = 80,
}

// BE definition
// ref: https://github.com/17media/api/blob/29a7d03b6fdb3e9fc51934beaf477d4f9d1ca291/models/purchase/order/order.go#L133
export enum SellingChannel {
  // Purchase
  GLOBAL_17LIVE_NEWEBPAY = 59,
  GLOBAL_17LIVE_PAYUNI = 89,
  TW_SUPER_VIP = 19,
  TW_SUPER_VIP_NEWEBPAY = 58,
  TW_SUPER_VIP_PAYUNI = 88,
  TW_BABY_VIP = 32,
  TW_BABY_VIP_NEWEBPAY = 57,
  TW_BABY_VIP_PAYUNI = 87,
  TW_BABY_VIP_ECPAY = 34,
  TW_BABY_VIP_JKOPAY = 46,
  TW_BABY_VIP_MYCARD = 47,
  TW_BABY_VIP_GASH = 48,
  TW_17LIVE = 29,
  TW_17LIVE_NEWEBPAY = 60,
  TW_17LIVE_PAYUNI = 86,
  TW_17LIVE_ECPAY = 33,
  TW_17LIVE_JKOPAY = 35,
  TW_17LIVE_MYCARD = 30,
  TW_17LIVE_GASH = 31,
  JP_SUPER_VIP_GMO = 22,
  JP_SUPER_VIP_PAIDY = 53,
  JP_BABY_VIP = 21,
  JP_BABY_VIP_PAIDY = 52,
  JP_17LIVE = 9,
  JP_17LIVE_PAIDY = 51,
  HK_VIP = 27,
  US_VIP = 24,
  IN_SUPER_VIP_STRIPE = 69,
  IN_17LIVE_STRIPE = 67,
  SEA_17LIVE_NEWEBPAY = 61, // MY, SG, PH, ID, TH, VN, MM 共用
  PH_17LIVE_PAYERMAX = 77,
  MY_17LIVE_PAYERMAX = 78,
  SG_17LIVE_PAYERMAX = 79,
  // Army
  GLOBAL_ARMY_NEWEBPAY = 63,
  GLOBAL_ARMY_ECPAY = 40,
  TW_ARMY_NEWEBPAY = 62,
  TW_ARMY_ECPAY = 37,
  JP_ARMY_GMO = 49,
  HK_ARMY_NEWEBPAY = 64,
  HK_ARMY_ECPAY = 38,
  US_ARMY_NEWEBPAY = 71,
  US_ARMY_ECPAY = 45,
  MY_ARMY_NEWEBPAY = 65,
  MY_ARMY_ECPAY = 39,
  IN_ARMY_NEWEBPAY = 70,
  IN_ARMY_ECPAY = 68,
  SEA_ARMY_NEWEBPAY = 66, // SG, PH, ID, TH, VN, MM 共用
}

export enum Currency {
  TWD = 'TWD',
  HKD = 'HKD',
  USD = 'USD',
  JPY = 'JPY',
  INR = 'INR',
  PHP = 'PHP',
  MYR = 'MYR',
  SGD = 'SGD',
}

/**
 * @deprecated 未來也要淘汰這個欄位了，改用 purchaseTimeMs 來判斷 order 為成功 or 失敗
 * Backend 定義的 Order state
 * refs: https://github.com/17media/api/blob/62272979d818335fd54a63adb5e5f81b6f101754/models/purchase/pay/pay.go#L401-L410
 * 此狀態無法判斷成功 or 失敗，僅代表三方進行到哪個階段
 */
export enum PayOrderState {
  NOT_FOUND = 0, // means not found
  PREPARE = 1, // means under prepare state
  RESULT = 2, // means under result state
  NOTIF = 3, // means under notif state
}

export enum PaymentType {
  CREDIT = 'CREDIT',
  CVS = 'CVS',
}
