/*
 *
 * LiveStream constants
 *
 */

export const SET_COMMENT_ERROR = 'app/LiveStream/SET_COMMENT_ERROR';
export const RECEIVE_MARQUEE = 'app/LiveStream/RECEIVE_MARQUEE';
export const RECEIVE_GLOBAL_MESSAGE = 'app/LiveStream/RECEIVE_GLOBAL_MESSAGE';

export const UNLOCK_PREMIUM_ROOM = 'app/LiveStream/UNLOCK_PREMIUM_ROOM';

export const ENTER_LIVE_STREAM = 'app/LiveStream/ENTER_LIVE_STREAM';
export const ENTER_LIVE_STREAM_SUCCESS =
  'app/LiveStream/ENTER_LIVE_STREAM_SUCCESS';
export const LEAVE_LIVE_STREAM = 'app/LiveStream/LEAVE_LIVE_STREAM';
export const UPDATE_BARRAGE_INFOS = 'app/LiveStream/UPDATE_BARRAGE_INFOS';
export const LEFT_LIVE_STREAM = 'app/LiveStream/LEFT_LIVE_STREAM';
export const GET_LIVE_STREAM_INFO = 'app/LiveStream/GET_LIVE_STREAM_INFO';
export const GET_ENTER_LIVE_STREAM_INFO =
  'app/LiveStream/GET_ENTER_LIVE_STREAM_INFO';
export const RECEIVED_LIVE_STREAM_INFO =
  'app/LiveStream/RECEIVED_LIVE_STREAM_INFO';
export const CLEAR_LIVE_STREAM_INFO = 'app/LiveStream/CLEAR_LIVE_STREAM_INFO';
export const CHANGE_LIVE_STREAM_PROVIDER =
  'app/LiveStream/CHANGE_LIVE_STREAM_PROVIDER';
export const RECEIVED_KEEP_VIEW_RESULT =
  'app/LiveStream/RECEIVED_KEEP_VIEW_RESULT';
export const ON_RECEIVED_LIVE_STREAM_INFO =
  'app/LiveStream/ON_RECEIVED_LIVE_STREAM_INFO';
export const RECEIVED_LIVE_STREAM_LIST =
  'app/LiveStream/RECEIVED_LIVE_STREAM_LIST';
export const PUBLISH_STREAM = 'app/LiveStream/PUBLISH_STREAM';
export const END_STREAM = 'app/LiveStream/END_STREAM';
export const KEEP_STREAM = 'app/LiveStream/KEEP_STREAM';
export const KEEP_STREAM_POLLING = 'app/LiveStream/KEEP_STREAM_POLLING';
export const KEEP_VIEW_STREAM_POLLING =
  'app/LiveStream/KEEP_VIEW_STREAM_POLLING';
export const RUN_KEEP_VIEW_STREAM_POLLING =
  'app/LiveStream/RUN_KEEP_VIEW_STREAM_POLLING';
export const RECEIVED_KEEP_STREAM = 'app/LiveStream/RECEIVED_KEEP_STREAM';
export const UPDATE_LIVE_STREAM_STATUS =
  'app/LiveStream/UPDATE_LIVE_STREAM_STATUS';

export const SUBSCRIBED_CHAT_ROOM = 'app/LiveStream/SUBSCRIBED_CHAT_ROOM';

export const SET_ROOMID_ALIVE_CALLED = 'app/LiveStream/SET_ROOMID_ALIVE_CALLED';

// group call
export const GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED =
  'app/LiveStream/GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED';

export const GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED_ONCE =
  'app/LiveStream/GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED_ONCE';
export const GROUP_CALL_ACTION = 'app/LiveStream/GROUP_CALL_ACTION';
export const GROUP_CALL_MEMBER_STATUS =
  'app/LiveStream/GROUP_CALL_MEMBER_STATUS';
export const CHANGE_ROOM = 'app/LiveStream/CHANGE_ROOM';
export const ENTER_GROUP_CALL_SUCCESS =
  'app/LiveStream/ENTER_GROUP_CALL_SUCCESS';
export const CLEAR_GROUP_CALL_INFO = 'app/LiveStream/CLEAR_GROUP_CALL_INFO';
export const SET_GROUP_CALL_WRAPPER_HOVER: string =
  'app/LiveStream/SET_GROUP_CALL_WRAPPER_HOVER';
export const SET_IS_LIVE_STREAM_LOADING =
  'app/LiveStream/SET_IS_LIVE_STREAM_LOADING';

// concert
export const RECEIVED_CONCERT_INFO = 'app/LiveStream/RECEIVED_CONCERT_INFO';

export const SUBSCRIBE_GLOBAL_CHANNEL =
  'app/LiveStream/SUBSCRIBE_GLOBAL_CHANNEL';

export const TRIVIA_TYPES = {
  NORMAL: 1,
  TV: 2,
};

export const LIVE_STREAM_TYPES = {
  NONE: 'NONE',
  NORMAL: 'NORMAL',
  TRIVIA: 'TRIVIA',
  GAMING: 'GAMING',
};

// Backend use this interval to calculate the duration of some of the features,
// so we have to force it to be 5 seconds
export const KEEP_VIEW_POLLING_INTERVAL = 5000; // 5 seconds

// Backend will check alive for 90s, increase keep live interval time
export const KEEP_LIVE_POLLING_INTERVAL = 30000; // 30 seconds

export const STREAM_MODE = {
  NONE: 'none',
  NORMAL: 'normal',
  PROGRAM: 'program',
};

export const ERROR_CODE = {
  NOT_ENOUGH_POINT: 16,
  INVALID_TOKEN: 7,
};

export const PREMIUM_TYPES = {
  NONE: 0,
  PAID: 1,
  ARMY: 2,
  NEW_USER: 3,
};

/**
 * types
 */
export type Tabs =
  | 'following'
  | 'suggested'
  | 'latest'
  | 'music'
  | 'show'
  | 'gaming'
  | 'suggestedSecond';

/**
 * tabs
 */
export const TAB_FOLLOWING: Tabs = 'following';
export const TAB_SUGGESTED: Tabs = 'suggested';
export const TAB_LATEST: Tabs = 'latest';
export const TAB_MUSIC: Tabs = 'music';
export const TAB_SHOW: Tabs = 'show';
export const TAB_GAMING: Tabs = 'gaming';
export const TAB_SUGGESTED_SECOND: Tabs = 'suggestedSecond';

export const SUBTABS = 'subtabs';

export const TAB_ROUTE_MAP: { [key: string]: string } = {
  [TAB_SUGGESTED]: '/suggested',
  [TAB_MUSIC]: '/music',
  [TAB_LATEST]: '/latest',
  [TAB_GAMING]: '/gaming',
  [TAB_FOLLOWING]: '/following',
  [TAB_SUGGESTED_SECOND]: '/suggested',
};

/**
 * list
 */
export const LIVE_STREAM_SHORT_LIST_COUNT: { [key: string]: number } = {
  [TAB_SUGGESTED]: 5,
  [TAB_MUSIC]: 5,
  [TAB_LATEST]: 8,
  [TAB_GAMING]: 4,
  [TAB_FOLLOWING]: 5,
  [TAB_SUGGESTED_SECOND]: 5,
};

export const TAB_WITH_OFFSET: Tabs[] = [
  // 0
  TAB_SUGGESTED,
  // 1
  TAB_SUGGESTED_SECOND,
];

export const FETCH_LATEST_THRESHOLD = 5 * 60 * 1000; // 5 mins in ms
export const TABS_LIST: Tabs[] = [TAB_SUGGESTED, TAB_MUSIC, TAB_LATEST];
// since JP, USA does not supports music tab, so it has list different from others
export const TABS_LIST_WITHOUT_MUSIC: Tabs[] = [
  TAB_SUGGESTED,
  TAB_SUGGESTED_SECOND,
  TAB_LATEST,
];

/**
 * styles
 */
export const LIVE_STREAM_LIST_HEIGHT: number = 422;

export const PREVIEW_COUNT: number = 1;
export const LANDSCAPE_TRENDING_COUNT: number = 2;
export const PORTRAIT_TRENDING_COUNT: number = 6;
