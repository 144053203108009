import React from 'react';

import styled, { css } from 'styled-components';

import {
  COLOR_BUTTON_STROKE_SECONDARY_DISABLED,
  COLOR_BUTTON_TEXT_PRIMARY_DISABLED,
  COLOR_NEUTRAL_100,
  COLOR_NEUTRAL_200,
  COLOR_NEUTRAL_700,
  COLOR_NEUTRAL_800,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from '@17live/design-system/color';

export interface ChipProps {
  chipLabel: string | JSX.Element;
  disabled?: boolean;
  isActive: boolean;
  isTheater: boolean;
  onClick: () => void;
  icon?: React.ReactNode | JSX.Element;
}

const activatedWrapperStyles = ({ isTheater }: { isTheater: boolean }) => css`
  background-color: ${isTheater ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK};
  border: 1px solid ${isTheater ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK};

  &:hover:not(:disabled) {
    background-color: ${isTheater ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK};
  }
`;

const disabledWrapperStyles = ({ isTheater }: { isTheater: boolean }) => css`
  border: 1px solid
    ${isTheater ? COLOR_NEUTRAL_700 : COLOR_BUTTON_STROKE_SECONDARY_DISABLED};

  color: ${isTheater
    ? COLOR_PRIMARY_WHITE
    : COLOR_BUTTON_TEXT_PRIMARY_DISABLED};

  cursor: not-allowed;
  opacity: ${isTheater ? 0.3 : 1};
`;

const ChipWrapper = styled.button<{
  disabled: boolean;
  isActive: boolean;
  isTheater: boolean;
  isContentRightIcon: boolean;
}>`
  display: flex;

  ${({ isTheater, isActive }) => css`
    background-color: ${isTheater ? COLOR_PRIMARY_BLACK : COLOR_PRIMARY_WHITE};
    border: 1px solid ${isTheater ? COLOR_NEUTRAL_700 : COLOR_NEUTRAL_200};
    border-radius: 16px;
    box-sizing: border-box;
    cursor: default;
    width: fit-content;
    height: 32px;
    padding: 8px 16px;

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: ${isTheater ? COLOR_NEUTRAL_800 : COLOR_NEUTRAL_100};
    }

    &:disabled {
      ${disabledWrapperStyles}
    }

    ${isActive && activatedWrapperStyles}
  `}
`;

const theaterIconFillColor = (isActive: boolean) =>
  isActive ? COLOR_PRIMARY_BLACK : COLOR_PRIMARY_WHITE;
const normalIconFillColor = (isActive: boolean) =>
  isActive ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK;

const IconWrapper = styled.div<{ isActive: boolean; isTheater: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 4px;

  /* To adjust icon size */
  font-size: 16px;

  svg,
  svg path {
    fill: ${({ isActive, isTheater }) =>
      isTheater
        ? theaterIconFillColor(isActive)
        : normalIconFillColor(isActive)};
  }
`;

const ChipText = styled.span<{
  disabled: boolean;
  isActive: boolean;
  isTheater: boolean;
}>`
  ${({ isTheater, isActive, disabled }) => css`
    display: flex;
    align-items: center;
    color: ${isTheater ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK};
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    white-space: nowrap;

    ${disabled &&
      css`
        color: ${isTheater
          ? COLOR_PRIMARY_WHITE
          : COLOR_BUTTON_TEXT_PRIMARY_DISABLED};
      `}

    ${isActive &&
      css`
        color: ${isTheater ? COLOR_PRIMARY_BLACK : COLOR_PRIMARY_WHITE};
      `}
  `}
`;

const Chip = ({
  chipLabel,
  disabled = false,
  isActive = false,
  isTheater = false,
  icon,
  onClick,
}: ChipProps) => {
  return (
    <ChipWrapper
      isActive={isActive}
      isTheater={isTheater}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <IconWrapper isActive={isActive} isTheater={isTheater}>
          {icon}
        </IconWrapper>
      )}
      <ChipText isActive={isActive} isTheater={isTheater} disabled={disabled}>
        {chipLabel}
      </ChipText>
    </ChipWrapper>
  );
};

export default Chip;
