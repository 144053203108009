import { setTrackAPIHeaders } from '@17live/app/services/analytics';

import apis from './shared/apis';

const API_PATH = 'pokes';

export const POKE_TYPES = Object.freeze({
  NORMAL: 0,
  FRESH_USER: 1,
});

// both userID and roomID are from the streamer
export const sendPoke = (userID, roomID, isPokeBack = true, traceID) =>
  apis.postJSON(
    API_PATH,
    {
      isPokeBack,
      userID,
      srcID: roomID,
    },
    setTrackAPIHeaders({ 'trace-id': traceID })
  );
