import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { parse } from 'query-string';

const useQueryParams = () => {
  const location = useLocation();

  const queryParams = useMemo(() => parse(location.search), [location.search]);

  return queryParams;
};

export default useQueryParams;
