// @flow
import { ungzip } from 'pako/dist/pako_inflate.min';
import { WANSU_MESSAGE_TYPES, WANSU_MESSAGE_TYPE_INDEX } from './constants';

export const getReconnectDelayMS = (times: number): number => {
  if (times < 0) {
    return 7000;
  } else if (times <= 1) {
    return 500;
  } else if (times <= 2) {
    return 1000;
  } else if (times <= 3) {
    return 2000;
  } else if (times <= 4) {
    return 3000;
  } else if (times <= 5) {
    return 5000;
  }

  return 7000;
};

const readMessage = (data: ArrayBuffer): Promise<Uint8Array> =>
  Promise.resolve(new Uint8Array(data));

const readMessageType = (data: ArrayBuffer): Promise<number> =>
  readMessage(data.slice(0, 7)).then(
    (message: Uint8Array) => message[WANSU_MESSAGE_TYPE_INDEX]
  );

export const processMessage = (
  data: ArrayBuffer
): Promise<{
  message: Uint8Array,
  messageType: number,
}> =>
  readMessageType(data).then((messageType: number) => {
    if (messageType === WANSU_MESSAGE_TYPES.UNKNOWN) {
      throw new Error('Unknown message type');
    }

    const msg =
      messageType === WANSU_MESSAGE_TYPES.PUBLIC_MESSAGE ? data.slice(7) : data;

    return readMessage(msg).then(message => ({ message, messageType }));
  });

const mapUnit8ArrayToString = (array: Uint8Array): string =>
  String.fromCodePoint.apply(null, array);

export const decodeMessage = (gzipMessage: Uint8Array): string =>
  [escape, decodeURIComponent, JSON.parse].reduce(
    (message: string, fn: string => string): string => fn(message),
    mapUnit8ArrayToString(ungzip(gzipMessage))
  );
