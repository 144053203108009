import React, { ReactNode } from 'react';
import { FormattedMessage as BaseFormattedMessage } from 'react-intl';

import en from '@/translations/en.json';

type Values = Record<
  string,
  number | string | JSX.Element | ((s: string) => JSX.Element) | ReactNode
>;

export type I18nKeys = keyof typeof en;

export const $t = (id: I18nKeys, values?: Values) => {
  if (id in en) {
    return (
      <BaseFormattedMessage id={id} defaultMessage={en[id]} values={values} />
    );
  }

  // TODO: 因為目前 Logger 的內容不如預期，避免打過多無效的 log 於 kibana，故先 comment 起來
  // ref: https://17media.slack.com/archives/GE43TED7V/p1717410489762589
  // Logger.warn('[i18n]', "Can't find lokalise key", {
  //   id,
  // });

  return id as string;
};

export const FormattedMessage = ({
  id,
  values,
  children,
}: {
  id: I18nKeys;
  values?: Values;
  children: (text: string) => string | JSX.Element;
}) => (
  <BaseFormattedMessage id={id} defaultMessage={en[id]} values={values}>
    {children}
  </BaseFormattedMessage>
);

export default $t;
