export enum SettingsRoutePath {
  LIVE = '/settings/live',

  // GenAI for SEO setting
  OPTIMIZED_SEO = '/optimized-seo',
}

export enum AvatarRoutePath {
  AVATAR = '/avatar',
  INFO_1 = '/info1',
  INFO_2 = '/info2',
}

export enum ContractApplication {
  CONTRACT_APPLICATION = '/contract-application',
  CONTRACT_APPLICATION_APP = '/contract-application/app',
  STEP1 = '/step1',
  STEP2 = '/step2',
  STATUS = '/status',
}

export enum ErrorRoutePath {
  NOT_FOUND = '/404',
  SYSTEM_MAINTENANCE = '/maintenance',
  OVERLOAD = '/overload',
  PRIVATE_CONTENT = '/private-content',
}

export enum WebViewRoutePath {
  VLIVER = '/v-live-character/introduction',
  CONTRACT_APPLICATION_APP = ContractApplication.CONTRACT_APPLICATION_APP,
  AI_ASSISTANT = '/ai-assistant/introduction',
}

export enum Auth {
  REDIRECT_AUTH = '/redirect_auth',
  REDIRECT_AUTH_LOGOUT = '/redirect_auth/logout',
}

export enum ShoppingRoutePath {
  SHOPPING_MANAGE = '/shopping',
}

export enum StreamerAnalyticsPath {
  ANALYTICS_OVERVIEW = '/analytics/overview',
}
