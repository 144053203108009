import { ajax } from 'rxjs/ajax';
import { catchError, map, retry } from 'rxjs/operators';

import omitDeep from '@/utils/omitDeep';

import { event } from '@17live/app/constants/configs';
import Logger from '@17live/app/services/Logger';

import { RETRIES } from './constants';

const LOGGER_LABEL = 'EventApi';

const eventApi = ({ endpoint, method, payload }) =>
  ajax(
    omitDeep({
      url: `${event.url}api/${endpoint}`,
      method,
      headers: {
        Accept: 'application/json',
      },
      crossDomain: true,
      body: payload,
    })
  ).pipe(
    retry(RETRIES),
    map(e => e.response),
    catchError(error => {
      Logger.info(LOGGER_LABEL, '17App event API failed', {
        endpoint,
        method,
        payload,
        error,
      });

      throw error;
    })
  );

export default eventApi;
