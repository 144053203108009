import { stringify } from 'query-string';

import apis from './shared/apis';

const API_PATH = 'army';

export const getArmyRelation = userID =>
  apis.getJSON(`${API_PATH}/relation/${userID}`);

export const searchArmy = query =>
  apis.getJSON(
    `${API_PATH}/search?${stringify({
      query,
      count: 100,
    })}`
  );

export const checkArmyAvailable = region =>
  apis.getJSON(`${API_PATH}/available/${region}`);
