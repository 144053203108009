import { VIPGroups } from '@17live/app/containers/PurchasePage/PurchasePage.enums';

export const API_PATH = 'pay';

export const PRODUCT_TYPES = {
  POINT: 1,
  VIP: 2,
  AUTO_TOPUP: 3,
  JA_POINT: 4,
  CN_POINT: 5,
  ARMY: 6,
  INTERNAL_ORDER_POINT: 7,
};

export const PRODUCT_DEVICES = {
  PC: '1',
  MOBILE: '2',
};

export const NEWEBPAY = 'NEWEBPAY'; // NewebPay 藍新金流 (前身為 SPG 智付通)
export const ECPAY = 'ECPAY'; // ECPay 綠界支付
export const JKOPAY = 'JKOPAY'; // JkoPay 街口支付
export const SMBC_GMO = 'SMBCGMO'; // SMBC GMO Payment
export const PAIDY = 'PAIDY'; // Paidy
export const MY_CARD = 'MYCARD'; // MyCard Payment
export const GASH = 'GASH'; // Gash Payment
export const FIRST_DATA = 'FIRSTDATA'; // FirstData
export const STRIPE = 'STRIPE'; // Stripe
export const PAYERMAX = 'PAYERMAX'; // PayerMax
export const PAYUNI = 'PAYUNI'; // PAYUNi

export const VIP_GROUPS_PATH = {
  [VIPGroups.NONE]: '',
  [VIPGroups.VIP_BABY]: '/vip_baby',
  [VIPGroups.VIP_SUPER]: '/vip_super',
};

export const ORDER_STATUS = {
  INIT: 0,
  PAY_SUCCESS: 1,
  PAY_FAILURE: 2,
  MANUAL_PRODUCT_PAY_SUCCESS: 3,
  MANUAL_PRODUCT_EXCHANGE_SUCCESS: 4,
  MANUAL_PRODUCT_EXCHANGE_FAILURE: 5,
  MANUAL_PRODUCT_TRANSFER_TO_INTERNAL_ORDER: 7,
};

// Backend 定義的 Order state
// refs: https://github.com/17media/api/blob/62272979d818335fd54a63adb5e5f81b6f101754/models/purchase/pay/pay.go#L401-L410
/**
 * @deprecated Change to use PayOrderState (packages/app/containers/PurchasePage/PurchasePage.types.ts)
 */
export const ORDER_STATE = {
  NOT_FOUND: 0, // means not found
  PREPARE: 1, // means under prepare state
  RESULT: 2, // means under result state
  NOTIF: 3, // means under notif state
};
