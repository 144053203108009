/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';

import { connectRouter } from 'connected-react-router/immutable';

import registerModalReducer from '@/containers/RegisterModal/reducer';
import userProviderReducer from '@/containers/UserProvider/reducer';

import configReducer from '@17live/app/containers/Config/reducer';
import * as eventPage from '@17live/app/containers/EventPage/reducer';
import languageProviderReducer from '@17live/app/containers/LanguageProvider/reducer';
import liveStreamReducer from '@17live/app/containers/LiveStream/reducer';
import loginProviderReducer from '@17live/app/containers/LoginProvider/reducer';
import preferenceProviderReducer from '@17live/app/containers/PreferenceProvider/reducer';
import snackReducer from '@17live/app/containers/Snack/Snack.reducer';
import snackRankSystemReducer from '@17live/app/containers/SnackRankSystem/SnackRankSystem.reducer';
import statusAnnouncementReducer from '@17live/app/containers/StatusAnnouncement/StatusAnnouncement.reducer';
/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer({ history, asyncReducers }) {
  return combineReducers({
    config: configReducer,
    router: connectRouter(history),
    language: languageProviderReducer,
    login: loginProviderReducer,
    registerModal: registerModalReducer,
    users: userProviderReducer,
    liveStream: liveStreamReducer,
    statusAnnouncement: statusAnnouncementReducer,
    preference: preferenceProviderReducer,
    eventPage: combineReducers(eventPage),
    snack: snackReducer,
    snackRankSystem: snackRankSystemReducer,
    ...asyncReducers,
  });
}
