import { defer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeAll } from 'rxjs/operators';

import configs from '@17live/app/constants/configs';
import Logger from '@17live/app/services/Logger';
import { handleError } from '@17live/app/utils/api';
import { authToken, getAuthHeader } from '@17live/app/utils/authToken';

import { METHODS } from './constants';

const LOGGER_LABEL = 'FileApi';
const endpoint = 'uploadFile';
const method = METHODS.post;

const uploadFileApi = formData => {
  return defer(async () => {
    let jwtAccessToken;

    try {
      jwtAccessToken = await authToken.getJwtToken();
    } catch (error) {
      return Promise.reject(error);
    }

    return ajax({
      method,
      url: `${configs.url}/${endpoint}`,
      headers: {
        ...getAuthHeader(jwtAccessToken),
      },
      crossDomain: true,
      body: formData,
    }).pipe(
      map(e => e.response),
      catchError(error => {
        Logger.info(LOGGER_LABEL, '17App uploadFile API failed', {
          endpoint,
          method,
          formData,
          error,
        });

        handleError(error);
        throw error;
      })
    );
  }).pipe(mergeAll());
};

export default uploadFileApi;
