import { VENDORS } from '@/containers/Purchase/constants';

import { setTrackAPIHeaders } from '@17live/app/services/analytics';

import apis from '../shared/apis';
import { API_PATH } from './constants';

/**
 * Pay Order
 */
export const requestPayOrder = ({
  traceID,
  vendor,
  productID,
  returnURL,
  clientBackURL,
  ...payload
}) => {
  const request = {
    ...payload,
    productID,
    vendor,
    returnURL,
    clientBackURL,
  };

  return apis.postJSON(
    `${API_PATH}/orders`,
    request,
    setTrackAPIHeaders({
      'trace-id': traceID,
    })
  );
};

export const requestPayOrderWithAuth = ({
  traceID,
  vendor,
  productID,
  returnURL,
  clientBackURL,
  ...payload
}) => {
  const request = {
    ...payload,
    productID,
    vendor,
    returnURL,
    clientBackURL,
  };

  return apis.postJSON(
    `${API_PATH}/orders/${VENDORS[vendor]}/auth`,
    request,
    setTrackAPIHeaders({
      'trace-id': traceID,
    })
  );
};

export const requestSaveCard = ({ orderID, ...payload }) => {
  const request = {
    ...payload,
    orderID,
  };

  // 目前僅 GMO 有儲存卡號功能，未來若有其他金流要實作，需傳入 vendor 參數。
  return apis.postJSON(`${API_PATH}/orders/gmo/saveCard`, request);
};

// 目前剩下 Army 訂閱結果會使用
export const getPayOrder = ({ orderID }) =>
  apis.getJSON(`${API_PATH}/orders/${orderID}`);

// 目前 Purchase 所有金流已從 getPayOrder 改用 getPayOrderInfos 取得交易結果
export const getPayOrderInfos = ({ orderID }) =>
  apis.getJSON(`${API_PATH}/orders/${orderID}/infos`);
