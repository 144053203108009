import { css } from 'styled-components';

import isRetina from '@17live/app/utils/isRetina';

export const getPathName = (path: string) => {
  const [ext] = path.match(/([^.])+$/) ?? [];
  const base = path
    .split('.')
    .slice(0, -1)
    .join('.');

  return { ext, base };
};

/**
 *
 * @param src
 * src (includes extname) is mean image's fallback path.
 * if browser is supported .webp format will use webp.
 * if is retina device will use @2x image.
 */

export const getWebp2xURL = (src: string, { has2x = true } = {}) => {
  const ratio = isRetina() && has2x ? '@2x' : '';

  const { base, ext } = getPathName(src);

  const extName = window.Modernizr?.webp ? `webp` : ext;

  return `${base}${ratio}.${extName}`;
};

export const webp2xBackgroundCSS = (src: string) => {
  return css`
    background-image: url('${getWebp2xURL(src)}');
    background-repeat: no-repeat;
  `;
};
