import { I18nKeys } from '@17live/app/utils';

export const ZH_HANT = 'zh-Hant';
export const ZH_TW = 'zh-TW';
export const ZH_HK = 'zh-HK';
export const JA = 'ja';
export const EN_US = 'en-US';
export const EN = 'en';

// group `zh-` lang
export const ZH = 'zh';

export type LangType =
  | typeof ZH_HANT
  | typeof ZH_TW
  | typeof ZH_HK
  | typeof JA
  | typeof EN_US
  | typeof EN;

export const LANG = Object.freeze({
  ZH_HANT,
  ZH_HK,
  JA,
  EN_US,
  EN,
});

export const GLOBAL = 'GLOBAL';
export const TW = 'TW';
export const US = 'US';
export const MY = 'MY';
export const HK = 'HK';
export const SG = 'SG';
export const JP = 'JP';
export const PH = 'PH'; // Philippines
export const ID = 'ID'; // Indonesia
export const TH = 'TH'; // Thailand
export const VN = 'VN'; // Vietnam
export const MM = 'MM'; // Myanmar
export const IN = 'IN'; // India

export type RegionType =
  | typeof GLOBAL
  | typeof TW
  | typeof US
  | typeof MY
  | typeof HK
  | typeof SG
  | typeof JP
  | typeof PH
  | typeof ID
  | typeof TH
  | typeof VN
  | typeof MM
  | typeof IN;

export const REGION = Object.freeze({
  GLOBAL,
  TW,
  US,
  MY,
  HK,
  SG,
  JP,
  PH,
  ID,
  TH,
  VN,
  MM,
  IN,
});

export const DEFAULT_LANG = EN;
export const DEFAULT_REGION = GLOBAL;

export const SUPPORT_LANGS = [
  LANG.ZH_HANT,
  LANG.ZH_HK,
  LANG.JA,
  LANG.EN_US,
  DEFAULT_LANG,
];

export const SUPPORT_REGIONS = [
  REGION.TW,
  REGION.GLOBAL,
  REGION.JP,
  REGION.HK,
  REGION.MY,
  REGION.US,
  REGION.SG,
  REGION.PH,
  REGION.ID,
  REGION.TH,
  REGION.VN,
  REGION.MM,
  REGION.IN,
];

export const SWAP_LANG_REGION = Object.freeze({
  [TW]: ZH_HANT,
  [HK]: ZH_HK,
  [JP]: JA,
  [US]: EN_US,
  [MY]: EN,
  [SG]: ZH_HANT,
  [GLOBAL]: EN,
  [PH]: EN,
  [ID]: EN,
  [TH]: EN,
  [VN]: EN,
  [MM]: EN,
  [IN]: EN,

  [ZH_HANT]: TW,
  [ZH_HK]: HK,
  [JA]: JP,
  [EN_US]: US,
  [EN]: GLOBAL,
});

export const MAP_BROWSER_LANG_TO_SUPPORT_LANG = Object.freeze({
  [ZH_TW]: ZH_HANT,
});

export const LANG_ROUTE = `/:lang(${Object.values(LANG).join('|')})`;

/**
 * 將前端定義的 lang 對應到後端定義的 lang
 *
 * BE 的定義
 * langMap = map[string]string{
 * 	 "us": "en_us",
 * 	 "id": "id",
 * 	 "tw": "tw",
 * 	 "hk": "tw",
 * 	 "cn": "cn",
 * 	 "jp": "jp",
 * 	 "kr": "kr",
 * 	 "ar": "ar",
 * }
 * DefaultLang = "en"
 */
export const MAP_FE_LANG_TO_BE_LANG = Object.freeze({
  [LANG.ZH_HANT]: 'tw',
  [LANG.ZH_HK]: 'tw',
  [LANG.JA]: 'jp',
  [LANG.EN_US]: 'en_us',
  [LANG.EN]: 'en',
});

export const REGION_KEY_MAP = {
  [REGION.GLOBAL]: 'app.regions.GLOBAL',
  [REGION.TW]: 'app.regions.TW',
  [REGION.US]: 'app.regions.US',
  [REGION.MY]: 'app.regions.MY',
  [REGION.HK]: 'app.regions.HK',
  [REGION.SG]: 'app.regions.SG',
  [REGION.JP]: 'app.regions.JP',
  [REGION.PH]: 'app.regions.PH',
  [REGION.ID]: 'app.regions.ID',
  [REGION.TH]: 'app.regions.TH',
  [REGION.VN]: 'app.regions.VN',
  [REGION.MM]: 'app.regions.MM',
};
