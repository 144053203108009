// @flow
import React from 'react';
import { type RouterHistory, Route } from 'react-router-dom';

import { LinkWithLang } from '@17live/app/components/Link';

type Props = {
  modalID: string,
  replace?: boolean,
};

const ModalLink = ({ modalID, ...props }: Props) => (
  <Route
    render={({ location }) => (
      <LinkWithLang
        to={{
          ...location,
          hash: `#${modalID}`,
        }}
        {...props}
      />
    )}
  />
);

export const openModal = (history: RouterHistory) => ({
  modalID,
  replace,
}: Props) =>
  (replace ? history.replace : history.push)({
    ...history.location,
    hash: `#${modalID}`,
  });

export default ModalLink;
