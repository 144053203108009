/**
 *
 * ImageOnLoad
 *
 */
import { Component } from 'react';

import { from, Subscription } from 'rxjs';

import imageOnLoad from '@17live/app/utils/imageOnLoad';
import isEqual from '@17live/app/utils/isEqual';

type Props = {
  src: string | string[]; // full url picture
  isLoaded?: boolean;
  loading?: React.ReactNode | null;
  onLoad?: () => void;
};

type State = {
  isLoaded: boolean;
};

class ImageOnLoad extends Component<Props, State> {
  load$: Subscription;

  state = {
    isLoaded: false,
  };

  componentDidMount() {
    this.handleLoad(this.props.src);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      !isEqual(nextProps.src, this.props.src) ||
      (!this.props.isLoaded && nextProps.isLoaded)
    ) {
      this.setState(
        {
          isLoaded: false,
        },
        () => {
          this.handleLoad(nextProps.src);
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.load$) {
      this.load$.unsubscribe();
    }
  }

  handleLoad = (src: string | string[]) => {
    const { isLoaded, onLoad } = this.props;

    // To show loading component when there haven't been any content yet
    if ((src && src.length) || isLoaded) {
      if (this.load$) {
        this.load$.unsubscribe();
      }

      this.load$ = from(imageOnLoad(src)).subscribe(image => {
        this.setState({
          // because we don't want to block multiple image error, so return true.
          isLoaded: Array.isArray(image) ? true : !image.getAttribute('error'),
        });

        if (onLoad) {
          onLoad();
        }
      });
    }
  };

  render() {
    const { loading, children } = this.props;
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return loading || null;
    }

    return children;
  }
}

export default ImageOnLoad;
