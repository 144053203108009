import { random } from 'lodash';

import { LIVE_STREAM_TYPE } from '@/services/Video/constants';

import { LiveStream } from '@17live/app/types/LiveStreams';
import { RTMPUrl } from '@17live/app/types/RTMPUrls';

export const randomByWeight = <T extends object = any>(
  list: T[] = [],
  getWeight: (item: T) => number
): T[] => {
  const newList = [...list] as (T & { stackedWeight: number })[];
  let stackedValue = 0;

  newList.forEach(item => {
    stackedValue += getWeight(item);
    item.stackedWeight = stackedValue;
  });

  const pickNumber = random(0, stackedValue);
  const pickItem =
    newList.find(item => getWeight(item) && item.stackedWeight >= pickNumber) ??
    newList[0];
  const index = newList.indexOf(pickItem);

  newList.splice(index, 1);
  newList.splice(0, 0, pickItem);
  newList.forEach(item => delete item.stackedWeight);

  return newList;
};

/**
 * In current architecture, it will select first one as provider.
 * So this function will random select one by weight and put it to first one.
 */
export const getLiveStreamRtmpUrlsByWeight = (
  rtmpUrls: RTMPUrl[] = []
): RTMPUrl[] => {
  // exclude throttle=true
  const availableRtmpUrls = rtmpUrls.filter(({ throttle }) => !throttle);
  const unavailableRtmpUrls = rtmpUrls.filter(({ throttle }) => throttle);

  if (availableRtmpUrls.length > 0) {
    if (availableRtmpUrls.some(({ weight }) => weight > 0)) {
      // return random one of valid by weight
      const finalRtmpUrls = randomByWeight<RTMPUrl>(
        availableRtmpUrls,
        ({ weight }) => weight
      );
      return finalRtmpUrls.concat(unavailableRtmpUrls);
    }
    // return random one of valid if no weight > 0
    const randomIndex = random(0, availableRtmpUrls.length - 1);
    const selectedRtmpUrl = availableRtmpUrls[randomIndex];
    const otherRtmpUrls = rtmpUrls.filter(
      ({ provider }) => provider !== selectedRtmpUrl.provider
    );
    return [selectedRtmpUrl, ...otherRtmpUrls];
  }

  return rtmpUrls;
};

export const getLiveStreamRtmpUrls = (liveStream: LiveStream): RTMPUrl[] => {
  const isConcert = liveStream?.streamerType === LIVE_STREAM_TYPE.CONCERT;
  const rtmpUrls = liveStream?.rtmpUrls;

  if (isConcert && rtmpUrls?.length > 1) {
    return getLiveStreamRtmpUrlsByWeight(rtmpUrls);
  }

  return rtmpUrls;
};

/**
 * 根據目前 provider 狀況來決定 keepViewAlive 是否要切換 provider
 * @param currentRtmpUrl
 * @param rtmpUrls
 */
export const getLiveStreamRtmpUrlsWithCheckCurrentProvider = (
  currentRtmpUrl,
  rtmpUrls
) => {
  const currentRtmpUrlNewData = rtmpUrls.find(
    ({ provider }) => provider === currentRtmpUrl?.provider
  );
  let newRtmpUrls = rtmpUrls;
  if (currentRtmpUrlNewData?.throttle === false) {
    // if throttle is equal to false, this means that
    // the current provider is running well
    // and does not need to be replaced
    const currentIndex = newRtmpUrls.indexOf(currentRtmpUrlNewData);
    if (currentIndex > 0) {
      newRtmpUrls.splice(currentIndex, 1);
      newRtmpUrls.splice(0, 0, currentRtmpUrlNewData);
    }
  } else {
    newRtmpUrls = getLiveStreamRtmpUrlsByWeight(rtmpUrls);
  }
  return newRtmpUrls;
};
