// @flow
import { SearchResult } from '@/containers/Search/types';

import { REGION } from '@17live/app/constants';

import apis from '../shared/apis';

// 因為前一版 search API 沒有做分頁，且最多回覆 50，這邊也先延用
const SEARCH_COUNT = 50;

// 目前 API 只有支援 JP, TW, HK, US, GLOBAL
// https://17media.slack.com/archives/C02B4UX41HA/p1709200685137149?thread_ts=1709196021.520229&cid=C02B4UX41HA
const SUPPORT_REGIONS = [
  // ML 團隊建議先用 global 來避免有些使用者在特定地區無法被查到的問題
  // https://17media.slack.com/archives/C1HS2FXRQ/p1711025158599059
  // REGION.JP,
  // REGION.TW,
  // REGION.HK,
  // REGION.US,
  REGION.GLOBAL,
];

/**
 * ML search API
 * https://17media.atlassian.net/wiki/spaces/ST/pages/735543561/Integrate+Search+Result+APIs
 */
export const getSearchUsers = ({ query, region }): SearchResult => {
  // If region from props is not support now, then use GLOBAL
  const supportedRegion = SUPPORT_REGIONS.includes(region)
    ? region
    : REGION.GLOBAL;

  return apis.getJSON(
    `search?tab=all&region=${supportedRegion}&count=${SEARCH_COUNT}&q=${query}`
  );
};
