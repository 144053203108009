import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { RegionType } from '@17live/app/constants';
import { makeSelectRegion } from '@17live/app/containers/LanguageProvider/selectors';
import { makeSelectLoginUserRegion } from '@17live/app/containers/LoginProvider/selectors';

const selectors = createStructuredSelector<
  unknown,
  { region: RegionType; loginUserRegion: RegionType }
>({
  // the region selected on Web
  region: makeSelectRegion(),
  // this region is bound to the user and the user can switch it on APP
  loginUserRegion: makeSelectLoginUserRegion(),
});

export const useRegion = () => {
  const selector = useSelector(selectors);

  return {
    ...selector,
  };
};
