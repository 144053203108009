import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { stringify } from 'query-string';
import { empty, forkJoin } from 'rxjs';
import { expand, map, reduce, switchMap } from 'rxjs/operators';

import { LiveStreamInfoConsumer } from '@17live/app/containers/LiveStream/enums';
import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import { getRegion } from '@17live/app/utils';

import apis from './shared/apis';
import {
  DEFAULT_COUNT,
  REQUEST_HEADER_FOR_GET_REAL_TIME_DATA,
} from './shared/constants';

const API_PATH = 'liveStreams';
const API_PATH_NEW = 'lives';

export const unlockPremiumRoom = roomID =>
  apis.postJSON(`${API_PATH_NEW}/${roomID}/unlock`);

export const quitViewLiveStream = roomID =>
  apis.post(`${API_PATH_NEW}/${roomID}/quit`);

/**
 * 需要拿到即時資訊的 calledFrom
 */
const NEED_REAL_TIME_CALLED_FROM = [
  // 開關播需要即時資訊
  LiveStreamInfoConsumer.RTMP,
];

/**
 * API /lives/:roomID/info
 * @param {string} roomID
 * @param {*} calledFrom
 * @param {Boolean} isAfterPublish FIXME: it's a workaround to let browser get the NEWEST data from API
 *    @see https://17media.slack.com/archives/C466YU363/p1694060078201699?thread_ts=1690966615.621079&cid=C466YU363
 *    @see https://17media.slack.com/archives/C05AP871VRR/p1694141622215889
 * @returns AjaxObservable
 */
export const getLiveStreamInfo = (roomID, calledFrom, isAfterPublish) => {
  const queryString = stringify({ isAfterPublish });

  let apiPath = `${API_PATH_NEW}/${roomID}/info`;
  if (isAfterPublish) {
    apiPath = `${apiPath}?${queryString}`;
  }

  return apis
    .getJSON(apiPath, undefined, {
      // 只有直播設定頁面需要拿即時的資訊
      ...(NEED_REAL_TIME_CALLED_FROM.includes(calledFrom)
        ? REQUEST_HEADER_FOR_GET_REAL_TIME_DATA
        : {}),
    })
    .pipe(
      map(liveStreamInfo => ({
        ...liveStreamInfo,
        roomID,
      }))
    );
};

export const getLatestLiveStreams = ({
  count = DEFAULT_COUNT,
  region = getRegion(),
  hashtag,
} = {}) => {
  const payload = {
    count,
    region,
    hashtag,
  };

  return apis.postJSON(`${API_PATH}/getLatestLiveStreams`, payload).pipe(
    map(list => ({
      list,
      cursor: '',
    }))
  );
};

export const getShowLiveStreams = () =>
  apis.getJSON(`${API_PATH}/programsV2`).pipe(
    expand(response => {
      const searchParams = new URLSearchParams({
        cursor: String(response.cursor),
      });

      return isEmpty(response.cursor)
        ? empty()
        : apis.getJSON(`${API_PATH}/programsV2?${searchParams.toString()}`);
    }),
    map(list => list.streams),
    reduce((allList, list) => allList.concat(list), []),
    map(list =>
      list.map(show =>
        omit(
          {
            ...show,
            roomID: show.userInfo.roomID,
          },
          // at this point, the `landscape` key isn't accurate so we temporary delete it
          'landscape'
        )
      )
    ),
    map(list => ({
      list,
      cursor: '',
    }))
  );

export const postChat = (roomID, content, traceID) => {
  const payload = {
    comment: content,
    commentType: 0,
  };

  return apis.postJSON(
    `${API_PATH_NEW}/${roomID}/comments`,
    payload,
    setTrackAPIHeaders({ 'trace-id': traceID }),
    {
      retries: 0, // 因為 comments API 有加 rate limit 限制，避免觸發 rate limit 時還是 retry 所以移除 retry
    }
  );
};

export const getBarrages = roomID =>
  apis.getJSON(`barrages?liveStreamID=${roomID}`);

export const postBarrage = (
  roomID,
  { content, type, marqueeType, traceID }
) => {
  const payload = {
    comment: content,
    commentType: 6,
    commentSubType: type,
  };

  if (marqueeType >= 0) {
    payload.commentType = 7;
    payload.commentSubType = marqueeType;
  }

  return apis
    .postJSON(
      `${API_PATH_NEW}/${roomID}/comments`,
      payload,
      setTrackAPIHeaders({
        'trace-id': traceID,
      })
    )
    .pipe(
      switchMap(() => forkJoin(apis.getJSON('money'), getBarrages(roomID))),
      map(res => ({
        ...res[0],
        ...res[1],
      }))
    );
};

export const likeLiveStreamBatch = (roomID, likeCount = 1) =>
  apis.postJSON(`${API_PATH}/likeLivestreamBatchUpdate`, {
    liveStreamID: roomID,
    likeCount,
  });

export const areUsersOnline = userIDs =>
  apis.postJSON(`${API_PATH}/areUsersOnlive`, {
    targetUserIDs: userIDs,
  });

export const getRegionList = () => apis.getJSON(`${API_PATH}/regionList`);

export const getStreamerConfig = () =>
  apis.getJSON(`${API_PATH}/config/streamer`);

export const patchReceiveArmySubscriptionMessage = animationToken =>
  apis.patchJSON(`${API_PATH_NEW}/animation/${animationToken}/received`);
