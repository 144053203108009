// @flow
import { createSelector } from 'reselect';

import { ARMY_SUPPORTED_REGIONS } from '@/containers/Army/constants';

import {
  LEADERBOARD_ARMY_VALID_PERIODS,
  LEADERBOARD_AVATAR_VALID_PERIODS,
  LEADERBOARD_PERIODS,
  LEADERBOARD_PERIODS_ORDER,
  LEADERBOARD_TYPES,
  LIKE_SUPPORTED_REGIONS,
  RANK_COLORS,
  SCORE_COLORS,
} from './constants';

export const getRankColor = (rank: number): string => {
  switch (rank) {
    case 0:
      return RANK_COLORS.RANK_1;
    case 1:
      return RANK_COLORS.RANK_2;
    case 2:
      return RANK_COLORS.RANK_3;
    default:
      return RANK_COLORS.RANK_OTHER;
  }
};

export const getScoreColor = (rank: number): string => {
  switch (rank) {
    case 0:
      return SCORE_COLORS.RANK_1;
    case 1:
      return SCORE_COLORS.RANK_2;
    case 2:
      return SCORE_COLORS.RANK_3;
    default:
      return SCORE_COLORS.RANK_OTHER;
  }
};

/**
 * We declare function here because I don't know the flow syntax with constant type :scream:
 * Note that this also remove all the duplicated value in the array,
 * but it should fit our simple use case.
 */
export function moveToFirst<T>(baseArray: T[], firstElement: T): T[] {
  return [...new Set([firstElement, ...baseArray])];
}

export const isTypeSupportedInRegion = (
  type: $Values<typeof LEADERBOARD_TYPES>,
  region: string
): boolean => {
  switch (type) {
    case LEADERBOARD_TYPES.ARMY:
      return ARMY_SUPPORTED_REGIONS.includes(region);
    case LEADERBOARD_TYPES.LIKE:
      return LIKE_SUPPORTED_REGIONS.includes(region);
    default:
      return true;
  }
};

export const getValidPeriods = createSelector(
  (
    type: $Values<typeof LEADERBOARD_TYPES>
  ): $Values<typeof LEADERBOARD_PERIODS>[] => {
    if (type === LEADERBOARD_TYPES.AVATAR_CHARM) {
      return LEADERBOARD_AVATAR_VALID_PERIODS;
    }

    return LEADERBOARD_PERIODS_ORDER.filter(
      period =>
        type !== LEADERBOARD_TYPES.ARMY ||
        LEADERBOARD_ARMY_VALID_PERIODS.includes(period)
    );
  },
  periods => periods
);

export const getLeaderboardTypes = ({
  hasAvatar = false,
}: {
  hasAvatar: boolean,
}) => {
  const leaderboardTypes = [
    LEADERBOARD_TYPES.POINT_FROM,
    LEADERBOARD_TYPES.POINT_TO,
    LEADERBOARD_TYPES.LIKE,
    LEADERBOARD_TYPES.ARMY,
  ];

  if (hasAvatar) {
    leaderboardTypes.push(LEADERBOARD_TYPES.AVATAR_CHARM);
  }

  return leaderboardTypes;
};
