import {
  KEY_BRAZE_CUSTOM_EVENTS_IS_ENABLED_IN_STAGE,
  KEY_DEV_ABLY_SERVER,
  KEY_DEV_BACKEND_SERVER,
  KEY_DEV_GLOBAL_MESSAGE_PROVIDER,
  KEY_DEV_IS_ENABLED,
  KEY_DEV_MESSAGE_PROVIDER,
  KEY_DEV_PUBNUB_SERVER,
  SessionStorage,
} from '@/services/Storage';

import getIsDevToolsEnabled from './getIsDevToolsEnabled';

const KEY_DEV_SETTINGS = [
  KEY_DEV_IS_ENABLED,
  KEY_DEV_BACKEND_SERVER,
  KEY_DEV_MESSAGE_PROVIDER,
  KEY_DEV_GLOBAL_MESSAGE_PROVIDER,
  KEY_DEV_PUBNUB_SERVER,
  KEY_DEV_ABLY_SERVER,
  KEY_BRAZE_CUSTOM_EVENTS_IS_ENABLED_IN_STAGE,
];

const devTools = {
  enable() {
    SessionStorage.setItem(KEY_DEV_IS_ENABLED, true);
  },

  getBackendServer() {
    return SessionStorage.getItem(KEY_DEV_BACKEND_SERVER) || {};
  },

  setBackendServer(server) {
    SessionStorage.setItem(KEY_DEV_BACKEND_SERVER, server);
  },

  resetBackendServer() {
    SessionStorage.removeItem(KEY_DEV_BACKEND_SERVER);
  },

  getMessageProvider() {
    return SessionStorage.getItem(KEY_DEV_MESSAGE_PROVIDER) || {};
  },

  setMessageProvider(provider) {
    SessionStorage.setItem(KEY_DEV_MESSAGE_PROVIDER, provider);
  },

  resetMessageProvider() {
    SessionStorage.removeItem(KEY_DEV_MESSAGE_PROVIDER);
  },

  getGlobalMessageProvider() {
    return SessionStorage.getItem(KEY_DEV_GLOBAL_MESSAGE_PROVIDER) || {};
  },

  setGlobalMessageProvider(provider) {
    SessionStorage.setItem(KEY_DEV_GLOBAL_MESSAGE_PROVIDER, provider);
  },

  resetGlobalMessageProvider() {
    SessionStorage.removeItem(KEY_DEV_GLOBAL_MESSAGE_PROVIDER);
  },

  getPubNubServer() {
    return SessionStorage.getItem(KEY_DEV_PUBNUB_SERVER) || {};
  },

  setPubNubServer(server) {
    SessionStorage.setItem(KEY_DEV_PUBNUB_SERVER, server);
  },

  resetPubNubServer() {
    SessionStorage.removeItem(KEY_DEV_PUBNUB_SERVER);
  },

  getAblyServer() {
    return SessionStorage.getItem(KEY_DEV_ABLY_SERVER) || {};
  },

  setAblyServer(server) {
    SessionStorage.setItem(KEY_DEV_ABLY_SERVER, server);
  },

  resetAblyServer() {
    SessionStorage.removeItem(KEY_DEV_ABLY_SERVER);
  },

  getBrazeCustomEventsIsEnabledInStage() {
    return (
      SessionStorage.getItem(KEY_BRAZE_CUSTOM_EVENTS_IS_ENABLED_IN_STAGE) ||
      false
    );
  },

  setBrazeCustomEventsIsEnabledInStage(enabled) {
    SessionStorage.setItem(
      KEY_BRAZE_CUSTOM_EVENTS_IS_ENABLED_IN_STAGE,
      enabled
    );
  },

  isBrazeCustomEventsCanUseInStage() {
    return (
      getIsDevToolsEnabled() && devTools.getBrazeCustomEventsIsEnabledInStage()
    );
  },

  reset() {
    KEY_DEV_SETTINGS.forEach(key => SessionStorage.removeItem(key));
  },
};

export default devTools;
