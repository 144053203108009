import shortid from 'shortid';

import { PUSH_SNACKBAR, REMOVE_SNACKBAR } from './constants';

export const pushSnackbar = (content: string, values?: any) => ({
  type: PUSH_SNACKBAR,
  payload: {
    id: shortid.generate(),
    content,
    values,
  },
});

export const removeSnackbar = (id: string) => ({
  type: REMOVE_SNACKBAR,
  payload: id,
});
