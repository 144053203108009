/*
 *
 * Event actions
 *
 */

import {
  CLOSE_EVENT_IFRAME,
  GET_AVATAR_WEEKLY,
  GET_EVENT_LIST,
  OPEN_EVENT_IFRAME,
  SET_AVATAR_WEEKLY_EVENT_INFO,
  SET_EVENT_LIST,
  SHOW_EVENT,
} from '@17live/app/containers/Event/constants';

export const getEventList = ({ region }) => ({
  type: GET_EVENT_LIST,
  payload: {
    region,
  },
});

export const setEventList = eventList => ({
  type: SET_EVENT_LIST,
  payload: eventList,
});

export const showEvent = eventID => ({
  type: SHOW_EVENT,
  payload: eventID,
});

export const getAvatarWeekly = ({ region }: { region: string }) => ({
  type: GET_AVATAR_WEEKLY,
  payload: {
    region,
  },
});

export const setAvatarWeeklyEventInfo = avatarWeeklyEventInfo => ({
  type: SET_AVATAR_WEEKLY_EVENT_INFO,
  payload: avatarWeeklyEventInfo,
});

export const openEventIFrame = ({
  eventID,
  iFrameUrl,
}: {
  eventID: number;
  iFrameUrl: string;
}) => {
  return {
    type: OPEN_EVENT_IFRAME,
    payload: {
      iFrameEventID: eventID,
      iFrameUrl,
      isIFrameActive: true,
    },
  };
};

export const closeEventIFrame = () => {
  return {
    type: CLOSE_EVENT_IFRAME,
    payload: {
      iFrameEventID: 0,
      iFrameUrl: '',
      isIFrameActive: false,
    },
  };
};
