export enum MktHost {
  PROD = 'https://mkt.17.live',
  STAG = 'https://sta-mkt.17.live',
}

export const MKT_HOST =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production' ? MktHost.PROD : MktHost.STAG; // use same url in stage and uat env

export const LP_PATHNAME = '/landingpage';

export const VALID_TIME = 7 * 24 * 60 * 60 * 1000; // 1 week
