/*
 *
 * Leaderboard constants
 *
 * @flow
 */
import {
  LEADERBOARD_PERIODS,
  LEADERBOARD_TYPES,
} from '@/services/17App/leaderboard';

import { REGION } from '@17live/app/constants';

import { constants } from './list';

/**
 * actions
 */
const { GET_LIST, SET_LIST, CLEAR_LIST, SET_ERROR, STATUS } = constants;

export { GET_LIST, SET_LIST, CLEAR_LIST, SET_ERROR, STATUS };

/**
 * definitions
 */
export const LEADERBOARD_MAX_SIZE = 100; // maximum of only top 100

/**
 * enums and constants
 */
export { LEADERBOARD_TYPES, LEADERBOARD_PERIODS };

export const SUPPORTED_REGIONS: string[] = [
  REGION.TW,
  REGION.GLOBAL,
  REGION.JP,
  REGION.HK,
  REGION.MY,
  REGION.SG,
  REGION.US,
  REGION.PH,
  REGION.IN,
  REGION.ID,
  REGION.TH,
  REGION.VN,
  REGION.MM,
];

// like board only should showed in `GLOBAL` region
export const LIKE_SUPPORTED_REGIONS: string[] = SUPPORTED_REGIONS.filter(
  region => region === 'GLOBAL'
);

export const LEADERBOARD_PERIODS_ORDER: $Values<
  typeof LEADERBOARD_PERIODS
>[] = [
  LEADERBOARD_PERIODS.DAY,
  LEADERBOARD_PERIODS.WEEK,
  LEADERBOARD_PERIODS.MONTH,
];

export const LEADERBOARD_ARMY_VALID_PERIODS: $Values<
  typeof LEADERBOARD_PERIODS
>[] = [LEADERBOARD_PERIODS.MONTH];

export const LEADERBOARD_AVATAR_VALID_PERIODS: $Values<
  typeof LEADERBOARD_PERIODS
>[] = [LEADERBOARD_PERIODS.ALL];

export const LEADERBOARD_ERROR_CODES = {
  CURSOR_EXPIRED: 1,
};

/**
 * styles
 */
export const TOP_ONE_HEIGHT: number = 300; // px
export const TOP_ONE_PREVIEW_HEIGHT: number = 180; // px
export const LEADERBOARD_COUNT: number = 9; // default viewable 9 items
export const LEADERBOARD_WIDTH: number = 540; // px
export const LEADERBOARD_MARGIN: number = 12; // px
export const LEADERBOARD_PREVIEW_HEIGHT: number = 702; // px

export const RANK_COLORS = Object.freeze({
  RANK_1: '#F750BC',
  RANK_2: '#C368F3',
  RANK_3: '#769AE9',
  RANK_OTHER: '#28232d',
});

export const SCORE_COLORS = Object.freeze({
  RANK_1: '#F750BC',
  RANK_2: '#C368F3',
  RANK_3: '#769AE9',
  RANK_OTHER: '#6f6777',
});
