/**
 * Typography
 * Design doc: https://17media.atlassian.net/wiki/spaces/17livedesign/pages/715293048/Typography
 */

import styled, { css } from 'styled-components';

import { space, SpaceProps } from 'styled-system';

import { COLOR_TEXT_BLACK_PRIMARY } from '@17live/design-system/color';

export interface TypographyProps {
  color?: string;
  margin?: string;
  textAlign?: string;
}

const TypographyMixin = css<TypographyProps & SpaceProps>`
  color: ${props => props.color || COLOR_TEXT_BLACK_PRIMARY};
  margin: ${props => props.margin || 0};
  text-align: ${props => props.textAlign || 'left'};

  ${space}
`;

/**
 * Headline 1
 */
export const Heading1 = styled.h1<TypographyProps>`
  font-size: 48px;
  font-weight: 500;
  line-height: 133%; /* 63.84px */
  letter-spacing: -0.5px;
  ${TypographyMixin}
`;

/**
 * Headline 2
 */
export const Heading2 = styled.h2<TypographyProps>`
  font-size: 32px;
  font-weight: 500;
  line-height: 150%; /* 48px */
  ${TypographyMixin}
`;

/**
 * Headline 3
 */
export const Heading3 = styled.h3<TypographyProps>`
  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 36px */
  ${TypographyMixin}
`;

/**
 * Headline 4
 */
export const Heading4 = styled.h4`
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
  ${TypographyMixin}
`;

/**
 * Headline 5
 */
export const Heading5 = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
  ${TypographyMixin}
`;

/**
 * Headline 6
 */
export const Heading6 = styled.h6`
  font-size: 14px;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  ${TypographyMixin}
`;

export const TextBody1 = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  ${TypographyMixin}
`;

export const TextBody2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  ${TypographyMixin}
`;

export const TextButtonL = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%; /* 16px */
  ${TypographyMixin}
`;

export const TextButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%; /* 14px */
  ${TypographyMixin}
`;

export const TextButtonS = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 100%; /* 12px */
  ${TypographyMixin}
`;

export const TextCaption1 = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  ${TypographyMixin}
`;

export const TextCaption2 = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  ${TypographyMixin}
`;

export const TextSmall1 = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 140%; /* 18.2px */
  ${TypographyMixin}
`;

export const TextSmall2 = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 133%; /* 15.96px */
  ${TypographyMixin}
`;

export const HyperlinkL = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  ${TypographyMixin}
`;

export const Hyperlink = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  ${TypographyMixin}
`;

export const HyperlinkS = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  ${TypographyMixin}
`;

export const TextMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  ${TypographyMixin}
`;
