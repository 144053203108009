// @flow
import { Component, type Node } from 'react';
import { findDOMNode } from 'react-dom';
import { TABBALE_ELEMENTS } from './constants';

type Props = {
  children: Node,
};

class TrapFocus extends Component<Props> {
  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    this.modalElement = findDOMNode(this);

    document.addEventListener('keydown', this.handleTrapFocus);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.children && this.props.children !== prevProps.children) {
      // FIXME: need to migrate this to ref, maybe easier to do this with hook
      // eslint-disable-next-line react/no-find-dom-node
      this.modalElement = findDOMNode(this);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleTrapFocus);
  }

  getFocusableElements = (): Array<HTMLElement> =>
    Array.from(
      (this.modalElement &&
        this.modalElement instanceof HTMLElement &&
        this.modalElement.querySelectorAll(TABBALE_ELEMENTS.join(','))) ||
        []
    );

  getFirstFocusable = (): ?HTMLElement => this.getFocusableElements()[0];

  getLastFocusable = (): ?HTMLElement => {
    const focusableElements = this.getFocusableElements();

    return focusableElements[focusableElements.length - 1];
  };

  handleTrapFocus = (e: KeyboardEvent) => {
    if (e.key === 'Tab' && this.modalElement) {
      const isShift = e.shiftKey;

      const firstFocusable = this.getFirstFocusable();
      const lastFocusable = this.getLastFocusable();

      if (!firstFocusable || !lastFocusable) {
        return;
      }

      if (!isShift && document.activeElement === lastFocusable) {
        firstFocusable.focus();
        e.preventDefault();
      } else if (isShift && document.activeElement === firstFocusable) {
        lastFocusable.focus();
        e.preventDefault();
      }
    }
  };

  modalElement: null | Element | Text = null;

  render() {
    return this.props.children;
  }
}

export default TrapFocus;
