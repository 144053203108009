import { ajax } from 'rxjs/ajax';

import apis from '@/services/17App/shared/apis.js';
import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';
import { dispatch } from '@/store';

import { DEFAULT_REGION, SWAP_LANG_REGION } from '@17live/app/constants';
import configs from '@17live/app/constants/configs';
import { TOKEN_INVALID } from '@17live/app/containers/App/epics';
import { getIPRegion } from '@17live/app/containers/LanguageProvider/utils';
import { logout } from '@17live/app/containers/LoginProvider/actions';
import { pushSnackbar } from '@17live/app/containers/Snackbars/actions';
import { redirectToOverload } from '@17live/app/containers/StatusAnnouncement/StatusAnnouncement.actions';
import { ErrorStatusCode } from '@17live/app/enums/ErrorStatus.enums';
import Logger from '@17live/app/services/Logger';
import { checkStatus, parseJSON } from '@17live/app/utils/apiUtils';
import {
  authToken,
  checkTokenInvalidStatus,
  getAuthHeader,
  getAuthHeaderFromQueryString,
} from '@17live/app/utils/authToken';
import { getLang } from '@17live/app/utils/getLang';

import { LoggerLabel } from '../constants/log';
import pxFetch from '../services/Perimeterx/pxFetch';

type RxAjax = typeof ajax;

type FetchOptions = RequestInit & {
  isAuthHeaderFromQueryString?: boolean;
};

// @ts-ignore
export const api = apis as RxAjax;

export default api;

export const MOCK_SERVER_HEADER = {
  '17live-Mock-Server': String(process.env.DEPLOY_ENV !== 'production'),
};

export const handleError = (error?: { [key: string]: any }) => {
  if (!error) {
    return;
  }

  const { status, displayErrMsg = {} } = error;
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO);

  if (loginInfo && checkTokenInvalidStatus(status)) {
    dispatch(logout(TOKEN_INVALID));
    return;
  }

  // redirect user to service overload page when api responded with 521 error code
  if (status === ErrorStatusCode.STATUS_SYSTEM_THROTTLING) {
    dispatch(redirectToOverload());
    return;
  }

  // push snackbar when api response 420 with displayErrMsg
  if (displayErrMsg?.key) {
    const { key, params } = displayErrMsg;

    if (status === ErrorStatusCode.STATUS_CLIENT_ERROR && key) {
      const values: { [key: string]: { value: string } } = {};
      if (Array.isArray(params) && params.length > 0) {
        params.forEach(
          (param, index) => (values[`param_${index + 1}`] = param.value)
        );
      }
      dispatch(pushSnackbar(key, values));
    }
  }
};

const apiGenerator = ({
  apiUrl,
  headers,
  restFetchParams = {},
}: {
  apiUrl: string;
  headers: {};
  restFetchParams: FetchOptions;
}) => {
  return () => {
    return (
      pxFetch(apiUrl, {
        headers,
        ...restFetchParams,
      })
        .then(checkStatus)
        .then(parseJSON)
        // .then(parseJSON)
        .catch(error => {
          // @ts-expect-error
          Logger.info(LoggerLabel.API, '17App API failed', {
            headers,
            ...restFetchParams,
            endpoint: apiUrl,
            error,
          });

          handleError(error);
          throw error;
        })
    );
  };
};

export const fetchAPI = async <T = any>(
  endpoint: string,
  fetchParams: FetchOptions = {
    isAuthHeaderFromQueryString: false,
  }
): Promise<T> => {
  const apiUrl = `${configs.url}/${configs.version}/${endpoint}`;

  const ipRegion = getIPRegion();
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) || {};
  const {
    headers: fetchParamsHeaders = {},
    isAuthHeaderFromQueryString,
    ...restFetchParams
  } = fetchParams;
  const { region } = loginInfo;

  const regionHeader = {};
  if (ipRegion) Object.assign(regionHeader, { userIpRegion: ipRegion });
  if (region) Object.assign(regionHeader, { userSelectedRegion: region });

  const cacheHeader = {};
  if (configs.options.isNeedNoCache) {
    Object.assign(cacheHeader, {
      'Cache-Control': 'no-cache',
    });
  }

  let jwtAccessToken;
  try {
    jwtAccessToken = await authToken.getJwtToken();
  } catch (error) {
    return Promise.reject(error);
  }

  const headers = {
    'Content-Type': 'application/json',
    ...cacheHeader,
    /**
     * this is currently workaround for fixing backend no real language value.
     * TODO: can try `getBackendLanguageCode(language)`
     */
    language: SWAP_LANG_REGION[getLang()] ?? DEFAULT_REGION,
    ...regionHeader,
    ...fetchParamsHeaders, // To override the header key 'userSelectedRegion' in regionHeader
    ...configs.device,
    ...(isAuthHeaderFromQueryString
      ? getAuthHeaderFromQueryString(jwtAccessToken)
      : getAuthHeader(jwtAccessToken)),
  };

  const callAPI = apiGenerator({
    apiUrl,
    headers,
    restFetchParams,
  });

  return callAPI();
};

export const fetchLegacyAPI = async (
  actionData: {
    action: string;
  } & Record<string, string> = { action: '' }
): Promise<any> => {
  let jwtAccessToken;

  try {
    jwtAccessToken = await authToken.getJwtToken();
  } catch (error) {
    return Promise.reject(error);
  }

  return pxFetch(`${configs.url}/apiGateWay`, {
    // @ts-expect-error
    headers: {
      ...configs.device,
      ...getAuthHeader(jwtAccessToken),
      language: SWAP_LANG_REGION[getLang()],
    },
    body: JSON.stringify({
      data: JSON.stringify(actionData),
    }),
    method: 'POST',
  })
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.json().then(({ data = {} }) => JSON.parse(data));
      }

      return res
        .text()
        .then(text => Promise.reject({ status: res.status, text }));
    })
    .catch(error => {
      // @ts-expect-error
      Logger.info(LoggerLabel.API, '17App Legacy API failed', {
        ...actionData,
        error,
      });

      handleError(error);
      throw error;
    });
};
