/*
 *
 * Army constants
 *
 */
import { SUBSCRIBE_ERROR_CODE } from '@/containers/Subscribe/constants';
import { ECPAY, NEWEBPAY, SMBC_GMO } from '@/services/17App/pay/constants';

import { LANG, REGION, SUPPORT_REGIONS } from '@17live/app/constants/langs';
import { ArmyPayVendor } from '@17live/app/enums/Army.enums';

/**
 * actions
 */
export const SEARCH_ARMY = 'app/Army/SEARCH_ARMY';
export const BLOCK_ARMY = 'app/Army/BLOCK_ARMY';
export const CLEAR_ERROR = 'app/Army/CLEAR_ERROR';
export const SET_ARMY_ONLY_BROADCAST_LEVEL =
  'app/Army/SET_ARMY_ONLY_BROADCAST_LEVEL';

/**
 * army periods
 */
export const PERIOD_DAYS_PER_MONTH = 30;

// Jerry TODO: move TS
export const ARMY_PAY_VENDORS_NAME_MAP = {
  [ArmyPayVendor.NEWEBPAY]: NEWEBPAY,
  [ArmyPayVendor.ECPAY]: ECPAY,
  [ArmyPayVendor.GMO]: SMBC_GMO,
  [ArmyPayVendor.BABYCOIN]: 'babycoins',
};

/**
 * Army search 頁面預設會顯示 following list
 * [WORK_AROUND] 這裡沒有顯示所有訂閱的人，之後有空再看是否需要改成批次載入。
 * 目前來說，用戶還是可以用搜尋找到想訂閱的主播，不影響操作。
 */
export const DEFAULT_FOLLOWING_SIZE = 100;

/**
 * supported region
 *
 * should excludes US, see APP-6131
 */
export const ARMY_SUPPORTED_REGIONS = SUPPORT_REGIONS.filter(
  region => region !== REGION.US
);

/**
 * Discount 顯示有分 "9 折" 和 "10% off" 兩種
 * ARMY_DISCOUNT_ZH_REGIONS 是屬於前者的 regions
 */
export const ARMY_DISCOUNT_ZH_LANGS = [LANG.ZH_HANT, LANG.ZH_HK];

/**
 * others
 */
export const DEFAULT_CURRENCY = 'TWD';
export const ARMY_ERROR_BLOCKED = 'BLOCKED';
export const ARMY_ERROR_INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE';
export const ARMY_ERROR_STREAMER = 'STREAMER';
export const ARMY_ERROR_GENERAL = 'GENERAL';

export const ARMY_ERROR_CODE_TYPE_MAP = {
  [SUBSCRIBE_ERROR_CODE.INSUFFICIENT_BALANCE]: ARMY_ERROR_INSUFFICIENT_BALANCE,
  [SUBSCRIBE_ERROR_CODE.ILLEGAL_ORDER]: ARMY_ERROR_STREAMER,
  [SUBSCRIBE_ERROR_CODE.ARMY_DISABLED]: ARMY_ERROR_STREAMER,
};
