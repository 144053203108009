import { stringify } from 'query-string';
import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import apis from '../shared/apis';
import { API_PATH } from './constants';

// New products API for 2020/1/1 bbcoin
export const getMerchandise = ({ traceID, ...params }) =>
  apis.getJSON(
    `${API_PATH}/merchandise/?${stringify(params)}`,
    null,
    setTrackAPIHeaders({
      'trace-id': traceID,
    })
  );
