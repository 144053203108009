import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import {
  closeEventIFrame,
  openEventIFrame,
} from '@17live/app/containers/Event/actions';
import {
  makeSelectEventIFrameEventID,
  makeSelectEventIFrameUrl,
  makeSelectEventIsIFrameActive,
} from '@17live/app/containers/Event/selectors';

const eventIFrameSelectors = createStructuredSelector({
  iFrameUrl: makeSelectEventIFrameUrl(),
  iFrameEventID: makeSelectEventIFrameEventID(),
  isIFrameActive: makeSelectEventIsIFrameActive(),
});
export const useEventIFrame = () => {
  const dispatch = useDispatch();
  const selector = useSelector(eventIFrameSelectors);

  const openIFrame = useCallback(
    ({ eventID, iFrameUrl }: { eventID: number; iFrameUrl: string }) => {
      dispatch(openEventIFrame({ eventID, iFrameUrl }));
    },
    [dispatch]
  );

  const closeIFrame = useCallback(() => {
    dispatch(closeEventIFrame());
  }, [dispatch]);

  return {
    ...selector,
    openIFrame,
    closeIFrame,
  };
};
