import { openModal } from '@/containers/Modal/actions';
import { MODAL_PHONE_VERIFY } from '@/containers/Modal/constants';

import {
  AUTH_WITH_17,
  AUTH_WITH_THIRD_PARTY,
  DO_THIRD_PARTY_REGISTER,
  LOGIN_WITH_THIRD_PARTY,
  REGISTER_WITH_THIRD_PARTY,
  SET_AUTH_PROCESSING_STATUS,
} from './constants';

export const authWithThirdParty = (type, action) => ({
  type: AUTH_WITH_THIRD_PARTY,
  payload: {
    type,
    action,
  },
});

export const authWith17 = (type, action, thirdPartyPayload) => ({
  type: AUTH_WITH_17,
  payload: {
    type,
    action,
    thirdPartyPayload,
  },
});

export const loginWithThirdParty = (type, payload) => ({
  type: LOGIN_WITH_THIRD_PARTY,
  payload: {
    type,
    ...payload,
  },
});

export const registerWithThirdParty = (type, payload) => ({
  type: REGISTER_WITH_THIRD_PARTY,
  payload: {
    type,
    ...payload,
  },
});

export const doThirdPartyRegister = (
  openID,
  oauthType,
  oauthInfo,
  phoneInfo,
  referer
) => ({
  type: DO_THIRD_PARTY_REGISTER,
  payload: {
    openID,
    oauthType,
    oauthInfo,
    phoneInfo,
    referer,
  },
});

export const setAuthProcessingStatus = status => ({
  type: SET_AUTH_PROCESSING_STATUS,
  payload: {
    status,
  },
});

export const openPhoneVerifyModal = (type, payload) =>
  openModal({
    name: MODAL_PHONE_VERIFY,
    params: {
      type,
      ...payload,
    },
  });
