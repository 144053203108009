/**
 * Shamelessly stolen from downshift
 * https://github.com/paypal/downshift/blob/master/src/utils.js#L101-L116
 *
 * This return a function that will call all the given functions with
 * the arguments with which it's called. It does a null-check before
 * attempting to call the functions and can take any number of functions.
 * @param {...Function} fns the functions to call
 * @return {Function} the function that calls all the functions
 *
 * @flow
 */

function callAll<T>(...fns: Array<?(...args: Array<T>) => void>) {
  return (...args: Array<T>) => {
    fns.forEach((fn) => {
      if (typeof fn === 'function') {
        fn(...args);
      }
    });
  };
}

export default callAll;
