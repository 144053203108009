// @flow
import type { StorageData, StorageInterface } from './types';

class MemoryStorage implements StorageInterface {
  storage: StorageData;

  constructor(storage: ?Storage) {
    this.storage = storage || {};
  }

  getItem(key: string) {
    return this.storage[key] || null;
  }

  setItem(key: string, value: mixed) {
    this.storage[key] = value;
  }

  removeItem(key: string) {
    delete this.storage[key];
  }
}

export default MemoryStorage;
