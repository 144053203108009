import React from 'react';

import styled, { css } from 'styled-components';

import { BannerImageWrapper } from '@17live/app/containers/Event/components/EventBanner';
import { useImmutableSelector } from '@17live/app/hooks';
import {
  useOpenUtmEvent,
  UseOpenUtmParams,
} from '@17live/app/hooks/useOpenUtmEvent';
import { RootState } from '@17live/app/types/RootState';
import { getJwtTokenFromLocalStorage } from '@17live/app/utils/authToken';

export const LinkToEvent = styled.a<{ width?: number }>`
  ${({ width = 100 }) => css`
    width: ${width}%;
    margin-bottom: 2em;

    ${BannerImageWrapper} {
      border-radius: 12px 12px 0 0;
      height: 180px;
    }
  `}
`;

type Props = React.DOMAttributes<HTMLAnchorElement> &
  Omit<UseOpenUtmParams, 'userID' | 'accessToken'> & {
    width?: number;
  };

export const LinkToEventWithUtm: React.FC<Props> = ({
  children,
  eventID,
  href,
  utmContent,
  ...props
}) => {
  const { userInfo } = useImmutableSelector(s =>
    s.get('login')
  ).toJSON() as RootState['login'];
  const { jwtAccessToken } = getJwtTokenFromLocalStorage();

  const onOpenEvent = useOpenUtmEvent({
    eventID,
    href,
    utmContent,
    userID: userInfo?.userID,
    jwtAccessToken,
  });

  return (
    <LinkToEvent {...props} href={href} onClick={onOpenEvent}>
      {children}
    </LinkToEvent>
  );
};

export default LinkToEventWithUtm;
