/*
 *
 * RegisterModal constants
 *
 * @flow
 */
import {
  ERROR_CODE_NEXMO_ERROR,
  ERROR_CODE_PHONENUMBER_ERROR,
  ERROR_CODE_PHONENUMBER_EXISTS,
  ERROR_CODE_PHONENUMBER_USED,
} from '@/services/17App';

import type { GenderType } from './types';

/**
 * action types
 */
export const SET_STATUS = 'app/RegisterModal/SET_STATUS';
export const REGISTER_BY_PHONE = 'app/RegisterModal/REGISTER_BY_PHONE';
export const LOGIN_BY_REGISTER = 'app/RegisterModal/LOGIN_BY_REGISTER';
export const VERIFY_BY_SMS = 'app/RegisterModal/VERIFY_BY_SMS';

export const VERIFICATION_COUNTDOWN = 35000; // ms

export const GENDERS: GenderType[] = ['male', 'female', 'private'];

export const PHONE_NUMBER_ERRORS = [
  ERROR_CODE_NEXMO_ERROR,
  ERROR_CODE_PHONENUMBER_EXISTS,
  ERROR_CODE_PHONENUMBER_ERROR,
  ERROR_CODE_PHONENUMBER_USED,
];

export const ERROR_PASSWORD_FORMAT = 'ERROR_PASSWORD_FORMAT';

export const PHONE_REGISTER = 'phone';

export const AUTH_WITH_THIRD_PARTY_STATUS = Object.freeze({
  Loading: 'loading',
  Success: 'success',
  Error: 'error',
});
