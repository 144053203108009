import { SourceURL } from '@17live/app/services/analytics/types';

export const GROUP_CALL_LIST_COUNTS: { [key: string]: number } = {
  xs: 2,
  sm: 3,
  md: 4,
  lg: 5,
  xl: 6,
  xxl: 6,
};

// because matomo still maintains the old name of GroupStream
// we use a mapping to correspond to the new name and the old name
// so that it is more easily to search in the future
export const groupCallSourceURLMap: { [key: string]: SourceURL } = {
  groupCall: 'GroupStream',
  groupCallGroupScreen: 'GroupStream_GroupScreen',
  groupCallPokedBack: 'GroupStream_PokedBack',
};

export const gridTemplateMap = {
  1: "'a'",
  2: "'a b'",
  3: "'a b' 'a c'",
  4: "'a b' 'c d'",
  5: "'a a a b b b' 'c c d d e e'",
  6: "'a b c' 'd e f'",
  7: "'a b c' 'a b c' 'a b f' 'd e f' 'd e g' 'd e g'",
  8: "'a b c' 'a b c' 'a e f' 'd e f' 'd g h' 'd g h'",
  9: "'a b c' 'd e f' 'g h i'",
};

export const gridTemplateValues = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];

export const PLAYER_SIZE_RATIO = 14 / 15;

export const GROUP_CALL_MSITE_LIST_GRID_COUNTS = 5; // api ㄧ次拿幾個
export const GROUP_CALL_LIST_GRID_COUNTS = 6;
export const GROUP_CALL_MINIMUM_COUNTS = 5; // minimum count to show in home page section

export const GROUP_CALL_MSITE_LIST_COUNTS = 5;
export const GROUP_CALL_MSITE_TOOLTIP_TIMEOUT = 10000; // 10 seconds
