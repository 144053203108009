import { stringify } from 'query-string';
import { map } from 'rxjs/operators';

import apis from './shared/apis';

const API_PATH = 'money';

export const getBalances = () => apis.get(API_PATH);

export const getBBFuTrades = ({
  category,
  unit,
  count = 10,
  cursor = '',
  action = 2,
}) =>
  apis
    .getJSON(
      `${API_PATH}/log/trade?${stringify({
        category,
        unit,
        action,
        count,
        cursor,
      })}`
    )
    .pipe(
      map(({ logs, ...rest }) => ({
        records: logs,
        ...rest,
      }))
    );
