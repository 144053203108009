// @flow
import { SIZE_LARGE, SIZE_MEDIUM } from '@/constants/sizes';
import {
  ECPAY,
  FIRST_DATA,
  GASH,
  JKOPAY,
  MY_CARD,
  NEWEBPAY,
  PAIDY,
  PAYERMAX,
  PAYUNI,
  SMBC_GMO,
  STRIPE,
} from '@/services/17App/pay/constants';

import { getPurchaseLogoImage, getWebp2xURL } from '@17live/app/utils';

/*
 *
 * Purchase constants
 *
 */
export const PAYMENT_WINDOW_NAME = 'Payment_Window';

export const REQUEST_PAY_ORDER_ERROR_CODE = {
  // Backend 定義的 errorCode，代表 user 可能被 block, freeze or ban 了
  SUSPICIOUS_USER: 5,
};

// Frontend 在 admin api 定義的 errorMessage，代表無法驗證 user 是否被 block，需請 user 重新登入
export const CHECK_IAP_BLOCK_FAIL_MSG = 'Cannot check user status.';

/**
 * Payment type
 * @deprecated change to use PaymentType (packages/app/containers/PurchasePage/PurchasePage.enums.ts)
 */
export const PAYMENT_TYPE_STORE = 'CVS';
export const PAYMENT_TYPE_ATM = 'VACC';

/**
 * Actions
 */
export const REQUEST_SAVE_CARD = 'app/Purchase/REQUEST_SAVE_CARD';
export const GET_PURCHASE_ORDER = 'app/Purchase/GET_PURCHASE_ORDER';
export const GET_MERCHANDISE_LIST = 'app/Purchase/GET_MERCHANDISE_LIST';
export const SET_MERCHANDISE_LIST = 'app/Purchase/SET_MERCHANDISE_LIST';
export const SET_BANKS = 'app/Purchase/SET_BANKS';
export const SET_ORDER = 'app/Purchase/SET_ORDER';
export const CLEAR_ORDER = 'app/Purchase/CLEAR_ORDER';

/**
 * style
 */
export const PURCHASE_PRODUCT_MARGIN: number = 10;
export const PURCHASE_PRODUCT_SIZES = {
  [SIZE_MEDIUM]: {
    width: 204,
    height: 270,
  },
  [SIZE_LARGE]: {
    width: 236,
    height: 312,
  },
};

export const PURCHASE_PRODUCT_FONT_SIZES = {
  [SIZE_MEDIUM]: 12,
  [SIZE_LARGE]: 14,
};

export const PURCHASE_PRODUCT_POINT_FONT_SIZES = {
  [SIZE_MEDIUM]: 39,
  [SIZE_LARGE]: 46,
};

export const PURCHASE_PRODUCT_BUTTON_FONT_SIZES = {
  [SIZE_MEDIUM]: 16,
  [SIZE_LARGE]: 18,
};

export const PURCHASE_ITEM_SIZES = {
  [SIZE_MEDIUM]: {
    height: 163,
  },
  [SIZE_LARGE]: {
    height: 184,
  },
};

export const PRODUCT_BLOCK_MOBILE_BREAK_POINT: number = 392;

/**
 * Payment vendor
 * TODO: Convert type to enum in TypeScript
 */
export type VendorsType = {
  [NEWEBPAY]: 'newebpay',
  [ECPAY]: 'ecpay',
  [JKOPAY]: 'jkopay',
  [SMBC_GMO]: 'smbcgmo',
  [MY_CARD]: 'mycard',
  [GASH]: 'gash',
  [FIRST_DATA]: 'firstdata',
  [STRIPE]: 'stripe',
  [PAYUNI]: 'payuni',
  [PAIDY]: 'paidy',
  [PAYERMAX]: 'payermax',
};

export const VENDORS = Object.freeze({
  [NEWEBPAY]: 'newebpay',
  [ECPAY]: 'ecpay',
  [JKOPAY]: 'jkopay',
  [SMBC_GMO]: 'smbcgmo',
  [PAIDY]: 'paidy',
  [MY_CARD]: 'mycard',
  [GASH]: 'gash',
  [FIRST_DATA]: 'firstdata',
  [STRIPE]: 'stripe',
  [PAYERMAX]: 'payermax',
  [PAYUNI]: 'payuni',
});

export const VENDOR_LOGOS: { [string]: string } = {
  newebpay: getWebp2xURL(getPurchaseLogoImage('ig-iap-newwbpay.png')),
  payuni: getWebp2xURL(getPurchaseLogoImage('ig_iap_payuni.png')),
  ecpay: getWebp2xURL(getPurchaseLogoImage('ig-iap-ecpay.png')),
  jkopay: getWebp2xURL(getPurchaseLogoImage('ig-iap-jkopay.png')),
  // smbcgmo: getWebp2xURL(getPurchaseLogoImage('ig-iap-smbcgmo.png')),
  smbcgmo: getWebp2xURL(getPurchaseLogoImage('ig_payment_default.png')), // [Workaround] 怕用戶無法理解什麼是 GMO，先暫時置換圖片來代替
  // paidy: getWebp2xURL(getPurchaseLogoImage('ig-iap-paidy.png')),
  paidy: getWebp2xURL(getPurchaseLogoImage('ig_payment_paidy.png')), // [Workaround] 怕用戶無法理解什麼是 Paidy，先暫時置換圖片來代替
  mycard: getWebp2xURL(getPurchaseLogoImage('ig-iap-mycard.png')),
  gash: getWebp2xURL(getPurchaseLogoImage('ig-iap-gash.png')),
  firstdata: getWebp2xURL(getPurchaseLogoImage('ig-iap-firstdata.png')),
  stripe: getWebp2xURL(getPurchaseLogoImage('ig-iap-stripe.png')),
  payermax: getWebp2xURL(getPurchaseLogoImage('ig-iap-payermax.png')),
};

export const VENDOR_LOGOS_BG_COLOR = {
  [JKOPAY]: '#e60a0f',
  [STRIPE]: '#32325D',
  [PAYUNI]: '#5C3A93',
};

export const PURCHASE_VIP_PATH_REGEX = '/purchase/:vendor/(vip_super|vip_baby)';
