import { useEffect, useRef, useState } from 'react';

import createIntersectionObserver from '@/utils/createIntersectionObserver';

export const useIntersectionObserver = <T extends HTMLElement>(
  onceIntersectingOrThreshold?: boolean | IntersectionObserverInit['threshold']
) => {
  const [io, setIntersection] = useState<IntersectionObserver>();

  const ref = useRef<T>(null);
  const optionsRef = useRef<IntersectionObserverInit>({});
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const isOnce =
    typeof onceIntersectingOrThreshold === 'boolean' &&
    onceIntersectingOrThreshold;

  if (!isOnce && onceIntersectingOrThreshold) {
    optionsRef.current = {
      threshold: onceIntersectingOrThreshold as IntersectionObserverInit['threshold'],
    };
  }

  useEffect(() => {
    createIntersectionObserver(([e]) => {
      setEntry(e);
      // @ts-ignore
    }, optionsRef.current).then(setIntersection);
  }, [ref.current]);

  useEffect(() => {
    if (io) {
      if (ref.current) {
        io.observe(ref.current);
      }

      return () => io.disconnect();
    }
  }, [io]);

  useEffect(() => {
    if (isOnce && entry && entry?.intersectionRatio > 0 && io) {
      io.disconnect();
    }
  }, [entry, isOnce, io]);

  return { ref, entry, instance: io };
};

export default useIntersectionObserver;
