import { useEffect, useCallback } from 'react';
import { trackEvent } from './helpers';
import { EventCategory, EventLabel, EventAction } from './types';

const closest = (
  el: HTMLElement,
  selector: string | ((el: HTMLElement) => boolean)
) => {
  const isMatches = () =>
    typeof selector === 'function'
      ? selector(el)
      : el.matches && el.matches(selector);

  while (el && !isMatches() && el !== document.body) {
    el = el.parentNode as HTMLElement;
  }

  return isMatches() ? el : null;
};

export const useTrackEventAttrs = () => {
  const sendClickTrackEvent = useCallback((e: MouseEvent) => {
    const el = e.target as HTMLElement;
    const delegateElm = closest(el, bubbleElement => {
      if (bubbleElement && bubbleElement.matches) {
        return (
          bubbleElement.matches('[data-track-action="click"]') ||
          bubbleElement.matches('[data-track-action="spend"]')
        );
      }
    });

    if (delegateElm) {
      const {
        trackCategory,
        trackAction,
        trackName,
        trackArgs,
      } = delegateElm.dataset;

      if (trackCategory && trackAction && trackName) {
        trackEvent(
          trackCategory as EventCategory,
          trackAction as EventAction,
          trackName as EventLabel,
          JSON.parse(trackArgs ?? '{}')
        );
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', sendClickTrackEvent);

    return () => {
      document.removeEventListener('click', sendClickTrackEvent);
    };
  }, [sendClickTrackEvent]);
};
