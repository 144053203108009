import React, { useCallback } from 'react';

import { stringify } from 'query-string';

import { UtmContent } from '@17live/app/components/Link/Link.enums';
import { AsideTab } from '@17live/app/containers/AsideLive/AsideLive.enums';
import { useAsideLiveTab } from '@17live/app/containers/AsideLive/AsideLive.hooks';
import { useEventIFrame } from '@17live/app/containers/Event/Event.hooks';

export const eventUtmContent = Object.freeze({
  EVENT_TOP: 'tab-event-top',
  EVENT_GENERAL: 'tab-event-general',

  ROOM_EVENT: 'stream-room-event-area',
  ROOM_GIFT: 'stream-room-giftbox-event',

  HOME_EVENT: 'tab-home-event',
  HOME_TOP: 'tab-home-top',
});

export type UseOpenUtmParams = {
  userID?: string;
  href: string;
  eventID: number;
  utmContent: keyof typeof eventUtmContent;
  jwtAccessToken?: string;
};

export const getEventUtm = (
  id: number,
  content: keyof typeof eventUtmContent
) => ({
  utm_source: '17live',
  utm_medium: 'banner',
  utm_campaign: id,
  utm_content: eventUtmContent[content],
});

export const getEventUtmUrl = ({
  href,
  userID,
  utmContent,
  eventID,
  jwtAccessToken,
}: UseOpenUtmParams) => {
  const mark = href.includes('?') ? '&' : '?';

  return `${href}${mark}${stringify({
    userID,
    jwtAccessToken,
    ...getEventUtm(eventID, utmContent),
  })}`;
};

export const useOpenUtmEvent = ({
  href,
  userID,
  eventID,
  utmContent,
  jwtAccessToken,
}: UseOpenUtmParams) => {
  const { openIFrame } = useEventIFrame();
  const { switchTab: switchAsideLiveTab } = useAsideLiveTab();
  const onOpenEvent = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();
      const iFrameUrl = getEventUtmUrl({
        href,
        userID,
        utmContent,
        eventID,
        jwtAccessToken,
      });

      // Click event banner in gift box of stream room
      // Show event page in event section instead of opening a new tab
      if (utmContent === UtmContent.ROOM_GIFT) {
        switchAsideLiveTab(AsideTab.EVENT);

        // Open iFrame
        openIFrame({
          eventID,
          iFrameUrl,
        });
        return;
      }

      window.open(iFrameUrl);
    },
    [
      href,
      userID,
      utmContent,
      eventID,
      jwtAccessToken,
      switchAsideLiveTab,
      openIFrame,
    ]
  );

  return onOpenEvent;
};

export default useOpenUtmEvent;
