import {
  KEY_HISTORY_LIVE_STREAMS,
  KEY_PREFERENCES,
  LocalStorage,
  PREFERENCE_KEY_MY_REVENUE,
} from '@/services/Storage';

import { LangType, RegionType } from '@17live/app/constants';
import { DefaultMyRevenuePreference } from '@17live/app/containers/RevenuePage/constants';
import { MyRevenuePreference } from '@17live/app/containers/RevenuePage/types';

export const defaultPreferences = Object.freeze({
  region: null,
  lang: null,
  muted: false,
  clipVolume: 0,
  clipFeedVolume: 0,
  hasEnhanceDownload: false,
  hasReadAchievementHint: false,
  volume: 0.5, // HTML5 <video /> volume 0.0 ~ 1.0
  carouselVolume: 0, // default carouselVolume is 0, because we don't want to interference to the user
  historyLiveStreams: LocalStorage.getItem(KEY_HISTORY_LIVE_STREAMS) || [],
  [PREFERENCE_KEY_MY_REVENUE]: DefaultMyRevenuePreference,
});

LocalStorage.setItem(KEY_PREFERENCES, {
  ...defaultPreferences,
  ...(LocalStorage.getItem(KEY_PREFERENCES) ?? {}),
});

type PreferenceKeys = keyof typeof defaultPreferences;

export const updatePreference = (key: PreferenceKeys, value: unknown) => {
  const preferences = LocalStorage.getItem(KEY_PREFERENCES) ?? {};

  LocalStorage.setItem(KEY_PREFERENCES, {
    ...preferences,
    [key]: value,
  });
};

type GetPreference = {
  (key: 'lang'): LangType;
  (key: 'region'): RegionType;
  (key: 'clipVolume'): number;
  (key: 'muted'): boolean;
  (key: 'hasEnhanceDownload'): boolean;
  (key: 'hasReadAchievementHint'): boolean;
  (key: typeof PREFERENCE_KEY_MY_REVENUE): MyRevenuePreference;
  (key: PreferenceKeys): unknown | undefined;
};

export const getPreference: GetPreference = (key: PreferenceKeys) => {
  const pref = LocalStorage.getItem(KEY_PREFERENCES) ?? {};

  return pref[key];
};
