// @flow
import { defer, Observable, throwError } from 'rxjs';
import { catchError, map, mergeAll, retry } from 'rxjs/operators';

import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';
import omitDeep from '@/utils/omitDeep';

import { SWAP_LANG_REGION } from '@17live/app/constants';
import configs from '@17live/app/constants/configs';
import { getIPRegion } from '@17live/app/containers/LanguageProvider/utils';
import Logger from '@17live/app/services/Logger';
import pxAjax from '@17live/app/services/Perimeterx/pxAjax';
import { getLang } from '@17live/app/utils';
import { handleError } from '@17live/app/utils/api';
import { authToken, getAuthHeader } from '@17live/app/utils/authToken';

import adminApi from './adminApi';
import { METHODS, RETRIES } from './constants';
import errorHandler from './errorHandler';
import eventApi from './eventApi';
import fileApi from './fileApi';
import legacyApiCore from './legacyApiCore';
import responseHandler from './responseHandler';
import uploadFileApi from './uploadFileApi';

const LOGGER_LABEL = 'apis';

type Obj = { [string]: any };

type Caller = (
  endpoint: string,
  method?: string,
  request?: ?Obj,
  headers?: ?Obj,
  options?: ?Obj
) => Observable<*>;

type APICaller = (
  endpoint: string,
  request?: ?Obj,
  headers?: ?Obj,
  options?: ?Obj
) => Observable<*>;

type ApisParams = {
  endpoint: string,
  method?: ?string,
  request?: ?Obj,
  headers?: ?Obj,
  retries?: number,
  ignoreFail?: boolean,
};

type Apis = ApisParams => Observable<*>;

type ApisInstance =
  | Apis
  | {
      get: APICaller,
      post: APICaller,
      patch: APICaller,
      put: APICaller,
      delete: APICaller,
      getJSON: APICaller,
      postJSON: APICaller,
      patchJSON: APICaller,
      putJSON: APICaller,
      deleteJSON: APICaller,
      legacy: APICaller,
    };

const apiGenerator = ({
  apiUrl,
  method,
  headers,
  request,
  loginInfo,
  retries,
  ignoreFail,
}) => {
  return () => {
    return pxAjax(
      omitDeep({
        url: apiUrl,
        method: method || METHODS.get,
        headers,
        body: request
          ? JSON.stringify(
              omitDeep({
                userID: loginInfo.userID,
                ...request,
              })
            )
          : null,
        crossDomain: true,
      })
    ).pipe(
      retry(retries),
      map(e => e.response),
      catchError(errorHandler),
      map(responseHandler),
      catchError(error => {
        if (!ignoreFail) {
          Logger.info(LOGGER_LABEL, '17App API failed', {
            endpoint: apiUrl,
            method,
            request,
            headers,
            error,
          });
        }

        handleError(error);
        throw error;
      })
    );
  };
};

const apis = ({
  endpoint,
  method,
  request,
  headers: paramsHeaders,
  retries = RETRIES,
  ignoreFail = false,
}: ApisParams) => {
  const apiUrl = `${configs.url}/${configs.version}/${endpoint}`;

  const ipRegion = getIPRegion();
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) || {};

  const { region } = loginInfo;

  const regionHeader = {};
  if (ipRegion) Object.assign(regionHeader, { userIpRegion: ipRegion });
  if (region) Object.assign(regionHeader, { userSelectedRegion: region });

  const cacheHeader = {};
  if (configs.options.isNeedNoCache) {
    Object.assign(cacheHeader, {
      'Cache-Control': 'no-cache',
    });
  }

  return defer(async () => {
    let jwtAccessToken;

    try {
      jwtAccessToken = await authToken.getJwtToken();
    } catch (error) {
      throw throwError(error);
    }

    const headers = {
      language: SWAP_LANG_REGION[getLang()],
      ...cacheHeader,
      ...configs.device,
      ...(paramsHeaders || {}),
      ...getAuthHeader(jwtAccessToken),
      ...regionHeader,
    };

    const callAPI = apiGenerator({
      apiUrl,
      method,
      headers,
      request,
      loginInfo,
      retries,
      ignoreFail,
    });

    return callAPI();
  }).pipe(mergeAll());
};

export const apiPlain: Caller = (
  endpoint,
  method,
  request,
  headers,
  options = {}
) =>
  apis({
    endpoint,
    method,
    request,
    ...options,
  });

export const apiJSON: Caller = (
  endpoint,
  method,
  request,
  headers,
  options = {}
) =>
  apis({
    endpoint,
    method,
    request,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...options,
  });

export const callByMethod = (method: string, caller: Caller) => (
  endpoint: string,
  request: ?Obj,
  headers: ?Obj,
  options?: ?Obj = {}
) => {
  return caller(endpoint, method, request, headers, options);
};

const setupAPI = (): ApisInstance => {
  Object.keys(METHODS).forEach(method => {
    apis[method] = callByMethod(METHODS[method], apiPlain);
    apis[`${method}JSON`] = callByMethod(METHODS[method], apiJSON);
  });

  apis.legacy = legacyApiCore;
  apis.admin = adminApi;
  apis.event = eventApi;
  apis.file = fileApi;
  apis.uploadFile = uploadFileApi;

  return apis;
};

export default setupAPI();
