import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import {
  makeSelectLang,
  makeSelectMessages,
} from '@17live/app/containers/LanguageProvider/selectors';

const selectors = createStructuredSelector({
  messages: makeSelectMessages(),
  lang: makeSelectLang(),
});

export const useMessages = () => {
  const selector = useSelector(selectors);

  return {
    ...selector,
  };
};
