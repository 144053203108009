// @flow
import isString from 'lodash/isString';

const responseHandler = (res: any) => {
  if (!res) {
    return;
  }

  try {
    if (
      Object.prototype.hasOwnProperty.call(res, 'data') &&
      isString(res.data)
    ) {
      const response = JSON.parse(res.data);

      if (response.result === 'fail') {
        throw response;
      }

      return response;
    }

    if (
      Object.prototype.hasOwnProperty.call(res, 'errorCode') ||
      res.result === 'fail'
    ) {
      throw res;
    }

    return res;
  } catch (err) {
    throw err;
  }
};

export default responseHandler;
