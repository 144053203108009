export enum SnackRankLevel {
  UNDEFINED = -1,
  LEVEL0,
  LEVEL1,
  LEVEL2,
  LEVEL3,
  LEVEL4,
  LEVEL5,
  LEVEL6,
  LEVEL7,
  LEVEL8,
  LEVEL9,
  LEVEL10,
  LEVEL11,
  LEVEL12,
  LEVEL13,
  LEVEL14,
  LEVEL15,
  LEVEL16,
}

export enum SnackRankSystemUIType {
  BADGE = 'BADGE',
  SMALL_BADGE = 'SMALL_BADGE',
  MSITE_BADGE = 'MSITE_BADGE',
  CARD = 'CARD',
  SMALL_CARD = 'SMALL_CARD',
  PILL = 'PILL',
  ICON = 'ICON',
  MSITE_ICON = 'MSITE_ICON',
}
