import React, { ComponentType, PureComponent } from 'react';

import styled from 'styled-components';

import { Subscription, timer } from 'rxjs';

type Options = {
  time?: number; // transition time, defaults to 0.2s
};

type State = {
  isFadedIn: boolean;
};

const withFadeIn = (
  WrappedComponent: ComponentType<any>,
  options: Options = {}
) => {
  const Com = styled(WrappedComponent)`
    opacity: ${props => (props.isFadedIn ? 1 : 0)};
    transition: opacity ${options.time || 0.2}s ease-out;
  `;

  return class extends PureComponent<any, State> {
    static displayName = `withFadeIn(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      ''})`;

    state = {
      isFadedIn: false,
    };

    componentDidMount() {
      this.fadeIn();
    }

    componentWillUnmount() {
      if (this.timer) {
        this.timer.unsubscribe();
      }
    }

    fadeIn = () => {
      this.timer = timer(0).subscribe(() => {
        this.setState({
          isFadedIn: true,
        });
      });
    };

    timer: Subscription = null;

    render() {
      const { isFadedIn } = this.state;

      return <Com isFadedIn={isFadedIn} {...this.props} />;
    }
  };
};

export default withFadeIn;
