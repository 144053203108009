// @flow
import { ActionsObservable, ofType } from 'redux-observable';

import type { Record } from 'immutable';
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { type Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { STATUS } from '@/constants/services';

export const getIsLoaded = (status: $Values<typeof STATUS>): boolean =>
  status === STATUS.COMPLETED || status === STATUS.ERROR;

export default (
  name: string,
  {
    getListFromService,
    transformReducer = list => list,
  }: {
    getListFromService: (*) => Observable<*>,
    transformReducer: (List<*>) => List<*>,
  }
) => {
  /**
   * constants
   */
  type Constant = 'GET_LIST' | 'SET_LIST' | 'CLEAR_LIST' | 'SET_ERROR';

  const constantCreator = (constant: Constant): string =>
    `app/containers/${name}/${constant}`;

  const constants = Object.freeze({
    GET_LIST: constantCreator('GET_LIST'),
    SET_LIST: constantCreator('SET_LIST'),
    CLEAR_LIST: constantCreator('CLEAR_LIST'),
    SET_ERROR: constantCreator('SET_ERROR'),
    /* deprecated: please use STATUS from '@/constants/services' */
    STATUS,
  });

  /**
   * actions
   */
  type ActionNames = 'getList' | 'setList' | 'clearList' | 'setError';

  type Action = {
    type: $Values<typeof constants>,
    payload: {
      listName: string,
    },
    error?: true,
  };

  type ActionCreator = (listName: string, payload: {}) => Action;

  const actions: {
    [ActionNames]: ActionCreator,
  } = {
    getList: (listName, payload = {}) => ({
      type: constants.GET_LIST,
      payload: {
        listName,
        ...payload,
      },
    }),
    setList: (listName, payload = {}) => ({
      type: constants.SET_LIST,
      payload: {
        listName,
        ...payload,
      },
    }),
    clearList: (listName, payload = {}) => ({
      type: constants.CLEAR_LIST,
      payload: {
        listName,
        ...payload,
      },
    }),
    setError: (listName, payload = {}) => ({
      type: constants.SET_ERROR,
      error: true,
      payload: {
        listName,
        ...payload,
      },
    }),
  };

  /**
   * reducer
   */
  type State = Map<{
    [listName: string]: Record<{
      list: List<string>,
      status: $Values<typeof STATUS>,
      error?: *,
      cursor?: string,
    }>,
  }>;

  const initialState = Map();

  const reducer = (
    state: State = initialState,
    action: $Values<typeof actions>
  ): State => {
    const { type, payload } = action;
    const listName = payload && payload.listName;

    switch (type) {
      case constants.GET_LIST:
        return state.update(listName, (listMap = Map()) =>
          listMap.set('status', STATUS.LOADING)
        );
      case constants.SET_LIST: {
        const { list, cursor, subTabs, shouldClear } = payload;
        const nextList = fromJS(transformReducer(list));

        return state.update(listName, (listMap = Map()) =>
          listMap
            .update('list', (data = List()) =>
              shouldClear
                ? nextList
                : data
                    .concat(nextList)
                    .toOrderedSet()
                    .toList()
            )
            .set('subTabs', fromJS(subTabs))
            .set('status', cursor ? STATUS.IDLE : STATUS.COMPLETED)
            .set('cursor', cursor)
            .delete('error')
        );
      }
      case constants.CLEAR_LIST:
        return state.set(
          listName,
          fromJS({
            list: [],
            status: STATUS.IDLE,
          })
        );
      case constants.SET_ERROR: {
        const { error } = payload;
        return state.update(listName, (listMap = Map()) =>
          listMap.set('status', STATUS.ERROR).set('error', fromJS(error))
        );
      }
      default:
        return state;
    }
  };

  /**
   * epics
   */
  const getListEpic = (action$: ActionsObservable<*>) =>
    action$.pipe(
      ofType(constants.GET_LIST),
      mergeMap(({ payload: { listName, shouldClear, ...payload } }) =>
        getListFromService({ listName, ...payload }).pipe(
          map(({ list, cursor, subTabs }) =>
            actions.setList(listName, {
              list,
              cursor,
              subTabs,
              shouldClear,
            })
          ),
          takeUntil(
            action$.pipe(
              ofType(constants.CLEAR_LIST),
              filter(
                ({ payload: { listName: actionListName } }) =>
                  listName === actionListName
              )
            )
          ),
          catchError(error => of(actions.setError(listName, { error })))
        )
      )
    );

  const epics = [getListEpic];

  /**
   * selectors
   */

  const selectDomain = (
    state: Map<{ [name: string]: Map<mixed> }>
  ): Map<mixed> => state.get(name);

  const selectListName = (
    state: Map<mixed>,
    ownProps: { listName?: string }
  ): string => ownProps.listName || name;

  const selectList = (
    domain: Map<{ [listName: string]: Map<mixed> }>,
    listName: string
  ): Map<mixed> => {
    return domain?.get(listName) || Map();
  };

  const makeSelectList = () =>
    createSelector(selectDomain, selectListName, selectList);

  const listSelector = makeSelectList();

  const selectors = {
    selectDomain,
    selectListName,
    selectList,
    makeSelectList,
    makeSelectListData: () =>
      createSelector(listSelector, listMap => listMap.get('list') || List()),
    makeSelectListStatus: () =>
      createSelector(
        listSelector,
        listMap => listMap.get('status') || STATUS.IDLE
      ),
    makeSelectListError: () =>
      createSelector(listSelector, listMap => listMap.get('error')),
    makeSelectListCursor: () =>
      createSelector(listSelector, listMap => listMap.get('cursor') || ''),
    makeSelectListSubTabs: () =>
      createSelector(listSelector, listMap => listMap.get('subTabs') || List()),
  };

  return {
    constants,
    actions,
    reducer,
    epics,
    selectors,
  };
};
