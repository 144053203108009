import create from 'zustand';

import {
  PerimeterXBlockResponse,
  PerimeterXCaptchaCallback,
} from '@17live/app/services/Perimeterx/types';

type State = {
  captchaCallback: PerimeterXCaptchaCallback | null;
  pxData: PerimeterXBlockResponse | null;
  isCaptchaModalVisible: boolean;
  openCaptchaModal: (
    blockResponse: PerimeterXBlockResponse,
    callback: PerimeterXCaptchaCallback
  ) => void;
  closeCaptchaModal: () => void;
};

const usePerimeterXStore = create<State>(set => ({
  isCaptchaModalVisible: false,
  captchaCallback: null,
  pxData: null,
  openCaptchaModal: (blockResponse, captchaCallback) => {
    set({
      isCaptchaModalVisible: true,
      pxData: blockResponse,
      captchaCallback,
    });
  },
  closeCaptchaModal: () => {
    set({
      isCaptchaModalVisible: false,
      captchaCallback: null,
    });
  },
}));

export default usePerimeterXStore;
