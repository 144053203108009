// @flow
import { stringify } from 'query-string';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { RecommendedUser } from '@/data/recommendedUser';
import apis from '../shared/apis';
import { DEFAULT_REGION } from '../shared/constants';

type Options = {
  count: number,
  region: string,
  cursor: string,
};

type Response = Observable<{
  list: RecommendedUser[],
  cursor: string,
}>;

const API_PATH = 'user';

export const getRecommendedList = (options?: Options): Response => {
  const { count = 20, region = DEFAULT_REGION, cursor = '' } = options || {};

  return apis
    .getJSON(
      `${API_PATH}/recommendedList?${stringify({ count, region, cursor })}`
    )
    .pipe(
      // unify the data for createListContainer
      map(response => ({
        list: response.users,
        cursor: response.cursor,
      }))
    );
};
