// @flow
import { stringify } from 'query-string';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Event } from '@/data/event';

import { EVENT_URGENT_THRESHOLD } from '@17live/app/containers/Event/constants';

import apis from './shared/apis';
import { DEFAULT_REGION } from './shared/constants';

const API_PATH = 'event';

type Stage = {
  startTime: number,
  endTime: number,
  endTimeCountdown: boolean,
};

type RawEvent = {
  ID: number,
  stages: Stage[],
  bannerURL: string,
  descriptionURL: string,
};

type EventResp = {
  events: {
    inProgress: RawEvent[],
    hasEnded?: RawEvent[],
  },
};

export const EVENT_STATUS = Object.freeze({
  IN_PROGRESS: 1,
  ENDED: 2,
});

export type EventStatus = $Values<typeof EVENT_STATUS>;

export const getEvents = ({
  region = DEFAULT_REGION,
  status = EVENT_STATUS.IN_PROGRESS,
}: {
  region: string,
  status?: EventStatus,
}): Observable<Event[]> =>
  apis.getJSON(`${API_PATH}?${stringify({ region, status })}`).pipe(
    map(({ events: { inProgress }, tagsInfo = {} }: EventResp) => {
      const now = Date.now();
      const tagMap = [
        ...(tagsInfo?.inProgress ?? []),
        ...(tagsInfo?.hasEnded ?? []),
      ].reduce((o, tag) => {
        return {
          ...o,
          [tag.ID]: tag.name,
        };
      }, {});

      // convert format to original event-list file
      return inProgress.map(
        ({ ID, bannerURL, descriptionURL, stages, tagIDs = [] }) => {
          const countdownStages = stages
            .filter(({ endTimeCountdown }) => endTimeCountdown)
            .map(({ startTime, endTime }) => ({
              startTime: startTime * 1000,
              endTime: endTime * 1000,
            }));

          // Maybe there is no stage need countdown
          const lastStage = countdownStages[countdownStages.length - 1] || {};
          const currentStage =
            countdownStages.find(
              ({ startTime, endTime }) => now >= startTime && now < endTime
            ) || lastStage;
          const isFinalCountdown = lastStage.endTime === currentStage.endTime;
          // TODO: can use isWithin24Hours instead
          const isUrgent =
            currentStage.endTime - now <= EVENT_URGENT_THRESHOLD * 1000;

          return {
            ...currentStage,
            isFinalCountdown,
            isUrgent,
            id: ID,
            bannerFilename: bannerURL,
            to: descriptionURL,
            tags: tagIDs.map(tag => tagMap[tag] ?? tag),
          };
        }
      );
    })
  );
