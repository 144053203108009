import { getLeaderboardList } from '@/services/17App/leaderboard';
import createListContainer from '@/utils/createListContainer';

const { constants, actions, reducer, epics, selectors } = createListContainer(
  'leaderboard',
  {
    getListFromService: getLeaderboardList,
  }
);

export { constants, actions, reducer, epics, selectors };
