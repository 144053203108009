import { isBlockResponse, openCaptchaModalPromise } from './abr';

/**
 * Handle exception thrown by JSON.parse to avoid interrupting flow
 * @param str
 */
const safeParseJSON = (str: string) => {
  let parsed;
  try {
    parsed = JSON.parse(str);
  } catch (e) {}
  return parsed;
};

/**
 * Decorate native fetch function for handling px block response
 * @param input RequestInfo
 * @param init RequestInit
 */
const pxFetch = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> =>
  window.fetch(input, init).then(async res => {
    if (res.status === 403) {
      const body = safeParseJSON(await res.text());
      if (isBlockResponse(body)) {
        await openCaptchaModalPromise(body);
        // TODO[PX]: We need better message :(
        throw new Error('PX_BLOCK_RESPONSE');
      }
    }
    return res;
  });

export default pxFetch;
