import { EventList } from './types';

export const FETCH_EVENTS = 'app/EventPage/FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'app/EventPage/FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'app/EventPage/FETCH_EVENTS_FAIL';

export const fetchEvent = (region?: string) => ({
  type: FETCH_EVENTS,
  payload: region,
});

export const fetchEventSuccess = (list: EventList[] = []) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload: list,
});
