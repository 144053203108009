// @flow
import React from 'react';
import invariant from 'invariant';
import callAll from '@/utils/callAll';
import BaseModal from './BaseModal';
import MemoryHistory from './MemoryHistory';
import { isShallowEqualLocation } from './utils';
import type { ModalProps } from './types';

const RouteModal = ({
  isOpen,
  getParentPath,
  closeModal,
  onOpenStateChanged,
  history,
  location,
  match,
  children,
  ...props
}: ModalProps) => {
  if (!getParentPath) {
    return (
      <BaseModal
        isOpen={isOpen}
        closeModal={closeModal}
        onOpenStateChanged={onOpenStateChanged}
        {...props}
      >
        {children}
      </BaseModal>
    );
  }

  invariant(getParentPath, 'Route modal should have `getParentPath` prop');

  const router = { history, location, match };

  return (
    <MemoryHistory>
      {({ getPreviousLocation }) => (
        <BaseModal
          {...props}
          {...router}
          isOpen={isOpen}
          onOpenStateChanged={callAll(
            closeModal
              ? openState => {
                  if (!openState) {
                    closeModal();
                  }
                }
              : undefined,
            onOpenStateChanged
          )}
          closeModal={() => {
            const previousLocation = getPreviousLocation();

            // already closed the modal
            if (!isOpen) {
              return;
            }

            invariant(history, 'history should be available');

            const parentPath = getParentPath(router);

            if (
              previousLocation &&
              isShallowEqualLocation(previousLocation, parentPath)
            ) {
              history.goBack();
            } else {
              history[previousLocation ? 'replace' : 'push'](parentPath);
            }
          }}
        >
          {children}
        </BaseModal>
      )}
    </MemoryHistory>
  );
};

export default RouteModal;
