import { ScreenSize, SIZE_LARGE, SIZE_MEDIUM } from '@/constants/sizes';

/* actions */
export const GET_EVENT_LIST: string = 'app/Event/GET_EVENT_LIST';
export const SET_EVENT_LIST: string = 'app/Event/SET_EVENT_LIST';
export const SHOW_EVENT: string = 'app/Event/SHOW_EVENT';
export const GET_AVATAR_WEEKLY = 'app/Event/GET_AVATAR_WEEKLY';
export const SET_AVATAR_WEEKLY_EVENT_INFO =
  'app/Event/SET_AVATAR_WEEKLY_EVENT_INFO';

export const OPEN_EVENT_IFRAME: string = 'app/Event/OPEN_EVENT_IFRAME';
export const CLOSE_EVENT_IFRAME: string = 'app/Event/CLOSE_EVENT_IFRAME';

/* styles */
export const EVENT_BANNER_HEIGHT: { [key in ScreenSize]: number } = {
  [SIZE_MEDIUM]: 95, // 114 * 0.83 according to AsideLive's minification
  [SIZE_LARGE]: 114,
};

/* configs */
export const EVENT_URGENT_THRESHOLD: number = 86400; // 1 day in seconds
export const WEBAPP_BASE_URL: string = 'https://17.media'; // default our event site
export const WEBCDN_BASE_URL: string = 'https://webcdn.17app.co'; // out-sourcing event site

/* event actions from webapp */
export const EVENT_SEND_AVATAR_LINK: string =
  '17live/Event/EVENT_SEND_AVATAR_LINK';
export const EVENT_SEND_URL_HASH: string = '17live/Event/EVENT_SEND_URL_HASH';

export const EVENT_ACTIONS: string[] = [EVENT_SEND_AVATAR_LINK];
