import { useCallback, useEffect, useReducer } from 'react';

const initState = {
  isLoading: false,
  prevTotalAmount: null,
};

enum STATUS {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

type InitState = typeof initState;

type Action = {
  type: STATUS;
  payload?: any;
};

const reducer: React.Reducer<InitState, Action> = (state, action) => {
  const { type } = action;
  switch (type) {
    case STATUS.LOADING: {
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    }
    case STATUS.IDLE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const useBaggage = (
  sendGift: {
    (giftID: string, tab: string): void;
    (giftID: string, tab: string): void;
    (arg0: any, arg1: any): void;
  },
  itemID: string,
  type: string,
  totalAmount: number
) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const handleSendGift = useCallback(() => {
    dispatch({
      type: STATUS.LOADING,
      payload: {
        prevTotalAmount: totalAmount,
      },
    });
    sendGift(itemID, type);
  }, [sendGift, itemID, type, totalAmount]);

  useEffect(() => {
    if (totalAmount !== state.prevTotalAmount) {
      dispatch({
        type: STATUS.IDLE,
      });
    }
  }, [totalAmount, state.prevTotalAmount]);

  return { isLoading: state.isLoading, handleSendGift };
};
