/*
 *
 * Subscribe constants
 *
 */

export const CHECK_SUBSCRIPTION_STATUS =
  'app/Subscribe/CHECK_SUBSCRIPTION_STATUS';
export const SET_SUBSCRIBE_STATUS = 'app/Subscribe/SET_SUBSCRIBE_STATUS';
export const REQUEST_SUBSCRIBE_FAILED =
  'app/Subscribe/REQUEST_SUBSCRIBE_FAILED';

export const TARGET_PLATFORM = 0;
export const TARGET_USER = 1;

// spec: https://docs.google.com/document/d/1snMeaTbcG1lQ6jmgMAJvhlc1ujpbWOweg8kZxO1MIPE/edit
export const SUBSCRIBE_ERROR_CODE = {
  WRONG_PARAMS: 7,
  INSUFFICIENT_BALANCE: 16,
  ILLEGAL_ORDER: 13001,
  ARMY_DISABLED: 13002,
};
