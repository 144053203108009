import { decodeCompressedData } from '@17live/app/services/Message/Message.utils';

export const getAblyDecodeData = message => {
  const { data, ...rest } = message;
  const msg = {
    cdata: [
      {
        alg: 'gzip_base64',
        data,
      },
    ],
    ...rest,
  };
  const resultData = decodeCompressedData(msg);

  return resultData;
};
