// @flow
/**
 * screen sizes
 */
export type ScreenSize = 'large' | 'medium' | 'small' | 'mobile';

export const SIZE_SMALL: ScreenSize = 'small';
export const SIZE_LARGE: ScreenSize = 'large';
export const SIZE_MEDIUM: ScreenSize = 'medium';
export const SIZE_MOBILE: ScreenSize = 'mobile';

export const SIZE_DEFAULT: number = 1440; // px
export const SIZE_MOBILE_MAX: number = 600; // max content size for mobile only page (army)
export const SIZE_MOBILE_DEFAULT: number = 414; // recommended default size for mobile
export const SIZE_MOBILE_BREAKPOINT: number = 1024; // breakpoint to start treating browser as in mobile device
export const LANDSCAPE_COMMON_RATIO: number = 16 / 9; // common ratio for landscape video
export const DEFAULT_IMAGE_SIZE: string = '60%';
export const DEFAULT_IMAGE_RECT_SIZE: string = '120px';

export const screenSizeBreakpoints: { [ScreenSize]: number } = {
  [SIZE_LARGE]: 1920,
  [SIZE_MEDIUM]: SIZE_DEFAULT,
  [SIZE_SMALL]: SIZE_MOBILE_BREAKPOINT,
  [SIZE_MOBILE]: 0,
};

/**
 * screen layouts
 */
export type ScreenLayout = 'portrait' | 'landscape';

export const LAYOUT_PORTRAIT: ScreenLayout = 'portrait';
export const LAYOUT_LANDSCAPE: ScreenLayout = 'landscape';

/**
 * actual window size
 * see @/components/ResponsiveWindowSize for the events
 */
export const WINDOW_HEIGHT = 'var(--window-height)';
export const WINDOW_WIDTH = 'var(--window-width)';
