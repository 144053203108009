/**
 * Do not edit directly
 * Generated on Tue, 30 May 2023 03:26:47 GMT
 */

export const COLOR_BLACK = '#000000';
export const COLOR_PRIMARY_BLACK = '#333333';
export const COLOR_PRIMARY_WHITE = '#ffffff';
export const COLOR_NEUTRAL_800 = '#525252';
export const COLOR_NEUTRAL_700 = '#707070';
export const COLOR_NEUTRAL_600 = '#8a8a8a';
export const COLOR_NEUTRAL_500 = '#a3a3a3';
export const COLOR_NEUTRAL_400 = '#bdbdbd';
export const COLOR_NEUTRAL_300 = '#d1d1d1';
export const COLOR_NEUTRAL_200 = '#e6e6e6';
export const COLOR_NEUTRAL_100 = '#f3f3f3';
export const COLOR_NEUTRAL_50 = '#fafafa';
export const COLOR_BRAND = '#ff0000';
export const COLOR_INFO = '#0e4dff';
export const COLOR_INFO_LIGHT = '#527fff';
export const COLOR_INFO_LIGHTER = '#97b2ff';
export const COLOR_INFO_DARK = '#0039d5';
export const COLOR_INFO_DARKER = '#0028ab';
export const COLOR_DANGER = '#eb0075';
export const COLOR_DANGER_LIGHT = '#f14b9e';
export const COLOR_DANGER_DARK = '#c80063';
export const COLOR_DANGER_DARKER = '#a50052';
export const COLOR_CAUTION = '#ffc107';
export const COLOR_CAUTION_LIGHT = '#ffd249';
export const COLOR_CAUTION_DARK = '#e39d06';
export const COLOR_CAUTION_DARKER = '#c77d06';
export const COLOR_SUCCESS = '#78e000';
export const COLOR_SUCCESS_LIGHT = '#9ae94d';
export const COLOR_SUCCESS_DARK = '#50c200';
export const COLOR_SUCCESS_DARKER = '#2fa400';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_70, 0.7)
 */
export const COLOR_OVERLAY_70 = '#000000';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_60, 0.6)
 */
export const COLOR_OVERLAY_60 = '#000000';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_50, 0.5)
 */
export const COLOR_OVERLAY_50 = '#000000';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_40, 0.4)
 */
export const COLOR_OVERLAY_40 = '#000000';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_30, 0.3)
 */
export const COLOR_OVERLAY_30 = '#000000';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_20, 0.2)
 */
export const COLOR_OVERLAY_20 = '#000000';

/**
 * 透明度使用 rgba(COLOR_TRANSPARENT, 0)
 */
export const COLOR_TRANSPARENT = '#000000';
export const COLOR_BUTTON_BACKGROUND_PRIMARY = '#333333';
export const COLOR_BUTTON_BACKGROUND_PRIMARY_PRESSED = '#4b4b4b';
export const COLOR_BUTTON_BACKGROUND_PRIMARY_DISABLED = '#c2c2c2';
export const COLOR_BUTTON_BACKGROUND_WHITE = '#ffffff';
export const COLOR_BUTTON_BACKGROUND_WHITE_PRESSED = '#e0e0e0';
export const COLOR_BUTTON_BACKGROUND_WHITE_DISABLED = '#707070';
export const COLOR_BUTTON_BACKGROUND_SECONDARY = '#e6e6e6';
export const COLOR_BUTTON_BACKGROUND_SECONDARY_PRESSED = '#c9c9c9';
export const COLOR_BUTTON_BACKGROUND_SECONDARY_DISABLED = '#f8f8f8';
export const COLOR_BUTTON_BACKGROUND_SUCCESS = '#50c200';
export const COLOR_BUTTON_BACKGROUND_SUCCESS_PRESSED = '#46ab00';
export const COLOR_BUTTON_BACKGROUND_SUCCESS_DISABLED = '#cbedb3';
export const COLOR_BUTTON_BACKGROUND_DANGER = '#eb0075';
export const COLOR_BUTTON_BACKGROUND_DANGER_PRESSED = '#cf0067';
export const COLOR_BUTTON_BACKGROUND_DANGER_DISABLED = '#f9b3d6';
export const COLOR_BUTTON_STROKE_PRIMARY = '#525252';
export const COLOR_BUTTON_STROKE_PRIMARY_DISABLED = '#cbcbcb';
export const COLOR_BUTTON_STROKE_WHITE = '#ffffff';
export const COLOR_BUTTON_STROKE_WHITE_DISABLED = '#707070';
export const COLOR_BUTTON_STROKE_SECONDARY = '#e6e6e6';
export const COLOR_BUTTON_STROKE_SECONDARY_DISABLED = '#f8f8f8';
export const COLOR_BUTTON_STROKE_SUCCESS = '#50c200';
export const COLOR_BUTTON_STROKE_SUCCESS_DISABLED = '#cbedb3';
export const COLOR_BUTTON_STROKE_DANGER = '#eb0075';
export const COLOR_BUTTON_STROKE_DANGER_DISABLED = '#f9b3d6';
export const COLOR_BUTTON_TEXT_PRIMARY = '#333333';
export const COLOR_BUTTON_TEXT_PRIMARY_DISABLED = '#c2c2c2';
export const COLOR_BUTTON_TEXT_WHITE = '#ffffff';
export const COLOR_BUTTON_TEXT_WHITE_DISABLED = '#707070';
export const COLOR_BUTTON_TEXT_SECONDARY = '#8a8a8a';
export const COLOR_BUTTON_TEXT_SECONDARY_DISABLED = '#dcdcdc';
export const COLOR_BUTTON_TEXT_SUCCESS = '#50c200';
export const COLOR_BUTTON_TEXT_SUCCESS_DISABLED = '#cbedb3';
export const COLOR_BUTTON_TEXT_DANGER = '#eb0075';
export const COLOR_BUTTON_TEXT_DANGER_DISABLED = '#f9b3d6';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_90, 0.9)
 */
export const COLOR_OVERLAY_90 = '#000000';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_WHITE_90, 0.9)
 */
export const COLOR_OVERLAY_WHITE_90 = '#ffffff';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_WHITE_10, 0.1)
 */
export const COLOR_OVERLAY_WHITE_10 = '#ffffff';

/**
 * 透明度使用 rgba(COLOR_OVERLAY_WHITE_50, 0.5)
 */
export const COLOR_OVERLAY_WHITE_50 = '#ffffff';
export const COLOR_GROUPCALL = '#3ed697';
export const COLOR_GROUPCALL_LIGHT = '#19ff9f';
export const COLOR_TEXT_BLACK_PRIMARY = '#333333';
export const COLOR_TEXT_BLACK_SECONDARY = '#707070';
export const COLOR_TEXT_BLACK_TERTIARY = '#a3a3a3';
export const COLOR_TEXT_BLACK_DISABLED = '#d1d1d1';
export const COLOR_TEXT_WHITE_PRIMARY = '#ffffff';
export const COLOR_TEXT_WHITE_SECONDARY = '#d1d1d1';
export const COLOR_TEXT_WHITE_TERTIARY = '#a3a3a3';
export const COLOR_TEXT_WHITE_DISABLED = '#707070';
export const COLOR_HYPERLINK = '#0028ab';
export const COLOR_HYPERLINK_PRESSED = '#002396';
export const COLOR_HYPERLINK_LIGHT = '#97b2ff';
export const COLOR_HYPERLINK_LIGHT_PRESSED = '#859de0';
export const COLOR_STROKE_WHITE_LIGHTER = '#f3f3f3';
export const COLOR_STROKE_WHITE = '#e6e6e6';
export const COLOR_STROKE_WHITE_DARKER = '#d9d9d9';
export const COLOR_STROKE_BLACK_LIGHTER = '#242424';
export const COLOR_STROKE_BLACK = '#1a1a1a';
export const COLOR_STROKE_BLACK_DARKER = '#000000';
export const COLOR_BACKGROUND_WHITE = '#ffffff';
export const COLOR_BACKGROUND_WHITE_PRESSED = '#fafafa';
export const COLOR_BACKGROUND_WHITE_DARK = '#fafafa';
export const COLOR_BACKGROUND_WHITE_DARK_PRESSED = '#f4f4f4';
export const COLOR_BACKGROUND_WHITE_DARKER = '#f3f3f3';
export const COLOR_BACKGROUND_WHITE_DARKER_PRESSED = '#ededed';
export const COLOR_BACKGROUND_WHITE_DARKEST = '#e6e6e6';
export const COLOR_BACKGROUND_WHITE_DARKEST_PRESSED = '#e0e0e0';
export const COLOR_BACKGROUND_BLACK = '#333333';
export const COLOR_BACKGROUND_BLACK_PRESSED = '#383838';
export const COLOR_BACKGROUND_BLACK_DARK = '#242424';
export const COLOR_BACKGROUND_BLACK_DARK_PRESSED = '#292929';
export const COLOR_BACKGROUND_BLACK_DARKER = '#1a1a1a';
export const COLOR_BACKGROUND_BLACK_DARKER_PRESSED = '#202020';
export const COLOR_BACKGROUND_BLACK_DARKEST = '#000000';
export const COLOR_BACKGROUND_BLACK_DARKEST_PRESSED = '#1a1a1a';
export const COLOR_BUTTON_BACKGROUND_BRAND = '#ff0000';
export const COLOR_BUTTON_BACKGROUND_BRAND_PRESSED = '#e00000';
export const COLOR_BUTTON_BACKGROUND_BRAND_DISABLED = '#ffb2b2';
export const COLOR_BUTTON_STROKE_BRAND = '#ff0000';
export const COLOR_BUTTON_STROKE_BRAND_DISABLED = '#ffb2b2';
export const COLOR_BUTTON_TEXT_BRAND = '#ff0000';
export const COLOR_BUTTON_TEXT_BRAND_DISABLED = '#ffb2b2';
export const COLOR_CHIP_BACKGROUND_DEFAULT = '#ffffff';
export const COLOR_CHIP_BACKGROUND_PRESSED = '#e0e0e0';
export const COLOR_CHIP_BACKGROUND_SELECTED = '#333333';
export const COLOR_CHIP_STROKE_DEFAULT = '#e6e6e6';
export const COLOR_CHIP_STROKE_DISABLED = '#f8f8f8';
export const COLOR_CHIP_TEXT_DEFAULT = '#333333';
export const COLOR_CHIP_TEXT_SELECTED = '#ffffff';
export const COLOR_CHIP_TEXT_DISABLED = '#c2c2c2';
export const GRADIENT_LINEAR_TRANSPARENT_BLACK =
  'linear-gradient(180deg, #00000000,#000000ff)';
export const GRADIENT_LINEAR_TRANSPARENT_OVERLAY_60 =
  'linear-gradient(180deg, #00000000,#00000099)';
export const GRADIENT_LINEAR_TRANSPARENT_OVERLAY_50 =
  'linear-gradient(180deg, #00000000,#00000080)';
export const GRADIENT_LINEAR_TRANSPARENT_OVERLAY_40 =
  'linear-gradient(180deg, #00000000,#00000066)';
export const GRADIENT_LINEAR_TRANSPARENT_OVERLAY_20 =
  'linear-gradient(180deg, #00000000,#00000033)';
