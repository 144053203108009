import { useCallback, useMemo } from 'react';

import { isDesktop } from '@/services/Device';

import {
  DEFAULT_REGION,
  RegionType,
  SUPPORT_REGIONS,
} from '@17live/app/constants';
import { useRegion } from '@17live/app/containers/LanguageProvider/hooks';
import { useLogin } from '@17live/app/containers/LoginProvider/hooks';
import {
  useFetchConfig,
  UseFetchConfigOptions,
} from '@17live/app/hooks/apis/useFetchConfig';
import useQueryParams from '@17live/app/hooks/useQueryParams';

/**
 * @ref https://github.com/17media/api/blob/master/models/feature/feature.go
 */
export const FEATURES_MAP = Object.freeze({
  Dummy: 0,
  Follow: 1,
  Hot: 2,
  Music: 3,
  Clip: 4,
  EC: 5,
  Werewolves: 6,
  Latest: 7,
  Gossip: 8,
  Simbo: 9,
  TV: 10,
  Trivia: 11,
  Army: 12,
  TextureGift: 13,
  TreasureChest: 14,
  Guide: 15,
  CustomEvent: 16,
  LiveStreamTopic: 17,
  Gaming: 19,
  Texture: 20,
  MLevel: 21,
  AutoEntryFirstStream: 22,
  PrivateMsg: 23,
  MarqueeComment: 24,
  Event: 25,
  CryptoCurrency: 26,
  DecoSticker: 27,
  Goods: 28,
  Royalty: 29,
  CellLiveStream: 30,
  Invisible: 31,
  NewbieIAPPromotion: 32,
  GamingCoin: 33,
  StreamCampaign: 34,
  StealthLeaderboard: 35,
  StreamRecapOpen: 36,
  Coupon: 37,
  Assistant: 38,
  FollowRemainder: 39,
  FreshUserEnterMsg: 40,
  ViewHistory: 41,
  ApnSetting: 42,
  VideoChat: 43,
  GloryRoad: 44,
  Piepie: 45,
  PromotedStreamer: 46,
  Gift_LuckyDraw: 47,
  InvisibleRockViewer: 48,
  GestureTextureGift: 49,
  EntryEffectEventRewards: 50,
  VipRecommend: 51,
  Explore: 52,
  League: 53,
  TopicTab: 54,
  RedEnvelope: 55,
  SendGiftAskFollow: 56,
  Hot_Opt: 57,
  LabelTab: 58,
  Mission: 59,
  MaleTab: 60,
  FemaleTab: 61,
  '17TVTab': 62,
  '17LivePlusTab': 63,
  ShowSubtabInCell: 64,
  StreamerLoyalty: 65,
  AblySubscription: 66,
  ReferralProject: 67,
  AgeVerification: 68,
  CreateRTMPVerification: 69,
  '17Games': 70,
  MusicJP: 71,
  LaborReward: 72,
  DismissRedenvelopeDialog: 73,
  BlockMarquee: 74,
  BlockMonster: 75,
  Hot_2019Q4: 76,
  BabyCoin: 77,
  DailyPoker: 78,
  RichBB: 79,
  HandsUp: 80,
  ShowMonthlyRevenueBefore2019: 81,
  ShowHashtagInLiveRoom: 82,
  HotOlderVersion: 83,
  HotOptOlderVersion: 84,
  GuestMode: 85,
  PremiumLaborReward: 86,
  BlockIAPPromotion: 87,
  CategorySuggestion: 88,
  FollowerOnlyCommentMode: 89,
  AllContractStreamersCanUseOBS: 90,
  UIRefresh2020Q3: 91,
  IdleMode: 92,
  FeaturePhoneValidation: 93,
  FeatureAvatar: 94,
  UserStylePage: 95,
  Classes: 96,
  FeatureCanUseOBS: 104,
  NewUserWelcome: 105,
  Archive: 115,
  Poll: 119,
  Vliver: 130,
  VliverExposure: 131, // ref: https://17media.atlassian.net/wiki/spaces/ST/pages/842268673/Exposure+for+vLiver+on+genre+page
  BirthdayVerification: 132,
  BirthdayUserChange: 133,
  IncentiveSuperfan: 138,
  IncentiveSpotlight: 140,
  IncentiveEnergyRank: 141,
  VliverVRM: 143,
  Incentive: 144,
  Shop: 146,
  NewArmyGroup1: 155, // ref: https://17media.atlassian.net/wiki/spaces/BIL/pages/1028358164/New+Sub+API+doc#Config
  NewArmyGroup2: 156,
  ArchiveClip: 157,
  NewArmyGroup3: 158,
  HideRankGeneral: 170,
  AutoCancelRankGeneral: 172,
  FeatureUserJoinStream: 174,
  FeatureSwitchEvent: 178,
  SnackRankSystem: 175,
  AiAssistantGame: 199,
});

/**
 * you can use `getFeatureConfig` instead.
 */
export const useHasConfigFeatures = (
  featureType: number,
  options: UseFetchConfigOptions = {}
) => {
  const { data: config, ...query } = useFetchConfig(options);

  return {
    data: !!config?.addOns.features[featureType],
    ...query,
  };
};

/**
 * useFeatureConfig
 * @returns getFeatureConfig: (featureType: number) => {}
 * @usage
 * ```
 * const { getFeatureConfig } = useFeatureConfig();
 * const { getFeatureConfig } = useFeatureConfig({ ...option });
 *
 * getFeatureConfig(FEATURES_MAP.Vliver)
 * ```
 */
export const useFeatureConfig = (options: UseFetchConfigOptions = {}) => {
  const { data: config, ...query } = useFetchConfig(options);

  return {
    getFeatureConfig: useCallback(
      (featureType: number) => !!config?.addOns.features[featureType],
      [config]
    ),
    ...query,
  };
};

export default useHasConfigFeatures;

export const useHasGoLiveFeature = () => {
  const { isLoggedIn } = useLogin();
  const { data: canUseOBS, ...query } = useHasConfigFeatures(
    FEATURES_MAP.FeatureCanUseOBS
  );

  const hasGoLive = useMemo(() => isDesktop() && canUseOBS && isLoggedIn, [
    canUseOBS,
    isLoggedIn,
  ]);

  return {
    data: hasGoLive,
    ...query,
  };
};

export const useHasArchiveFeature = () => {
  const { region: webRegion } = useRegion();
  return useHasConfigFeatures(FEATURES_MAP.Archive, {
    overrideRegion: webRegion,
  });
};

export const useHasSnackFeature = () => {
  // Snack feature config based on Web region
  // ref: https://17media.slack.com/archives/C02B4UX41HA/p1658129746831689?thread_ts=1658127709.769729&cid=C02B4UX41HA
  const { region: webRegion } = useRegion();
  return useHasConfigFeatures(FEATURES_MAP.LaborReward, {
    overrideRegion: webRegion,
  });
};

export const useHasAiAssistantGameFeature = () => {
  const queryParams = useQueryParams();
  const searchRegion = queryParams?.region as RegionType;
  const region = SUPPORT_REGIONS.includes(searchRegion)
    ? searchRegion
    : DEFAULT_REGION;

  return useHasConfigFeatures(FEATURES_MAP.AiAssistantGame, {
    overrideRegion: region,
  });
};
