export const TEST_IDS = Object.freeze({
  NAV_LOGIN_BUTTON: 'navLoginButton',
  NAV_LOGIN_BUTTON_MSITE: 'navLoginButtonMsite',
  LOGIN_MODAL_ERROR: 'loginModalError',
  VOD_ITEM: 'VODItem',
  MOBILE_BLOCK: 'mobileBlock',
  LIVE_STREAM_PREVIEW: 'LiveStreamPreview',
  VOD_PLAYER: 'vodPlayer',
  GIFT_BOX: 'GiftBox',
  GIFT_BOX_EMPTY: 'GiftBoxEmpty',
  GIFT_BOX_TAB_LIST: 'GiftBoxTabList',
  GIFT_ITEM_NORMAL: 'GiftItemNormal',
  LOADING_SVG: 'LoadingSVG',
  QRCODE_POPUP: 'QrcodePopup',
  QRCODE_POPUP_CLOSE: 'QrcodePopupClose',
  VIDEO_PLAYER_NORMAL: 'VideoPlayerNormal',
  CREATE_STREAMING: 'createStreaming',
  START_STREAMING: 'startStreaming',
  STOP_STREAMING: 'stopStreaming',
  PICTURE_MODAL_CONFIRM_BUTTON: 'pictureModalConfirmButton',
});
