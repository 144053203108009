import { stringify } from 'query-string';
import apis from './shared/apis';

const API_PATH = 'bbfu';
const NEW_API_PATH = 'bbrich';

export const getWalletInfo = () => apis.getJSON(`${API_PATH}/wallet/`);
export const getWalletInfoV2 = () => apis.getJSON(`${NEW_API_PATH}/wallet/`);

export const getRemittanceList = ({
  walletTypes,
  page,
  limit,

  // optional
  snapshot,
  createTimeFrom,
  createTimeTo,
  status,
}) =>
  apis.getJSON(
    `${API_PATH}/remittances?${stringify({
      walletTypes,
      limit,
      page,
      snapshot,
      createTimeFrom,
      createTimeTo,
      status,
    })}`
  );
export const getRemittanceListV2 = ({
  walletTypes,
  page,
  limit,

  // optional
  snapshot,
  createTimeFrom,
  createTimeTo,
  status,
}) =>
  apis.getJSON(
    `${NEW_API_PATH}/remittances?${stringify({
      walletTypes,
      limit,
      page,
      snapshot,
      createTimeFrom,
      createTimeTo,
      status,
    })}`
  );

export const previewRemittance = ({
  remittanceID,
  withFee,
  fromWalletType,
  toWalletType,
}) =>
  apis.getJSON(
    `${API_PATH}/remittance/claim?${stringify({
      remittanceID,
      withFee,
      fromWalletType,
      toWalletType,
    })}`
  );
export const previewRemittanceV2 = ({
  remittanceID,
  fromWalletType,
  toWalletType,
}) =>
  apis.getJSON(
    `${NEW_API_PATH}/remittance/claim?${stringify({
      remittanceID,
      withFee: false,
      fromWalletType,
      toWalletType,
    })}`
  );

export const claimRemittance = ({ remittanceID, withFee }) =>
  apis.postJSON(`${API_PATH}/remittance/claim`, {
    remittanceID,
    withFee,
  });

export const claimRemittanceV2 = ({ remittanceID }) =>
  apis.postJSON(`${NEW_API_PATH}/remittance/claim`, {
    remittanceID,
    withFee: false, // 新版固定帶 false
  });

export const getTransactionHistory = ({ walletTypes, action, count, cursor }) =>
  apis.getJSON(
    `${API_PATH}/wallet/history?${stringify({
      walletTypes,
      action,
      count,
      cursor,
    })}`
  );
export const getTransactionHistoryV2 = ({
  walletTypes,
  action,
  count,
  cursor,
}) =>
  apis.getJSON(
    `${NEW_API_PATH}/wallet/history?${stringify({
      walletTypes,
      action,
      count,
      cursor,
    })}`
  );

export const previewTransfer = ({
  fromWalletType,
  toWalletType,
  fromAmount,
  toAmount,
}) =>
  apis.getJSON(
    `${API_PATH}/wallet/transfer?${stringify({
      fromWalletType,
      toWalletType,
      withFee: true, // transfer fee is free for now, keep this field for future use
      fromAmount,
      toAmount,
    })}`
  );

export const transfer = ({
  fromWalletType,
  toWalletType,
  fromAmount,
  toAmount,
  previewResult,
}) =>
  apis.postJSON(`${API_PATH}/wallet/transfer`, {
    fromWalletType,
    toWalletType,
    fromAmount,
    toAmount,
    withFee: true, // transfer fee is free for now, keep this field for future use
    previewResult,
  });

export const previewWithdraw = ({ walletType, amount }) =>
  apis.getJSON(
    `${API_PATH}/wallet/withdrawal?${stringify({
      walletType,
      amount,
    })}`
  );

export const previewWithdrawV2 = ({ walletType, amount, payoutAgent }) =>
  apis.getJSON(
    `${NEW_API_PATH}/wallet/withdrawal?${stringify({
      walletType,
      amount,
      payoutAgent,
      withFee: false,
    })}`
  );

export const withdraw = ({ walletType, amount, feeType, payoutAgent }) =>
  apis.postJSON(`${API_PATH}/wallet/withdrawal`, {
    wallet: {
      type: walletType,
      amount,
    },
    feeType,
    payoutAgent,
  });

export const withdrawV2 = ({ walletType, amount, payoutAgent }) =>
  apis.postJSON(`${NEW_API_PATH}/wallet/withdrawal`, {
    wallet: {
      type: walletType,
      amount,
    },
    withFee: false,
    payoutAgent,
  });
