import { API_PATH } from './constants';
import apis from '../shared/apis';

export const getPreference = userID => apis.getJSON(`${API_PATH}/${userID}/preference`);

export const putPreference = (userID, preference) => apis.putJSON(`${API_PATH}/${userID}/preference`, preference);

export const patchPreference = (userID, preference) => apis.patchJSON(`${API_PATH}/${userID}/preference`, preference);

export const deletePreference = userID => apis.deleteJSON(`${API_PATH}/${userID}/preference`);
