/*
 * Login constants
 */

// action types
export const LOGIN = 'app/LoginProvider/LOGIN';
export const LOGIN_SUCCESS = 'app/LoginProvider/LOGIN_SUCCESS';
export const LOGIN_ADDITIONAL = 'app/LoginProvider/LOGIN_ADDITIONAL';
export const UPDATE_LOGIN_STATE = 'app/LoginProvider/UPDATE_LOGIN_STATE';
export const LOGIN_FAILED = 'app/LoginProvider/LOGIN_FAILED';
export const LOGOUT = 'app/LoginProvider/LOGOUT';
export const LOGOUT_SUCCESS = 'app/LoginProvider/LOGOUT_SUCCESS';
export const SET_FOLLOWING = 'app/LoginProvider/SET_FOLLOWING';
export const SET_FOLLOWING_STATUS = 'app/LoginProvider/SET_FOLLOWING_STATUS';
export const GET_FOLLOW_STATUS = 'app/LoginProvider/GET_FOLLOW_STATUS';
export const GET_BALANCES = 'app/LoginProvider/GET_BALANCES';
export const SET_BALANCES = 'app/LoginProvider/SET_BALANCES';
export const SET_PROGRAM_INFO = 'app/LoginProvider/SET_PROGRAM_INFO';
export const LOGIN_THEN = 'app/LoginProvider/LOGIN_THEN';
export const REGISTER_THEN = 'app/LoginProvider/REGISTER_THEN';

// login status identification
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

export const ID_PASSWORD_LOGIN = 'ID_PASSWORD_LOGIN';
