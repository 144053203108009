// @flow
import React from 'react';

import styled, { keyframes } from 'styled-components';

import loadingCircle from '@/assets/loading-circle.svg';
import SVG from '@/components/SVG';

import { TEST_IDS } from '@17live/app/constants/testIDs.constants';

type Props = {
  size?: number,
};

const rotatingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingCircle = styled(SVG)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    animation: ${rotatingAnimation} 0.9s linear infinite;
    will-change: transform;
  }
`;

const LoadingSVG = ({ size = 30, ...props }: Props) => (
  <LoadingCircle
    data-testid={TEST_IDS.LOADING_SVG}
    src={loadingCircle}
    size={size}
    {...props}
  />
);

export default LoadingSVG;
