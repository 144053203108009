/*
 * @flow
 *
 * FollowButton constants
 *
 */

export const FOLLOW: string = 'app/FollowButton/FOLLOW';
export const FOLLOW_SUCCESS: string = 'app/FollowButton/FOLLOW_SUCCESS';
export const UNFOLLOW: string = 'app/FollowButton/UNFOLLOW';
export const CANCEL_FOLLOW_REQUEST: string =
  'app/FollowButton/CANCEL_FOLLOW_REQUEST';

/**
 * @deprecated
 * use packages/app/enums/Follow.enums.ts
 */
export const FOLLOW_STATUS = Object.freeze({
  NONE: 0,
  FOLLOWING: 1,
  REQUESTING: 2,
  LOADING: 3,
});
