import { defer, throwError } from 'rxjs';
import { catchError, map, mergeAll, retry } from 'rxjs/operators';

import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';
import omitDeep from '@/utils/omitDeep';

import configs from '@17live/app/constants/configs';
import { getIPRegion } from '@17live/app/containers/LanguageProvider/utils';
import Logger from '@17live/app/services/Logger';
import pxAjax from '@17live/app/services/Perimeterx/pxAjax';
import { handleError } from '@17live/app/utils/api';
import { authToken, getAuthHeader } from '@17live/app/utils/authToken';

import { POST, RETRIES } from './constants';
import responseHandler from './responseHandler';

const LOGGER_LABEL = 'LegacyApiCore';

const legacyApiCore = (action, request, headers = {}) => {
  const ipRegion = getIPRegion();
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) || {};
  const fullRequest = Object.assign(
    {},
    {
      nonce: `nonce-17live-${Date.now()}`,
      action,
    },
    {
      userID: loginInfo.userID || '',
    },
    configs.device,
    request || {}
  );

  const formData = `cypher=0_v2&data=${JSON.stringify(fullRequest)}`;

  const { region } = loginInfo;

  const regionHeader = {};
  if (ipRegion) Object.assign(regionHeader, { userIpRegion: ipRegion });
  if (region) Object.assign(regionHeader, { userSelectedRegion: region });

  return defer(async () => {
    let jwtAccessToken;

    try {
      jwtAccessToken = await authToken.getJwtToken();
    } catch (error) {
      throw throwError(error);
    }

    return pxAjax({
      url: `${configs.url}/apiGateWay`,
      headers: omitDeep({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        ...headers,
        ...getAuthHeader(jwtAccessToken),
        ...regionHeader,
      }),
      method: POST,
      body: formData,
      crossDomain: true,
    }).pipe(
      retry(RETRIES),
      map(e => e.response),
      map(responseHandler),
      catchError(error => {
        Logger.info(LOGGER_LABEL, '17App legacy API failed', {
          action,
          request,
          error,
        });

        handleError(error);
        throw error;
      })
    );
  }).pipe(mergeAll());
};

export default legacyApiCore;
