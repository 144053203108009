// @flow
import { Component } from 'react';

type Props = {
  app: ?HTMLElement,
};

class ToggleAriaHidden extends Component<Props> {
  componentDidMount() {
    const { app } = this.props;

    if (app) {
      this.originalHiddenState = app.getAttribute('aria-hidden') === 'true';
      app.setAttribute('aria-hidden', 'true');
    }
  }

  componentWillUnmount() {
    if (this.props.app) {
      this.props.app.setAttribute(
        'aria-hidden',
        String(this.originalHiddenState)
      );
    }
  }

  originalHiddenState: boolean = false;

  render() {
    return null;
  }
}

export default ToggleAriaHidden;
