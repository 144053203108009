import styled from 'styled-components';

import {
  background,
  BackgroundProps,
  BackgroundSizeProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  fontSize,
  FontSizeProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

export type BoxProps = BorderProps &
  ColorProps &
  SpaceProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  TextAlignProps &
  FontSizeProps &
  FlexboxProps &
  BackgroundProps &
  BackgroundSizeProps;

const Box = styled.div<BoxProps>`
  ${fontSize}
  ${textAlign}
  ${border}
  ${color}
  ${space}
  ${layout}
  ${position}
  ${shadow}
  ${flexbox}
  ${background}
`;

export default Box;

export const Flex = styled(Box)<{ gap?: number }>`
  display: flex;
  gap: ${p => p.gap || 0}px;
`;

export const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;
