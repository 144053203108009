// @flow
const Z_INDEXES: string[][] = [
  /**
   * z-index: 0
   */
  ['PLACEHOLDER'],

  /**
   * z-index: 1
   */
  ['PLAYER', 'PLAYER_OVERLAY', 'ASIDE_LIVE', 'ARMY_RANK_TAB'],

  /**
   * Animation layer
   */
  ['LIVE_STREAM_BLOCK'],
  ['AVATAR'],
  ['LIKE_BUTTON_IMAGE'],

  ['LOADING'],
  ['EVENT_OVERLAY'],

  /**
   * Pixi layer
   */
  ['PIXI_CANVAS'],

  /**
   * Business layer
   */
  ['CONTROL', 'LIKE_BUTTON', 'MISSION_STICKER', 'SHARE_BUTTON'],
  ['SNACK'],
  ['GIFT'],
  ['POLL_DIALOG', 'RED_ENVELOPE', 'RISING_BOX'],
  ['VIDEO_BOX'],
  ['ENTER_BUTTON', 'PAUSE_BUTTON', 'CLOSE_BUTTON'],
  ['LANDING_PAGE_STICKER'],
  ['NOTIFICATION', 'FIXED_USER_INFO'],
  ['HOME_PAGE_AVATAR'],

  /**
   * Nav
   */
  ['NAV'],

  /**
   * Popup
   */
  ['BAGGAGE'],
  ['DOWNLOAD_APP', 'TUTORIAL_HINT', 'STREAMER_GROWTH'],
  ['SHARE_POPUP', 'PIP', 'CLIP_VIDEO'],
  ['CLIP_OVERLAY'],
  ['TOOLTIP'],

  /**
   * Modal
   */
  ['MODAL'],

  /**
   * System notice
   */
  ['SNACKBAR', 'SNACKBAR_PORTAL', 'RISING_STAR_NOTIFY', 'TOAST', 'SSM_MASK'],
];

export const ELEMENT_ORDERS: { [key: string]: number } = Z_INDEXES.reduce(
  (current, elements, index) =>
    elements.reduce(
      (orders, element) => ({
        ...orders,
        [element]: index,
      }),
      current
    ),
  {}
);
