import { stringify } from 'query-string';
import { map } from 'rxjs/operators';

import { setTrackAPIHeaders } from '@17live/app/services/analytics';

import apis from '../shared/apis';
import { API_PATH } from './constants';

// https://17media.atlassian.net/wiki/spaces/ST/pages/836010281/Follow+API#Unfollow-a-user

export const FOLLOW_API_PATH = 'follow/users';

export const followUser = ({ userID, traceID }) =>
  apis.postJSON(
    `${FOLLOW_API_PATH}/${userID}`,
    {},
    setTrackAPIHeaders({ 'trace-id': traceID })
  );

export const unfollowUser = ({ userID, traceID }) =>
  apis.deleteJSON(
    `${FOLLOW_API_PATH}/${userID}`,
    {},
    setTrackAPIHeaders({ 'trace-id': traceID })
  );

export const cancelFollowRequest = (userID, traceID) =>
  apis.deleteJSON(
    `${FOLLOW_API_PATH}/${userID}/followRequests`,
    {},
    setTrackAPIHeaders({ 'trace-id': traceID })
  );

export const getFollowStatus = (userID, targetUserIDs) =>
  apis.postJSON(`${API_PATH}/${userID}/getFollowStatus`, {
    targetUserIDs,
  });

export const getUserFollowing = ({ userID, count, cursor }) =>
  apis
    .getJSON(`follow/users/${userID}/followees?${stringify({ count, cursor })}`)
    .pipe(
      // unify the data for createListContainer
      map(response => ({
        list: response.followees,
        cursor: response.cursor,
      }))
    );
