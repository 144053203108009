import apis from '@/services/17App/shared/apis';

import { MessageProviderFeatureTypes } from '@17live/app/services/Message/Message.enums';

const API_PATH = 'liveStreams';

export const searchLiveStreamers = query =>
  apis.getJSON(`${API_PATH}/search?query=${query}`);

/**
 * @name getMessageProvider
 * @param featureType
 */
export const getMessageProvider = (featureType: MessageProviderFeatureTypes) =>
  apis.getJSON(`${API_PATH}/messageProvider?feat=${featureType}`);
