// Branding color
export const BRANDING = '#ff0000';

// Brand highlight colors
export const BD_HIGHLIGHT_PINK = '#ff15d3';
export const BD_LINK = '#f5487d';
export const BD_LIGHT_PINK = '#f6696c';
export const BD_ORANGE = '#f69355';

// Background colors
export const BG_WHITE = '#ffffff';
export const BG_LIGHT_WHITE = '#fdfdfd';
export const BG_DARK_WHITE = '#fcfcfc';
export const BG_DARKER_WHITE = '#fafafa';
export const BG_DEFAULT = '#f8f8f8';
export const BG_MOBILE = '#f7f7f7';
export const BG_PLACEHOLDER = '#f7f7f7';
export const BG_DARKEN_WHITE = '#f7f7f7';
export const BG_DARK_PLACEHOLDER = '#eeeeee';
export const BG_DARKEN_PLACEHOLDER = '#e0e0e0';
export const BG_LIGHT_GRAY = '#e6e6e6';
export const BG_SECTION_WHITE = '#f5f5f5';
export const BD_DARKEST_BLACK = '#000000';
export const BG_BLACK = '#28232d';
export const BG_OAUTH_HOVER = 'rgba(255, 255, 255, 0.08)';
export const BG_OAUTH_ACTIVE = 'rgba(0, 0, 0, 0.2)';
export const BG_ARMY_SUB = '#f7faf1';
export const BG_CONTROL_OVERLAY =
  'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));';
export const BG_MODAL_OVERLAY = 'rgba(40, 35, 45, 0.4)';
export const BG_TV_WALL_THUMBNAIL = '#605c64';

// text color
export const BD_WHITE = '#ffffff';
export const BD_DARKEN_WHITE = '#ebebeb';
export const BD_BLACK = '#28232d';
export const BD_BLACK_HOVER = '#322e37';
export const BD_BLACK_ACTIVE = '#201c24';
export const BD_LIGHT_BLACK = '#6f6777';
export const BD_LIGHTEN_BLACK = '#a9a4b0';
export const BD_DARK_GRAY = '#999999';
export const BD_ARMY_TEXT = '#838b6f';
export const BD_ARMY_TITLE = '#384330';

// Others
export const BD_BLUE = '#7483d6';
export const BD_LIGHT_BLUE = '#8a9bff';
export const BD_GRAY = '#d2d2d7';
export const BD_DARK_ORANGE = '#ff5900';
export const BD_BORDER_WHITE = '#f2f2f2';
export const BD_BORDER_DARK_WHITE = '#ededed';
export const BD_BORDER_DARKEN_WHITE = '#e0e0e0';
export const BD_BORDER_GRAY = '#d9d9d9';
export const BD_BRIGHT_GRAY = '#d2d2d7';
export const BD_LIGHT_GRAY = '#e6e6e6';
export const BD_DIM_GRAY = '#cccccc';
export const BD_DANGER = '#ff6569';
export const BD_DANGER_PINK = '#EB0075';
export const BD_DANGER_LIGHT_PINK = '#F14B9E';
export const BD_WARNING = '#f69355';
export const BD_URGENT = '#f6696c';
export const BD_FACEBOOK = '#5579c2';
export const BD_TWITTER = '#1da1f2';
export const BD_LINE = '#00b900';
export const BD_GOOGLE = '#4285f4';
export const BD_APPLE = '#0b0b0a';
export const BD_OAUTH_DISABLED = 'rgba(255, 255, 255, 0.5)';
export const BD_ARMY_BORDER = '#e6eed3';
export const BD_ARMY_BORDER_SUB = '#c9d6ab';
export const BD_ARMY_ACTIVE = '#819259';
export const BD_PURCHASE_CONFIRM_GRAY = '#9d98a3';
export const BD_PURCHASE_CONFIRM_DARKEN_GRAY = '#8e8a94';
export const BD_INFO_DARKER = '#0028AB';
export const LEVEL_BORDER_COLOR = BD_LIGHT_GRAY;

// New Design system color
// TODO: export js file from figma
// @see https://www.figma.com/file/XQwmxqt2aH9fmWUsUA4ebJ/01---Brand-%26-Colors?node-id=1%3A3
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const NEUTRAL_100 = '#F3F3F3';
export const NEUTRAL_200 = '#E5E5E5';
export const NEUTRAL_300 = '#D1D1D1';
export const NEUTRAL_500 = '#A3A3A3';
export const NEUTRAL_600 = '#8A8A8A';
export const NEUTRAL_700 = '#707070';
export const NEUTRAL_800 = '#525252';
export const NEUTRAL_900 = '#333333';
export const RED_900 = '#FF0000';
export const GREEN_600 = '#50C200';
export const BLUE_500 = '#0E4DFF';
export const PINK_500 = '#EB0075';

/**
 * TODO: these color ref from app color, waiting to web color complate to change figma link
 * @see https://www.figma.com/file/uNnZyFtVSlUPdYmapJpGUr/00---Design-Tokens?node-id=2%3A521
 */
export const BUTTON_BACKGROUND_PRIMARY = '#333333';
export const BUTTON_BACKGROUND_PRIMARY_PRESSED = '#4B4B4B';
export const BUTTON_BACKGROUND_PRIMARY_DISABLED = '#C2C2C2';
export const BUTTON_BACKGROUND_WHITE_PRESSED = '#E0E0E0';
export const BUTTON_TEXT_PRIMARY = '#333333';
export const BUTTON_TEXT_PRIMARY_DISABLED = '#C2C2C2';
export const BUTTON_TEXT_WHITE = '#FFFFFF';
export const BUTTON_STROKE_SECONDARY = '#E6E6E6';
export const BUTTON_STROKE_SECONDARY_DISABLED = '#F8F8F8';
