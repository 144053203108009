/*
 *
 * UserProvider reducer
 *
 */

import { fromJS } from 'immutable';
import { STATUS } from '@/constants/services';
import { GET_USER_INFO, RECEIVED_USER_INFO } from './constants';

const initialState = fromJS({});

const userProviderReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_USER_INFO: {
      return state.setIn(
        [
          // userID
          payload,
          'status',
        ],
        STATUS.LOADING
      );
    }
    case RECEIVED_USER_INFO: {
      const usersInfo = [].concat(payload);

      return state.mergeDeep(
        usersInfo.reduce(
          (obj, cur) => ({
            ...obj,
            [cur.userID]: {
              ...cur,
              status: STATUS.COMPLETED,
            },
          }),
          {}
        )
      );
    }

    default:
      return state;
  }
};

export default userProviderReducer;
