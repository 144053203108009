export const medias = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
};

const breakpoints = [
  `${medias.xs}`,
  `${medias.sm}px`,
  `${medias.md}px`,
  `${medias.lg}px`,
  `${medias.xl}px`,
  `${medias.xxl}px`,
];

breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.xxl = breakpoints[5];

export default breakpoints;
