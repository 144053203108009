import PubNub from 'pubnub';
import { Observable } from 'rxjs';

import { KEY_DEV_PUBNUB_SERVER, SessionStorage } from '@/services/Storage';

import {
  GLOBAL_ANNOUNCEMENT_CHANNEL_ID,
  GLOBAL_CHANNEL_ID,
  GLOBAL_LIVE_PROGRAM_CHANNEL_ID,
} from '@17live/app/services/Message/Message.constants';
import {
  decodeCompressedData,
  getSubscribeKeyByEnv,
} from '@17live/app/services/Message/Message.utils';
import {
  STAGE_SUBSCRIBE_KEY,
  SUBSCRIBE_KEY,
  UAT_SUBSCRIBE_KEY,
} from '@17live/app/services/PubNub/PubNub.constants';

const { key = STAGE_SUBSCRIBE_KEY } =
  SessionStorage.getItem(KEY_DEV_PUBNUB_SERVER) || {};
const subscribeKey = getSubscribeKeyByEnv(
  'PubNub',
  key,
  SUBSCRIBE_KEY,
  STAGE_SUBSCRIBE_KEY,
  UAT_SUBSCRIBE_KEY
);

const pubNubClient = new PubNub({
  subscribeKey,
  suppressLeaveEvents: true,
  autoNetworkDetection: true,
  listenToBrowserNetworkEvents: false,
});

export const unsubscribeChatRoom = roomID => {
  pubNubClient.unsubscribe({
    channels: [`media17.${roomID}`],
  });

  return roomID;
};

export const subscribeChatRoom = roomID =>
  new Observable(observer => {
    pubNubClient.addListener({
      message: ({ message }) => {
        observer.next(decodeCompressedData(message));
      },
    });
    pubNubClient.subscribe({
      channels: [`media17.${roomID}`],
    });

    return () => unsubscribeChatRoom(roomID);
  });

export const unsubscribeGlobalLiveProgram = () => {
  pubNubClient.unsubscribe({
    channels: [GLOBAL_LIVE_PROGRAM_CHANNEL_ID],
  });
};

export const subscribeGlobalLiveProgram = () =>
  new Observable(observer => {
    pubNubClient.addListener({
      message: ({ message }) => {
        observer.next(decodeCompressedData(message));
      },
    });

    pubNubClient.subscribe({
      channels: [GLOBAL_LIVE_PROGRAM_CHANNEL_ID],
    });

    return () => unsubscribeGlobalLiveProgram();
  });

export const unsubscribeGlobalAnnouncementChannel = () => {
  pubNubClient.unsubscribe({
    channels: [GLOBAL_ANNOUNCEMENT_CHANNEL_ID],
  });
};

export const subscribeGlobalAnnouncementChannel = () =>
  new Observable(observer => {
    pubNubClient.addListener({
      message: ({ message }) => {
        // Only one type is available for now
        if (message.type === 44) {
          observer.next();
        }
      },
      status: status => {
        if (status.category === 'PNConnectedCategory') {
          // start with initial request of getAnnouncement
          observer.next();
        }
      },
    });

    pubNubClient.subscribe({
      channels: [GLOBAL_ANNOUNCEMENT_CHANNEL_ID],
    });

    return () => unsubscribeGlobalAnnouncementChannel();
  });

export const unsubscribeGlobalChannel = () => {
  pubNubClient.unsubscribe({
    channels: [GLOBAL_CHANNEL_ID],
  });
};

export const subscribeGlobalChannel = () =>
  new Observable(observer => {
    pubNubClient.addListener({
      message: ({ message }) => {
        observer.next(decodeCompressedData(message));
      },
    });

    pubNubClient.subscribe({
      channels: [GLOBAL_CHANNEL_ID],
    });

    return () => unsubscribeGlobalChannel();
  });
