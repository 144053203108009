import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import { SHIELD_API_HOST } from '@17live/app/constants/hosts';

const API_PATH = 'announcement';

export const getAnnouncement = (language = 'EN') =>
  ajax({
    url: `https://${SHIELD_API_HOST}/${API_PATH}`,
    headers: {
      language,
    },
    crossDomain: true,
  }).pipe(
    /**
     * @see AnnouncementResponse @17live/app/types/Announcement
     */
    map(e => e.response),
    // ignore error
    catchError(() => of(null))
  );
