// @flow

import bowser from '@/utils/bowser';
import { medias } from '@/constants/breakpoints';

/**
 *
 * Because iPad >=iOS13 safari userAgent is Macintosh, so we use maxTouchPoints to judgment.
 * refs https://developer.apple.com/forums/thread/119186
 *
 */
export const isiPad = (): boolean =>
  Boolean(
    navigator.userAgent.match(/(iPad)/) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );

export const isMobile = (): boolean =>
  Boolean(bowser.mobile) || window.screen.width < medias.sm;

export const isTablet = (): boolean => Boolean(bowser.tablet) || isiPad();

export const isHandheld = (): boolean => Boolean(bowser.mobile) || isTablet();

export const isDesktop = (): boolean => !isHandheld();

export const isMacOS = () => Boolean(bowser.mac);

export const isIOS = (): boolean => Boolean(bowser.ios);

export const isAndroid = (): boolean => Boolean(bowser.android);

export const isEdge = (): boolean => Boolean(bowser.msedge);

export const isSafari = () => Boolean(bowser.safari);

export const isSupported = (): boolean =>
  // IEs are not supported
  !bowser.msie &&
  // The following browsers are not supported by flv.js
  // Safari < 10.1
  !(bowser.safari && Number(bowser.version) < 10.1) &&
  // Edge < 15.15048
  !(bowser.msedge && Number(bowser.version) < 15.15048) &&
  // Firefox < 42
  !(bowser.firefox && Number(bowser.version) < 42) &&
  // Chrome < 43
  !(bowser.chrome && Number(bowser.version) < 43) &&
  // FLV.js livestream requires MSE
  !!window.MediaSource;
