// @flow
import { Component } from 'react';

let scrollbarSize;

function getScrollbarSize(): number {
  if (typeof scrollbarSize !== 'undefined') {
    return scrollbarSize;
  }

  const doc = document.documentElement;
  const dummyScroller = document.createElement('div');
  dummyScroller.setAttribute(
    'style',
    'width:99px;height:99px;position:absolute;top:-9999px;overflow:scroll;'
  );

  if (doc) {
    doc.appendChild(dummyScroller);
    scrollbarSize = dummyScroller.offsetWidth - dummyScroller.clientWidth;
    doc.removeChild(dummyScroller);
  }

  return scrollbarSize || 0;
}

function hasScrollbar(): boolean {
  return (
    !!document.documentElement &&
    document.documentElement.scrollHeight > window.innerHeight
  );
}

class DisableScroll extends Component<{}> {
  componentDidMount() {
    const doc = document.documentElement;

    if (!doc) {
      return;
    }

    if (doc.dataset.disableScroll === 'true') {
      return;
    }

    this.scrollTop = window.pageYOffset;
    this.scrollLeft = window.pageXOffset;

    doc.dataset.disableScroll = 'true';
    if (hasScrollbar()) {
      doc.style.width = `calc(100% - ${getScrollbarSize()}px)`;
    } else {
      doc.style.width = '100%';
    }
    doc.style.position = 'fixed';
    doc.style.top = `${-this.scrollTop}px`;
    doc.style.left = `${-this.scrollLeft}px`;
    doc.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    const doc = document.documentElement;

    if (!doc) {
      return;
    }

    // Use the attribute of role to query dialog.
    const dialogs = doc.querySelectorAll(`[role="dialog"]`);
    // If there is more than one dialog on the screen.
    // Then we don't disable the scroll bar in the document.
    // Otherwise, when one of the dialogs is closed,
    // the scroll bar may appear on the screen,
    // even if there are still other dialogs on the screen.
    if (dialogs?.length > 1) {
      return;
    }

    doc.dataset.disableScroll = 'false';
    doc.style.width = '';
    doc.style.position = '';
    doc.style.top = '';
    doc.style.left = '';
    doc.style.overflow = '';

    window.scrollTo(0, this.scrollTop);
  }

  scrollTop: number = 0;
  scrollLeft: number = 0;

  render() {
    return null;
  }
}

export default DisableScroll;
