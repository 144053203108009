import { stringify } from 'query-string';
import { map } from 'rxjs/operators';

import apis from './shared/apis';
import { DEFAULT_REGION } from './shared/constants';

const API_PATH = 'leaderboards';

export const LEADERBOARD_TYPES = Object.freeze({
  POINT_FROM: 1,
  POINT_TO: 2,
  LIKE: 5,
  ARMY: 15,
  /**
   * Because clearing old data is difficult, so we change avatar charm type from 52 to 53
   * @see https://17media.slack.com/archives/C049ENRG796/p1668738210664039
   */
  AVATAR_CHARM: 53,
});

export const LEADERBOARD_PERIODS = Object.freeze({
  ALL: 1,
  DAY: 2,
  MONTH: 3,
  WEEK: 4,
});

export const getLeaderboard = ({
  type = LEADERBOARD_TYPES.POINT_TO,
  region = DEFAULT_REGION,
  period = LEADERBOARD_PERIODS.MONTH,
  // 0 means this month, -1 means last month, -3 means last 3 month, etc.
  dateShift = 0,
  count = 20,
  cursor,
  table = 'platform',
} = {}) =>
  apis.getJSON(
    `${API_PATH}?${stringify({
      type,
      period,
      region,
      dateShift,
      count,
      cursor,
      // get all platform instead of individual user info
      table,
      // get roomID and isLive info
      getLiveStreamID: 1,
    })}`
  );

export const getLeaderboardList = options =>
  getLeaderboard(options).pipe(
    map(({ scoreRank, cursor }) => ({
      list: scoreRank ?? [],
      cursor,
    }))
  );
