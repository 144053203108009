// @flow
import bowser from 'bowser';

const value = val => val || 'Unknown';

export const getEnvInfo = () => {
  const ua = navigator.userAgent;
  const { name, version, osname, osversion } = bowser._detect(ua); // eslint-disable-line no-underscore-dangle
  return {
    version: process.env.VERSION || 'development',
    locale: window.location.pathname.split('/')[1],
    path: window.location.origin,
    browser: `${value(name)}-${value(version)}`,
    os: `${value(osname)}-${value(osversion)}`,
    ua: value(ua),
    environment: process.env.DEPLOY_ENV || 'development',
    project: process.env.PROJECT || '17live',
  };
};
