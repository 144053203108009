// @flow
import { parse } from 'query-string';

import configs from '@17live/app/constants/configs';

import { DIMENSIONS, GA4_TAG_ID_GROUPS, HIT_TYPES } from './constants';
import loadScript from './loadScript';
import pageView from './pageView';
import { getContentGroup } from './pageView/contentGroups';

export const GA4_TAG_ID: string =
  // eslint-disable-next-line no-nested-ternary
  process.env.DEPLOY_ENV === 'production'
    ? GA4_TAG_ID_GROUPS.PROD
    : process.env.DEPLOY_ENV === 'stage'
    ? GA4_TAG_ID_GROUPS.STAGE
    : GA4_TAG_ID_GROUPS.UAT;

// load ga
export const loadGA = async () => {
  // load google analytics
  loadScript(GA4_TAG_ID);

  // istanbul ignore next
  /* eslint-disable */
  window.gtag = window.gtag ||
  function() {
    (window.dataLayer = window.dataLayer || []).push(arguments);
  };

  window.gtag('js', new Date());
  window.gtag('config', GA4_TAG_ID);
  /* eslint-enable */

  // parse referer override
  const referer = parse(location.search).referer;
  if (referer) {
    window.gtag('set', {
      referrer: referer,
    });
    window.ga('set', 'referrer', referer);
  }

  /**
   * 我們使用 GA clientID 作為 API 的 deviceID，他是用來識別用戶的參數，會帶在註冊等 API header 上
   * @see https://17media.atlassian.net/browse/APP-61319
   */
  window.gtag('get', GA4_TAG_ID, 'client_id', clientID => {
    configs.device.deviceID = clientID;
  });

  // lazily load plugins
  await import(
    /* webpackChunkName: "GA-plugins" */
    /* webpackPrefetch: true */
    './plugins'
  ).then(plugins => plugins.default());

  // load plugins
  window.ga('require', 'eventTracker', {
    attributePrefix: 'data-ga-',
  });
  window.ga('require', 'outboundLinkTracker');
  window.ga('require', 'urlChangeTracker', {
    shouldTrackUrlChange: function shouldTrackUrlChange(newPath, oldPath) {
      const shouldAutoTrackUrlChange = pageView.shouldAutoTrackUrlChange(
        newPath,
        oldPath
      );

      if (shouldAutoTrackUrlChange) {
        pageView.setCurPath(newPath);
      }

      /**
       * always update the current path in urlChangeTracker
       * because some features would disable auto track
       * but it still send page view
       */
      this.path = newPath;

      return shouldAutoTrackUrlChange;
    },
    hitFilter: model => {
      const contentGroup = getContentGroup();

      model.set(contentGroup, null, true);
    },
  });

  // load ECommerceGA
  window.ga('require', 'ec');
};

export const identifyUser = (userID: ?string = null) => {
  if (window.gtag) {
    window.gtag('set', {
      user_id: userID,
    });
    window.gtag('set', {
      [DIMENSIONS.USER_TYPE]: !userID ? 'guest' : 'member',
    });
  }

  if (window.ga) {
    window.ga('set', 'userId', userID);
    window.ga('set', DIMENSIONS.USER_TYPE, !userID ? 'guest' : 'member');
  }
};

export const recordUserVolume = (volume: number = 0) => {
  if (window.gtag) {
    window.gtag('set', {
      parameters: {
        [DIMENSIONS.VOLUME]: volume > 0 ? 'enable' : 'disable',
      },
    });
  }

  if (window.ga) {
    window.ga('set', DIMENSIONS.VOLUME, volume > 0 ? 'enable' : 'disable');
  }
};

export const trackEvent = (
  eventCategory: string | {} = '',
  eventAction: string = '',
  eventLabel: string = '',
  args: {} = {}
) => {
  if (window.gtag) {
    window.gtag(HIT_TYPES.EVENT, eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      ...args,
    });
  }

  if (window.ga) {
    const eventPayload = {
      eventCategory,
      eventAction,
      eventLabel,
      ...args,
    };

    window.ga('send', {
      hitType: HIT_TYPES.EVENT,
      ...eventPayload,
    });
  }
};
