/*
 * ChatRoom Messages
 *
 * This contains all the text for the ChatRoom component.
 */
import defineMessages from 'define-messages';

export const registerToChat = defineMessages({
  id: 'web_liveroom_optimization_comment',
  defaultMessage: 'Register now to start chatting',
});

export const sendChat = defineMessages({
  id: 'livestream_send_message',
  defaultMessage: 'Send',
});

export default defineMessages({
  commentWaitDuration: {
    id: 'follow_only_chat_mode_wait_duration',
    defaultMessage: 'Waiting for {minutes} minute(s) to chat.',
  },
  marqueeBarrageHint: {
    id: 'mlevel_marquee_barrage_hint',
    defaultMessage: 'Trial Price {point}  Coins per marquee',
  },
  barrageMarquee0: {
    id: 'barrage_marquee_0',
    defaultMessage: 'Marquee',
  },
  barrageInfiniteCount: {
    id: 'barrage_infinite_count',
    defaultMessage: 'Unlimited free announcements',
  },
  barrageFreeCount: {
    id: 'barrage_free_count',
    defaultMessage: 'You have {count} free announcements',
  },
  barrageSendPoint: {
    id: 'barrage_send_point',
    defaultMessage: 'Trial Price {point} Coins per announcement',
  },
  tabName: {
    id: 'app.containers.AsideLive.chat',
    defaultMessage: 'Chat',
  },
  blocked: {
    id: 'app.containers.ChatRoom.blocked',
    defaultMessage: 'You have been blocked',
  },
  vipEnterMessage: {
    id: 'app.containers.ChatRoom.vipEnterMessage',
    defaultMessage: '{name} is here!',
  },
  guardianEnterMessage: {
    id: 'app.containers.ChatRoom.guardianEnterMessage',
    defaultMessage: '{name} is here!',
  },
  scrollToReadMore: {
    id: 'app.containers.ChatRoom.scrollToReadMore',
    defaultMessage: '... more messages',
  },
  chatPlaceholder: {
    id: 'app.containers.ChatRoom.chatPlaceholder',
    defaultMessage: 'Leave messages for steamer...',
  },
  chatPlaceholderOffline: {
    id: 'app.containers.ChatRoom.chatPlaceholderOffline',
    defaultMessage: 'streamer is currently offline...',
  },
  emptyPlaceholder: {
    id: 'app.containers.ChatRoom.emptyPlaceholder',
    defaultMessage: 'Go chat with streamer',
  },
  emptyPlaceholderOffline: {
    id: 'app.containers.ChatRoom.emptyPlaceholderOffline',
    defaultMessage: 'Streamer is currently offline',
  },
  likeMessage: {
    id: 'app.containers.ChatRoom.likeMessage',
    defaultMessage: 'liked streamer',
  },
  followMessage: {
    id: 'app.containers.ChatRoom.followMessage',
    defaultMessage: 'followed streamer',
  },
  shareFBMessage: {
    id: 'app.containers.ChatRoom.shareFBMessage',
    defaultMessage: 'shared this live stream on Facebook',
  },
  share17Message: {
    id: 'app.containers.ChatRoom.share17Message',
    defaultMessage: 'shared this live stream on LIVIT ',
  },
  sendGift: {
    id: 'app.containers.ChatRoom.sendGift',
    defaultMessage: 'sent {giftName} to streamer',
  },
  sendLuckyBag: {
    id: 'app.containers.ChatRoom.sendLuckyBag',
    defaultMessage: 'opened {luckyBagName}, streamer got {giftName}',
  },
  sendSlotWin: {
    id: 'app.containers.ChatRoom.sendSlotsWin',
    defaultMessage: 'hit the jackpot! Congratulations!',
  },
  sendSlotMiss: {
    id: 'app.containers.ChatRoom.sendSlotsMiss',
    defaultMessage: 'missed the jackpot, next time will be better.',
  },
  sendTreasureChestWin: {
    id: 'app.containers.ChatRoom.sendTreasureChestWin',
    defaultMessage:
      'opened the {giftName} and hit the jackpot! Congratulations!',
  },
  sendTreasureChestLose: {
    id: 'app.containers.ChatRoom.sendTreasureChestLose',
    defaultMessage:
      'opened the {giftName}, but missed the jackpot. Next time will be better.',
  },
  sendPoke: {
    id: 'app.containers.ChatRoom.sendPoke',
    defaultMessage: 'Streamer poked @{receiverDisplayName}',
  },
  sendPokeBack: {
    id: 'app.containers.ChatRoom.sendPokeBack',
    defaultMessage: 'Poke back',
  },
  checkingLevelMessageBasic: {
    id: 'app.containers.ChatRoom.checkingLevelMessageBasic',
    defaultMessage: "Wow! You've checked in {num} times, nice!",
  },
  checkingLevelMessageKeepGoing: {
    id: 'app.containers.ChatRoom.checkingLevelMessageKeepGoing',
    defaultMessage:
      "Wow! You've checked in {num} times, nice! Keep it up and go for {nextNum}",
  },
  goToLink: {
    id: 'app.containers.ChatRoom.goToLink',
    defaultMessage: 'Go check it out',
  },
  globalLuckyBagMessage: {
    id: 'app.containers.ChatRoom.globalLuckyBagMessage',
    defaultMessage:
      'Congratulations {senderDisplayName} on winning the largest award {giftName}, and gifted to {receiverDisplayName}!!',
  },
  globalSlotMessage: {
    id: 'app.containers.ChatRoom.globalSlotMessage',
    defaultMessage:
      'Congratulations on {senderDisplayName} hitting jackpot, won {points} coins.',
  },
  globalTreasureChestMessage: {
    id: 'app.containers.ChatRoom.globalTreasureChestMessage',
    defaultMessage:
      'Congratulations on {senderDisplayName} hitting jackpot, won {points} coins.',
  },
  snackLiveStreamDescription: {
    id: 'snack_livestream_description',
    defaultMessage: 'Send snack to support streamer for free!',
  },
  snackLiveStreamDescriptionDone: {
    id: 'snack_livestream_description_done',
    defaultMessage: 'Daily free snack are all sent out',
  },
  barrageStreamroomLengthRemider: {
    id: 'barrage_streamroom_length_reminder',
    defaultMessage: 'Tip: better effect if within 20 characters.',
  },
  withoutBabycoinsBarrage: {
    id: 'without_babycoins_barrage',
    defaultMessage: 'Not enough coins',
  },
  axeTreasureboxFailMsg: {
    id: 'axe_treasurebox_fail_msg',
    defaultMessage:
      'You dropped a {giftName} in the river, but Lady Luck did not take pity on you...',
  },
  axeTreasureboxWinMsg: {
    id: 'axe_treasurebox_win_msg',
    defaultMessage:
      'You dropped a {giftName} in the river. Fortune has returned it to you along with many treasures!',
  },
});
