import apis from './shared/apis';

const API_PATH = 'config';

export const getConfig = ({ forceRegion } = {}) =>
  apis.getJSON(
    `${API_PATH}`,
    {},
    {
      'force-region': forceRegion,
    }
  );

export const getPersonalConfig = () => apis.getJSON(`${API_PATH}/personal`);
