import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import * as actions from '@17live/app/containers/LoginProvider/actions';
import {
  makeSelectContractInfo,
  makeSelectIsLoggedIn,
  makeSelectLogin,
  makeSelectLoginRoomID,
  makeSelectLoginUserID,
  makeSelectProgramInfo,
  makeSelectStreamMode,
} from '@17live/app/containers/LoginProvider/selectors';

const selectors = createStructuredSelector({
  contractInfo: makeSelectContractInfo(),
  loginInfo: makeSelectLogin(),
  programInfo: makeSelectProgramInfo(),
  streamMode: makeSelectStreamMode(),
  isLoggedIn: makeSelectIsLoggedIn(),
  loginUserID: makeSelectLoginUserID(),
  roomID: makeSelectLoginRoomID(),
});

export const useLogin = ({ userID }: { userID: string } = { userID: '' }) => {
  const selector = useSelector(selectors);
  const dispatch = useDispatch();

  return {
    ...selector,
    isContract: !!selector.contractInfo,
    isLoginUser: userID === selector.loginUserID,
    loginThen: useCallback(payload => dispatch(actions.loginThen(payload)), [
      dispatch,
    ]),
  };
};

export default useLogin;
