import { Epic, ofType } from 'redux-observable';

import { push } from 'connected-react-router';
import { concat } from 'rxjs';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { ERROR_ACTION } from '@/utils/errorAction';

import { logout } from '@17live/app/containers/LoginProvider/actions';
import { LOGOUT_SUCCESS } from '@17live/app/containers/LoginProvider/constants';
import { openModal } from '@17live/app/containers/Modal/actions';
import { MODAL_LOGIN } from '@17live/app/containers/Modal/constants';
import { subscribeGlobalAnnouncementChannel } from '@17live/app/containers/StatusAnnouncement/StatusAnnouncement.actions';
import { getMessageProvider } from '@17live/app/services/17App/liveStreams';
import Message from '@17live/app/services/Message/Message';
import { MessageProviderFeatureTypes } from '@17live/app/services/Message/Message.enums';
import { getMessengerConfig } from '@17live/app/services/Message/Message.utils';

import { CHANGE_ROUTER, PUSH_ROUTER } from './constants';

export const TOKEN_INVALID = '#login-modal';

export const tokenInvalidEpic: Epic = action$ =>
  concat(
    action$.pipe(
      ofType(ERROR_ACTION),
      filter(({ payload: { error } }) => error?.status === 401),
      mapTo(logout(TOKEN_INVALID))
    ),

    action$.pipe(
      ofType(LOGOUT_SUCCESS),
      filter(({ payload }) => payload === TOKEN_INVALID),
      mapTo(openModal({ name: MODAL_LOGIN }))
    )
  );

export const pushRouterEpic: Epic = action$ =>
  action$.pipe(
    ofType(PUSH_ROUTER),
    map(({ payload }) => push(payload))
  );

/**
 * @name changeRouterEpic
 * @description This epic trigger by react-route history change from MainRoute history.listen
 * We need do something when this moment
 * 1. Get dynamic message provider and re-subscribe globalAnnouncementChannel
 * @see https://17media.atlassian.net/l/cp/e1Ff1Kne
 */
export const changeRouterEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_ROUTER),
    switchMap(() => getMessengerConfig(state$)),
    filter(
      ({ enableDynamicGlobalMessageProvider }) =>
        enableDynamicGlobalMessageProvider
    ),
    switchMap(() => {
      return getMessageProvider(
        MessageProviderFeatureTypes.MESSAGE_PROVIDER_DYNAMIC
      ).pipe(
        filter(
          ({ globalMessageProvider }) =>
            globalMessageProvider !== Message.globalMessageProvider
        ),
        tap(({ globalMessageProvider }) =>
          Message.setGlobalMessageProvider(globalMessageProvider)
        ),
        map(() => subscribeGlobalAnnouncementChannel())
      );
    })
  );

export default [tokenInvalidEpic, pushRouterEpic, changeRouterEpic];
