// @flow
export type HandleIntersect = (entries: IntersectionObserverEntry[]) => void;

export type Options = {
  threshold?: number,
};

const DEFAULT_THRESHOLD: number = 0.5;

export const DEFAULT_OPTIONS: Options = {
  threshold: DEFAULT_THRESHOLD,
};

export const getIsIntersecting = (
  entries: IntersectionObserverEntry[],
  threshold?: number = DEFAULT_THRESHOLD,
) =>
  // if any one of the entries is intersecting
  // edge will have a bug when intersectionRatio is 0 but isIntersecting is true
  entries.some(
    entry => entry.isIntersecting && entry.intersectionRatio > threshold
  );

const createIntersectionObserver = async (
  handleIntersect: HandleIntersect,
  options?: Options = DEFAULT_OPTIONS
) => {
  // When polyfill.io failed to load the polyfill, we manually inject it
  if (!window.IntersectionObserver) {
    await import(/* webpackChunkName: "IntersectionObserverPolyfill" */ 'intersection-observer');
  }

  return new window.IntersectionObserver(handleIntersect, options);
};

export default createIntersectionObserver;
