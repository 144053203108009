import apis from './shared/apis';
import { GET } from './shared/constants';

const API_PATH = 'votingEvent';

export const getVoteConfig = configURL =>
  apis.event({
    endpoint: configURL,
    method: GET,
  });

export const getVoteEvent = eventID => apis.getJSON(`${API_PATH}/${eventID}`);

export const sendVote = (eventID, topic, userID) =>
  apis.postJSON(
    `${API_PATH}/${eventID}/vote/${topic}/nominee/${userID}`,
    {},
    {},
    { retries: 0 }
  );
