// @flow
/**
 *
 * AnimatedSprite
 *
 */
import styled, { keyframes } from 'styled-components';
import { webp2xBackgroundCSS } from '@17live/app/utils';

const animationFrame = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
`;

const AnimatedSprite = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  z-index: 0;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.height * props.steps}px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    will-change: transform;
    animation: ${animationFrame} ${props => props.duration}s
      steps(${props => props.steps}, end) infinite;

    ${props => webp2xBackgroundCSS(props.src, { has2xSrc: props.has2xSrc })}
  }
`;

export default AnimatedSprite;
