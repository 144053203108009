import { fromJS } from 'immutable';

import { SnackStatus } from '@17live/app/containers/ChatRoom/components/SubmitChat.constants';
import {
  ANIMATION_STATUS,
  COUNTDOWN_STATUS,
  CURRENT_DURATION_SEC,
  ORIGINAL_DURATION_SEC,
  RESET_SNACK_ANIMATION_INFO,
  SET_SNACK_ANIMATION_INFO,
  SET_SNACK_ANIMATION_STATUS,
  SET_SNACK_COUNTDOWN_STATUS,
  SET_SNACK_INIT_TIMER,
  SET_SNACK_STATUS,
  SNACK_ANIMATION_INFO,
  SNACK_STATUS,
  UPDATE_SNACK_TIMER,
} from '@17live/app/containers/Snack/Snack.constants';
import {
  AnimationStatus,
  CountdownStatus,
} from '@17live/app/enums/Snack.enums';

const getInitialState = () => {
  return fromJS({
    [COUNTDOWN_STATUS]: CountdownStatus.INIT,
    [CURRENT_DURATION_SEC]: 0,
    [ORIGINAL_DURATION_SEC]: 0,
    [ANIMATION_STATUS]: AnimationStatus.INIT,
    [SNACK_STATUS]: SnackStatus.NORMAL,
    [SNACK_ANIMATION_INFO]: { fanExp: 0 },
  });
};

const snackReducer = (state = getInitialState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SNACK_COUNTDOWN_STATUS:
      return state.set(
        COUNTDOWN_STATUS,
        payload?.status || CountdownStatus.INIT
      );

    case UPDATE_SNACK_TIMER:
      return state.set(CURRENT_DURATION_SEC, payload.durationSec || 0);

    case SET_SNACK_INIT_TIMER:
      return state
        .set(CURRENT_DURATION_SEC, payload.durationSec || 0)
        .set(ORIGINAL_DURATION_SEC, payload.durationSec || 0)
        .set(COUNTDOWN_STATUS, CountdownStatus.INIT);

    case SET_SNACK_ANIMATION_STATUS:
      return state.set(
        ANIMATION_STATUS,
        payload.status || AnimationStatus.INIT
      );

    case SET_SNACK_STATUS: {
      return state.set(SNACK_STATUS, payload.status || SnackStatus.NORMAL);
    }

    case SET_SNACK_ANIMATION_INFO:
      return state.set(SNACK_ANIMATION_INFO, payload.animationInfo);

    case RESET_SNACK_ANIMATION_INFO:
      return state.set(
        SNACK_ANIMATION_INFO,
        // reset animationInfo with initial state: [SNACK_ANIMATION_INFO]: { fanExp: 0 }
        getInitialState().get(SNACK_ANIMATION_INFO)
      );

    default:
      return state;
  }
};

export default snackReducer;
