// @flow
import RouteModal from './RouteModal';
import BaseModal from './BaseModal';
import {
  ModalConsumer,
  Portal,
  Background,
  Container,
  Body,
  Wrapper,
  Title,
  Description,
} from './Elements';
import ModalLink, { openModal } from './ModalLink';
import ModalSwitch from './ModalSwitch';
import GenericRoute from './GenericRoute';

export {
  // main exports
  BaseModal,
  RouteModal,
  // elements
  ModalConsumer,
  Portal,
  Background,
  Container,
  Body,
  Wrapper,
  Title,
  Description,
  // helpers
  ModalLink,
  openModal,
  GenericRoute,
  ModalSwitch,
};

export default RouteModal;
