import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { push as routerPush } from 'connected-react-router';
import { LocationState, Path } from 'history';
import { createStructuredSelector } from 'reselect';

import { getPathWithLang } from '@17live/app/components/Link/utils';
import { makeSelectLocation } from '@17live/app/containers/App/selectors';
import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';

const selectors = createStructuredSelector({
  location: makeSelectLocation(),
});

export const useLocation = () => {
  const { location } = useSelector(selectors);
  const dispatch = useDispatch();
  const { lang } = useLocale();

  return {
    location,
    pushWithLang: useCallback(
      (path: Path, state: LocationState = {}) =>
        dispatch(routerPush(getPathWithLang(lang, path), state)),
      [dispatch, lang]
    ),
  };
};
