/**
 * <GenericRoute />
 *
 * A wrapper around react-router's <Route /> component, which behaves similar
 * but with the support of matching location hash.
 *
 * Note that this component only accepts `children` as the render method, as it
 * is essential for react-router-modal to work.
 *
 * @flow
 */
import React, { type ElementProps, type Node } from 'react';
import { Route } from 'react-router-dom';
import { isMatchHash } from './utils';

type RouteProps = ElementProps<typeof Route>;
type RequiredRouteProps = $Diff<
  RouteProps,
  {
    children: *,
    render: *,
    component: *,
  }
>;
type Props = {
  ...RequiredRouteProps,
  id?: string,
  children: (router: *) => Node,
};

const GenericRoute = ({ id, children, render, component, ...props }: Props) => (
  <Route {...props}>
    {router => {
      if (!id) {
        return children(router);
      }

      const isMatch = isMatchHash(router.location.hash, id);

      return children({
        ...router,
        match: isMatch ? Object.assign({}, router.match, { id }) : null,
      });
    }}
  </Route>
);

export default GenericRoute;
