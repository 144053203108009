// @flow
import React, { Component, Fragment } from 'react';
import TrapFocus from './TrapFocus';
import ToggleAriaHidden from './ToggleAriaHidden';
import DisableScroll from './DisableScroll';
import ModalProvider from './ModalProvider';
import OpenStateUpdater from './OpenStateUpdater';
import type { ModalProps } from './types';

class BaseModal extends Component<ModalProps> {
  static defaultProps = {
    // common guess to get the application node
    getApplicationNode: () =>
      document.getElementById('app') || document.getElementById('root'),
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleCloseModalOnESC);

    if (this.props.getApplicationNode) {
      this.app = this.props.getApplicationNode();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleCloseModalOnESC);
  }

  handleCloseModalOnESC = (e: KeyboardEvent) => {
    const {
      isOpen,
      closeModal,
      beforeCloseModal,
      disableCloseOnESC,
    } = this.props;

    if (!disableCloseOnESC && isOpen && e.code === 'Escape' && closeModal) {
      if (beforeCloseModal) {
        beforeCloseModal(e);
      }
      closeModal();
    }
  };

  app: ?HTMLElement = null;

  render() {
    const { enableScroll, isOpen, onOpenStateChanged } = this.props;

    return (
      <Fragment>
        {isOpen && !enableScroll && <DisableScroll />}
        {isOpen && <ToggleAriaHidden app={this.app} />}

        <TrapFocus>
          <OpenStateUpdater
            isOpen={!!isOpen}
            onOpenStateChanged={onOpenStateChanged}
          >
            <ModalProvider {...this.props} />
          </OpenStateUpdater>
        </TrapFocus>
      </Fragment>
    );
  }
}

export default BaseModal;
