export const parseJSON = (response: Response) => {
  return response.text().then(text => (text ? JSON.parse(text) : {}));
};

export const checkStatus = async (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const data = await response.json();
  return Promise.reject({ status: response.status, ...data });
};

/**
 * Because requestIdleCallback is not supported in all browsers,
 * need to make a polyfill for it.
 * @ref https://developers.google.com/web/updates/2015/08/using-requestidlecallback#checking_for_requestidlecallback
 * code @ref https://gist.github.com/paullewis/55efe5d6f05434a96c36#file-requestidlecallback-js-L20-L31
 */
export const handleInitRequestIdleCallback = () => {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function(cb) {
      return setTimeout(function() {
        const start = Date.now();
        cb({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          },
        });
      }, 1);
    };

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function(id) {
      clearTimeout(id);
    };
};
