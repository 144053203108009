import { QueryFunction, useQuery, UseQueryOptions } from 'react-query';

import { fetchAPI, fetchLegacyAPI } from '@17live/app/utils/api';

type MockDataOptions<QueryFnData, Error> = {
  isSuccess: boolean;
  successResult: QueryFunction<QueryFnData>;
  error?: () => Error;
  delay?: number;
};

export const useFetch = <
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData
>(
  queryKey: unknown[],
  fetchParams: RequestInit = {},
  useQueryOptions: UseQueryOptions<QueryFnData, Error, Data> = {}
) =>
  useQuery<QueryFnData, Error, Data>(
    queryKey,
    ({ queryKey: [endpoint] }) => fetchAPI(endpoint, fetchParams),
    useQueryOptions
  );

export const useFetchWithMockData = <
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData
>(
  queryKey: unknown[],
  mockDataOptions: MockDataOptions<QueryFnData, Error>,
  useQueryOptions: UseQueryOptions<QueryFnData, Error, Data> = {}
) =>
  useQuery<QueryFnData, Error, Data>(
    queryKey,
    (...args) =>
      new Promise((resolve, reject) =>
        setTimeout(
          () =>
            mockDataOptions.isSuccess
              ? resolve(mockDataOptions.successResult(...args))
              : reject(mockDataOptions.error),
          mockDataOptions.delay || 0
        )
      ),
    useQueryOptions
  );

export const useFetchLegacy = <
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData
>(
  queryKey: unknown[],
  actionData: { action: string } & Record<string, string>,
  useQueryOptions: UseQueryOptions<QueryFnData, Error, Data> = {}
) =>
  useQuery<QueryFnData, Error, Data>({
    queryKey,
    queryFn: () => fetchLegacyAPI(actionData),
    ...useQueryOptions,
  });
