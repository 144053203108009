/**
 * Army Rank
 * BE defs: https://github.com/17media/api/blob/master/models/army/army.go#L47-L80
 * 等級順序由小到大 5 > 1 > 2 > 3 > 4
 *
 * BE 有定義兩種版本的 ArmyRank，且都有在使用，因次在串接 API 上時，需要特別注意是用哪個版本？
 */
export enum ArmyRank {
  UNSUBSCRIBE = 0,
  /** Sergeant (士官長) */
  SERGEANT = 1,
  /** Captain (上尉) */
  CAPTAIN = 2,
  /** Colonel (上校) */
  COLONEL = 3,
  /** General (上將) */
  GENERAL = 4,
  /** Corporal (下士) */
  CORPORAL = 5,
}

/**
 * Army Rank Sorted
 * BE defs: https://github.com/17media/api/blob/master/models/army/army.go#L84-L91
 * 等級順序由小到大 1 > 2 > 3 > 4 > 5
 *
 * BE 有定義兩種版本的 ArmyRank，且都有在使用，因次在串接 API 上時，需要特別注意是用哪個版本？
 */
export enum ArmyRankSorted {
  UNSUBSCRIBE = 0,
  /** Corporal (下士) */
  CORPORAL = 1,
  /** Sergeant (士官長) */
  SERGEANT = 2,
  /** Captain (上尉) */
  CAPTAIN = 3,
  /** Colonel (上校) */
  COLONEL = 4,
  /** General (上將) */
  GENERAL = 5,
}

/**
 * Army periods
 */
export enum ArmyPlanPeriod {
  PERIOD_1_MONTH = 'P30D',
  PERIOD_3_MONTH = 'P90D',
  PERIOD_6_MONTH = 'P180D',
}

/**
 * Army subscription state
 * BE defs: https://github.com/17media/api/blob/master/models/army/army.go#L132-L159
 * state defs: https://17media.slack.com/archives/C044LFYF2UX/p1668141170378519?thread_ts=1668078353.981759&cid=C044LFYF2UX
 */
export enum ArmySubscriptionState {
  /** 不應該出現的預設值 */
  UNKNOWN = 0,
  /** 效期中, 且會續訂 */
  SUBSCRIBE = 1,
  /** 效期中, 但是因為用戶取消續訂, 或者嘗試續訂失敗, 導致不會續訂下一期資格 */
  TERMINATE = 2,
  /**
   * 非續訂成功並且效期到期的情況
   * A資格 upgrade 成 B 資格以後, 將 A 資格標為 REVOKE 代表失效
   */
  REVOKE = 3,
  /**
   * 使用 slackbot refund 會進入這個狀態
   * ref: https://17media.slack.com/archives/C029G407FKQ/p1682653969270429
   */
  REFUND = 4,
  /** 當前一期A續訂成功產生下一期B, 把 A 標為 DELETED */
  DELETED = 5,
}

/**
 * Army pay vendor (platform)
 * BE defs: https://github.com/17media/api/blob/master/models/pay/pay.go#L117-L215
 */
export enum ArmyPayVendor {
  UNKNOWN = 0,
  IOS = 1,
  ANDROID = 2,
  GMO = 9,
  BABYCOIN = 26, // BE naming: Army_BabyCoin
  ECPAY = 27,
  NEWEBPAY = 30,
}

/**
 * Army pay channel
 * BE defs: https://github.com/17media/api/blob/8bcfdd5e8addd010eaa6b0ac704917da11113318/stores/army/impl.go#L467-L479
 */
export enum ArmyPayChannel {
  Android = 'Android',
  iOS = 'iOS',
  Web = 'Web',
  Gift = 'Gift',
}

export enum ArmyPayType {
  CreditCard = 'CreditCard',
  BabyCoins = 'BabyCoins',
}

/**
 * refs: https://17media.atlassian.net/wiki/spaces/SABE/pages/1023901700/API+Spec+Release+1+-+New+Subscription+System#1-3-1-1-%E9%80%B2%E5%85%A5%E7%9B%B4%E6%92%AD%E9%96%93%E5%89%8D%E6%AA%A2%E6%A0%B8
 */
export enum PremiumContentArmyInfoStatus {
  /** 白名單可進入直播間 */
  IN_WHITELIST = 0,
  /** 可進入直播間 */
  ELIGIBLE = 1,
  /** 非訂閱用戶 */
  NOT_SUBSCRIBE = 2,
  /** 未達最低要求等級 */
  NOT_REACH_LEVEL = 3,
}

export enum ArmySubscriptionLevelI18nKey {
  ALL_LEVEL = 'army_only_stream_level_setting_all_level',
  LEVEL = 'army_only_stream_level_setting_level',
  TOP_LEVEL = 'army_only_stream_level_setting_top_level',
}
