// @flow

import { of } from 'rxjs';

import { ErrorStatusCode } from '@17live/app/enums/ErrorStatus.enums';

function errorHandler(err: any) {
  const { status } = err;

  // service overload
  if (status === ErrorStatusCode.STATUS_SYSTEM_THROTTLING) {
    throw err;
  }

  // reach rate limit
  if (status === ErrorStatusCode.STATUS_RATE_LIMITING) {
    throw err;
  }

  const errorResponse = err.xhr && err.xhr.response;

  if (
    status === ErrorStatusCode.STATUS_CLIENT_ERROR &&
    errorResponse.displayErrMsg
  ) {
    /**
     * handle api response 420 with displayErrorMessage
     */
    const errorPayload = {
      status,
      ...errorResponse,
    };

    throw errorPayload;
  } else if (errorResponse) {
    /**
     * try to handle api response failed with data: '{ result: "fail", message: "<errorCode>" }'
     */
    return of({
      status,
      ...errorResponse,
    });
  }

  throw errorResponse;
}

export default errorHandler;
