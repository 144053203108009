/**
 * @see https://github.com/17media/api/blob/master/models/livestream.go#L2314-L2334
 */
export enum RtmpProviderValue {
  DEFAULT = 0,
  UCLOUD = 1,
  QINIU = 2,
  QCLOUD = 3,
  WANSU = 4,
  WANSU_LOW_LATENCY = 5,
  WANSU_SPECIFIED_IP = 6,
  SRS = 7,
  CHT = 8,
  AWS = 9,
  QINIU_AUTH = 10,
  WANSU_AUTH = 11,

  /**
   * @alias S+
   */
  LIVE17 = 12,

  WANSU_CDN = 13,
  GOOGLE_CDN = 14,
  AKAMAI_CDN = 15,
  CLOUDFRONT_CDN = 16,
  TENCENT = 17,
  LIVE17_AUTH = 18,
}

export enum LiveStreamInfoConsumer {
  CONCERT = 0,
  FOLLOW,
  GENERAL,
  LIVE,
  RTMP,
}

export enum LiveStreamInfoRequestTag {
  INITIAL = 0,
}

export enum LiveStreamEndReason {
  KillBySkyeye = 'Killed',
  CloseByUser = 'normalEnd',
}
