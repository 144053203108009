// @flow
import {
  matchPath,
  type Location,
  type LocationShape,
  type Match,
} from 'react-router-dom';
import type { MatchOptionsType } from './types';

export const isMatchHash = (hash: string, id: string) => hash === `#${id}`;

export const matchModal = (
  location: Location,
  { id, ...matchOptions }: MatchOptionsType & { id?: string }
): Match | (Match & { id: string }) | null => {
  const pathMatched = matchPath(location.pathname, matchOptions);

  if (!id) {
    return pathMatched;
  }

  const isMatched = isMatchHash(location.hash, id);

  return isMatched
    ? {
      ...(pathMatched || {}),
      id,
    }
    : pathMatched;
};

const normalizeLocation = (
  location: LocationShape | Location | string
): string => {
  if (typeof location === 'string') {
    return location;
  }

  return `${String(location.pathname)}${String(location.search)}${String(
    location.hash
  )}`;
};

export const isShallowEqualLocation = (
  locationA: LocationShape | Location | string,
  locationB: LocationShape | Location | string
): boolean => normalizeLocation(locationA) === normalizeLocation(locationB);
