import { useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime as RxDebounceTime } from 'rxjs/operators';

export const useScroll = (
  { debounceTime = 0, bottomThreshold = 0 } = {},
  target = window
) => {
  const [scroll, setScroll] = useState({ x: 0, y: 0, isBottom: false });

  useEffect(() => {
    const scroll$ = fromEvent(target, 'scroll', { passive: true })
      .pipe(RxDebounceTime(debounceTime))
      .subscribe(() => {
        setScroll({
          x: window.scrollX,
          y: window.scrollY,
          isBottom:
            window.scrollY >=
            document.documentElement.scrollHeight -
              document.documentElement.offsetHeight -
              bottomThreshold,
        });
      });

    return () => {
      scroll$.unsubscribe();
    };
  }, [debounceTime, bottomThreshold]);

  return scroll;
};
