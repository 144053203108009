import { API_PATH } from './constants';
import apis from '../shared/apis';

// error codes for bidGuardian
export const ERROR_CODE_GUARDIAN_PRICE_TOO_LOW = 15;
export const ERROR_CODE_GUARDIAN_BALANCE_NOT_ENOUGH = 16;

export const getGuardianInfo = userID => apis.getJSON(`${API_PATH}/${userID}/guardian`);

export const bidGuardian = (userID, bidPrice) => apis.postJSON(`${API_PATH}/${userID}/guardian/bid`, { bidPrice });
