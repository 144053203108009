import getIsDevToolsEnabled from '@/services/DevTools/getIsDevToolsEnabled';
import { KEY_DEV_BACKEND_SERVER, SessionStorage } from '@/services/Storage';

import {
  ADMIN_API_HOST,
  API_VERSION,
  API_WAP_HOST,
  EVENT_API_HOST,
} from '@17live/app/constants/hosts';
import { PerimeterXAPPID } from '@17live/app/constants/thirdParty';
import { getIsSettingsLivePath } from '@17live/app/utils/path';

const configs = {
  get url() {
    const apiURL = `https://${API_WAP_HOST}`;

    if (getIsDevToolsEnabled()) {
      const { url } = SessionStorage.getItem(KEY_DEV_BACKEND_SERVER) || {};

      return url || apiURL;
    }

    return apiURL;
  },
  version: API_VERSION,
  device: {
    deviceType: 'WEB',
    version: process.env.VERSION || 'development',
    deviceID: null,
  },
  options: {
    get isNeedNoCache() {
      return getIsSettingsLivePath(location.pathname);
    },
  },
};

export default configs;

export const event = {
  get url() {
    const apiURL = `https://${EVENT_API_HOST}`;

    return apiURL;
  },
};

export const admin = {
  get url() {
    return `https://${ADMIN_API_HOST}/${API_VERSION}`;
  },
};

export const log = {
  get url() {
    return process.env.LOG_URL || 'https://log-collector.17app.co/frontend';
  },
};

export const px = {
  get appID() {
    return PerimeterXAPPID;
  },

  get appIDWithoutPX() {
    return px.appID.replace(/^PX/, '');
  },
};
