import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';

import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/types';
import { LOG_LEVELS } from './constants';
import fluentd from './fluentd';

const log = (
  level: typeof LOG_LEVELS | Sentry.Severity,
  label: string,
  message: string,
  additionalInfo = {}
) => {
  fluentd({
    level,
    label,
    message,
    additionalInfo: {
      version: process.env.VERSION,
      path: window.location.pathname,
      userID: (LocalStorage.getItem(KEY_LOGIN_INFO) || {}).userID,
      ...additionalInfo,
    },
  });

  if (
    level === ('fatal' as SeverityLevel) ||
    level === ('error' as SeverityLevel)
  ) {
    Sentry.captureMessage(message, level);
  }
};

const Logger = { log };

Object.values(LOG_LEVELS).forEach(level => {
  Logger[level] = (...params) => {
    // don't send log in development mode (already have console)
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      return console[level](...params);
    }

    return log(level, ...params);
  };
});

export default Logger;
