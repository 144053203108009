// @flow
import { isCollection, Map } from 'immutable-v4';
import { parse } from 'query-string';

import type { SimpleUserInfo, UserInfo } from '@/data/userInfo';
import transformURLToHttps from '@/utils/transformURLToHttps';

import {
  VIDEO_TYPES,
  VIEWING_STATUS,
  VOD_PLAY_HLS_DURATION_THRESHOLD,
} from './constants';
import type {
  CustomAttrs,
  LiveStream,
  Video,
  VideoInfo,
  VideoTypes,
  VOD,
} from './types';

const { vodPreference } = parse(window.location.search);

// not sure why sometimes userInfo may not be Immutable.Map
const getSafeUserInfo = (video: Map<Video>): Map<SimpleUserInfo | UserInfo> => {
  const userInfo = video.get('userInfo') || Map();

  return isCollection(userInfo) ? userInfo : Map(userInfo);
};

export const getIsLiveStream = (videoType: VideoTypes): boolean =>
  videoType === VIDEO_TYPES.STREAM;

const getCustomAttrs = (video: Map<Video>): CustomAttrs => {
  const videoType = video.get('videoType');
  const viewingStatus = video.get('viewingStatus');
  const isLiveStream = getIsLiveStream(videoType);
  const newSalaryAndRemunerationInfo = video.get(
    'newSalaryAndRemunerationInfo'
  );
  return {
    videoType,
    viewingStatus,
    newSalaryAndRemunerationInfo: newSalaryAndRemunerationInfo?.toJS() || {
      featureEnabled: false,
    },
    isLiveStream,
    isBlocked: viewingStatus === VIEWING_STATUS.BLOCKED,
    isLoaded: viewingStatus > VIEWING_STATUS.NONE,
    isAvailable: viewingStatus === VIEWING_STATUS.AVAILABLE,
  };
};

export const getLiveStreamInfo = (
  liveStream: Map<LiveStream>
): VideoInfo & CustomAttrs => {
  const userInfo = getSafeUserInfo(liveStream);

  return {
    // required attribute
    url: liveStream.getIn(['rtmpUrls', 0, 'webUrl']),
    provider: liveStream.getIn(['rtmpUrls', 0, 'provider']),
    picture: liveStream.getIn(['userInfo', 'picture']),
    openID: userInfo.get('openID'),
    userID: userInfo.get('userID'),
    title: liveStream.get('caption') || userInfo.get('name'),
    duration: liveStream.get('duration'),
    isLandscape: liveStream.get('landscape'),
    isOnline: liveStream.get('isOnline'),
    thumbnail: liveStream.get('thumbnail'),
    userInfo,

    // optional attribute
    roomID: String(
      liveStream.get('roomID') ||
        liveStream.get('liveStreamID') ||
        userInfo.get('roomID')
    ),
    streamID: liveStream.get('streamID'),
    achievementValue: liveStream.get('achievementValue'),
    isTV: userInfo.has('programInfo'),
    streamerType: liveStream.get('streamerType'),
    beginTime: liveStream.get('beginTime'),
    endTime: liveStream.get('endTime'),
    coverPhoto: transformURLToHttps(liveStream.get('coverPhoto')),
    description: userInfo.get('bio', ''),
    isLoaded: liveStream.get('isLoaded'),
    hashtags: liveStream.get('hashtags'),
    premiumContent: liveStream.get('premiumContent'),
    isPK: liveStream.has('pkInfo'),
    redEnvelopeEventInfo: liveStream.get('redEnvelopeEventInfo'),
    concertVodStartTimestamp: liveStream.get('concertVodStartTimestamp'),
    concertVodEndTimestamp: liveStream.get('concertVodEndTimestamp'),

    // 17live custom attribute
    ...getCustomAttrs(liveStream),
  };
};

export const getVODInfo = (vod: Map<VOD>): VideoInfo => {
  const duration = vod.get('duration');
  let url = vod.get('videoReencode1280') || vod.get('vodURL');
  /**
   * only load mp4 when the video duration is under the threshold for fixing
   * the network pending when the video is too large and loaded for the first time.
   * Unless explicitly set preference for desired player in query-string (for debug purpose)
   */
  if (vodPreference === 'mp4') {
    url = vod.get('videoReencode1280') || url;
  } else if (
    vodPreference === 'hls' ||
    duration >= VOD_PLAY_HLS_DURATION_THRESHOLD
  ) {
    url = vod.get('vodURL');
  }

  const userInfo = getSafeUserInfo(vod);
  // $FlowFixMe
  return {
    // required attribute
    url: transformURLToHttps(url),
    picture: transformURLToHttps(vod.get('imageURL')),
    title: vod.get('title'),
    openID: vod.getIn(['userInfo', 'openID']),
    duration: vod.get('duration'),
    isLandscape: true,
    isOnline: true,
    userInfo,
    roomID: String(userInfo.get('roomID')),
    // optional attribute
    vodID: vod.get('vodID'),
    createdAt: vod.get('createdAt'),
    isTV: true,
    description: vod.get('description', ''),

    // 17live custom attribute
    ...getCustomAttrs(vod),
  };
};
