import { QueryClient } from 'react-query';

import { RETRIES } from '@/services/17App/shared/constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: RETRIES,
      retryDelay: attempt => attempt * 500,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});
