/*
 * ErrorBlock Messages
 *
 * This contains all the text for the ErrorBlock component.
 */
import defineMessages from 'define-messages';

export const somethingWrong = defineMessages({
  id: 'app.components.ErrorBlock.somethingWrong',
  defaultMessage: 'Something went wrong...',
});

export const refresh = defineMessages({
  id: 'app.components.ErrorBlock.refresh',
  defaultMessage: 'refresh',
});
