import React from 'react';

import styled, { css } from 'styled-components';

import { ModalConsumer } from '@/components/ReactRouteModal';
import { mobileCSS } from '@/utils/media';
import { gradient } from '@/utils/styles';

export const fullPageStyle = mobileCSS`
  position: relative;
  left: 0;
  width: 100%;
  flex-grow: 1;
  justify-content: flex-start;
  border-radius: 0;
  border: 0;

  &:after {
    border-radius: 0;
  }
`;

const BodyForm = styled.form`
  ${props => props.styles};

  ${mobileCSS`
    max-width: none;
    width: 100%;
  `};
`;

export const gradientStyle = css`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-image: ${gradient.left()};
  }
`;

const ModalBody = styled(
  ({ hasGradient, onOpenStateChanged, customBodyStyle, ...props }) => (
    <ModalConsumer>
      {({ getBodyProps, getBodyStyles }) => (
        <BodyForm
          {...getBodyProps({
            ...props,
            styles: getBodyStyles(),
            style: customBodyStyle,
          })}
        />
      )}
    </ModalConsumer>
  )
)`
  ${props => props.hasGradient && gradientStyle};
`;

export default ModalBody;
