import { ActionsObservable, ofType } from 'redux-observable';

import { Map } from 'immutable';
import { concat, of } from 'rxjs';
import { concatMap, delayWhen, filter, takeUntil } from 'rxjs/operators';

import { FOLLOW_STATUS } from '@/containers/FollowButton/constants';
import { CLOSE_MODAL } from '@/containers/Modal/constants';
import { PRIVACY_MODE } from '@/containers/UserProvider/constants';
import { isUserPrivate } from '@/containers/UserProvider/utils';
import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';

import { LOGGED_IN, LOGIN_SUCCESS } from './constants';

export const getIsLoggedIn = (loginInfo: Map<any, any>): boolean =>
  loginInfo && loginInfo.get('status') === LOGGED_IN;

export const getUserRegion = (userInfo: { [key: string]: any }) =>
  (userInfo.contract && userInfo.contract.region) || userInfo.region;

export const convertToUserFollowingStatus = (
  isFollowing: number,
  followRequestTime: number,
  privacyMode: keyof typeof PRIVACY_MODE
) => {
  if (isFollowing) {
    return FOLLOW_STATUS.FOLLOWING;
  } else if (followRequestTime && isUserPrivate(privacyMode)) {
    return FOLLOW_STATUS.REQUESTING;
  }

  return FOLLOW_STATUS.NONE;
};

export const isGuest = (): boolean => !LocalStorage.getItem(KEY_LOGIN_INFO);

export const isUser = (): boolean => !!LocalStorage.getItem(KEY_LOGIN_INFO);

export const withLoginThen = (
  actionType: string,
  getFallbackAction: (payload: {}) => {
    type: string;
    payload: {};
  }
) => (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType(actionType),
    concatMap(({ payload }) => {
      if (isUser()) {
        // if already login, dispatch the original action
        return of(payload.action);
      } else if (payload.shouldContinue) {
        // should continue the original action
        return concat(
          // not logged in, open the login modal
          of(getFallbackAction(payload)),
          // Once logged in, dispatch the original action
          of(payload.action).pipe(
            // wait for the login success
            // $FlowFixMe: no idea why flow is missing `delayWhen`
            delayWhen(() => action$.pipe(ofType(LOGIN_SUCCESS))),
            // drop when the user cancel and close the login modal
            takeUntil(
              action$.pipe(
                ofType(CLOSE_MODAL),
                filter(() => isGuest())
              )
            )
          )
        );
      }

      // should not continue the original action, only open the login modal
      return of(getFallbackAction(payload));
    })
  );
