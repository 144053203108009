import { SettingsRoutePath } from '@17live/app/constants/routePath';

export const getIsSettingsLivePath = (pathname: string) =>
  new RegExp(SettingsRoutePath.LIVE, 'i').test(pathname);

/**
 * 檢查 url domain 是否在 whitelist 中
 * match 判斷同 server/utils/index.js checkUrlInArray
 */
export const checkUrlInArray = (url: string, array: string[]) => {
  if (url) {
    // 使用正則表達式提取 https:// 或 http:// 到第一個 / 之間的部分
    /* eslint-disable no-useless-escape */
    const match = url.match(/^https?:\/\/([^\/]+)(\/|$)/);
    // 如果正則表達式匹配成功並且匹配的部分存在於給定的字串陣列中，返回 true，否則返回 false
    return match && array.includes(match[1]);
  }
  return false;
};
