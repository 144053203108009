/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { DEFAULT_LANG, LANG } from '@17live/app/constants';

import enTranslationMessages from './translations/en.json';
import zhTWTranslationMessages from './translations/zh-Hant.json';

export const formatTranslationMessages = (lang, messages) => {
  const defaultFormattedMessages =
    lang !== DEFAULT_LANG
      ? formatTranslationMessages(DEFAULT_LANG, enTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && lang !== DEFAULT_LANG) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

// default set of languages
export const translationMessages = {
  [LANG.EN]: formatTranslationMessages(LANG.EN, enTranslationMessages),
  [LANG.ZH_HANT]: formatTranslationMessages(
    LANG.ZH_HANT,
    zhTWTranslationMessages
  ),
};
