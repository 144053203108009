import { SIZE_LARGE, SIZE_MEDIUM, SIZE_SMALL } from '@/constants/sizes';

/**
 * actions
 */
export const INIT_APP: string = 'app/App/INIT_APP';
export const GET_CONFIG: string = 'app/App/GET_CONFIG';
export const GET_PERSONAL_CONFIG: string = 'app/App/GET_PERSONAL_CONFIG';
export const PUSH_ROUTER: string = 'app/App/PUSH_ROUTER';
export const CHANGE_ROUTER = 'app/App/CHANGE_ROUTER';

/**
 * styles
 */
export const CONTAINER_WIDTH: number = 1100; // px
export const CONTAINER_SIZE_MAX_WIDTH: number = 1260; // px
export const CONTAINER_SIZES = {
  [SIZE_SMALL]: {
    width: 1016, // px
  },
  [SIZE_MEDIUM]: {
    width: 1260, // px
  },
  [SIZE_LARGE]: {
    width: 1920, // px
  },
};
